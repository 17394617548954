import { report } from "../models";
import { localization } from "../settings";

const defaultFields: report.Field[] = [{
	id: 'transactionId',
	name: localization.reports_page_field_transactionId_text,
	type: 'default'
},{
	id: 'operationId',
	name: localization.reports_page_field_operationId_text,
	type: 'default'
},{
	id: 'node',
	name: localization.reports_page_field_node_text,
	type: 'default'
},{
	id: 'correspondentNode',
	name: localization.reports_page_field_correspondent_text,
	type: 'default'
},{
	id: 'author',
	name: localization.reports_page_field_author_text,
	type: 'default'
},{
	id: 'transactionType',
	name: localization.reports_page_field_transaction_type_text,
	type: 'default'
},{
	id: 'conversion',
	name: localization.reports_page_field_conversion_text,
	type: 'default'
},{
	id: 'comment',
	name: localization.reports_page_field_comment_text,
	type: 'default'
},{
	id: 'createdAt.year',
	name: localization.reports_page_field_created_at_year_text,
	type: 'default'
},{
	id: 'createdAt.month',
	name: localization.reports_page_field_created_at_month_text,
	type: 'default'
},{
	id: 'createdAt.day',
	name: localization.reports_page_field_created_at_day_text,
	type: 'default'
},{
	id: 'customDate.year',
	name: localization.reports_page_field_custom_date_year_text,
	type: 'default'
},{
	id: 'customDate.month',
	name: localization.reports_page_field_custom_date_month_text,
	type: 'default'
},{
	id: 'customDate.day',
	name: localization.reports_page_field_custom_date_day_text,
	type: 'default'
}];

export default defaultFields;