import React, { memo } from 'react';

import { BalanceAttribute, BalanceAttributeType } from '../../../models';
import { slugs } from '../../../settings';
import RadioBalanceAttribute from './RadioBalanceAttribute';
import SelectBalanceAttribute from './SelectBalanceAttribute';
import TextBalanceAttribute from './TextBalanceAttribute';

import './RadioBalanceAttribute.scss';

export interface BalanceAttributeViewProps{
	className?: string,
	path: string[],

	balanceAttribute: BalanceAttribute,
	balanceAttributeTypes: BalanceAttributeType[]
}

const BalanceAttributeView = (props: BalanceAttributeViewProps) => {
	const {
		className, path,
		balanceAttribute, balanceAttributeTypes
	} = props;
	const typeSlug = balanceAttributeTypes.find(bat => bat.id === balanceAttribute.typeId)?.slug;
	switch(typeSlug){
		case slugs.balanceAttribute_type.radio:
			return (
				<RadioBalanceAttribute
					className = {className}
					path = {path}
					balanceAttribute = {balanceAttribute}
				/>
			)
		case slugs.balanceAttribute_type.select:
			return (
				<SelectBalanceAttribute
					className = {className}
					path = {path}
					balanceAttribute = {balanceAttribute}
				/>
			)
		default:
			return (
				<TextBalanceAttribute
					className = {className}
					path = {path}
					balanceAttribute = {balanceAttribute}
				/>
			)
	}
}

export default memo(BalanceAttributeView);