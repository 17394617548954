import { connect } from 'react-redux'

import actions from '../../actions'
import { BalanceAttributeInfo, CommonPageProps } from '../../models';
import { State } from '../../reducers';

import Balances from './Balances';

import './Balances.scss';
import './BalancesList.scss';

const mapStateToProps = (state: State, _: CommonPageProps) => {
	return {
		balancesPerTree: state.balances,
		treesList: state.trees.list,
		isTreesListLoading: state.trees.isLoading,
		balanceTypes: state.balanceTypes.list,
		isBalanceTypesLoading: state.balanceTypes.isLoading,
		balanceLimits: state.balanceLimits.list,
		isBalanceLimitsLoading: state.balanceLimits.isLoading,
		balanceAttributes: state.balanceAttributes,
		balanceAttributeTypes: state.balanceAttributeTypes,
		token: state.auth.token,
		nodesInfo: state.nodes
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadTrees: (token: string) =>
		dispatch(actions.trees.load(token)),
	unloadTree: (treeId: number) =>
		dispatch(actions.tree.unload(`${treeId}`)),
	loadBalances: (treeId: number, token: string) =>
		dispatch(actions.balances.load(treeId, token)),
	loadBalanceTypes: (token: string) => 
		dispatch(actions.balanceTypes.load(token)),
	loadBalanceLimits: (token: string) =>
		dispatch(actions.balanceLimits.load(token)),
	loadBalanceAttributes: (balanceId: number, treeId: number, token: string) => 
		dispatch(actions.balanceAttributes.load(balanceId, treeId, token)),
	loadBalanceAttributeTypes: (token: string) => 
		dispatch(actions.balanceAttributeTypes.load(token)),
	createBalance: (name: string, typeId: number, creditLimitId: number | null, limit: number | null, treeId: number, token: string) =>
		dispatch(actions.balance.create({name, typeId, creditLimitId, limit}, treeId, token)),
	updateBalance: (balanceId: number, balance: {name?: string, creditLimitId?: number, limit?: number | null}, treeId: number, token: string) => 
		dispatch(actions.balance.update(balanceId, balance, treeId, token)),
	deleteBalance: (balanceId: number, treeId: number, token: string) => 
		dispatch(actions.balance.delete(balanceId, treeId, token)),
	reorderBalances: (orderedIds: {id: number, order: number}[], treeId: number, token: string) => 
		dispatch(actions.balance.order(orderedIds, treeId, token)),
	createBalanceAttribute: (info: BalanceAttributeInfo, balanceId: number, treeId: number, token: string) =>
		dispatch(actions.balanceAttributes.create({name: info.name, typeId: info.typeId, isRequired: info.isRequired}, balanceId, treeId, token)),
	updateBalanceAttribute: (info: BalanceAttributeInfo, balanceId: number, treeId: number, token: string) =>
		info.id && dispatch(actions.balanceAttributes.update({id: info.id, name: info.name, typeId: info.typeId, isRequired: info.isRequired}, balanceId, treeId, token)),
	deleteBalanceAttribute: (balanceAttributeId: number, balanceId: number, treeId: number, token: string) =>
		dispatch(actions.balanceAttributes.delete(balanceAttributeId, balanceId, treeId, token)),
	orderBalanceAttributes: (idsWithOrder: {id: number, order: number}[], balanceId: number, treeId: number, token: string) => 
		dispatch(actions.balanceAttributes.order(idsWithOrder, balanceId, treeId, token)),
	createBalanceAttributeValue: (name: string, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => 
		dispatch(actions.balanceAttributeValue.create(name, balanceAttributeId, balanceId, treeId, token)),
	deleteBalanceAttributeValue: (id: number, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => 
		dispatch(actions.balanceAttributeValue.delete(id, balanceAttributeId, balanceId, treeId, token)),
	orderBalanceAttributeValues: (idsWithOrder: {id: number, order: number}[], balanceAttributeId: number, balanceId: number, treeId: number, token: string) =>
		dispatch(actions.balanceAttributeValue.order(idsWithOrder, balanceAttributeId, balanceId, treeId, token)),
	resetTree: (id: number) =>
		dispatch(actions.tree.reset(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Balances)