import React from 'react';
import { X } from 'react-feather';

import history from '../../history';

interface Tab {
	id?: React.ReactText,
	title?: string | null,
	url?: string | null,
	onClose?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

interface TabsProps{
	tabs?: Tab[] | null,
	activeTabId?: React.ReactText,
	className?: string | null
}

const containerClassName = 'tabs';

const renderTab = (tab: Tab, activeTabId?: React.ReactText) => {
	const isActive = tab.id === activeTabId;
	const effectiveClassName = isActive ? `${containerClassName}__tab active` : `${containerClassName}__tab`;
	return (
		<div
			className = {effectiveClassName}
			key = {`tab__${tab.id}`}
			onClick = {() => tab.url && !isActive && history.push(tab.url)}
		>
			<span className={`${containerClassName}__tab__content`}>
				{tab.title}
			</span>
			<span className={`${containerClassName}__tab__close`} onClick={tab.onClose}>
				<X/>
			</span>
		</div>
	);
}

const Tabs = (props: TabsProps): JSX.Element | null => {
	const {className, tabs, activeTabId} = props;
	const effectiveClassName = className ? `${containerClassName} ${className}`: containerClassName;

	const tabElements = tabs?.map(t => renderTab(t, activeTabId))

	return (
		<div className = {effectiveClassName}>
			{tabElements}
		</div>
	);
}

export default Tabs;