import actions from '../actions';
import { SingleEntityPerTree, TransactionsTableState } from '../models';

export interface TransactionsContainerStates extends SingleEntityPerTree<TransactionsTableState>{
}

const defaultState: TransactionsContainerStates = {
}

const transactionsContainerStates = (state: TransactionsContainerStates = defaultState, action: any): TransactionsContainerStates => {
	switch(action.type){
		case actions.transactionsContainerState.types.TRANSACTIONS_CONTAINER_STATE_SAVE: {
			const {treeId, transactionsState} = action.payload;
			return {
				...state,
				[treeId]: {
					entity: transactionsState,
					error: null,
					isLoading: false
				}
			}
		}

		case actions.transactions.types.TRANSACTIONS_UNLOAD: {
			const {id} = action.payload;
			const treeId = parseInt(id);
			const updatedState = {
				...state
			};
			delete updatedState[treeId];
			return updatedState;
		}
	}
	return state;
}

export default transactionsContainerStates;