import React, { memo, useState } from 'react';
import {Input as AntdInput} from 'antd';

interface InputProps{
	className?: string;
	isEditable?: boolean;
	emptyText?: string;

	children?: string | null;
	onChange?: (text: string | null) => void
}

const containerClassName = 'bra-transaction-input';

const renderAsText = (text?: string | null, emptyText?: string) => {
	if(!text){
		return (
			<span className = {`${containerClassName}__content-empty`}>
				{emptyText}
			</span>
		)
	}
	return (
		<AntdInput
			className = {`${containerClassName}__content-text`}
			value = {text}
			readOnly
			bordered = {false}
			title = {text}
		/>
	);
}

const Input = (props: InputProps) => {
	const {
		className,
		isEditable, emptyText,
		children,
		onChange
	} = props;

	const [text, setText] = useState(children);
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
	}

	const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter'){
			onChange && onChange(text || null);
			const target = e.target as any;
			if('blur' in target && typeof target.blur === 'function'){
				target.blur();
			}
		}
	}

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	const contentElement = isEditable ? (
		<AntdInput
			value = {text || undefined}
			onChange = {onChangeHandler}
			onKeyPress = {onKeyPressHandler}
		/>
	) : renderAsText(text, emptyText);

	return (
		<div
			className = {effectiveClassName}
		>
			{contentElement}
		</div>
	)
}

export default memo(Input);