import React, { Key } from 'react';
import { Moment } from 'moment';
import { DatePicker, Popover, Select, Tag, Tree } from 'antd';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { ChevronDown, ChevronLeft, ChevronRight, RefreshCw, Save, X } from 'react-feather';

import { report, Report} from '../../models';
import { localization } from '../../settings';
import { Button, ConfirmDelete, SetNameDialog } from '../../components';
import { rangePickerRanges } from '../../tools';

const { RangePicker } = DatePicker;

interface PivotReportOptionsProps{
	className?: string,

	nodeId: number,
	token: string,

	isCollapsed: boolean,
	setIsCollapsed: (value: boolean) => void,

	templates: report.Template[] | null,
	applyTemplate: (nodeId: number, template: report.Template) => void,
	saveTemplate: (nodeId: number, template: report.TemplateWithoutId, token: string) => void,
	deleteTemplate: (nodeId: number, id: number, token: string) => void,

	report: Report | null,
	updateReport: (options?: {dates?: [Moment, Moment], type?: report.ReportDateType}) => void,
	updateReportField: (nodeId: number, fieldId: string, update: report.ReportFieldUpdate) => void,
	doExport: () => void;
}

const containerClassName = 'pivot-report-options';

class PivotReportOptions extends React.Component<PivotReportOptionsProps>{
	getFieldsAsDataNodes = (isMeasure?: boolean): DataNode[] => {
		const {report} = this.props;
		const reportFields = (report?.fields || []);
		const result: DataNode[] = reportFields
			.filter(f => (f.type === 'balance' || f.type === 'calculatedBalance') === (isMeasure))
			.filter(f => f.type !== 'balanceAttribute' && f.type !== 'nodeAttribute')
			.map(f => ({
				key: f.id,
				title: f.name,
				disabled: f.readonly,
				checkable: true,
			}));

		if(!isMeasure){
			const balanceNodeAttributes: DataNode = {
				key: 'balanceAttributes.node',
				title: `${localization.reports_page_balance_attributes_folder_name} ${localization.reports_page_node_postfix}`,
				children: reportFields
					.filter(f => f.type === 'balanceAttribute' && f.relates === 'node')
					.map(f => ({
						key: f.id,
						title: f.name,
						// disabled: f.readonly,
						checkable: true,
					}))
			}
			const balanceCorrespondentAttributes: DataNode = {
				key: 'balanceAttributes.correspondent',
				title: `${localization.reports_page_balance_attributes_folder_name} ${localization.reports_page_correspondent_postfix}`,
				children: reportFields
					.filter(f => f.type === 'balanceAttribute' && f.relates === 'correspondent')
					.map(f => ({
						key: f.id,
						title: f.name,
						// disabled: f.readonly,
						checkable: true,
					}))
			}
			const nodeNodeAttributes: DataNode = {
				key: 'nodeAttributes.node',
				title: `${localization.reports_page_node_attributes_folder_name} ${localization.reports_page_node_postfix}`,
				children: reportFields
					.filter(f => f.type === 'nodeAttribute' && f.relates === 'node')
					.map(f => ({
						key: f.id,
						title: f.name,
						// disabled: f.readonly,
						checkable: true,
					}))
			}
			const nodeCorrespondentAttributes: DataNode = {
				key: 'nodeAttributes.correspondent',
				title: `${localization.reports_page_node_attributes_folder_name} ${localization.reports_page_correspondent_postfix}`,
				children: reportFields
					.filter(f => f.type === 'nodeAttribute' && f.relates === 'correspondent')
					.map(f => ({
						key: f.id,
						title: f.name,
						// disabled: f.readonly,
						checkable: true,
					}))
			}
			result.push(balanceNodeAttributes, balanceCorrespondentAttributes, nodeNodeAttributes, nodeCorrespondentAttributes);
		}
		return result;
	}

	getCheckedFieldKeys = (isMeasure?: boolean): string[] => {
		const {report} = this.props;
		return (report?.fields || [])
			.filter(f => (f.type === 'balance' || f.type === 'calculatedBalance') === (isMeasure))
			.filter(f => !!f.area)
			.map(f => f.id);
	}

	onDatesChanged = (dates: [Moment | null, Moment | null] | null) => {
		const {
			report,
			updateReport
		} = this.props;
		if(dates && dates[0] && dates[1]){
			if(report?.date[0] !== dates[0] || report.date[1] !== dates[1]){
				updateReport({
					type: report?.dateType,
					dates: [dates[0], dates[1]]
				});
			}
		}
	}

	onDateTypeChanged = (value: report.ReportDateType) => {
		const {
			report,
			updateReport
		} = this.props;

		if(value !== (report?.dateType || 'customDate')){
			updateReport({
				type: value, dates: report?.date
			});
		}
	}

	onFieldChecked = (_checked: Key[] | {
		checked: Key[];
		halfChecked: Key[];
	}, info: {
		node: EventDataNode;
		checked: boolean;
	}) => {
		const {nodeId, report, updateReportField} = this.props;


		const affectedField = report?.fields?.find(f => f.id === info.node.key)
		if(!affectedField){
			return;
		}

		let newArea: report.ReportFieldArea | undefined;
		if(!affectedField.area){
			newArea = (affectedField.type === 'balance' || affectedField.type === 'calculatedBalance') ? 'data' : 'row';
		}
		const update: report.ReportFieldUpdate = {
			area: newArea
		}
		updateReportField(nodeId, affectedField.id, update);
	}

	onExport = () => {
		const {doExport} = this.props;
		doExport();
	}

	onRefresh = () => {
		const {
			report,
			updateReport
		} = this.props;

		updateReport({
			dates: report?.date,
			type: report?.dateType
		});
	}

	onTemplateCreate = () => {
		const {
			nodeId, token, saveTemplate
		} = this.props;
		SetNameDialog.show({
			title: localization.reports_page_template__save_template_title,
			onOk: (name) => {
				const {report} = this.props;
				const templateContent: report.TemplateField[] = (report?.fields || [])
					.filter(f => f.area)
					.map(f => ({
						id: f.id,
						name: f.name,
						area: f.area,
						areaIndex: f.areaIndex,
						filterType: f.filterType,
						filterValues: f.filterValues,
					}))
				const template: report.TemplateWithoutId = {
					name: name,
					content: templateContent
				}
				saveTemplate(nodeId, template, token)
			}
		})
	}

	getOnTemplateDelete = (id: number) => (event: React.MouseEvent<Element, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();

		const {
			nodeId, token, deleteTemplate
		} = this.props;

		ConfirmDelete({
			title: localization.reports_page_template__confirm_delete_template_title,
			content: localization.reports_page_template__confirm_delete_template_text,
			deleteEntity: () => {
				deleteTemplate(nodeId, id, token);
			}
		})

	}

	getOnApplyTemplate = (template: report.Template) => (event: React.MouseEvent<Element, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();

		const {nodeId, applyTemplate} = this.props;
		applyTemplate(nodeId, template);
	}

	renderTemplatesList = () => {
		const {templates} = this.props;
		return (
			<div
				className = {`${containerClassName}__fields__templates`}
			>
				{templates?.map(template => {
					return (
						<div
							className = {`${containerClassName}__fields__templates__template`}
							key = {template.id}
							onClick = {this.getOnApplyTemplate(template)}
						>
							<div
								className = {`${containerClassName}__fields__templates__template__name`}
							>
								{template.name}
							</div>
							<div
								className = {`${containerClassName}__fields__templates__template__x`}
							>
								<X
									className = {'feather-ico'}
									onClick = {this.getOnTemplateDelete(template.id)}
								/>
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	renderCollapsed = () => {
		const {className, setIsCollapsed} = this.props;
		const collapsedContainerClassName = `${containerClassName}--collapsed`;
		const effectiveClassName = className ? `${collapsedContainerClassName} ${className}` : collapsedContainerClassName;
		return (
			<div className = {effectiveClassName}
				onClick = {() => setIsCollapsed(false)}
			>
				<div
					className = {`${collapsedContainerClassName}__button`}
				>
					<ChevronLeft className = {'feather-ico'}/>
				</div>
			</div>
		)
	}

	renderRangePickerFooter = () => {
	const { report } = this.props;
		return (
			<>
		{Object.entries(rangePickerRanges).map(([key, value]) => {
					return (
						<Tag
						className={`${containerClassName}__dates__selector__tag`}
							key={key}
							color={
								report?.date[0].isSame(value[0]) &&
								report?.date[1].isSame(value[1])
								  ? 'blue'
									: 'default'
								}
								onClick={() => this.onDatesChanged(value)}
							>
							{key}
						</Tag>
					);
				})}
			</>
		);
	};

	render(){
		const {
			className,
			isCollapsed, setIsCollapsed,
			report, templates
		} = this.props;

		if(isCollapsed){
			return this.renderCollapsed();
		}

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		return (
			<div className = {effectiveClassName}>
				<div
					className = {`${containerClassName}__header`}
				>
					<div
						className = {`${containerClassName}__header__buttons`}
					>
						<div
							className = {`${containerClassName}__header__buttons__collapse`}
							onClick = {() => setIsCollapsed(true)}
						>
							<span>
								{localization.reports_page_properties_collapse_text}
							</span>
							<ChevronRight className = {'feather-ico'}/>
						</div>
					</div>
					<div
						className = {`${containerClassName}__header__text`}
					>
						{localization.reports_page_properties_header_text}
					</div>
				</div>
				<div
					className = {`${containerClassName}__dates`}
				>
					<div
						className = {`${containerClassName}__dates__text`}
					>
						<Select<report.ReportDateType>
							className = {`${containerClassName}__dates__text__select`}
							size = 'small'
							bordered = {false}
							value = {report?.dateType || 'customDate'}
							onChange = {this.onDateTypeChanged}
						>
							<Select.Option value = {'customDate'}>
								{localization.reports_page_properties_dates_text}
							</Select.Option>
							<Select.Option value = {'createdAt'}>
								{localization.reports_page_properties_dates_createdAt_text}
							</Select.Option>
						</Select>
					</div>
					<div
						className = {`${containerClassName}__dates__selector`}
					>
						<RangePicker
							value = {report?.date}
							onChange = {(values) => this.onDatesChanged(values)}
              renderExtraFooter={this.renderRangePickerFooter}
						/>
					</div>
				</div>
				<div
					className = {`${containerClassName}__fields`}
				>
					<div
						className = {`${containerClassName}__fields__text`}
					>
						<span
							className = {`${containerClassName}__fields__text__content`}
						>
							{localization.reports_page_properties_fields_text}
						</span>
						{templates && templates.length > 0 && (
							<Popover
								content = {this.renderTemplatesList()}
								placement = "bottomLeft"
							>
								<ChevronDown
									className = {`feather-ico`}
									onClick = {() => {}}
								/>
							</Popover>
						)}
						<Save
							className = {`feather-ico`}
							onClick = {() => this.onTemplateCreate()}
						/>
						<RefreshCw
							className = {`feather-ico`}
							onClick = {() => this.onRefresh()}
						/>
					</div>
					<div
						className = {`${containerClassName}__fields__selector`}
					>
						<Tree
							treeData = {this.getFieldsAsDataNodes(false)}
							checkedKeys = {this.getCheckedFieldKeys(false)}
							checkable
							selectable = {false}
							onCheck = {this.onFieldChecked}
						/>
					</div>
				</div>
				<div
					className = {`${containerClassName}__fields`}
				>
					<div
						className = {`${containerClassName}__fields__text`}
					>
						{localization.reports_page_properties_balances_text}
					</div>
					<div
						className = {`${containerClassName}__fields__selector`}
					>
						<Tree
							treeData = {this.getFieldsAsDataNodes(true)}
							checkedKeys = {this.getCheckedFieldKeys(true)}
							checkable
							selectable = {false}
							onCheck = {this.onFieldChecked}
						/>
					</div>
				</div>
				<div
					className = {`${containerClassName}__buttons`}
				>
					<Button
						className = {`${containerClassName}__buttons__export`}
						type = 'primary'
						onClick = {this.onExport}
					>
						{localization.export}
					</Button>
				</div>
			</div>
		)
	}
}

export default PivotReportOptions;
