import { connect } from 'react-redux';

import actions from '../../actions';
import { State } from '../../reducers';

import AttributesDialog from './AttributesDialog';

import './AttributesDialog.scss';

const mapStateToProps = (state: State) => {
	return {
		nodesInfo: state.nodes,
		token: state.auth.token,
		isAttributeVisibilitiesLoading: state.attributeVisibilities.isLoading,
		attributeVisibilities: state.attributeVisibilities.visibilities,
		attributeGroups: state.attributeGroups
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadAttributeVisibilities: (token: string) => 
		dispatch(actions.attributeVisibilities.load(token)),
	loadAttributeGroups: (treeId: number, token: string) => 
		dispatch(actions.attributeGroups.load(treeId, token)),
	loadNodeAttributes: (nodeId: number, token: string) => 
		dispatch(actions.node.loadNodeAttributes(nodeId, token)),
	createAttribute: (name: string, nodeId: number, treeId: number, token: string) =>
		dispatch(actions.attribute.create({name, nodeId, visibilityId: 1, treeId}, token)),
	updateAttribute: (attribute: {id: number, name?: string, visibilityId?: number, nodeId: number}, treeId: number, token: string) =>
		dispatch(actions.attribute.update(attribute, treeId, token)),
	deleteAttribute: (id: number, nodeId: number, treeId: number, token: string) =>
		dispatch(actions.attribute.delete(id, nodeId, treeId, token)),
	createAttributeValue: (value: {name: string, visibilityId: number}, nodeId: number, attributeId: number, token: string) =>
		dispatch(actions.attributeValue.create(value, nodeId, attributeId, token)),
	editAttributeValue: (id: number, value: {name: string | null, visibilityId: number | null}, nodeId: number, attributeId: number, token: string) =>
		dispatch(actions.attributeValue.update(id, value, nodeId, attributeId, token)),
	deleteAttributeValue: (id: number, nodeId: number, attributeId: number, token: string) =>
		dispatch(actions.attributeValue.delete(id, nodeId, attributeId, token)),
	setAttributeValue: (nodeId: number, attributeId: number, value: {id: number, propagate?: boolean}, token: string) =>
		dispatch(actions.attribute.setValue(nodeId, attributeId, value, token)),
	setAttributeGroups: (attributeId: number, nodeId: number, treeId: number, newGroupIds: number[], currentGroupIds: number[], token: string) => 
		dispatch(actions.attribute.setGroups(nodeId, attributeId, newGroupIds, currentGroupIds, treeId, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(AttributesDialog)