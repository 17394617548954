const isNumberString = (text?: string | undefined): boolean => {
	if(text == null){
		return false;
	}
	return /^-?\d+,?\d*/g.test(text);
}
const formatNumber = (number: number, precision?: number): string => {
	let	value = precision ? number.toFixed(precision) : String(number);
	if(precision === 0) value = number.toFixed();
	const dotIndex = value.indexOf('.');
	const integerPart = value
		.substring(0, dotIndex === -1 ? value.length : dotIndex)
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return `${integerPart}${value.substring(dotIndex === -1 ? value.length : dotIndex)}`;
}

export {
	isNumberString,
	formatNumber
};
