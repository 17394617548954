import { BalanceAttribute } from "../models";

const actionTypes = {
	BALANCE_ATTRIBUTE_LOAD: 'BALANCE_ATTRIBUTE\\LOAD',
	BALANCE_ATTRIBUTE_LOAD_SUCCESS: 'BALANCE_ATTRIBUTE\\LOAD_SUCCESS',
	BALANCE_ATTRIBUTE_LOAD_FAILED: 'BALANCE_ATTRIBUTE\\LOAD_FAILED',

	BALANCE_ATTRIBUTE_CREATE: 'BALANCE_ATTRIBUTE\\CREATE',
	BALANCE_ATTRIBUTE_CREATE_SUCCESS: 'BALANCE_ATTRIBUTE\\CREATE_SUCCESS',
	BALANCE_ATTRIBUTE_CREATE_FAILED: 'BALANCE_ATTRIBUTE\\CREATE_FAILED',

	BALANCE_ATTRIBUTE_UPDATE: 'BALANCE_ATTRIBUTE\\UPDATE',
	BALANCE_ATTRIBUTE_UPDATE_SUCCESS: 'BALANCE_ATTRIBUTE\\UPDATE_SUCCESS',
	BALANCE_ATTRIBUTE_UPDATE_FAILED: 'BALANCE_ATTRIBUTE\\UPDATE_FAILED',

	BALANCE_ATTRIBUTE_DELETE: 'BALANCE_ATTRIBUTE\\DELETE',
	BALANCE_ATTRIBUTE_DELETE_SUCCESS: 'BALANCE_ATTRIBUTE\\DELETE_SUCCESS',
	BALANCE_ATTRIBUTE_DELETE_FAILED: 'BALANCE_ATTRIBUTE\\DELETE_FAILED',

	BALANCE_ATTRIBUTE_ORDER: 'BALANCE_ATTRIBUTE\\ORDER',
	BALANCE_ATTRIBUTE_ORDER_SUCCESS: 'BALANCE_ATTRIBUTE\\ORDER_SUCCESS',
	BALANCE_ATTRIBUTE_ORDER_FAILED: 'BALANCE_ATTRIBUTE\\ORDER_FAILED',
}

const load = (balanceId: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_LOAD,
	payload: {
		balanceId,
		treeId,
		token
	}
})

const loadSuccess = (balanceId: number, treeId: number, balanceAttributes: BalanceAttribute[]) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_LOAD_SUCCESS,
	payload: {
		balanceId,
		treeId,
		balanceAttributes
	}
})

const loadFailed = (balanceId: number, treeId: number, message: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_LOAD_FAILED,
	payload: {
		balanceId,
		treeId,
		message: message
	}
})

const create = (balanceAttribute: {name: string, typeId: number, isRequired: boolean}, balanceId: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_CREATE,
	payload: {
		name: balanceAttribute.name,
		typeId: balanceAttribute.typeId,
		isRequired: balanceAttribute.isRequired,
		balanceId,
		treeId,
		token
	}
})

const createSuccess = (id: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_CREATE_SUCCESS,
	payload: {
		id,
		balanceId,
		treeId
	}
})

const createFailed = (message: string, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_CREATE_FAILED,
	payload: {
		message,
		balanceId,
		treeId
	}
})

const update = (balanceAttribute: {id: number, name: string, typeId: number, isRequired: boolean}, balanceId: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_UPDATE,
	payload: {
		balanceAttributeId: balanceAttribute.id,
		name: balanceAttribute.name,
		typeId: balanceAttribute.typeId,
		isRequired: balanceAttribute.isRequired,
		balanceId,
		treeId,
		token
	}
})

const updateSuccess = (id: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_UPDATE_SUCCESS,
	payload: {
		id,
		balanceId,
		treeId
	}
})

const updateFailed = (message: string, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_UPDATE_FAILED,
	payload: {
		message,
		balanceId,
		treeId
	}
})

const deleteBalanceAttribute = (id: number, balanceId: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_DELETE,
	payload: {
		id,
		balanceId,
		treeId,
		token
	}
})

const deleteSuccess = (id: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_DELETE_SUCCESS,
	payload: {
		id,
		balanceId,
		treeId
	}
})

const deleteFailed = (message: string, id: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_DELETE_FAILED,
	payload: {
		id,
		message,
		balanceId,
		treeId
	}
})

const order = (idsWithOrder: {id: number, order: number}[], balanceId: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_ORDER,
	payload: {
		idsWithOrder,
		balanceId,
		treeId,
		token
	}
})

const orderSuccess = (balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_ORDER_SUCCESS,
	payload: {
		balanceId,
		treeId
	}
})

const orderFailed = (message: string, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_ORDER_FAILED,
	payload: {
		message,
		balanceId,
		treeId
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	create,
	createSuccess,
	createFailed,
	update,
	updateSuccess,
	updateFailed,
	delete: deleteBalanceAttribute,
	deleteSuccess,
	deleteFailed,
	order,
	orderSuccess,
	orderFailed
};