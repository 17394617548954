import { call, put, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'
import actions from '../actions';
import { Permission} from '../models';
import handleResponse from './handleResponse';

const {loadSuccess, loadFailed} = actions.permissions;
const {raise} = actions.error;

const tryLoad = (payload: {token: string}): Promise<{status: number, body: any} | {error: any}> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/permissions`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractPermission = (entry: any): Permission => {
	return {
		id: entry.id,
		name: entry.name,
		slug: entry.slug
	}
}

const loadPermissionsSaga = function*(action: any){
	try{
		const response = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(body.data.map(extractPermission)),
			(message: any) => loadFailed(message)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed('An error occurred'));
		return;
	}
}

const watchPermissions = function*() {
	yield takeLeading(actions.permissions.types.PERMISSIONS_LOAD, loadPermissionsSaga)
}

export default watchPermissions;