import { Input } from 'antd';
import React, { memo, useState } from 'react';

import { Button, CustomPopup } from '../../components';
import { localization } from '../../settings';

interface AttributeValueDialogProps{
	visible: boolean,

	onClose: () => void,
	onSave: (name: string) => void,
}

const containerClassName = 'bav-dialog';

const AttributeValueDialog = (props: AttributeValueDialogProps) => {
	const {visible, onClose, onSave} = props;
	const [name, setName] = useState('')

	const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	}

	const onSaveName = () => {
		onSave(name);
		setName('');
		onClose();
	}

	return (
		<CustomPopup
			visible = {visible}
			top = {100}
			left = {100}
			overlayStyle = {{zIndex: 10010}}
			style = {{zIndex: 10011}}
			onClose = {onClose}
		>
			<div
				className = {`${containerClassName}`}
			>
				<div
					className = {`${containerClassName}__title`}
				>
					{localization.add_balanceAttributeValue_title}
				</div>
				<Input
					value = {name}
					onChange = {onNameChanged}
					placeholder = {localization.add_balanceAttributeValue_namePlaceholder}
				/>
				<div
					className = {`${containerClassName}__footer`}
				>
					<Button
						type = "primary"
						disabled = {name === ''}
						onClick = {onSaveName}
					>{localization.save}</Button>
					<Button
						onClick = {onClose}
					>{localization.cancel}</Button>
				</div>
			</div>
		</CustomPopup>
	);
}

export default memo(AttributeValueDialog);