import { Tree } from '../models';

const findNode = (id: React.ReactText | null, tree: Tree | null): Tree | null => {
	if(tree?.id === id){
		return tree;
	}
	for(const subtree of tree?.children || []){
		const foundNode = findNode(id, subtree);
		if(foundNode){
			return foundNode;
		}
	}
	return null;
}

export default findNode;