import { TransactionsElasticAttribute } from "../models";

const actionTypes = {
	TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD: 'TRANSACTIONS_ELASTIC_ATTRIBUTES\\LOAD',
	TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD_SUCCESS: 'TRANSACTIONS_ELASTIC_ATTRIBUTES\\LOAD_SUCCESS',
	TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD_FAILED: 'TRANSACTIONS_ELASTIC_ATTRIBUTES\\LOAD_FAILED',
}

const load = (treeId: number, token: string) => ({
	type: actionTypes.TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD,
	payload: {
		treeId,
		token
	}
})

const loadSuccess = (treeId: number, attributes: TransactionsElasticAttribute[]) => ({
	type: actionTypes.TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD_SUCCESS,
	payload: {
		treeId,
		attributes
	}
})

const loadFailed = (treeId: number, message: string) => ({
	type: actionTypes.TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD_FAILED,
	payload: {
		treeId,
		message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
};