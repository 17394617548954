import actions from '../actions';
import { Tree, TreeListEntry, TreesHash, UserOnTree, UsersOnTree } from '../models';

export interface TreesState{
	/**
	 * list of all available trees (id, name) in the system
	 */
	list: TreeListEntry[] | null,
	isLoading: boolean,
	error: string | null,
	isTreeUpdating: boolean,
	/**
	 * detailed information on tree, including nodes hierarchy
	 */
	trees: TreesHash
}

const defaultState: TreesState = {
	list: null,
	isLoading: false,
	isTreeUpdating: false,
	error: null,

	trees: {}
}

/// Updates specified tree in app state
/// tree - updated tree if specified, null to reset, undefined to leave untouched
const updateTreeInState = (
	state: TreesState,
	id: string,
	tree: Tree | null | undefined,
	isLoading: boolean,
	error: string | null,
	loadedAt: Date | null | undefined
): TreesState => {
	const safeId = parseInt(id);
	const updatedTrees = {...state.trees};
	if(tree !== undefined){
		updatedTrees[safeId] = {
			...updatedTrees[safeId],
			loadedAt: loadedAt === undefined ? updatedTrees[safeId]?.loadedAt || null : loadedAt,
			tree: tree,
			isLoading: isLoading,
			error: error
		}
	}

	return {
		...state,
		trees: updatedTrees
	}
}

const updateAttributeUserInfosForTree = (
	state: TreesState,
	treeId: number,
	userId: number,
	update: (userInfo?: UserOnTree) => UserOnTree
): TreesState => {
	const updatedTrees = {...state.trees};
	const updatedUsers: UsersOnTree = {...updatedTrees[treeId]?.users}
	updatedUsers[userId] = update(updatedUsers[userId]);
	updatedTrees[treeId] = {
		tree: null,
		isLoading: false,
		error: null,
		loadedAt: null,
		...updatedTrees[treeId],
		users: updatedUsers
	}
	return {
		...state,
		trees: updatedTrees
	}
}

const trees = (state: TreesState = defaultState, action: any): TreesState => {
	switch(action.type){
		case actions.trees.types.TREES_LOAD:
			return {...state, list: null, isLoading: true};
		case actions.trees.types.TREES_LOAD_SUCCESS:
			const { trees } = action.payload;
			return {...state, list: trees, isLoading: false, error: null}
		case actions.trees.types.TREES_LOAD_FAILED:
			console.error('trees failed\n%o', action.payload);
			return {...state, list: null, isLoading: false};

		case actions.trees.types.TREES_RESET:
			return defaultState;

		case actions.tree.types.TREE_CREATE:
			return {...state, list: null, trees: [], isLoading: true};
		case actions.tree.types.TREE_CREATE_SUCCESS:
			return {...state, list: null, trees: [], isLoading: false};
		case actions.tree.types.TREE_CREATE_FAILED:
			return {...state, list: null, trees: [], isLoading: false};

		case actions.tree.types.TREE_LOAD: {
			const { id } = action.payload;
			return updateTreeInState(state, id, null, true, null, null);
		}
		case actions.tree.types.TREE_LOAD_SUCCESS: {
			const {id, tree} = action.payload;
			return updateTreeInState(state, id, tree, false, null, new Date());
		}
		case actions.tree.types.TREE_LOAD_FAILED: {
			const {id, message} = action.payload;
			return updateTreeInState(state, id, null, false, message, null);
		}

		case actions.tree.types.TREE_UNLOAD: {
			const {id} = action.payload;
			const updatedState = {...state}
			delete updatedState.trees[id];
			return updatedState;
		}
		case actions.tree.types.TREE_RESET: {
			const {id} = action.payload;
			return updateTreeInState(state, id, null, false, null, undefined);
		}

		case actions.tree.types.TREE_UPDATE:
		case actions.tree.types.TREE_DELETE:
		case actions.tree.types.TREE_NODE_ADD: {
			const {treeId} = action.payload;
			return updateTreeInState(state, treeId, undefined, true, null, null);
		}
		case actions.tree.types.TREE_UPDATE_SUCCESS:
		case actions.tree.types.TREE_DELETE_SUCCESS:
		case actions.tree.types.TREE_NODE_ADD_SUCCESS: {
			const {treeId} = action.payload;
			return updateTreeInState(state, treeId, null, false, null, null);
		}
		case actions.tree.types.TREE_UPDATE_FAILED:
		case actions.tree.types.TREE_DELETE_FAILED:
		case actions.tree.types.TREE_NODE_ADD_FAILED: {
			const {treeId, message} = action.payload;
			console.error(`${action.type}:\n%o`, action.payload);
			return updateTreeInState(state, treeId, null, false, message, null);
		}
		//assigned attribute groups
		case actions.tree.types.TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD: {
			const {treeId, userId} = action.payload;
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				balanceIds: null,
				isBalancesLoading: false,
				...info,
				attributeGroupIds: null,
				isAttributeGroupsLoading: true
			}));
		}
		case actions.tree.types.TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD_SUCCESS: {
			const {treeId, userId, assignedIds} = action.payload;
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				balanceIds: null,
				isBalancesLoading: false,
				...info,
				attributeGroupIds: assignedIds,
				isAttributeGroupsLoading: false
			}));
		}
		case actions.tree.types.TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD_FAILED: {
			const {treeId, userId} = action.payload;
			console.error(`${action.type}:\n%o`, action.payload);
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				balanceIds: null,
				isBalancesLoading: false,
				...info,
				attributeGroupIds: [],
				isAttributeGroupsLoading: false
			}));
		}

		case actions.tree.types.TREE_ASSIGN_ATTRIBUTE_GROUP_SUCCESS:{
			const {treeId, userId} = action.payload;
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				balanceIds: null,
				isBalancesLoading: false,
				...info,
				attributeGroupIds: null,
				isAttributeGroupsLoading: false
			}));
		}
		case actions.tree.types.TREE_ASSIGN_ATTRIBUTE_GROUP_FAILED:{
			const {treeId, userId} = action.payload;
			console.error(`${action.type}:\n%o`, action.payload);
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				balanceIds: null,
				isBalancesLoading: false,
				...info,
				attributeGroupIds: null,
				isAttributeGroupsLoading: false
			}))
		}
		//assigned balances
		case actions.tree.types.TREE_ASSIGNED_BALANCES_LOAD: {
			const {treeId, userId} = action.payload;
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				attributeGroupIds: null,
				isAttributeGroupsLoading: false,
				...info,
				balanceIds: null,
				isBalancesLoading: true,
			}));
		}
		case actions.tree.types.TREE_ASSIGNED_BALANCES_LOAD_SUCCESS: {
			const {treeId, userId, assignedIds} = action.payload;
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				attributeGroupIds: null,
				isAttributeGroupsLoading: false,
				...info,
				balanceIds: assignedIds,
				isBalancesLoading: false,
			}))
		}
		case actions.tree.types.TREE_ASSIGNED_BALANCES_LOAD_FAILED: {
			const {treeId, userId} = action.payload;
			console.error(`${action.type}\n%o`, action.payload);
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				attributeGroupIds: null,
				isAttributeGroupsLoading: false,
				...info,
				balanceIds: [],
				isBalancesLoading: false,
			}))
		}
		case actions.tree.types.TREE_ASSIGN_BALANCE_SUCCESS:{
			const {treeId, userId} = action.payload;
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				attributeGroupIds: null,
				isAttributeGroupsLoading: false,
				...info,
				balanceIds: null,
				isBalancesLoading: false,
			}))
		}
		case actions.tree.types.TREE_ASSIGN_BALANCE_FAILED:{
			const {treeId, userId} = action.payload;
			console.error(`${action.type}\n%o`, action.payload);
			return updateAttributeUserInfosForTree(state, treeId, userId, (info) => ({
				attributeGroupIds: null,
				isAttributeGroupsLoading: false,
				...info,
				balanceIds: null,
				isBalancesLoading: false,
			}))
		}

		// Transactions
		case actions.transactions.types.TRANSACTION_CREATE_SUCCESS:{
			const {treeIds}: {treeIds: number[]} = action.payload;
			const resultState = treeIds.reduce((acc, cur) => {
				return updateTreeInState(acc, `${cur}`, null, false, null, null);
			}, state)
			return resultState
		}
		case actions.transactions.types.TRANSACTION_CREATE_FAILED:{
			const {treeIds}: {treeIds: number[]} = action.payload;
			console.error(`${action.type}\n%o`, action.payload);
			const resultState = treeIds.reduce((acc, cur) => {
				return updateTreeInState(acc, `${cur}`, null, false, null, null);
			}, state)
			return resultState
		}
	}
	return state;
}

export default trees;