import { NodesState } from '../models';
import UserAccount from '../models/userAccount';
import permissions from './permissions.json';
import treePermissions from './treePermissions.json';

interface CRUD{
	canRead: boolean,
	canCreate: boolean,
	canEdit: boolean,
	canDelete: boolean
}

export interface TreeSecurityProvider{
	node: {
		canAdd: boolean,
		canEdit: boolean,
		canDelete: boolean,
		users: {
			canView: boolean,
			canAssign: boolean
		},
		attributes: {
			canView: boolean,
			canAssign: boolean
		},
		attributeGroups: CRUD & {canAssignToUser: boolean},
		report: {
			canView: boolean
		},
		balances: CRUD
	},
	transactions: {
		canReadFullTransactions: boolean,
		canReadTransactions: boolean,
		canEditTransactions: boolean,
		canEditOperations: boolean,
		canCancel: boolean,
	},
	allowedTransactions: {
		consumption: boolean,
		income: boolean,
		replenishment: boolean,
		"write-off": boolean,
		transfer : boolean
	},
	transactionChanges: {
		canRead: boolean
	}
}


const getTreeSecurityProvider = (currentUser: UserAccount, treeId: number | null, nodesInfo: NodesState) => {
	const treePermissions = treeId ? nodesInfo[treeId]?.users[currentUser.id]?.treePermissions || [] : [];
	return getTreeSecurityProviderByPermissions(currentUser.permissions, treePermissions)
}

const getTreeSecurityProviderByPermissions = (currentPermissions: string[], currentTreePermissions: string[]): TreeSecurityProvider => {
	const isAdmin = currentPermissions.indexOf(permissions.access_all_nodes) > -1;
	const hasPermissions = (...permissions: string[]) => {
		return isAdmin || permissions.reduce((acc: boolean, cur) => acc && currentTreePermissions.includes(cur), true);
	}

	const canCreateNode = hasPermissions(treePermissions.create_node);
	const canEditNode = hasPermissions(treePermissions.update_node);
	const canDeleteNode = hasPermissions(treePermissions.delete_node);

	const canViewNodeUsers = hasPermissions(treePermissions.view_user_groups);
	const canAssignNodeUsers = hasPermissions(treePermissions.assign_action_group);

	const canViewAttributes = hasPermissions(treePermissions.read_node_attribute);
	const canAssignAttributes = hasPermissions(treePermissions.attach_node_attribute);


	const canReadAttributeGroups = hasPermissions(
		treePermissions.read_attribute_groups,
		treePermissions.read_tree_attribute_groups
	);
	const canCreateAttributeGroups = hasPermissions(treePermissions.create_attribute_groups);
	const canEditAttributeGroups = hasPermissions(treePermissions.update_attribute_groups);
	const canDeleteAttributeGroups = hasPermissions(treePermissions.delete_attribute_groups);
	const canAssignAttributeGroupsToUser = hasPermissions(treePermissions.attach_attribute_groups_user);
	const canViewReport = hasPermissions(treePermissions.reports_view);

	const canEditTransactions = hasPermissions(treePermissions.update_transactions);
	const canReadTransactions = hasPermissions(treePermissions.transaction_view);
	const canReadFullTransactions = hasPermissions(treePermissions.read_full_transactions);
	const canEditOperations = hasPermissions(treePermissions.update_operations);

	const canCancelTransaction = true;

	const canReadTransactionChanges = hasPermissions(treePermissions.access_transactions_log);

	const canConsumption = hasPermissions(treePermissions.transaction_consumption);
	const canIncome = hasPermissions(treePermissions.transaction_income);
	const canReplenishment = hasPermissions(treePermissions.transaction_replenishment);
	const canWriteoff = hasPermissions(treePermissions.transaction_writeoff);
	const canTransfer = hasPermissions(treePermissions.transaction_transfer);

	return {
		node: {
			canAdd: canCreateNode,
			canEdit: canEditNode,
			canDelete: canDeleteNode,
			users: {
				canView: canViewNodeUsers,
				canAssign: canAssignNodeUsers
			},
			attributes: {
				canView: canViewAttributes,
				canAssign: canAssignAttributes
			},
			attributeGroups: {
				canRead: canReadAttributeGroups,
				canCreate: canCreateAttributeGroups,
				canEdit: canEditAttributeGroups,
				canDelete: canDeleteAttributeGroups,
				canAssignToUser: canAssignAttributeGroupsToUser
			},
			balances: {
				canRead: true,
				canCreate: true,
				canEdit: true,
				canDelete: true,
			},
			report: {
				canView: canViewReport
			}
		},
		transactions: {
			canReadFullTransactions: canReadFullTransactions,
			canReadTransactions: canReadTransactions,
			canEditOperations: canEditOperations,
			canEditTransactions: canEditTransactions,
			canCancel: canCancelTransaction,
		},
		allowedTransactions: {
			consumption: canConsumption,
			income: canIncome,
			replenishment: canReplenishment,
			"write-off": canWriteoff,
			transfer : canTransfer
		},
		transactionChanges: {
			canRead: canReadTransactionChanges
		}
	};
}

export default getTreeSecurityProvider;
export {getTreeSecurityProviderByPermissions};