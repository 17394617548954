import { call, put, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { Balance } from '../models';
import handleResponse from './handleResponse';

const {loadSuccess, loadFailed} = actions.balances;
const {raise} = actions.error;

const tryLoad = (payload: {treeId: number, token: string}): Promise<{status: number, body: any} | {error: any}> => {
	try{
		const {treeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/balances/`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractBalance = (entry: any): Balance => {
	return {
		id: entry.id,
		name: entry.name,
		typeId: entry.typeId,
		creditLimitId: entry.creditLimitId,
		limit: entry.creditLimit != null ? entry.creditLimit : null,
		order: entry.order
	}
}

const loadBalancesSaga = function*(action: any){
	const {treeId} = action.payload;
	try{
		const response = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(treeId, body.data.map(extractBalance)),
			(message: any) => loadFailed(treeId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(treeId, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield takeLeading(actions.balances.types.BALANCES_LOAD, loadBalancesSaga)
}

export default watch;