import { connect } from 'react-redux';

import actions from '../../actions';
import { CommonPageProps, Tree } from '../../models';
import { State } from '../../reducers';

import UserRightsDialog from './UserRightsDialog';

import './index.scss';

interface UserRightsDialogProps extends CommonPageProps{
	node: Tree
}

const mapStateToProps = (state: State, _: UserRightsDialogProps) => {
	return {
		users: state.users.list,
		isUsersLoading: state.users.isLoading,

		actionGroups: state.actionGroups.list,
		isActionGroupsLoading: state.actionGroups.isLoading,

		attributeGroupsPerTree: state.attributeGroups,

		balancesPerTree: state.balances,

		nodesInfo: state.nodes,
		trees: state.trees.trees,
		token: state.auth.token,
		userId: state.auth.userId
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadUsers: (token: string) =>
		dispatch(actions.users.load(token)),
	loadNodeUsers: (nodeId: number, token: string) =>
		dispatch(actions.node.loadNodeUsers(nodeId, token)),
	loadActionGroups: (token: string) =>
		dispatch(actions.actionGroups.load(token)),
	loadAttributeGroups: (treeId: number, token: string) =>
		dispatch(actions.attributeGroups.load(treeId, token)),
	loadUserNodeActionGroups: (userId: number, nodeId: number, token: string) =>
		dispatch(actions.node.loadActionGroups(userId, nodeId, token)),
	assignActionGroup: (add: boolean, userId: number, actionGroupId: number, nodeId: number, token: string) => 
		dispatch(actions.node.assignActionGroup(add, userId, actionGroupId, nodeId, token)),
	loadAssignedAttributeGroups: (treeId: number, userId: number, token: string) =>
		dispatch(actions.tree.loadAssignedAttributeGroups(treeId, userId, token)),
	assignAttributeGroup: (treeId: number, attributeGroupId: number, userId: number, isAdd: boolean, token: string) => 
		dispatch(actions.tree.assignAttributeGroup(treeId, userId, attributeGroupId, isAdd, token)),

	loadBalances: (treeId: number, token: string) =>
		dispatch(actions.balances.load(treeId, token)),
	loadAssignedBalances: (treeId: number, userId: number, token: string) => 
		dispatch(actions.tree.loadAssignedBalances(treeId, userId, token)),
	assignBalance: (treeId: number, balanceId: number, userId: number, isAdd: boolean, token: string) =>
		dispatch(actions.tree.assignBalance(treeId, userId, balanceId, isAdd, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRightsDialog)