import actions from '../actions';

export interface PermissionsState{
	list: any[] | null,
	isLoading: boolean
}

const defaultState: PermissionsState = {
	list: null,
	isLoading: false
}

const permissions = (state: PermissionsState = defaultState, action: any): PermissionsState => {
	switch(action.type){
		case actions.permissions.types.PERMISSIONS_LOAD:
			return {...state, isLoading: true};
		case actions.permissions.types.PERMISSIONS_LOAD_SUCCESS:
			const { permissions } = action.payload;
			return {...state, list: permissions, isLoading: false}
		case actions.permissions.types.PERMISSIONS_LOAD_FAILED:
			console.error('permissions failed\n%o', action.payload);
			return {...state, list: [], isLoading: false}
	}
	return state;
}

export default permissions;