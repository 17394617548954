import { Permission } from "../models";

const actionTypes = {
	TREE_PERMISSIONS_LOAD: 'TREE_PERMISSIONS\\LOAD',
	TREE_PERMISSIONS_LOAD_SUCCESS: 'TREE_PERMISSIONS\\LOAD_SUCCESS',
	TREE_PERMISSIONS_LOAD_FAILED: 'TREE_PERMISSIONS\\LOAD_FAILED'
}

const load = (token: string) => ({
	type: actionTypes.TREE_PERMISSIONS_LOAD,
	payload: {
		token: token
	}
})

const loadSuccess = (treePermissions?: Permission[]) => ({
	type: actionTypes.TREE_PERMISSIONS_LOAD_SUCCESS,
	payload: {
		treePermissions
	}
})

const loadFailed = (message: string) => ({
	type: actionTypes.TREE_PERMISSIONS_LOAD_FAILED,
	payload: {
		message: message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed
};