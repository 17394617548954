import React from 'react';
import { Button, Tooltip } from 'antd';
import { Edit, Paperclip, Plus, Trash } from 'react-feather';

import { localization } from '../../settings';

interface ActionPanelProps{
	className?: string,

	addNode?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | null,
	renameNode?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | null,
	deleteNode?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | null,
	fixRows?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | null,
}

const containerClassName = 'action-panel';

const ActionPanel = (props: ActionPanelProps) => {
	const {
		className,
		addNode, renameNode, deleteNode, fixRows
	} = props;
	const effectiveContainerClassName = className ? `${containerClassName} ${className}` : containerClassName;

	return (
		<div className = {effectiveContainerClassName}>
			<Tooltip
				title = {localization.newNodeButton}
			>
				<Button
					icon = {<Plus className = {`${containerClassName}__icon`} />}
					className = {`${containerClassName}__add`}
					disabled = {!addNode}
					block
					type = "text"
					onClick={addNode || undefined}
				/>
			</Tooltip>
			<Tooltip
				title = {localization.fix_row}
			>
				<Button
					icon={<Paperclip className = {`${containerClassName}__icon`} />}
					className = {`${containerClassName}__fix`}
					block
					type = "text"
					onClick = {fixRows || undefined}
				/>
			</Tooltip>
			<Tooltip
				title = {localization.editName}
			>
				<Button
					icon={<Edit className = {`${containerClassName}__icon`} />}
					className = {`${containerClassName}__edit`}
					disabled = {!renameNode}
					block
					type = "text"
					onClick={renameNode || undefined}
				/>
			</Tooltip>
			<Tooltip
				title = {localization.deleteNodeButton}
			>
				<Button
					danger
					icon={<Trash className = {`${containerClassName}__icon`} />}
					className = {`${containerClassName}__delete`}
					disabled = {!deleteNode}
					block
					type = "text"
					onClick={deleteNode || undefined}
				/>
			</Tooltip>
		</div>
	)
}

export default ActionPanel;