import { payloads, Transaction, TransactionsFilters } from "../models";

const actionTypes = {
	TRANSACTIONS_LOAD: 'TRANSACTIONS\\LOAD',
	TRANSACTIONS_LOAD_SUCCESS: 'TRANSACTIONS\\LOAD_SUCCESS',
	TRANSACTIONS_LOAD_FAILED: 'TRANSACTIONS\\LOAD_FAILED',

	TRANSACTIONS_UNLOAD: 'TRANSACTIONS\\UNLOAD',

	TRANSACTION_CREATE: 'TRANSACTION\\CREATE',
	TRANSACTION_CREATE_SUCCESS: 'TRANSACTION\\CREATE_SUCCESS',
	TRANSACTION_CREATE_FAILED: 'TRANSACTION\\CREATE_FAILED',

	TRANSACTION_UPDATE: 'TRANSACTION\\UPDATE',
	TRANSACTION_UPDATE_SUCCESS: 'TRANSACTION\\UPDATE_SUCCESS',
	TRANSACTION_UPDATE_FAILED: 'TRANSACTION\\UPDATE_FAILED',

	TRANSACTION_CANCEL: 'TRANSACTION\\CANCEL',
	TRANSACTION_CANCEL_SUCCESS: 'TRANSACTION\\CANCEL_SUCCESS',
	TRANSACTION_CANCEL_FAILED: 'TRANSACTION\\CANCEL_FAILED',
}

const load = (treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, token: string) => ({
	type: actionTypes.TRANSACTIONS_LOAD,
	payload: {
		treeId,
		currentPage,
		pageSize,
		filters,
		token
	}
})

const loadSuccess = (treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, transactions: Transaction[]) => ({
	type: actionTypes.TRANSACTIONS_LOAD_SUCCESS,
	payload: {
		treeId,
		currentPage,
		pageSize,
		filters,
		transactions
	}
})

const loadFailed = (treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, message: string) => ({
	type: actionTypes.TRANSACTIONS_LOAD_FAILED,
	payload: {
		treeId,
		currentPage,
		pageSize,
		filters,
		message
	}
})

const unload = (treeId: number) => ({
	type: actionTypes.TRANSACTIONS_UNLOAD,
	payload: {
		treeId
	}
})

const create = (payload: payloads.CreateTransactionPayloadType, token: string) => ({
	type: actionTypes.TRANSACTION_CREATE,
	payload: {
		...payload,
		token: token,
	}
})

const createSuccess = (treeIds: number[]) => ({
	type: actionTypes.TRANSACTION_CREATE_SUCCESS,
	payload: {
		treeIds,
	}
})

const createFailed = (treeIds: number[], message: string) => ({
	type: actionTypes.TRANSACTION_CREATE_FAILED,
	payload: {
		treeIds,
		message
	}
})

const update = (payload: payloads.UpdateTransactionPayloadType, token: string) => ({
	type: actionTypes.TRANSACTION_UPDATE,
	payload: {
		...payload,
		token
	}
})

const updateSuccess = (treeId: number, transactionId: number) => ({
	type: actionTypes.TRANSACTION_UPDATE_SUCCESS,
	payload: {
		treeId,
		transactionId,
	}
})

const updateFailed = (treeId: number, transactionId: number, message: string) => ({
	type: actionTypes.TRANSACTION_UPDATE_FAILED,
	payload: {
		treeId,
		transactionId,
		message
	}
})

const cancel = (treeId: number, transactionId: number, token: string) => ({
	type: actionTypes.TRANSACTION_CANCEL,
	payload: {
		treeId,
		transactionId,
		token
	}
})

const cancelSuccess = (treeId: number, transactionId: number) => ({
	type: actionTypes.TRANSACTION_CANCEL_SUCCESS,
	payload: {
		treeId,
		transactionId
	}
})

const cancelFailed = (treeId: number, transactionId: number, message: string) => ({
	type: actionTypes.TRANSACTION_CANCEL_FAILED,
	payload: {
		treeId,
		transactionId,
		message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	unload,
	create,
	createSuccess,
	createFailed,
	update,
	updateSuccess,
	updateFailed,
	cancel,
	cancelSuccess,
	cancelFailed
};