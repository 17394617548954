import { connect } from 'react-redux';

import actions from '../../actions';
import { State } from '../../reducers';
import { CommonPageProps } from '../../models';

import TreeView from './TreeView';

import './TreeView.scss';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		trees: state.trees.trees,
		token: state.auth.token,
		nodesInfo: state.nodes,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadTree: (id: string, token: string) => 
		dispatch(actions.tree.load(id, token)),
	loadNodeUsers: (nodeId: number, token: string) => 
		dispatch(actions.node.loadNodeUsers(nodeId, token)),
	loadNodeActionGroups: (nodeId: number, userId: number, token: string) =>
		dispatch(actions.node.loadActionGroups(userId, nodeId, token)),
	reset: () => dispatch(actions.trees.reset())
})

export default connect(mapStateToProps, mapDispatchToProps)(TreeView)