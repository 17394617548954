import { connect } from 'react-redux';
import {Moment} from 'moment';

import actions from '../../actions';
import { State } from '../../reducers';
import { CommonPageProps } from '../../models';
import {transactions} from '../../settings';

import TreeProperties from './TreeProperties';

import './TreeProperties.scss';
import './content/RecordsList.scss';
import './content/NodeInfo.scss';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		users: state.users.list,
		isUsersLoading: state.users.isLoading,
		token: state.auth.token,
		nodesInfo: state.nodes,
		balances: state.balances,
		balanceAttributes: state.balanceAttributes,
		balanceAttributeTypes: state.balanceAttributeTypes,
		balanceTypes: state.balanceTypes.list,
		isBalanceTypesLoading: state.balanceTypes.isLoading,
		currentUser: ownProps.currentUser,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadUsers: (token: string) =>
		dispatch(actions.users.load(token)),
	addNode: (treeId: number, parentId: number, name: string, token: string) =>
		dispatch(actions.tree.addNode(treeId, parentId, name, token)),
	updateNodeName: (treeId: number, nodeId: number, name: string, token: string) =>
		dispatch(actions.tree.update(treeId, nodeId, name, token)),
	loadNodeAttributes: (nodeId: number, token: string) =>
		dispatch(actions.node.loadNodeAttributes(nodeId, token)),
	loadBalances: (treeId: number, token: string) =>
		dispatch(actions.balances.load(treeId, token)),
	loadBalanceTypes: (token: string) =>
		dispatch(actions.balanceTypes.load(token)),
	loadBalanceAttributes: (treeId: number, balanceId: number, token: string) =>
		dispatch(actions.balanceAttributes.load(balanceId, treeId, token)),
	loadBalanceAttributeTypes: (token: string) =>
		dispatch(actions.balanceAttributeTypes.load(token)),
	loadReport: (nodeId: number, date: [Moment, Moment], token: string) =>
		dispatch(actions.report.load(nodeId, date, 'customDate', token)),
		loadTransactions: (treeId: number, token: string) =>
		dispatch(actions.transactions.load(treeId, transactions.defaultFirstPage, transactions.defaultPageSize, {}, token)),
	loadTransactionsElastic: (treeId: number, token: string) =>
		dispatch(actions.transactionsElastic.load(treeId, transactions.defaultFirstPage, transactions.defaultPageSize, {}, null, token)),
	go: (url: string) =>
		dispatch(actions.navigation.go(url))
})

export default connect(mapStateToProps, mapDispatchToProps)(TreeProperties)