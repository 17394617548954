const actionTypes = {
	ATTRIBUTE_GROUP_CREATE: 'ATTRIBUTE_GROUP\\CREATE',
	ATTRIBUTE_GROUP_CREATE_SUCCESS: 'ATTRIBUTE_GROUP\\CREATE_SUCCESS',
	ATTRIBUTE_GROUP_CREATE_FAILED: 'ATTRIBUTE_GROUP\\CREATE_FAILED',

	ATTRIBUTE_GROUP_UPDATE: 'ATTRIBUTE_GROUP\\UPDATE',
	ATTRIBUTE_GROUP_UPDATE_SUCCESS: 'ATTRIBUTE_GROUP\\UPDATE_SUCCESS',
	ATTRIBUTE_GROUP_UPDATE_FAILED: 'ATTRIBUTE_GROUP\\UPDATE_FAILED',

	ATTRIBUTE_GROUP_DELETE: 'ATTRIBUTE_GROUP\\DELETE',
	ATTRIBUTE_GROUP_DELETE_SUCCESS: 'ATTRIBUTE_GROUP\\DELETE_SUCCESS',
	ATTRIBUTE_GROUP_DELETE_FAILED: 'ATTRIBUTE_GROUP\\DELETE_FAILED',
}

const create = (name: string, treeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_GROUP_CREATE,
	payload: {
		name,
		treeId,
		token
	}
})

const createSuccess = (id: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_GROUP_CREATE_SUCCESS,
	payload: {
		id,
		treeId
	}
})

const createFailed = (message: string, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_GROUP_CREATE_FAILED,
	payload: {
		message,
		treeId
	}
})

const update = (id: number, name: string, treeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_GROUP_UPDATE,
	payload: {
		id,
		name,
		treeId,
		token
	}
})

const updateSuccess = (treeId: number) => ({
	type: actionTypes.ATTRIBUTE_GROUP_CREATE_SUCCESS,
	payload: {
		treeId
	}
})

const updateFailed = (message: string, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_GROUP_CREATE_FAILED,
	payload: {
		message,
		treeId
	}
})

const deleteAttributeGroup = (id: number, treeId: number, token: string) =>({
	type: actionTypes.ATTRIBUTE_GROUP_DELETE,
	payload: {
		id,
		treeId,
		token

	}
})

const deleteSuccess = (treeId: number) => ({
	type: actionTypes.ATTRIBUTE_GROUP_DELETE_SUCCESS,
	payload: {
		treeId
	}
})

const deleteFailed = (message: string, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_GROUP_DELETE_FAILED,
	payload: {
		message,
		treeId
	}
})

export default {
	types: actionTypes,
	create,
	createSuccess,
	createFailed,
	update,
	updateSuccess,
	updateFailed,
	delete: deleteAttributeGroup,
	deleteSuccess,
	deleteFailed
};