import { AttributeInfoEntry, AttributeLocator, UpdateAttributeLocatorType } from "../models";

const actionTypes = {
	TRANSACTION_ATTRIBUTES_LOAD: 'TRANSACTION_ATTRIBUTES\\LOAD',
	TRANSACTION_ATTRIBUTES_LOAD_SUCCESS: 'TRANSACTION_ATTRIBUTES\\LOAD_SUCCESS',
	TRANSACTION_ATTRIBUTES_LOAD_FAILED: 'TRANSACTION_ATTRIBUTES\\LOAD_FAILED',

	TRANSACTION_ATTRIBUTES_UPDATE: 'TRANSACTION_ATTRIBUTES\\UPDATE',
	TRANSACTION_ATTRIBUTES_UPDATE_SUCCESS: 'TRANSACTION_ATTRIBUTES\\UPDATE_SUCCESS',
	TRANSACTION_ATTRIBUTES_UPDATE_FAILED: 'TRANSACTION_ATTRIBUTES\\UPDATE_FAILED',
}

const load = (attributes: AttributeLocator[], token: string) => ({
	type: actionTypes.TRANSACTION_ATTRIBUTES_LOAD,
	payload: {
		attributes,
		token
	}
})

const loadSuccess = (attributesInfo: AttributeInfoEntry[]) => ({
	type: actionTypes.TRANSACTION_ATTRIBUTES_LOAD_SUCCESS,
	payload: {
		attributesInfo
	}
})

const loadFailed = (attributes: AttributeLocator[], message: string) => ({
	type: actionTypes.TRANSACTION_ATTRIBUTES_LOAD_FAILED,
	payload: {
		attributes,
		message
	}
})

const update = (attribute: UpdateAttributeLocatorType, token: string) => ({
	type: actionTypes.TRANSACTION_ATTRIBUTES_UPDATE,
	payload: {
		attribute,
		token
	}
})

const updateSuccess = (attribute: UpdateAttributeLocatorType) => ({
	type: actionTypes.TRANSACTION_ATTRIBUTES_UPDATE_SUCCESS,
	payload: {
		attribute
	}
})

const updateFailed = (attribute: UpdateAttributeLocatorType, message: string) => ({
	type: actionTypes.TRANSACTION_ATTRIBUTES_UPDATE_FAILED,
	payload: {
		attribute,
		message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	update,
	updateSuccess,
	updateFailed
};