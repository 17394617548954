import moment, { Moment } from 'moment';

export const rangePickerRanges: Record<string, [Moment, Moment]> = {
  Today: [moment().startOf('days'), moment().startOf('minute')],
  Yesterday: [
    moment().subtract(1, 'day').startOf('day'),
    moment().subtract(1, 'day').endOf('day'),
  ],
  '7 days': [
    moment().subtract(6, 'day').startOf('day'),
    moment().startOf('minute'),
  ],
  'Previous week': [
    moment().weekday(1).subtract(1, 'week').startOf('day'),
    moment().weekday(1).subtract(1, 'day').endOf('day'),
  ],
  'Current week': [
    moment().weekday(1).startOf('day'),
    moment().startOf('minute'),
  ],
  '30 days': [
    moment().subtract(29, 'day').startOf('day'),
    moment().startOf('minute'),
  ],
  'Previous month': [
    moment().startOf('month').subtract(1, 'month'),
    moment().startOf('month').subtract(1, 'day').endOf('day'),
  ],
  'Current month': [moment().startOf('month'), moment().startOf('minute')],
  'Previous year': [
    moment().startOf('year').subtract(1, 'year'),
    moment().startOf('year').subtract(1, 'day').endOf('day'),
  ],
  'Current year': [moment().startOf('year'), moment().startOf('minute')],
  'All time': [
    moment(new Date('2000-01-01T00:00:00')).startOf('year'),
    moment().startOf('minute'),
  ],
};
