import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import actions from '../actions';

const TOKEN_COOKIE_NAME = 'token';

interface TokenInfo{
	sub: string,
	scopes: string[]
}

export interface AuthState{
	token: string,
	userId: number | null,
	isLoading: boolean,
	error: string | null
}

const extractUserInfo = (token?: string): number | null => {
	const tokenInfo = token ? jwtDecode<TokenInfo>(token) : null;
	if(tokenInfo){
		const id = parseInt(tokenInfo.sub);
		return id
	}
	return null;
}
const tokenFromCookie = Cookies.get(TOKEN_COOKIE_NAME);

const defaultState: AuthState = {
	token: tokenFromCookie || '',
	userId: extractUserInfo(tokenFromCookie),
	isLoading: false,
	error: null
}

const auth = (state: AuthState = defaultState, action: any): AuthState => {
	switch(action.type){
		case actions.auth.types.AUTH_LOGIN:
			return {...state, isLoading: true, error: null}
		case actions.auth.types.AUTH_LOGIN_SUCCESS:{
			const token = action.payload.data.token;
			const userId = extractUserInfo(token);
			Cookies.set(TOKEN_COOKIE_NAME, token);
			return {isLoading: false, error: null, token: token, userId: userId}
		}
		case actions.auth.types.AUTH_LOGIN_FAILURE:
			return {...state, isLoading: false, error: action.message}
		case actions.auth.types.AUTH_LOGOUT:{
			Cookies.remove(TOKEN_COOKIE_NAME);
			return {isLoading: false, error: null, token: '', userId: null}
		}
	}
	return state;
}

export default auth