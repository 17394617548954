const actionTypes = {
	NAVIGATION_GO: 'NAVIGATION\\GO'
}

const go = (url: string) => ({
	type: actionTypes.NAVIGATION_GO,
	payload: {
		url
	}
})

export default {
	types: actionTypes,
	go
}