import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Skeleton } from 'antd';

import { User, Role, CommonPageProps, EditUserState } from "../../models";
import NotFound from '../NotFound';

import EditUserGeneral, {SubmitUserType} from './EditUserGeneral';
import { site } from '../../settings';
import { PageTitle } from '../../components';
import { EditUserStates } from '../../reducers/editUserState';


interface EditUserProps extends CommonPageProps{
	isUsersLoading: boolean,
	isUserUpdating: boolean,
	isRolesLoading: boolean,
	users: User[] | null,
	roles: Role[] | null,
	userStates: EditUserStates,
	isNew?: boolean,
	token: string,

	loadUsers: (token: string) => void,
	loadRoles: (token: string) => void,
	updateUser: SubmitUserType,
	createUser: SubmitUserType,
	resetUsers: () => void,
	setUserState: (userId: number, userState: EditUserState) => void
}

const containerClassName = 'edit-user';

const EditUser = (props: EditUserProps) => {
	const {id} = useParams<{id: string}>();
	const {
		users, isUsersLoading, isUserUpdating, roles, isRolesLoading,
		token, securityProvider, isNew, userStates,
		loadUsers, updateUser, createUser, loadRoles, setUserState
	} = props;

	useEffect(() => {
		if(!isNew && users === null && !isUsersLoading){
			loadUsers(token);
		}
		if(roles === null && !isRolesLoading && securityProvider.roles.canRead){
			loadRoles(token);
		}
	});

	let submitUser: SubmitUserType;
	let user: User | undefined;

	if(!isNew){
		if(!isUsersLoading){
			user = users?.find(u => u.id?.toString() === id);

			if(!user){
				return (<NotFound/>);
			}
		}
		submitUser = updateUser;
	} else {
		submitUser = createUser;
	}
	const safeUserId = user?.id || 0;
	let content: JSX.Element;
	if((user || isNew) && !isUsersLoading && !isRolesLoading && !isUserUpdating){
		content = (
			<EditUserGeneral
				isNew = {isNew}
				user = {user}
				roles = {roles}
				token = {token}
				userState = {userStates[safeUserId] || null}
				setUserState = {(userState) => setUserState(safeUserId, userState)}
				submitUser = {submitUser}
				securityProvider = {securityProvider}
				className = {`${containerClassName}__content`}
			/>
		)
	} else {
		content = <Skeleton active/>
	}

	return (
		<Row className = {`${containerClassName} page-content`}>
			<Col md={{span: 12, offset: 2}}>
				<PageTitle
					title = {isNew ? site.newUser.name : user?.name || ''}
					backUrl = {site.users.url}
				/>
				{content}
			</Col>
		</Row>
	);
}

export default EditUser;