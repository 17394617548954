import actions from '../actions';
import { ActionGroup } from '../models';

export interface ActionGroupsState{
	list: ActionGroup[] | null,
	isLoading: boolean,

	isActionGroupUpdating: boolean
}

const defaultState: ActionGroupsState = {
	list: null,
	isLoading: false,

	isActionGroupUpdating: false
}

const actionGroups = (state: ActionGroupsState = defaultState, action: any): ActionGroupsState => {
	switch(action.type){
		case actions.actionGroups.types.ACTION_GROUPS_LOAD:
			return {...state, isLoading: true};
		case actions.actionGroups.types.ACTION_GROUPS_LOAD_SUCCESS:
			const { actionGroups } = action.payload;
			return {...state, list: actionGroups, isLoading: false}
		case actions.actionGroups.types.ACTION_GROUPS_LOAD_FAILED:
			console.error('ACTION_GROUPS_LOAD_FAILED\n%o', action.payload);
			return {...state, list: [], isLoading: false};

		case actions.actionGroups.types.ACTION_GROUPS_RESET:
			return {...defaultState};

		case actions.actionGroup.types.ACTION_GROUP_CREATE:
			return {...state, isActionGroupUpdating: true};
		case actions.actionGroup.types.ACTION_GROUP_UPDATE:
			return {...state, isActionGroupUpdating: true};
		case actions.actionGroup.types.ACTION_GROUP_DELETE:
			return {...state, isActionGroupUpdating: true};
		case actions.actionGroup.types.ACTION_GROUP_REQUEST_SUCCESS:
			return {...state, isActionGroupUpdating: false, list: null};
		case actions.actionGroup.types.ACTION_GROUP_REQUEST_FAILED:
			console.error('ACTION_GROUP_REQUEST_FAILED\n%o', action.payload);
			return {...state, isActionGroupUpdating: false, list: null};
	}
	return state;
}

export default actionGroups