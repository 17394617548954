import { call, takeEvery} from 'redux-saga/effects'

import {config} from '../settings'
import actions from '../actions';
import { TransactionsElasticAttribute } from '../models';

import handleResponse from './handleResponse';
import { TransactionsElasticAttributeValue } from '../models/transactionsElasticAttribute';

type ResponseType = {status: number, body: any} | {error: any};

const {loadSuccess, loadFailed} = actions.transactionsElasticAttributes;

const tryLoadAttributes = (payload: {treeId: number, token: string}): Promise<ResponseType> => {
	try{
		const {treeId, token} = payload;
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);

		return fetch(`${config.api_url}/reports/linear/${treeId}/filters/attributes/values`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractAttributeValues = (data: any): TransactionsElasticAttributeValue[] => {
	if(!data || !Array.isArray(data)){
		return [];
	}
	return data.map(entry => ({
		id: entry.id,
		name: entry.name
	}))
}

const extractAttributes = (data: any): TransactionsElasticAttribute[] => {
	if(!data || !Array.isArray(data)){
		return [];
	}
	return data.map(entry => {
		const values = entry.allowValues ? extractAttributeValues(entry.values) : [];
		return {
			attributeId: Number(entry.attributeId),
			attributeName: entry.attributeName,
			type: entry.type,
			allowValues: !!entry.allowValues,
			values: values
		}
	})
}

const loadSaga = function*(action: any){
	const {treeId} = action.payload;
	const response: ResponseType = yield call(tryLoadAttributes, action.payload);
	yield* handleResponse(
		response,
		(body: any) => loadSuccess(treeId, extractAttributes(body.data)),
		(message: any) => loadFailed(treeId, message)
	)
}

const watch = function*() {
	yield takeEvery(actions.transactionsElasticAttributes.types.TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD, loadSaga)
}

export default watch;