const actionTypes = {
	DEVELOPMENT_CLEAR_CACHE: 'DEVELOPMENT\\CLEAR_CACHE',
	DEVELOPMENT_SYNC_ELASTIC: 'DEVELOPMENT\\SYNC_ELASTIC',
}

const clearCache = (token: string) => ({
	type: actionTypes.DEVELOPMENT_CLEAR_CACHE,
	payload: {
		token
	}
})

const syncElastic = (token: string) => ({
	type: actionTypes.DEVELOPMENT_SYNC_ELASTIC,
	payload: {
		token
	}
})

export default {
	types: actionTypes,
	clearCache,
	syncElastic,
}