import {all } from 'redux-saga/effects';

import authSaga from './auth';
import usersSaga from './users';
import userSaga from './user';
import rolesSaga from './roles';
import roleSaga from './role';
import permissionsSaga from './permissions';
import actionGroupsSaga from './actionGroups';
import actionGroupSaga from './actionGroup';
import treePermissionsSaga from './treePermissions';
import treesSaga from './trees';
import treeSaga from './tree';
import nodesSaga from './nodes';
import accountSaga from './account';
import attributesSaga from './attributes'
import attributeValueSaga from './attributeValue';
import attributeVisibilitiesSaga from './attributeVisibilities';
import attributeGroupSaga from './attributeGroup';
import attributeGroupsSaga from './attributeGroups';
import nodeAttributesForTreeSaga from './nodeAttributesForTree';
import balanceSaga from './balance';
import balancesSaga from './balances';
import balanceLimits from './balanceLimits';
import balanceTypesSaga from './balanceTypes';
import balanceFormulaSaga from './balanceFormula';
import balanceAttributesSaga from './balanceAttributes';
import balanceAttributeTypesSaga from './balanceAttributeTypes';
import balanceAttributeValueSaga from './balanceAttributeValue';
import transactionsSaga from './transactions';
import transactionsElastic from './transactionsElastic';
import transactionFilterValuesSaga from './transactionFilterValues';
import transactionAttributesSaga from './transactionAttributes';
import transactionChangeLogsSaga from './transactionChangeLogs';
import reportSaga from './report';
import reportTemplatesSaga from './reportTemplates';
import developmentSaga from './development';
import versionSaga from './version';
import transactionsElasticAttributesSaga from './transactionsElasticAttributes';
import passwordChangeSaga from './passwordChange';

const rootSaga = function*(){
	yield all([
		authSaga(),
		usersSaga(),
		userSaga(),
		rolesSaga(),
		roleSaga(),
		permissionsSaga(),
		actionGroupsSaga(),
		actionGroupSaga(),
		treePermissionsSaga(),
		treesSaga(),
		treeSaga(),
		nodesSaga(),
		accountSaga(),
		attributesSaga(),
		attributeValueSaga(),
		attributeVisibilitiesSaga(),
		attributeGroupSaga(),
		attributeGroupsSaga(),
		nodeAttributesForTreeSaga(),
		balanceSaga(),
		balancesSaga(),
		balanceLimits(),
		balanceTypesSaga(),
		balanceFormulaSaga(),
		balanceAttributesSaga(),
		balanceAttributeTypesSaga(),
		balanceAttributeValueSaga(),
		transactionsSaga(),
		transactionsElastic(),
		transactionFilterValuesSaga(),
		reportSaga(),
		reportTemplatesSaga(),
		transactionAttributesSaga(),
		transactionChangeLogsSaga(),
		developmentSaga(),
		transactionsElasticAttributesSaga(),
		versionSaga(),
		passwordChangeSaga()
	])
}

export default rootSaga;