import { memo } from "react";
import { connect } from "react-redux";

import actions from "../../actions";
import { CommonPageProps, TransactionChangeLogsFilters } from "../../models";
import { State } from "../../reducers";

import TransactionChangeLogs from "./transactionChangeLogs";

import './transactionChangeLogsContainer.scss';

const mapStateToProps = (state: State, _: CommonPageProps) => {
	return {
		transactionChangeLogs: state.transactionChangeLogs,
		nodes: state.nodes,
		token: state.auth.token,
		users: state.users,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadTransactionChangeLogs: (nodeId: number, currentPage: number, pageSize: number, filters: TransactionChangeLogsFilters, token: string) =>
		dispatch(actions.transactionChangeLogs.load(nodeId, currentPage, pageSize, filters, token)),
	loadNodeName: (nodeId: number, token: string) =>
		dispatch(actions.node.loadName(nodeId, token)),
	loadUsers: (token: string) =>
		dispatch(actions.users.load(token)),
	unloadTransactionChangeLogs: (nodeId: number) =>
		dispatch(actions.transactionChangeLogs.unload(nodeId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(TransactionChangeLogs));
