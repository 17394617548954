import React from 'react';
import {Modal, Skeleton, Tooltip } from 'antd';
import _ from 'lodash';

import { User, Tree, ActionGroup, CommonPageProps, TreesHash, EntityPerTree, Balance, AttributeGroup, NodesState, AssignedUser } from '../../models';
import { localization, TreeSecurityProvider } from '../../settings';
import { AddUserPanel, Switcher, UserPanel } from '../../components';

import RightSide from './RightSide';
import { AttributeGroupsState } from '../../reducers/attributeGroups';
import { removeCurrentUserFromAssignedList } from '../../tools';

type PagesType = 'action groups' | 'attribute groups' | 'balances';
const dialogClassName = "user-action-roles-dialog";

interface UserActionRolesDialogProps<TUser extends User> extends CommonPageProps{
	users: TUser[] | null,
	isUsersLoading: boolean,

	actionGroups: ActionGroup[] | null,
	isActionGroupsLoading: boolean,

	attributeGroupsPerTree: AttributeGroupsState,

	balancesPerTree: EntityPerTree<Balance>,

	treeId: number,
	nodesInfo: NodesState,
	trees: TreesHash,
	node: Tree | null,
	visible: boolean,
	token: string,
	userId: number | null,
	treeSecurityProvider: TreeSecurityProvider,

	onClose: () => void,

	loadUsers: (token: string) => void,
	loadNodeUsers: (nodeId: number, token: string) => void,
	loadUserNodeActionGroups: (userId: number, nodeId: number, token: string) => void,
	loadActionGroups: (token: string) => void,
	assignActionGroup: (add: boolean, userId: number, actionGroupId: number, nodeId: number, token: string) => void,

	loadAttributeGroups: (treeId: number, token: string) => void,
	loadAssignedAttributeGroups: (treeId: number, userId: number, token: string) => void,
	assignAttributeGroup: (treeId: number, attributeGroupId: number, userId: number, isAdd: boolean, token: string) => void,

	loadBalances: (treeId: number, token: string) => void,
	loadAssignedBalances: (treeId: number, userId: number, token: string) => void,
	assignBalance: (treeId: number, balanceId: number, userId: number, isAdd: boolean, token: string) => void,
}

interface UserActionRolesDialogState{
	selectedUserId: number | null;
	selectedPage: PagesType,
	tempUsers: AssignedUser[]
}

class UserActionRolesDialog<TUser extends User>
	extends React.Component<UserActionRolesDialogProps<TUser>, UserActionRolesDialogState>{

	constructor(props: UserActionRolesDialogProps<TUser>){
		super(props);

		this.state = {
			selectedUserId: null,
			selectedPage: 'action groups',
			tempUsers: []
		};
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			users, isUsersLoading, loadUsers,
			actionGroups, isActionGroupsLoading, loadActionGroups,
			nodesInfo, loadNodeUsers, loadUserNodeActionGroups,
			attributeGroupsPerTree, loadAttributeGroups,
			balancesPerTree, loadBalances, loadAssignedBalances,
			trees, loadAssignedAttributeGroups,
			treeId, node, token,
		} = this.props;

		const {selectedUserId} = this.state;

		if(!users && !isUsersLoading){
			loadUsers(token);
		}

		if(!actionGroups && !isActionGroupsLoading){
			loadActionGroups(token);
		}

		if(!attributeGroupsPerTree[treeId]?.list && !attributeGroupsPerTree[treeId]?.isLoading){
			loadAttributeGroups(treeId, token);
		}

		if(!balancesPerTree[treeId]?.list && !balancesPerTree[treeId]?.isLoading){
			loadBalances(treeId, token);
		}

		const selectedNodeInfo = node?.id ? nodesInfo[node?.id] : null;

		if(selectedNodeInfo && !selectedNodeInfo?.assignedUsers && !selectedNodeInfo?.isAssignedUsersLoading && node?.id){
			loadNodeUsers(node.id, token);
		}

		const usersInfo = trees[treeId]?.users;
		if(selectedUserId){
			if( !usersInfo ||
				!usersInfo[selectedUserId] ||
				(!usersInfo[selectedUserId]?.attributeGroupIds && !usersInfo[selectedUserId]?.isAttributeGroupsLoading)
			)
			{
				loadAssignedAttributeGroups(treeId, selectedUserId, token);
			}
			if( !usersInfo ||
				!usersInfo[selectedUserId] ||
				(!usersInfo[selectedUserId]?.balanceIds && !usersInfo[selectedUserId]?.isBalancesLoading)
			){
				loadAssignedBalances(treeId, selectedUserId, token);
			}
			if(
				node?.id &&
				(
					!selectedNodeInfo?.users ||
					(
						!selectedNodeInfo.users[selectedUserId]?.actionGroupIds &&
						!selectedNodeInfo.users[selectedUserId]?.isActionGroupsLoading
					)
				)
			){
				loadUserNodeActionGroups(selectedUserId, node?.id, token);
			}
		}

	}

	getSelectUserClickHandler = (userId: number) => {
		return () => {
			this.setState({
				...this.state,
				selectedUserId: userId
			})
		};
	}

	onUserInvited = (userId: number, nodeId: number) => {
		const {users} = this.props;
		const newUser = users?.find(u => u.id === userId);
		const newUserWithNode: AssignedUser = {
			...newUser,
			inheritedFromNodeId: nodeId
		}
		if(newUser){
			this.setState((state) => {
				const updatedTempUsers = [...state.tempUsers, newUserWithNode]
				return {
					...state,
					tempUsers: updatedTempUsers
				}
			})
		}
	}

	renderInviteUserElement = () => {
		const {users, userId, node} = this.props;

		const availableUsers = users?.filter(u => u.id !== userId);

		return (
			<AddUserPanel
				users = {availableUsers}
				addUser = {(userId) => node?.id && this.onUserInvited(userId, node.id)}
			/>
		)
	}

	renderUsers = () => {
		const {users, nodesInfo, node, userId, treeSecurityProvider} = this.props;
		const {tempUsers, selectedUserId} = this.state;

		if(!users || !nodesInfo || !node?.id || !nodesInfo[node.id] || !nodesInfo[node.id]?.assignedUsers){
			return <Skeleton/>
		}

		const assignedUsers = removeCurrentUserFromAssignedList(nodesInfo[node.id]?.assignedUsers, userId);

		const assignedUsersAndTemp = _.unionBy(assignedUsers, tempUsers, 'id');

		return (
			<>
				{assignedUsersAndTemp.map((user: AssignedUser) => {
					return (
						<Tooltip
							title = {`${user.firstname} ${user.lastname}`}
							placement = "right"
							key = {`uard-usr-${user.id}`}
						>
							<UserPanel
								user = {user}
								selected = {selectedUserId === user.id}
								onClick = {this.getSelectUserClickHandler(user.id || -1)}
							/>
						</Tooltip>
					)
				})}
				{treeSecurityProvider.node.users.canAssign && this.renderInviteUserElement()}
			</>
		);
	}

	switchPage = (page: PagesType) => {
		this.setState((state) => ({
			...state,
			selectedPage: page
		}))
	}

	renderSwitcher = () => {
		const {selectedPage} = this.state;
		const options: {label: string, value: PagesType}[] = [{
			label: localization.userActionGroups_actionGroups,
			value: 'action groups'
		},{
			label: localization.userActionGroups_attributeGroups,
			value: 'attribute groups'
		},{
			label: localization.userActionGroups_balances,
			value: 'balances'
		}];

		return (
			<Switcher
				onChange = {this.switchPage}
				options = {options}
				selectedId = {selectedPage}
			/>
		)
	}

	renderRightPanel = () => {
		const {
			users, actionGroups, attributeGroupsPerTree, balancesPerTree,
			trees, treeId, node, nodesInfo,
			token, treeSecurityProvider,
			assignActionGroup, assignAttributeGroup, assignBalance
		} = this.props;

		const {selectedUserId, selectedPage} = this.state;
		const selectedUser = users && selectedUserId && users.find(u => u.id === selectedUserId);
		if(!node || !selectedUser){
			return null;
		}
		const nodeInfo = node && nodesInfo[node.id];
		const usersInfo = nodeInfo && nodeInfo?.users;
		const userActionGroupIds = (usersInfo && selectedUserId && usersInfo[selectedUserId]?.actionGroupIds) || null;

		const usersOnTree = trees[treeId]?.users;

		const attributeGroups = attributeGroupsPerTree[treeId]?.list || null;
		const userAttributeGroups = usersOnTree && selectedUserId && usersOnTree[selectedUserId]?.attributeGroupIds;

		const balances = balancesPerTree[treeId]?.list || null;
		const userBalances = usersOnTree && selectedUserId && usersOnTree[selectedUserId]?.balanceIds;

		const selectedAssignedUser = nodeInfo?.assignedUsers?.find(au => au.id === selectedUserId)
		const isUserInherited = selectedAssignedUser && selectedAssignedUser.inheritedFromNodeId !== node.id;

		let entities: AttributeGroup[] | ActionGroup[] | Balance[] | null = null;
		let selectedIds = null;
		let assign = null;
		let disabled = false;
		switch(selectedPage){
			case 'attribute groups':
				entities = attributeGroups;
				selectedIds = userAttributeGroups || null;
				assign = (groupId: number, isAdd: boolean) =>
					selectedUserId && assignAttributeGroup(treeId, groupId, selectedUserId, isAdd, token);
				break;
			case 'balances':
				entities = balances;
				selectedIds = userBalances || null;
				assign = (balanceId: number, isAdd: boolean) =>
					selectedUserId && assignBalance(treeId, balanceId, selectedUserId, isAdd, token);
				if(selectedUser.permissions?.find(permisssion => permisssion.slug === 'access_all_balances')) {
					disabled = true;
				}
				break;
			default:
				entities = actionGroups;
				selectedIds = userActionGroupIds;
				assign = (agId: number, isAdd: boolean) =>
					selectedUserId && assignActionGroup(isAdd, selectedUserId, agId, node.id, token);
		}

		return (
			<RightSide<{id?: number, name?: string}>
				selectedUser = {selectedUser}
				disabled = {isUserInherited || disabled}
				nodeId = {node.id}
				entities = {entities}
				selectedIds = {selectedIds}
				treeSecurityProvider = {treeSecurityProvider}
				assign = {assign}
			>
				{this.renderSwitcher()}
			</RightSide>
		)
	}

	render(){
		const {
			node,
			visible, onClose
		} = this.props;

		return (
			<Modal
				visible = {visible}
				className = {dialogClassName}
				onCancel = {onClose}
				footer = {null}
				centered
				width={800}
			>
				<div className={`${dialogClassName}__content`}>
					<div className={`${dialogClassName}__content__users`}>
						<div className={`${dialogClassName}__content__users__title`}>
							{localization.userActionGroups_users} {node?.name}
						</div>
						<div className={`${dialogClassName}__content__users__wrapper`}>
							{this.renderUsers()}
						</div>
					</div>
					<div className={`${dialogClassName}__content__right-panel`}>
						{this.renderRightPanel()}
					</div>
				</div>
			</Modal>
		)
	}
}

export default UserActionRolesDialog;