import {formatters} from "../../tools";

const SEPARATOR = '-%-';

// pass string ${id}${SEPARATOR}${caption} to devexpress as data to:
// 1. avoid grouping records with the same captions and different ids
// 2. use ids instead of captions in filters
const processSingleEntry = (data: any, balTypes: number[], precision: Record<string, number>) => {
	return {
		key: `${(data.id === '' || data.id == null) ? '' : data.id}${SEPARATOR}${data.caption}`,
		summary: data.summary.map((summ: number, i: number) => {
			if(balTypes[i] === 1) {
				return formatters.formatNumber(summ, precision.natural)
			} else if(balTypes[i] === 2) {
				return formatters.formatNumber(summ, precision.finance)
			} else {
				return formatters.formatNumber(summ, precision.calculate)
			}
		})
	};
}

const processTree = (data: any, balTypes: number[], precision: Record<string, number>) => {
	const items = data.items?.map((e: any) => processTree(e, balTypes, precision)) || null;
	const result = {
		...processSingleEntry(data, balTypes, precision),
		items: items
	};
	return result;
}

const pivotGridPlug = {
	convert: processTree,
	revert: (value: string | number): [string | null, string] => {
		const text = typeof value === 'string' ? value : `${value}`;
		const separatorIndex = text.indexOf(SEPARATOR);
		const idStr = text.substring(0, separatorIndex);
		const caption = text.substring(separatorIndex + SEPARATOR.length);
		return [
			idStr,
			caption
		];
	},

	getText: (cellInfo: {
		value?: string | number | Date;
		valueText?: string;
	}) => {
		const text = cellInfo.valueText || '';
		const [, caption] = pivotGridPlug.revert(text);
		return caption;
	},
	alphabeticSort: (a: {value?: string | number}, b: {value?: string | number}) => {
		const [, captionA] = pivotGridPlug.revert(`${a.value}`);
		const [, captionB] = pivotGridPlug.revert(`${b.value}`);
		return captionA.localeCompare(captionB);
	},
	attributesSort: (a: {value?: string | number}, b: {value?: string | number}) => {
		const [idA, captionA] = pivotGridPlug.revert(`${a.value}`);
		const [idB, captionB] = pivotGridPlug.revert(`${b.value}`);
		if(idA === '0'){
			return Infinity;
		}
		if(idB === '0'){
			return -Infinity;
		}
		const isDeletedA = captionA.match(/Deleted \([^)]+\)/)
		const isDeletedB = captionB.match(/Deleted \([^)]+\)/)
		if (isDeletedA && !isDeletedB) return 1
		if (!isDeletedA && isDeletedB) return -1
		return captionA.localeCompare(captionB);
	}
}


export default pivotGridPlug;
