import { notification } from 'antd';

import actions from '../actions'

import { State } from '.';

const preHandler = (state: State | undefined, action: any): State | undefined => {
	switch(action.type){
		case actions.auth.types.AUTH_LOGOUT:
			// wipe all data from state when user logs out
			return undefined;

		case actions.error.types.ERROR_RAISE:{
			const {message} = action.payload;
			notification.error({
				message: message,
				duration: 0,
			});
		}
	}
	return state;
}

export default preHandler;