import React from 'react';
import { Table, Modal, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { CommonPageProps, Role, User } from '../../models';
import { site, localization } from '../../settings';
import { EditButtonsSet, PageTitle } from '../../components';
import { Trash } from 'react-feather';

type DeleteUserType = (id: number, token: string) => void;

interface UsersProps extends CommonPageProps{
	users: User[] | null,
	isLoading: boolean,
	roles: Role[] | null,
	isRolesLoading: boolean,
	token: string,

	loadUsers: (token: string) => void,
	deleteUser: DeleteUserType,
	loadRoles: (token: string) => void
}

const containerClassName = 'users-page';

class Users extends React.Component<UsersProps>{
	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			users, isLoading, loadUsers,
			roles, isRolesLoading, loadRoles,
			securityProvider, token
		} = this.props;

		if(users === null && !isLoading){
			loadUsers(token);
		}
		if(roles == null && !isRolesLoading && securityProvider.roles.canRead){
			loadRoles(token);
		}
	}

	getDeleteUserClickHandler = (user?: User) => {
		const {deleteUser, token} = this.props;

		return () => {
			Modal.confirm({
				title: localization.delete_user_title.replace('{}', `${user?.name}`),
				icon: <Trash style = {{float: 'left', marginRight: 10}} />,
				content: localization.delete_user_content.replace('{}', `${user?.name}`),
				okText: localization.yes,
				okType: 'danger',
				cancelText: localization.no,
				onOk() {
					if(user?.id !== undefined){
						deleteUser(user.id, token);
					}
				}
			})
		}
	}

	getColumns = () => {
		const {roles, securityProvider} = this.props;

		const getSorterFor = (fieldName: string) => (a:any, b:any) => {
			return (a[fieldName] || '').localeCompare(b[fieldName] || '')
		};

		const roleFilters = roles?.map(r => ({
			text: r.name,
			value: r.id || ''
		}));

		const onRoleFilter = (value: any, user: any) => {
			return user.roles.findIndex((r: any) => r.id === value) > -1;
		};

		const columns: ColumnsType<any> = [{
			title: localization.login,
			dataIndex: 'name',
			sorter: getSorterFor('name'),
			showSorterTooltip: false
		},{
			title: localization.firstname,
			dataIndex: 'firstname',
			sorter: getSorterFor('firstname'),
			showSorterTooltip: false
		},{
			title: localization.lastname,
			dataIndex: 'lastname',
			sorter: getSorterFor('lastname'),
			showSorterTooltip: false
		},{
			title: localization.email,
			dataIndex: 'email',
			sorter: getSorterFor('email'),
			showSorterTooltip: false
		}]

		if(securityProvider.roles.canRead){
			columns.push({
				title: localization.roles,
				dataIndex: 'roles',
				filters: roleFilters,
				onFilter: onRoleFilter,
				render: this.renderRoles
			})
		}
		if(securityProvider.users.canEdit || securityProvider.users.canDelete){
			columns.push({
				title: null,
				render: (user: User) => (
					<EditButtonsSet
						editUrl = {`${site.users.url}/${user.id}`}
						isEditAvailable = {securityProvider.users.canDelete}
						onDelete = {this.getDeleteUserClickHandler(user)}
						isDeleteAvailable = {securityProvider.users.canDelete}
						className = {`${containerClassName}__actions`}
					/>
				)
			})
		}
		return columns;
	}

	renderRoles = (roles: Role[]) => {
		return (
			<>
				{roles.map((role => {
					return (
						<Tag key={role.id} style={{margin: 5}}>
							{role.name}
						</Tag>
					);
				}))}
			</>
		)
	}

	render(){
		const {users, isLoading, securityProvider} = this.props;

		const dataSource = users?.map((user: User) => ({
			key: user.id,
			id: user.id,
			name: user.name,
			firstname: user.firstname,
			lastname: user.lastname,
			email: user.email,
			roles: user.roles || []
		}));

		return (
			<div className={`${containerClassName} page-content`}>
				<PageTitle
					title = {site.users.name}
					buttonTitle = {localization.newUserButton}
					buttonUrl = {site.newUser.url}
					buttonClassName = {`${containerClassName}__new`}
					showButton = {securityProvider.users.canCreate}
				/>
				<Table
					columns = {this.getColumns()}
					dataSource = {dataSource}
					loading = {isLoading}
					pagination = {false}
					className = {`${containerClassName}__table`}
				/>
			</div>
		)

	}
}

export default Users;