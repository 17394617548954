import { connect } from 'react-redux';

import actions from '../../actions';
import { CommonPageProps, TransactionsTableState, TransactionsFilters, TransactionsElasticAttributesFilter } from '../../models';
import { State } from '../../reducers';

import './TransactionsElasticContainer.scss';

import TransactionsElastic from './TransactionsElastic';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		nodes: state.nodes,
		transactionsPerTree: state.transactionsElastic,
		transactionFilterValues: state.transactionFilterValues,
		transactionAttributes: state.transactionAttributes,
		transactionsElasticContainerStates: state.transactionsElasticContainerStates,
		transactionsElasticAttributes: state.transactionsElasticAttributes,
		token: state.auth.token,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadNodeName: (nodeId: number, token: string) =>
		dispatch(actions.node.loadName(nodeId, token)),
	loadTransactions: (treeId: number, page: number, size: number, filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter | null, token: string) => 
		dispatch(actions.transactionsElastic.load(treeId, page, size, filters, attributes, token)),
	unloadTransactions: (treeId: number) =>
		dispatch(actions.transactionsElastic.unload(treeId)),
	loadTransactionFilterValues: (treeId: number, fieldName: string, token: string) =>
		dispatch(actions.transactionFilterValues.loadFilter(treeId, fieldName, token)),
	loadNodeActionGroups: (nodeId: number, userId: number, token: string) =>
		dispatch(actions.node.loadActionGroups(userId, nodeId, token)),
	loadTransactionsElasticAttributes: (treeId: number, token: string) =>
		dispatch(actions.transactionsElasticAttributes.load(treeId, token)),
	saveContainerState: (treeId: number, state: TransactionsTableState) =>
		dispatch(actions.transactionsElasticContainerState.save(treeId, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsElastic)