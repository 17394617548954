import { connect } from 'react-redux';

import actions from '../../actions';
import { AttributeLocator, CommonPageProps, payloads, TransactionsTableState, TransactionsFilters, UpdateAttributeLocatorType } from '../../models';
import { State } from '../../reducers';

import Transactions from './Transactions';

import './TransactionsContainer.scss';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		nodes: state.nodes,
		transactionsPerTree: state.transactions,
		transactionFilterValues: state.transactionFilterValues,
		transactionAttributes: state.transactionAttributes,
		transactionsContainerStates: state.transactionsContainerStates,
		token: state.auth.token,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadNodeName: (nodeId: number, token: string) =>
		dispatch(actions.node.loadName(nodeId, token)),
	loadTransactions: (treeId: number, page: number, size: number, filters: TransactionsFilters, token: string) => 
		dispatch(actions.transactions.load(treeId, page, size, filters, token)),
	unloadTransactions: (treeId: number) =>
		dispatch(actions.transactions.unload(treeId)),
	updateTransaction: (payload: payloads.UpdateTransactionPayloadType, token: string) =>
		dispatch(actions.transactions.update(payload, token)),
	loadTransactionFilterValues: (treeId: number, fieldName: string, token: string) =>
		dispatch(actions.transactionFilterValues.loadFilter(treeId, fieldName, token)),
	loadTransactionAttributes: (attributes: AttributeLocator[], token: string) => 
		dispatch(actions.transactionAttributes.load(attributes, token)),
	updateTransactionAttributes: (attribute: UpdateAttributeLocatorType, token: string) =>
		dispatch(actions.transactionAttributes.update(attribute, token)),
	cancelTransaction: (treeId: number, transactionId: number, token: string) => 
		dispatch(actions.transactions.cancel(treeId, transactionId, token)),
	loadNodeActionGroups: (nodeId: number, userId: number, token: string) =>
		dispatch(actions.node.loadActionGroups(userId, nodeId, token)),
	saveContainerState: (treeId: number, state: TransactionsTableState) =>
		dispatch(actions.transactionsContainerState.save(treeId, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)