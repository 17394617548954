import React from 'react';
import { Form, Input, Modal } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../actions';
import { State } from '../../../../reducers';

interface IForm {
  password: string;
  confirmPassword: string;
}

interface IProps {
  visible: boolean;
  toggleModal: () => void;
}

const authSelector = (state: State) => ({
  token: state.auth.token,
  currentUserId: state.auth.userId,
});

export const PasswordChangeModal = ({ visible, toggleModal }: IProps) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm<IForm>();

  const { token, currentUserId } = useSelector(authSelector);

  const onSubmit = () => {
    toggleModal();
    const password = form.getFieldValue('password');
    dispatch(actions.passwordChange.save(token, currentUserId!, password));
  };

  const equalFieldsRuleValidator = (_: RuleObject, value: string) =>
    value !== form.getFieldValue('password')
      ? Promise.reject('Введенные пароли не совпадают')
      : Promise.resolve();

  return (
    <Modal
      title="Смена пароля"
      afterClose={form.resetFields}
      onOk={form.submit}
      onCancel={toggleModal}
      visible={visible}
      okText="Сохранить"
      cancelText="Отменить"
    >
      <Form
        form={form}
        onFinish={onSubmit}
        name="changePassword"
        validateTrigger={['onBlur']}
      >
        <Form.Item
          name="password"
          label="Новый пароль"
          colon={false}
          labelCol={{ span: 24 }}
          rules={[
            { required: true, message: 'Необходимо ввести пароль' },
            { min: 8, message: 'Длина пароля должна быть не меньше 8 символов' },
          ]}
          required={false}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Повторите пароль"
          colon={false}
          labelCol={{ span: 24 }}
          rules={[{ validator: equalFieldsRuleValidator }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
