import React, { useState } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { v4 as uuid4 } from 'uuid';

import DndListEntry from './DndListEntry';

interface DndListProps<TEntry extends {id: React.ReactText}>{
	className?: string | null,
	entryClassName?: string | null,
	children?: React.ReactNode,
	items: TEntry[],

	render: (item: TEntry, ref: React.RefObject<HTMLDivElement>) => JSX.Element,
	onMoveItem?: (source: TEntry, initialIndex: number, finalIndex: number) => void
}
const containerClassName = 'dnd-list';

const DndList = <TEntry extends {id: React.ReactText}>(props: DndListProps<TEntry>) => {
	const {
		items, className, entryClassName, children,
		render, onMoveItem
	} = props;

	const [uuid] = useState(uuid4());

	const getMoveEntry = (source: TEntry) => (dragIndex: number, hoverIndex: number) => {
		dragIndex !== hoverIndex && onMoveItem && onMoveItem(source, dragIndex, hoverIndex);
	};

	const entries = items.map((item, index) => {
		return (
			<DndListEntry
				className = {entryClassName}
				index = {index}
				listDescriptor = {uuid}
				key = {item.id}
				item = {item}
				render = {render}
				moveFinished = {getMoveEntry(item)}
			/>
		)
	})

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<DndProvider backend = {HTML5Backend}>
			<div className = {effectiveClassName}>
				{entries}
				{children}
			</div>
		</DndProvider>
	)
}

export default DndList;