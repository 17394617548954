import React from 'react';
import { TreeSelector } from '../../components';

import { NodesState, CommonPageProps, TreeListEntry, EntityPerTree, Balance, BalanceType, BalanceLimit, EntityPerBalance, BalanceAttribute, EntityHolder, BalanceAttributeType, BalanceAttributeInfo } from '../../models';
import UserAccount from '../../models/userAccount';
import { localization, SecurityProvider } from '../../settings';

import BalancesList from './BalancesList';

interface AttributeGroupsProps extends CommonPageProps{
	balancesPerTree: EntityPerTree<Balance>,
	treesList: TreeListEntry[] | null,
	isTreesListLoading: boolean,
	balanceTypes: BalanceType[] | null,
	isBalanceTypesLoading: boolean,
	balanceLimits: BalanceLimit[] | null,
	isBalanceLimitsLoading: boolean,
	balanceAttributes: EntityPerBalance<BalanceAttribute>,
	balanceAttributeTypes: EntityHolder<BalanceAttributeType>
	token: string,
	nodesInfo: NodesState,

	currentUser: UserAccount,
	securityProvider: SecurityProvider,

	loadTrees: (token: string) => void,
	unloadTree: (treeId: number) => void,
	loadBalances: (treeId: number, token: string) => void,
	loadBalanceTypes: (token: string) => void,
	loadBalanceLimits: (token: string) => void,
	loadBalanceAttributes: (balanceId: number, treeId: number, token: string) => void,
	loadBalanceAttributeTypes: (token: string) => void,
	createBalance: (name: string, typeId: number, creditLimitId: number | null, limit: number | null, treeId: number, token: string) => void,
	updateBalance: (balanceId: number, balance: {name?: string, creditLimitId?: number, limit?: number | null}, treeId: number, token: string) => void,
	deleteBalance: (balanceId: number, treeId: number, token: string) => void,
	reorderBalances: (orderedIds: {id: number, order: number}[], treeId: number, token: string) => void,
	createBalanceAttribute: (info: BalanceAttributeInfo, balanceId: number, treeId: number, token: string) => void,
	updateBalanceAttribute: (info: BalanceAttributeInfo, balanceId: number, treeId: number, token: string) => void,
	deleteBalanceAttribute: (balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void,
	orderBalanceAttributes: (idsWithOrder: {id: number, order: number}[], balanceId: number, treeId: number, token: string) => void,
	createBalanceAttributeValue: (name: string, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void,
	deleteBalanceAttributeValue: (id: number, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void,
	orderBalanceAttributeValues: (orderedIds: {id: number, order: number}[], balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void
	resetTree: (id: number) => void
}

interface AttributeGroupsState{
	selectedTreeId: number | null
}

const defaultState: AttributeGroupsState = {
	selectedTreeId: null
}

const containerClassName = 'balances-page';

class AttributeGroups extends React.Component<AttributeGroupsProps, AttributeGroupsState>{
	constructor(props: AttributeGroupsProps){
		super(props)

		this.state = defaultState;
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			treesList, isTreesListLoading, loadTrees,
			token
		} = this.props;
		if(!treesList && !isTreesListLoading){
			loadTrees(token);
		}
	}

	selectTreeHandler = (treeId?: number, event?: React.MouseEvent) => {
		event && event.stopPropagation();
		if(treeId != null){
			this.setState((state) => ({
				...state,
				selectedTreeId: treeId
			}))
		}
	}

	render(){
		const {
			treesList,
			token, currentUser, securityProvider,
			balancesPerTree, loadBalances,
			balanceTypes, isBalanceTypesLoading, loadBalanceTypes,
			balanceLimits, isBalanceLimitsLoading, loadBalanceLimits,
			balanceAttributes, loadBalanceAttributes,
			balanceAttributeTypes, loadBalanceAttributeTypes,
			createBalance, updateBalance, deleteBalance, reorderBalances,
			createBalanceAttribute, updateBalanceAttribute, deleteBalanceAttribute, orderBalanceAttributes,
			createBalanceAttributeValue, deleteBalanceAttributeValue, orderBalanceAttributeValues,
			resetTree
		}= this.props;
		const {selectedTreeId} = this.state;
		const treeInfo = treesList && selectedTreeId ? treesList.find(tree => tree.id === selectedTreeId) : null;
		const balances = selectedTreeId ? balancesPerTree[selectedTreeId] : null;

		return ( 
			<div className = {containerClassName}>
					<TreeSelector
						treesList = {treesList}
						title = {localization.attributeGroups_trees}
						selectedTreeId = {selectedTreeId}
						onSelect = {this.selectTreeHandler}
						className = {`${containerClassName}__trees`}
					/>
				<BalancesList
					className = {`${containerClassName}__balances`}
					treeInfo = {treeInfo || null}
					balances = {balances}
					loadBalances = {loadBalances}
					balanceTypes = {balanceTypes}
					isBalanceTypeLoading = {isBalanceTypesLoading}
					loadBalanceTypes = {loadBalanceTypes}
					balanceLimits = {balanceLimits}
					isBalanceLimitsLoading = {isBalanceLimitsLoading}
					loadBalanceLimits = {loadBalanceLimits}
					balanceAttributes = {balanceAttributes}
					loadBalanceAttributes = {loadBalanceAttributes}
					balanceAttributeTypes = {balanceAttributeTypes}
					loadBalanceAttributeTypes = {loadBalanceAttributeTypes}
					token = {token}
					currentUser = {currentUser}
					securityProvider = {securityProvider}
					createBalance = {createBalance}
					updateBalance = {updateBalance}
					deleteBalance = {deleteBalance}
					reorderBalances = {reorderBalances}
					createBalanceAttribute = {createBalanceAttribute}
					updateBalanceAttribute = {updateBalanceAttribute}
					deleteBalanceAttribute = {deleteBalanceAttribute}
					orderBalanceAttributes = {orderBalanceAttributes}
					createBalanceAttributeValue = {createBalanceAttributeValue}
					deleteBalanceAttributeValue = {deleteBalanceAttributeValue}
					orderBalanceAttributeValues = {orderBalanceAttributeValues}
					resetTree = {resetTree}
				/>
			</div>
		)
	}
}

export default AttributeGroups;