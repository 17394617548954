import React from 'react';
import { RefreshCw, Search } from 'react-feather';
import moment, { Moment } from 'moment';
import dateFormat from 'dateformat';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import { TransactionChangeLogEntry, TransactionChangeLogs, TransactionChangeLogsFilters, User, UserAccount } from '../../models';
import { getSecurityProvider, localization, TreeSecurityProvider, transactionChanges as transactionChangesSettings } from '../../settings';
import { Filters, Pagination } from '../../components'

import Workspace from '../Workspace';
import { AttributeValueChange, SimpleValueChange } from '../../models/transactionChangeLogs';

const containerClassName = 'transaction-change-logs-container';
const DATE_FROM = 'dateChangeAtFrom';
const DATE_TO = 'dateChangeAtTo';
const AUTHOR_ID = 'authorId';

type TransactionChangeLogEntryRepresentation = TransactionChangeLogEntry & {
	key: React.ReactText
}

interface TransactionChangeLogsContainerProps{
	treeId: number,
	treeName: string | null,
	usersList: User[] | null,
	transactionChangeLogs?: TransactionChangeLogs,

	currentUser: UserAccount,
	treeSecurityProvider: TreeSecurityProvider,
	token: string,

	loadTransactionChangeLogs: (nodeId: number, currentPage: number, pageSize: number, filters: TransactionChangeLogsFilters, token: string) => void
}

interface TransactionChangeLogsContainerState{
}

const defaultState: TransactionChangeLogsContainerState = {
}

class TransactionChangeLogsContainer extends React.Component<TransactionChangeLogsContainerProps, TransactionChangeLogsContainerState>{
	constructor(props: TransactionChangeLogsContainerProps){
		super(props);

		this.state = {...defaultState}
	}

	getData = (): TransactionChangeLogEntryRepresentation[] | undefined => {
		const {transactionChangeLogs} = this.props;
		const records = transactionChangeLogs?.list?.map(e => ({
			...e,
			key: `${dateFormat(e.changeDate, 'dd-mm-yyyy hh:mm:ss', true)}---${e.operationId}---${e.authorName}`
		}));
		return records || undefined;
	}

	renderDropdownRange = (props: FilterDropdownProps) => {
		const {
			treeId,
			transactionChangeLogs,
			token,
			loadTransactionChangeLogs
		} = this.props;

		const {
			selectedKeys,
			setSelectedKeys, confirm, clearFilters
		} = props;

		if(!transactionChangeLogs){
			return null;
		}

		const onRangeFilterChange = (startDate: Moment | null, endDate: Moment | null) => {
			if(!transactionChangeLogs){
				return;
			}
			if(!startDate && !endDate){
				return onRangeFilterReset();
			}
			// As type of key doos not accept null or undefined, store null value as string
			setSelectedKeys([startDate?.valueOf() || 'NULL', endDate?.valueOf() || 'NULL']);
	
			const {pageSize, filters} = transactionChangeLogs;
			const updatedFilters = {
				...filters
			}
			if(startDate != null){
				updatedFilters[DATE_FROM] = startDate.format('DD-MM-YYYY');
			}
			if(endDate != null){
				updatedFilters[DATE_TO] = endDate.format('DD-MM-YYYY');
			}
			loadTransactionChangeLogs(treeId, transactionChangesSettings.defaultFirstPage, pageSize, updatedFilters, token);
	
			confirm();
		}
	
		const onRangeFilterReset = () => {
			if(!transactionChangeLogs){
				return;
			}
			const {pageSize, filters} = transactionChangeLogs;
			const updatedFilters = {
				...filters,
			}
			delete updatedFilters[DATE_FROM];
			delete updatedFilters[DATE_TO];
			loadTransactionChangeLogs(treeId, transactionChangesSettings.defaultFirstPage, pageSize, updatedFilters, token);

			clearFilters && clearFilters();
		}

		const startDate = typeof selectedKeys[0] === 'number' ? moment(selectedKeys[0]) : null;
		const endDate = typeof selectedKeys[1] === 'number' ? moment(selectedKeys[1]) : null;
		return (
			<Filters.RangeFilter
				startDate = {startDate}
				endDate = {endDate}
				onChange = {onRangeFilterChange}
				onReset = {onRangeFilterReset}
			/>
		)
	}

	renderAuthorSelectDropdown =  (props: FilterDropdownProps) => {
		const {
			treeId, usersList,
			transactionChangeLogs,
			token,
			loadTransactionChangeLogs
		} = this.props;

		const {
			selectedKeys,
			setSelectedKeys, confirm, clearFilters
		} = props;

		const availableValues = (usersList || []).map(u => ({
			id: u.id || -1,
			text: u.name || ''
		}));
		const selectedValueId = selectedKeys[0] != null ? Number.parseInt(`${selectedKeys[0]}`) : null;

		const onAuthorFilterChange = (newValueId : number) => {
			if(!transactionChangeLogs){
				return;
			}
			setSelectedKeys([`${newValueId}`]);
			const {pageSize, filters} = transactionChangeLogs;
			const updatedFilters = {
				...filters,
				[AUTHOR_ID]: `${newValueId}`
			}
			loadTransactionChangeLogs(treeId, transactionChangesSettings.defaultFirstPage, pageSize, updatedFilters, token);
	
			confirm();
		}
	
		const onAuthorFilterReset = () => {
			if(!transactionChangeLogs){
				return;
			}
			const {pageSize, filters} = transactionChangeLogs;
			const updatedFilters = {
				...filters,
			}
			delete updatedFilters[AUTHOR_ID];
			loadTransactionChangeLogs(treeId, transactionChangesSettings.defaultFirstPage, pageSize, updatedFilters, token);

			clearFilters && clearFilters();
		}

		return (
			<Filters.SelectFilter
				availableValues = {availableValues}
				valueId = {selectedValueId}
				onChange = {onAuthorFilterChange}
				onReset = {onAuthorFilterReset}
			/>
		)
	}

	getColumns = (): ColumnType<TransactionChangeLogEntryRepresentation>[] => {
		const getSearchIconElement = (filtered: boolean) => (
			<div className = {`${containerClassName}__filter`}>
				<Search className = 'feather-ico'/>
			</div>
		)

		return [{
			title: localization.transaction_changes_page_changeDate,
			dataIndex: 'changeDate',
			filterIcon: getSearchIconElement,
			filterDropdown: this.renderDropdownRange,
			render: (value: Date) => {
				const className = `${containerClassName}__entry__cell`;
				return (
					<span className = {className}>
						{dateFormat(value, 'dd-mm-yyyy hh:mm:ss', true)}
					</span>
				);
			}
		}, {
			title: localization.transaction_changes_page_author_name,
			dataIndex: 'authorName',
			filterIcon: getSearchIconElement,
			filterDropdown: this.renderAuthorSelectDropdown,
			render: (value: any) => {
				const className = `${containerClassName}__entry__cell`;
				return (
					<span className = {className}>
						{value}
					</span>
				);
			}
		}, {
			title: localization.transaction_changes_page_transaction_number,
			dataIndex: 'transactionId',
			render: (value: any) => {
				const className = `${containerClassName}__entry__cell`;
				return (
					<span className = {className}>
						{value}
					</span>
				);
			}
		}, {
			title: localization.transaction_changes_page_operation_number,
			dataIndex: 'operationId',
			render: (value: any) => {
				const className = `${containerClassName}__entry__cell`;
				return (
					<span className = {className}>
						{value}
					</span>
				);
			}
		}, {
			title: localization.transaction_changes_page_field_name,
			dataIndex: 'fieldName',
			render: (value: any) => {
				const className = `${containerClassName}__entry__cell`;
				return (
					<span className = {className}>
						{value}
					</span>
				);
			}
		}, {
			title: localization.transaction_changes_page_changes,
			render: (_value: any, record: TransactionChangeLogEntryRepresentation) => {
				const className = `${containerClassName}__entry__cell`;
				const extractTitle = (value: SimpleValueChange | AttributeValueChange) => {
					if('attributeId' in value && value.valueId != null){
						return `${value.valueId}`;
					}
					return undefined;
				}
				return (
					<span className = {className}>
						{'attributeId' in record.newValue && (
							<span
								className = {`${className}__attribute-name`}
								title = {`${record.newValue.attributeId}`}
							>
								{record.newValue.attributeName}
							</span>
						)}
						<span
							className = {`${className}__old-value`}
							title = {extractTitle(record.oldValue)}
						>
							{record.oldValue.value}
						</span>
						<span
							className = {`${className}__new-value`}
							title = {extractTitle(record.newValue)}
						>
							{record.newValue.value}
						</span>
					</span>
				);
			}
		}, {
			title: localization.transaction_changes_page_ip,
			dataIndex: 'ipAddress',
			render: (value: any) => {
				const className = `${containerClassName}__entry__cell`;
				return (
					<span className = {className}>
						{value}
					</span>
				);
			}
		}]
	}

	onRefresh = () => {
		const {
			treeId, transactionChangeLogs,
			token,
			loadTransactionChangeLogs
		} = this.props;
		const currentPage = transactionChangeLogs?.currentPage || transactionChangesSettings.defaultFirstPage;
		const pageSize = transactionChangeLogs?.pageSize || transactionChangesSettings.defaultPageSize;
		const filters = transactionChangeLogs?.filters || {};
		loadTransactionChangeLogs(treeId, currentPage, pageSize, filters, token);
	}

	handlePageChange = (pageNumber: number) => {
		const {
			treeId, transactionChangeLogs,
			token,
			loadTransactionChangeLogs
		} = this.props;
		transactionChangeLogs && loadTransactionChangeLogs(
			treeId, pageNumber, transactionChangeLogs.pageSize, transactionChangeLogs.filters, token
		);
	}

	renderPageTitle = (title: string) => {
		return (
			<div className = {`${containerClassName}__header`}>
				<span
					className = {`${containerClassName}__header__text`}
				>
					{title}
				</span>
				<span
					className = {`${containerClassName}__header__icon`}
				>
					<RefreshCw
						className = {`feather-ico`}
						onClick = {() => this.onRefresh()}
					/>
				</span>
			</div>
		);
	}

	render(){
		const {
			treeName, treeId,
			transactionChangeLogs,
			currentUser
		} = this.props;

		const data = this.getData();
		const columns = this.getColumns();
		const title = localization.transaction_change_logs_title_template.replace('{}', `${treeName}`);

		return (
			<div className = {containerClassName}>
				<Workspace
					title = {title}
					pageTitle = {this.renderPageTitle(title)}
					transactionsChangesListId = {treeId}
					currentUser = {currentUser}
					securityProvider = {getSecurityProvider(currentUser.permissions)}
				>
										<div className = {`${containerClassName}__content`}>
						<div className = {`${containerClassName}__content__table`}>
							<Table<TransactionChangeLogEntryRepresentation>
								dataSource = {data}
								columns = {columns}
								loading = {!data}
								scroll = {{
									x: '100%',
									y: '100%'
								}}
								pagination = {false}
							/>
						</div>
						<div className = {`${containerClassName}__content__pagination`}>
							{transactionChangeLogs && (
								<Pagination
									pageNumber = {transactionChangeLogs.currentPage}
									onChange = {this.handlePageChange}
								/>
							)}
						</div>
					</div>
				</Workspace>
			</div>
		)
	}
}

export default TransactionChangeLogsContainer;
