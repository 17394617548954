import React from 'react';
import { Select, Spin, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { AttributeInfo, AttributeLocator, AttributeType, TransactionAttributes, TransactionOperation, TransactionOperationAttribute, UpdateAttributeLocatorType } from '../../models';
import { localization } from '../../settings';
import { Input } from '..';
import {formatters} from "../../tools";

interface TransactionOperationRepresentation extends TransactionOperation{
	key: number
}

interface OperationsListProps{
	treeId: number,
	operations: TransactionOperation[],
	isEditable: boolean,

	className?: string,
	numericClassName?: string,
	transactionAttributes: TransactionAttributes,

	token: string,

	loadTransactionAttributes?: (attributes: AttributeLocator[], token: string) => void,
	updateTransactionAttributes?: (attribute: UpdateAttributeLocatorType, token: string) => void
}

const containerClassName = 'operations-list';
const MIN_COLUMN_WIDTH = 150;

class OperationsList extends React.Component<OperationsListProps>{
	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			operations, isEditable,
			transactionAttributes,
			token,
			loadTransactionAttributes
		} = this.props;

		if(!isEditable){
			return;
		}

		const allAttributes = operations.reduce((acc, cur) => {
			const brandNewAttributes = cur.attributes
				.filter(a => !acc.find(accA => accA.attributeId === a.attributeId && accA.type === a.type));
			return [...acc, ...brandNewAttributes];
		}, [] as TransactionOperationAttribute[]);

		const notLoadedAttributesYet = allAttributes
			.filter(a => {
				let info: AttributeInfo | undefined;
				if(a.type === 'node'){
					info = transactionAttributes.nodeAttributes[a.attributeId];
				} else {
					info = transactionAttributes.balanceAttributes[a.attributeId];
				}
				return !info || (!info.entry && !info.isLoading && !info.error);
			});
		
			if(notLoadedAttributesYet.length > 0){
				const locators: AttributeLocator[] = notLoadedAttributesYet
					.map(a => ({
						id: a.attributeId,
						type: a.type
					}))
					loadTransactionAttributes && loadTransactionAttributes(locators, token);
			}
	}

	getColumnTitleElement = (caption: string) => {
		return (
			<div className = {`${containerClassName}__title`}>
				{caption}
			</div>
		);
	}

	onAttributeValueChanged = (operationId: number, attributeId: number, type: AttributeType, value: {
		valueId: number
	} | {
		value: string
	}) => {
		const {
			treeId,
			token,
			updateTransactionAttributes
		} = this.props;
		if(!updateTransactionAttributes){
			return;
		}
		const attribute: UpdateAttributeLocatorType = {
			...value,
			id: attributeId,
			treeId: treeId,
			operationId: operationId,
			type: type,
		}
		updateTransactionAttributes(attribute, token);
	}

	renderOperationAttribute = (record: TransactionOperationRepresentation, attribute: TransactionOperationAttribute) => {
		const {
			isEditable,
			transactionAttributes
		} = this.props;

		const operationAttribute = record.attributes
			.find(a => a.attributeId === attribute.attributeId && a.type === attribute.type);
		if(isEditable && operationAttribute){
			const attributesToLook = attribute.type === 'node' ?
				transactionAttributes.nodeAttributes :
				transactionAttributes.balanceAttributes;
			const attributeInfo = attributesToLook[attribute.attributeId]?.entry;
			if(!attributeInfo){
				return <Spin/>
			}
			if(attributeInfo.allowValues === false){
				return (
					<div className = {`${containerClassName}__attribute`}>
						<div className = {`${containerClassName}__attribute__title`}>
							{attribute.attributeName}
						</div>
						<div className = {`${containerClassName}__attribute__values`}>
							<Input
								isEditable = {true}
								emptyText = {localization.operations_page_transaction_comment_notext}
								onChange = {(text) => {
									this.onAttributeValueChanged(
										record.id, attribute.attributeId, attribute.type, {value: text || ''}
									);
								}}
							>
								{operationAttribute.value}
							</Input>
						</div>
					</div>
				);
			}
			if(attributeInfo.readOnly){
				return (
					<div className = {`${containerClassName}__attribute`}>
						<div className = {`${containerClassName}__attribute__title`}>
							{attribute.attributeName}
						</div>
						<div className = {`${containerClassName}__attribute__values`}>
							<Input
								isEditable = {false}
								emptyText = {localization.operations_page_transaction_comment_notext}
							>
								{operationAttribute.value}
							</Input>
						</div>
					</div>
				);
			}
			const options = attributeInfo.values
				.map(v => (
					<Select.Option
						key = {v.id}
						value = {v.id}
					>
						{v.name}
					</Select.Option>
				));
			return (
				<div className = {`${containerClassName}__attribute`}>
					<div className = {`${containerClassName}__attribute__title`}>
						{attribute.attributeName}
					</div>
					<div className = {`${containerClassName}__attribute__values`}>
						<Select
							className = {`${containerClassName}__attribute__select`}
							value = {operationAttribute.valueId || undefined}
							onChange = {(valueId) => {
								this.onAttributeValueChanged(
									record.id, attribute.attributeId, attribute.type, {valueId}
								);
							}}
						>
							{options}
						</Select>
					</div>
				</div>
			)
		}
		return (
			<div className = {`${containerClassName}__attribute`}>
				<div className = {`${containerClassName}__attribute__title`}>
					{attribute.attributeName}
				</div>
				<div className = {`${containerClassName}__attribute__values`}>
					{operationAttribute?.value}
				</div>
			</div>
		)
	}

	getColumns = (): ColumnType<TransactionOperationRepresentation>[] => {
		const {
			operations
		} = this.props;

		const allAttributes = operations.reduce((acc: TransactionOperationAttribute[], cur) => {
			acc.push(...cur.attributes);
			return acc;
		}, []);
		// const deduplicatedAttributes = allAttributes
		// 	.filter((attr, index, arr) => arr.findIndex(tmp => tmp.attributeId === attr.attributeId) === index);
		const attrColumns: ColumnType<TransactionOperationRepresentation>[] = allAttributes
			.map(attribute => ({
				key: attribute.attributeId,
				title: this.getColumnTitleElement(attribute.attributeName),
				width: MIN_COLUMN_WIDTH,
				render: (_: any, record: TransactionOperationRepresentation) =>
					this.renderOperationAttribute(record, attribute)
			}))

		return [{
			title: this.getColumnTitleElement(localization.operations_page_operations_amount),
			dataIndex: 'value',
			fixed: 'left',
			width: 160,
			render: (_: any, operation: TransactionOperationRepresentation) => {
				const {numericClassName} = this.props;
				return (
					<span className = {numericClassName}>
						{formatters.formatNumber(operation.value)}
					</span>
				)
			}
		},{
			title: this.getColumnTitleElement(localization.operations_page_operations_node),
			width: 200,
			fixed: 'left',
			dataIndex: 'node',
			render: (value: any) => {
				return value?.name;
			}
		},{
			title: this.getColumnTitleElement(localization.operations_page_operations_correspondent),
			width: 200,
			fixed: 'left',
			dataIndex: 'correspondent',
			render: (value: any) => {
				return value?.name;
			}
		}, ...attrColumns]
	}

	getData = () => {
		const {operations} = this.props;

		return operations.map(o => ({
			...o,
			key: o.id
		}))
	}

	render() {
		const {
			className
		} = this.props

		const columns = this.getColumns();
		const data = this.getData();

		const effectiveClassName = className ? `${containerClassName} ${className}`: containerClassName;
		return (
			<div className = {effectiveClassName}>
				<Table
					columns = {columns}
					dataSource = {data}
					showHeader = {false}
					pagination = {false}
					bordered
					scroll = {{
						x: 'max-content',
						y: 300
					}}
				/>
			</div>
		);
	}
} 

export default OperationsList