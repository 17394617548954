import React from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

import FormulaEntry, {itemType as formulaEntryType} from './FormulaEntry';
import FormulaEntryModel from './FormulaEntryModel';
import DraggableEntryModel from './DraggableEntryModel';

export interface FormulaLegendBlock{
	id: string,
	title: string,
	values: FormulaEntryModel[]
}

interface FormulaLegendProps{
	className?: string;
	legend: FormulaLegendBlock[],
	getEntryName: (model: FormulaEntryModel) => string | null,

	onDrop?: (draggedItem: DraggableEntryModel<FormulaEntryModel>, hoveredItem?: DraggableEntryModel<FormulaEntryModel>) => void,
}

const containerClassName = 'bra-formula-legend'

const FormulaLegend = (props: FormulaLegendProps) => {
	const {
		className,
		legend,
		getEntryName,
		onDrop
	} = props;

	const [{ isOver }, drop] = useDrop({
		accept: formulaEntryType,
		drop: (item: DraggableEntryModel<FormulaEntryModel>, monitor: DropTargetMonitor) => {
			const alreadyDropped = monitor.didDrop();
			if(!alreadyDropped){
				onDrop && onDrop(item)
			}
		},
		collect: (monitor: DropTargetMonitor) => {
			return {
				isOver: monitor.isOver(),
			};
		}
	});

	let effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	if(isOver){
		effectiveClassName += ` ${containerClassName}--over`
	}
	return (
		<div
			className = {effectiveClassName}
			ref = {drop}
		>
			{legend.map(e => (
				<div
					key = {e.id}
					className = {`${containerClassName}__block`}
				>
					<div
						className = {`${containerClassName}__block__title`}
					>
						{e.title}
					</div>
					<div
						className = {`${containerClassName}__block__values`}
					>
						{e.values.map(v => (
							<FormulaEntry
								key = {`${e.id}-${v.id}`}
								model = {v}
								getName = {getEntryName}
								className = {`${containerClassName}__block__values__entry`}
								onDrop = {onDrop}
							/>
						))}
					</div>
				</div>
			))}
		</div>
	)
}

export default FormulaLegend;