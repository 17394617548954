import { all, call, put, takeLatest} from 'redux-saga/effects';

import {site, config} from '../settings';
import actions from '../actions';
import { Role } from '../models';
import history from '../history';

import handleResponse from './handleResponse';

type DeleteRequestPayload = {id: string, token: string};
type PostRequestPayload = {
	role: Role,
	prevPermissionIds?: string[],
	nextPermissionIds?: string[],
	token: string
};
type ResponsePromise = Promise<{status: number, body: any} | {error: any}>

const {requestSuccess, requestFailed} = actions.role;
const {raise} = actions.error;

const deleteRole = (payload: DeleteRequestPayload): ResponsePromise => {
	try{
		const {id} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/roles/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const postRole = (payload: PostRequestPayload): ResponsePromise => {
	try{
		const {role} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const formData = new FormData();
		if(role.name){
			formData.append('name', role.name);
		}
		if(role.slug){
			formData.append('slug', role.slug);
		}

		const url = role.id == null ?
			`${config.api_url}/roles` :
			`${config.api_url}/roles/${role.id}`;

		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	} catch(ex) {
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const addDeleteRole = (isAdd: boolean, roleId:string, permissionId: string, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		return fetch(`${config.api_url}/roles/${roleId}/permissions/${permissionId}`, {
			method: isAdd ? 'POST' : 'DELETE',
			headers: headers
		})
			.then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	} catch(ex) {
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const getUpdateRolePermissionsSaga = (action: any) => function*(postResponse: any){
	const {role, token, prevPermissionIds, nextPermissionIds} = action.payload;
	const {id} = postResponse;
	const roleId = id ? id : role.id;
	const toAdd = (nextPermissionIds || [])
		.filter((p: string) => !prevPermissionIds.includes(p));
	const addRequests = toAdd
		.map((permissionId: string) => call(addDeleteRole, true, roleId, permissionId, token))
	yield all(addRequests);
	const toRemove = (prevPermissionIds || [])
		.filter((p:string) => !nextPermissionIds.includes(p));
	const removeRequests = toRemove
		.map((permissionId: string) => call(addDeleteRole, false, roleId, permissionId, token))
	yield all(removeRequests);
	yield call(history.push, site.roles.url)
}

const createUpdateRoleSaga = function*(action: any) {
	try{
		const response = yield call(postRole, action.payload);
		yield* handleResponse(response, requestSuccess, requestFailed, getUpdateRolePermissionsSaga(action));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(requestFailed('An error occurred'));
		return;
	}
}

const deleteRoleSaga = function*(action: any){
	try{
		const response = yield call(deleteRole, action.payload);
		yield* handleResponse(response, requestSuccess, requestFailed);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(requestFailed('An error occurred'));
		return;
	}
}

const watchRole = function*() {
	yield all([
		takeLatest([
			actions.role.types.ROLE_CREATE,
			actions.role.types.ROLE_UPDATE
		], createUpdateRoleSaga),
		takeLatest(actions.role.types.ROLE_DELETE, deleteRoleSaga)
	])
}

export default watchRole;