import { NodeAttributeShort } from "../models"

const actionTypes = {
	NODE_ATTRIBUTES_FOR_TREE_LOAD: 'NODE_ATTRIBUTES_FOR_TREE\\LOAD',
	NODE_ATTRIBUTES_FOR_TREE_LOAD_SUCCESS: 'NODE_ATTRIBUTES_FOR_TREE\\LOAD_SUCCESS',
	NODE_ATTRIBUTES_FOR_TREE_LOAD_FAILED: 'NODE_ATTRIBUTES_FOR_TREE\\LOAD_FAILED',
}

const load = (treeId: number, token: string) => ({
	type: actionTypes.NODE_ATTRIBUTES_FOR_TREE_LOAD,
	payload: {
		treeId,
		token
	}
})

const loadSuccess = (treeId: number, nodeAttributes: NodeAttributeShort[]) => ({
	type: actionTypes.NODE_ATTRIBUTES_FOR_TREE_LOAD_SUCCESS,
	payload: {
		treeId,
		nodeAttributes
	}
})

const loadFailed = (treeId: number, message: string) => ({
	type: actionTypes.NODE_ATTRIBUTES_FOR_TREE_LOAD_FAILED,
	payload: {
		treeId,
		message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed
}