import actions from '../actions';
import { EntityPerBalance, BalanceAttribute } from '../models';

export interface BalanceAttributesState extends EntityPerBalance<BalanceAttribute>{}

const defaultState: EntityPerBalance<BalanceAttribute> = {}

const updateBalanceAttributes = (state: BalanceAttributesState, balanceId: number, values: BalanceAttribute[] | null | undefined, isLoading: boolean) => {
	const updatedState = {...state};
	const updatedValue = {
		list: null,
		...updatedState[balanceId],
		isLoading: isLoading
	}
	if(values !== undefined){
		updatedValue.list = values
	}
	updatedState[balanceId] = updatedValue;
	return updatedState;
}

const reducer = (state: BalanceAttributesState = defaultState, action: any): BalanceAttributesState => {
	switch(action.type){
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_LOAD:{
			const {balanceId} = action.payload;
			return updateBalanceAttributes(state, balanceId, null, true);
		}

		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_LOAD_SUCCESS:{
			const {balanceId, balanceAttributes} = action.payload;
			return updateBalanceAttributes(state, balanceId, balanceAttributes, false);
		}

		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_LOAD_FAILED:{
			const {balanceId} = action.payload;
			return updateBalanceAttributes(state, balanceId, [], false);
		}

		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_ORDER:{
			const {balanceId} = action.payload;
			return updateBalanceAttributes(state, balanceId, undefined, true);
		}
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_ORDER_SUCCESS:{
			const {balanceId} = action.payload;
			return updateBalanceAttributes(state, balanceId, null, false);
		}
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_ORDER_FAILED:{
			const {balanceId} = action.payload;
			console.error(`${action.type}:\n%o`, action.payload);
			return updateBalanceAttributes(state, balanceId, null, false);
		}
		
		case actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_ORDER:{
			const {balanceId} = action.payload;
			return updateBalanceAttributes(state, balanceId, undefined, true);
		}
		case actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_ORDER_SUCCESS:{
			const {balanceId} = action.payload;
			return updateBalanceAttributes(state, balanceId, null, false);
		}
		case actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_ORDER_FAILED:{
			const {balanceId} = action.payload;
			console.error(`${action.type}:\n%o`, action.payload);
			return updateBalanceAttributes(state, balanceId, null, false);
		}
		

		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_CREATE_SUCCESS:
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_UPDATE_SUCCESS:
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_DELETE_SUCCESS:
		case actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_CREATE_SUCCESS:
		case actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_DELETE_SUCCESS:{
			const {balanceId} = action.payload;
			return updateBalanceAttributes(state, balanceId, null, false);
		}
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_CREATE_FAILED:
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_UPDATE_FAILED:
		case actions.balanceAttributes.types.BALANCE_ATTRIBUTE_DELETE_FAILED:
		case actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_CREATE_FAILED:
		case actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_DELETE_FAILED:{
			const {balanceId} = action.payload;
			console.error(`${action.type}:\n%o`, action.payload);
			return updateBalanceAttributes(state, balanceId, null, false);
		}
	}
	return state;
}

export default reducer;