import React from 'react';

interface EmptyPageProps{
    className?: string | null,
    caption?: JSX.Element | null
}

const EmptyPage = (props: EmptyPageProps) => {
    const {caption, className} = props;
    const containerClassName = 'empty-page';
    const effectiveContainerClassName = className ?
        `${containerClassName} ${className}` :
        containerClassName;
    return (
        <div className = {effectiveContainerClassName}>
            <div className = {`${containerClassName}__caption-wrapper`}>
                {caption}
            </div>
        </div>
    )
}

export default EmptyPage;