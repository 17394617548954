import React from 'react';
import { PlusCircle, User as UserIcon } from 'react-feather';

import { User } from '../../models';
import { localization } from '../../settings';

interface UserPanelProps{
	user: User | "add" | "custom",
	selected?: boolean,
	render?: () => JSX.Element | null,

	onClick?: (id?: number) => void
}

const UserPanel = (props: UserPanelProps) => {
	const {user, selected, render, onClick} = props;
	const onClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if(onClick){
			event.stopPropagation();
			if(typeof user === "string"){
				return onClick();
			}
			return onClick(user.id || -1); 
		}
	}

	let className = 'user-entry';
	if(selected){
		className += ' active';
	}
	if(onClick){
		className += ' interactive';
	}

	if(user === "custom"){
		return(
			<div
				className = {className}
				onClick = {onClickHandler}
			>
				{render && render()}
			</div>
		)
	}
	
	let iconElement: JSX.Element | null = null;
	let nameElement = null;
	switch(user){
		case "add":
			iconElement = (<PlusCircle />);
			nameElement = localization.newUserButtonShort;
			break;
		default:
			iconElement = (<UserIcon />);
			nameElement = user.name;
			break;
	}

	return (
		<div
			className = {className}
			onClick = {onClickHandler}
		>
			<div className="user-entry__icon">
				{iconElement}
			</div>
			<div className="user-entry__name">
				{nameElement}
			</div>
		</div>
	);
}

export default UserPanel;