import { report } from "../models"

const actionTypes = {
	REPORT_TEMPLATE_LOAD: 'REPORT_TEMPLATE\\LOAD',
	REPORT_TEMPLATE_LOAD_SUCCESS: 'REPORT_TEMPLATE\\LOAD_SUCCESS',
	REPORT_TEMPLATE_LOAD_FAILED: 'REPORT_TEMPLATE\\LOAD_FAILED',

	REPORT_TEMPLATE_SAVE: 'REPORT_TEMPLATE\\SAVE',
	REPORT_TEMPLATE_SAVE_SUCCESS: 'REPORT_TEMPLATE\\SAVE_SUCCESS',
	REPORT_TEMPLATE_SAVE_FAILED: 'REPORT_TEMPLATE\\SAVE_FAILED',

	REPORT_TEMPLATE_DELETE: 'REPORT_TEMPLATE\\DELETE',
	REPORT_TEMPLATE_DELETE_SUCCESS: 'REPORT_TEMPLATE\\DELETE_SUCCESS',
	REPORT_TEMPLATE_DELETE_FAILED: 'REPORT_TEMPLATE\\DELETE_FAILED',

	REPORT_TEMPLATE_APPLY: 'REPORT_TEMPLATE\\APPLY'
}

const load = (nodeId: number, token: string) => ({
	type: actionTypes.REPORT_TEMPLATE_LOAD,
	payload: {
		nodeId,
		token
	}
})

const loadSuccess = (nodeId: number, templates: report.Template[]) => ({
	type: actionTypes.REPORT_TEMPLATE_LOAD_SUCCESS,
	payload: {
		nodeId,
		templates
	}
})

const loadFailed = (nodeId: number, message: string) => ({
	type: actionTypes.REPORT_TEMPLATE_LOAD_FAILED,
	payload: {
		nodeId,
		message
	}
})

const save = (nodeId: number, template: report.TemplateWithoutId, token: string) => ({
	type: actionTypes.REPORT_TEMPLATE_SAVE,
	payload: {
		nodeId,
		template,
		token
	}
})

const saveSuccess = (nodeId: number, template: report.TemplateWithoutId) => ({
	type: actionTypes.REPORT_TEMPLATE_SAVE_SUCCESS,
	payload: {
		nodeId,
		template
	}
})

const saveFailed = (nodeId: number, template: report.TemplateWithoutId, message: string) => ({
	type: actionTypes.REPORT_TEMPLATE_SAVE_FAILED,
	payload: {
		nodeId,
		template,
		message
	}
})

const deleteTemplate = (nodeId: number, id: number, token: string) => ({
	type: actionTypes.REPORT_TEMPLATE_DELETE,
	payload: {
		nodeId,
		id,
		token
	}
})

const deleteSuccess = (nodeId: number, id: number) => ({
	type: actionTypes.REPORT_TEMPLATE_DELETE_SUCCESS,
	payload: {
		nodeId,
		id
	}
})

const deleteFailed = (nodeId: number, id: number, message: string) => ({
	type: actionTypes.REPORT_TEMPLATE_DELETE_FAILED,
	payload: {
		nodeId,
		id,
		message
	}
})

const apply = (nodeId: number, template: report.Template) => ({
	type: actionTypes.REPORT_TEMPLATE_APPLY,
	payload: {
		nodeId,
		template
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	save,
	saveSuccess,
	saveFailed,
	delete: deleteTemplate,
	deleteSuccess,
	deleteFailed,
	apply
}