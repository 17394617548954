import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Error } from '../../components';

import { AttributeLocator, CommonPageProps, NodesState, payloads, TransactionAttributes, TransactionsTableState, TransactionsFilters, TransactionsPerTree, UpdateAttributeLocatorType } from '../../models';
import { TransactionFilterValuesState } from '../../reducers/transactionFilterValues';
import { TransactionsContainerStates } from '../../reducers/transactionsContainerState';
import { getTreeSecurityProvider, transactions as transactionsSettings } from '../../settings';

import TransactionsContainer from './TransactionsContainer';
import { transactionTypes } from '../../settings/transaction';

interface TransactionsProps extends CommonPageProps{
	nodes: NodesState,
	transactionsPerTree: TransactionsPerTree,
	transactionsContainerStates: TransactionsContainerStates,
	transactionFilterValues: TransactionFilterValuesState,
	transactionAttributes: TransactionAttributes,
	token: string,

	loadTransactions: (treeId: number, page: number, size: number, filters: TransactionsFilters, token: string) => void,
	loadNodeName: (nodeId: number, token: string) => void,
	loadTransactionFilterValues: (treeId: number, fieldName: string, token: string) => void,
	loadNodeActionGroups: (nodeId: number, userId: number, token: string) => void,

	unloadTransactions: (treeId: number) => void,

	updateTransaction: (payload: payloads.UpdateTransactionPayloadType, token: string) => void,
	loadTransactionAttributes: (attributes: AttributeLocator[], token: string) => void,
	updateTransactionAttributes: (attribute: UpdateAttributeLocatorType, token: string) => void,
	cancelTransaction: (treeId: number, transactionId: number, token: string) => void,
	saveContainerState: (treeId: number, state: TransactionsTableState) => void,
}

const Transactions = (props: TransactionsProps) => {
		const {treeId} = useParams<{treeId: string}>();
		const {
			nodes,
			transactionsPerTree,
			currentUser, token,
			transactionFilterValues,
			transactionsContainerStates, saveContainerState,
			
			loadTransactionFilterValues,
			loadTransactions, loadNodeName,
			unloadTransactions,
			updateTransaction, cancelTransaction,

			transactionAttributes,
			loadTransactionAttributes,
			updateTransactionAttributes,
			loadNodeActionGroups
		} = props;

		const safeId = parseInt(treeId);
		const node = nodes && nodes[safeId];
		const transactions = transactionsPerTree[safeId];
		const filterValuesOnTree = transactionFilterValues[safeId];

		useEffect(() => {
			if(!transactions || (!transactions.list && !transactions.isLoading && !transactions.error)){
				const currentPage = transactions?.currentPage || transactionsSettings.defaultFirstPage;
				const pageSize = transactions?.pageSize || transactionsSettings.defaultPageSize;
				const filters = transactions?.filters || {};
				loadTransactions(safeId, currentPage, pageSize, filters, token)
			}
			if(!node || (!node.name && !node.isNameLoading && !node.nameError)){
				loadNodeName(safeId, token);
			}
			if(!filterValuesOnTree || !filterValuesOnTree.authorId ||
				(!filterValuesOnTree.authorId.list && !filterValuesOnTree.authorId.isLoading && !filterValuesOnTree.authorId.error)
			){
				loadTransactionFilterValues(safeId, "authorId", token);
			}
			if(!filterValuesOnTree || !filterValuesOnTree.nodeId ||
				(!filterValuesOnTree.nodeId.list && !filterValuesOnTree.nodeId.isLoading && !filterValuesOnTree.nodeId.error)
			){
				loadTransactionFilterValues(safeId, "nodeId", token);
			}
			if(!filterValuesOnTree || !filterValuesOnTree.correspondentId ||
				(!filterValuesOnTree.correspondentId.list && !filterValuesOnTree.correspondentId.isLoading && !filterValuesOnTree.correspondentId.error)
			){
				loadTransactionFilterValues(safeId, "correspondentId", token);
			}
			const rootNode = nodes[safeId];
			const rootNodeSelfInfo = rootNode && rootNode.users && rootNode.users[currentUser.id]
			if(!rootNodeSelfInfo){
				loadNodeActionGroups(safeId, currentUser.id, token);
			}
		});

		if(transactions?.error){
			return (
				<Error
					reset = {() => unloadTransactions(safeId)}
					message = {transactions.error}
				/>
			)
		}

		const authorIdFilterValues = transactionFilterValues[safeId]?.authorId?.list || [];
		const nodeIdFilterValues = transactionFilterValues[safeId]?.nodeId?.list || [];
		const correspondentIdFilterValues = transactionFilterValues[safeId]?.correspondentId?.list || [];
		const containerState = transactionsContainerStates[safeId]?.entity || null;

		const treeSecurityProvider = getTreeSecurityProvider(currentUser, safeId, nodes);

    const transactionTypesOnTree = transactionTypes?.filter(
	    (t) =>
	      	treeSecurityProvider?.allowedTransactions[
	      	  	t.slug as keyof typeof treeSecurityProvider.allowedTransactions
	      	],
	  );

		return (
			<TransactionsContainer
				treeId = {safeId}
				treeName = {node?.name || null}
				transactionTypes = {transactionTypesOnTree || null}
				transactions = {transactions || null}
				authorIdFilterValues = {authorIdFilterValues || null}
				nodeIdFilterValues = {nodeIdFilterValues || null}
				correspondentIdFilterValues = {correspondentIdFilterValues || null}
				transactionAttributes = {transactionAttributes}
				containerState = {containerState}
				currentUser = {currentUser}
				token = {token}
				treeSecurityProvider = {treeSecurityProvider}
				loadTransactions = {loadTransactions}
				updateTransaction = {(transactionId, changes, token) => {
					updateTransaction({
						treeId: safeId,
						transactionId,
						changes,
					}, token);
				}}
				loadTransactionAttributes = {loadTransactionAttributes}
				loadTransactionFilterValues = {loadTransactionFilterValues}
				updateTransactionAttributes = {updateTransactionAttributes}
				cancelTransaction = {cancelTransaction}
				saveContainerState = {saveContainerState}
			/>
		)
}

export default Transactions;