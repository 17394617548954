import React from "react";
import { Layout } from 'antd';

const {Content} = Layout;

const NotFound = () => (
	<Layout>
		<Content>Not Found</Content>
	</Layout>
)

export default NotFound;