import actions from '../actions';
import { BalanceAttributeType, EntityHolder } from '../models';

const defaultState: EntityHolder<BalanceAttributeType> = {
	list: null,
	isLoading: false
}

const reducer = (state: EntityHolder<BalanceAttributeType> = defaultState, action: any): EntityHolder<BalanceAttributeType> => {
	switch(action.type){
		case actions.balanceAttributeTypes.types.BALANCE_ATTRIBUTE_TYPES_LOAD:
			return {...state, isLoading: true};

		case actions.balanceAttributeTypes.types.BALANCE_ATTRIBUTE_TYPES_LOAD_SUCCESS:
			const { balanceAttributeTypes } = action.payload;
			return {...state, list: balanceAttributeTypes, isLoading: false};

		case actions.balanceAttributeTypes.types.BALANCE_ATTRIBUTE_TYPES_LOAD_FAILED:
			console.error(`${action.type}\n%o`, action.payload);
			return {...state, list: [], isLoading: false}
	}
	return state;
}

export default reducer;