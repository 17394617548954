import React, { useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

type MoveActionType = (dragIndex: any, hoverIndex: any) => void

interface DndListEntryProps<TItem>{
	className?: string | null,
	index: number,
	item: TItem,
	listDescriptor: string,

	render: (item: TItem, ref: React.RefObject<HTMLDivElement>) => JSX.Element,
	move?: MoveActionType,
	moveFinished?: MoveActionType,
}

const typeName = 'DndListEntry';
const containerClassName = 'dnd-list-entry';

const withBlackJack = (action?: MoveActionType): MoveActionType => {
	return (dragIndex: number, hoverIndex: number) => {
		if(dragIndex > hoverIndex){
			action && action(dragIndex, hoverIndex);
		}
	}
}

const performActionOnMove = (
	ref: React.RefObject<HTMLDivElement>,
	originalIndex: number,
	action: MoveActionType
) => (
	item: any
) => {
	if (!ref.current) {
		return
	}

	const dragIndex = item.index
	const hoverIndex = originalIndex

	action(dragIndex, hoverIndex)
}

const DndListEntry = <TItem extends {}>(props: DndListEntryProps<TItem>) => {
	const {
		className,
		index, item, listDescriptor,
		render,
		move, moveFinished
	} = props;

	const typenameWithList = `${typeName}${listDescriptor}`

	const ref = useRef<HTMLDivElement>(null);

	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: typenameWithList,
		hover: withBlackJack(move && performActionOnMove(ref, index, move)),
		drop: moveFinished && performActionOnMove(ref, index, moveFinished),
		collect: monitor => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
				return {};
			}
			return {
				isOver: monitor.isOver(),
				dropClassName: dragIndex < index ? 'drop-before' : 'drop-after',
			};
		}
	})

	const [{ isDragging }, drag] = useDrag({
		item: { type: typenameWithList, index },
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	drag(drop(ref));

	const itemElement = render(item, ref);

	const classNameWithDrag = isDragging ? `${containerClassName}--dragging` : containerClassName;
	const classNameWithBlackJack = isOver ? `${classNameWithDrag} ${dropClassName}` : classNameWithDrag;
	const effectiveClassName = className ? `${classNameWithBlackJack} ${className}` : classNameWithBlackJack;
	return (
		<div className = {effectiveClassName}>
			{itemElement}
		</div>
	)
}

export default DndListEntry;