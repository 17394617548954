import React from 'react';

import { getTreeSecurityProvider, localization } from '../../settings';
import {
	CommonPageProps,
	TreesHash,
	TreeInState,
	EntityPerTree,
	Balance,
	NodesState,
	TreeContainerState,
	Tree,
} from '../../models';
import {ConfirmDelete, Error} from '../../components';
import {findNode} from '../../tools';

import UserRightsDialog from '../UserRightsDialog';

import AttributesDialog from '../AttributesDialog';
import TransactionWizardWrapper from './TransactionWizardWrapper';

export interface TreeContainerProps extends CommonPageProps{
	treeInfo?: TreeInState | null,
	treeContainerState: TreeContainerState | null,
	treeId: number,
	trees: TreesHash,
	token: string,
	nodesInfo: NodesState,
	balances: EntityPerTree<Balance>,

	addNode: (treeId: number, parentId: number, name: string, token: string) => void,
	deleteNode: (treeId: number, nodeId: number, token: string) => void,
	updateNodeName: (treeId: number, nodeId: number, name: string, token: string) => void,
	updateActionGroup: (nodeId: number, userId: number, groups: any) => void,
	saveState: (treeId: number, state: TreeContainerState) => void,
	reset: () => void,
	loadTree: (id: string, token: string) => void,
	loadBalances: (treeId: number, token: string) => void,
}

const defaultState: TreeContainerState = {
	fixedRowIds: [],
	expandedRows: [],
	selectedRowId: null,
	selectedBalanceId: null,
	userActionRolesDialogVisible: false,
	userActionRolesDialogNodeId: null,
	nodeAttributesDialogNodeIdOrHidden: null
}

const containerClassName = "tree-container";

class TreeContainer extends React.Component<TreeContainerProps> {
	constructor(props: TreeContainerProps){
		super(props);

		this.state = {...defaultState};
	}

	getGlobalState = () => {
		const {
			treeContainerState
		} = this.props;
		const currentState = treeContainerState || defaultState;
		return {...currentState};
	}

	updateGlobalState = (update: (state: TreeContainerState) => TreeContainerState) => {
		const {
			treeId,
			saveState
		} = this.props;
		const updatedState = update(this.getGlobalState());
		saveState(treeId, updatedState);
	}

	showUserTreePermissionsDialog = (nodeId: React.ReactText) => {

		this.updateGlobalState((state) => ({
			...state,
			userActionRolesDialogVisible: true,
			userActionRolesDialogNodeId: nodeId
		}));
	}

	closeUserTreePermissionsDialog = () => {
		this.updateGlobalState((state) => ({
			...state,
			userActionRolesDialogVisible: false,
			userActionRolesDialogNodeId: null
		}));
	}

	showNodeAttributesDialog = (nodeId: React.ReactText) => {
		this.updateGlobalState((state) => ({
			...state,
			nodeAttributesDialogNodeIdOrHidden: nodeId
		}))
	}

	closeNodeAttributesDialog = () => {
		this.updateGlobalState((state) => ({
			...state,
			nodeAttributesDialogNodeIdOrHidden: null
		}))
	}

	clearSelection = () => {
		this.updateGlobalState((state) => ({
			...state,
			selectedRowId: null
		}))
	}

	confirmDeleteNode = (treeId: number, nodeId: number) => {
		const {token, deleteNode} = this.props;

		ConfirmDelete({
			title: localization.delete_node_title,
			content: localization.delete_node_content,
			deleteEntity: () => deleteNode(treeId, nodeId, token)
		})
	}

	toggleFixRows = () => {
		this.updateGlobalState((state) => {
			const {fixedRowIds, selectedRowId} = state;
			if(!selectedRowId){
				return state;
			}
			const updatedFixedRows = [...fixedRowIds];
			const index = updatedFixedRows.indexOf(selectedRowId);
			if(index > -1){
				updatedFixedRows.splice(index, 1);
			} else {
				updatedFixedRows.push(selectedRowId);
			}
			return {
				...state,
				fixedRowIds: updatedFixedRows
			}
		})
	}

	setExpandedRows = (keys: React.ReactText[]) => {
		this.updateGlobalState((state) => ({
			...state,
			expandedRows: keys
		}))
	}

	 searchTree = (tree: Tree, id: number) => {
		const path: any[] = []; // Store the path from the root node to the current node

		 if(tree.id === id && !tree.action_groups) {
			 return this.searchForChildren(tree);
		 }
		// Helper function to traverse the tree and find the closest parent with a non-nullish action_groups property
		const traverse = (node: Tree): Tree | undefined | null => {
			if (node.id === id) {
				if (node.action_groups !== null) {
					return node;
				}
				if (path.length > 0) {
					for (let i = 1; i < path.length; i++) {
						const parent = path[path.length - i];
						if (parent.action_groups !== null) {
							return parent;
						}
					}
				}
        
				return tree.action_groups;
			}

			path.push(node);

			if (node.children) {
				for (const child of node.children) {
					const result = traverse(child);
					if (result) {
						return result;
					}
				}
			}

			path.pop();

			return null;
		}

		return traverse(tree);
	}

	searchForChildren = (tree: Tree) => {
		let actionGroups: { action_groups: Record<number, any> } = {
			action_groups: {}
		}
		if (tree.children) {
			for (const child of tree.children) {
				if(child.action_groups) {
					if(child.action_groups[48]) {
						actionGroups.action_groups[48] = child.action_groups[48]
					}
					if(child.action_groups[49]) {
						actionGroups.action_groups[49] = child.action_groups[49]
					}
				}
			}
		}
		return actionGroups
	}
  
	recursiveExtraction = (updatingNodeId: number, nodeId: number, userId: number, tree?: Tree | null): any => {
		const nearestNodeWithActionGroups = this.searchTree(tree!, nodeId);
		if(nearestNodeWithActionGroups) {
      console.log('123', nearestNodeWithActionGroups);
      
			this.props.updateActionGroup(updatingNodeId, userId, nearestNodeWithActionGroups.action_groups);
		} else {
			this.props.updateActionGroup(updatingNodeId, userId, {});
		}
	}

	onEntryClick = (nodeId: React.ReactText, balanceId?: React.ReactText) =>  {
		const {treeId, currentUser, treeInfo} = this.props;
		this.recursiveExtraction(treeId, Number(nodeId), currentUser.id, treeInfo?.tree)
		this.updateGlobalState((state) => {
			let selectedRowId: React.ReactText | null = null;
			let selectedBalanceId: number | null = null;
			if(state.selectedRowId !== nodeId || selectedBalanceId !== balanceId){
				selectedRowId = nodeId;
				selectedBalanceId = balanceId ? Number(balanceId) : null;
			}
			return {
				...state,
				selectedRowId,
				selectedBalanceId
			}
		})
	}

	render(){
		const {
			trees, treeInfo, currentUser,
			nodesInfo, securityProvider,
			balances, treeId,
			token,
			updateNodeName, addNode,
			reset,
			loadTree, loadBalances,
		} = this.props;

		const {
			selectedRowId, selectedBalanceId,
			fixedRowIds, expandedRows,
			userActionRolesDialogVisible,
			userActionRolesDialogNodeId,
			nodeAttributesDialogNodeIdOrHidden,
		} = this.getGlobalState();

		if(treeInfo?.error){
			return (
				<Error
					reset = {reset}
					message = {treeInfo.error}
				/>
			)
		}
		const currentTree = (treeInfo?.isLoading ? null : treeInfo?.tree) || null;

		const dialogForNode = findNode(userActionRolesDialogNodeId, currentTree);
		const currentTreeSecurityProvider = getTreeSecurityProvider(currentUser, currentTree?.id || null, nodesInfo);
		const nodeAttributesDialogNode = findNode(nodeAttributesDialogNodeIdOrHidden, currentTree);

		const selectedNode = findNode(selectedRowId, currentTree);

		return (
			<div className={containerClassName}>
				<TransactionWizardWrapper
					className = {containerClassName}
					trees = {trees}
					treeId = {treeId}
					currentTree = {currentTree}
					selectedNode = {selectedNode}
					selectedBalanceId = {selectedBalanceId}
					balances = {balances}
					selectedRowId = {selectedRowId}
					fixedRowIds = {fixedRowIds}
					expandedRows = {expandedRows}

					token = {token}
					currentUser = {currentUser}
					securityProvider = {securityProvider}
					treeSecurityProvider = {currentTreeSecurityProvider}

					clearSelection = {this.clearSelection}
					toggleFixRows = {this.toggleFixRows}
					setExpandedRows = {this.setExpandedRows}
					confirmDeleteNode = {this.confirmDeleteNode}
					showUserTreePermissionsDialog = {this.showUserTreePermissionsDialog}
					showNodeAttributesDialog = {this.showNodeAttributesDialog}
					addNode = {addNode}
					updateNodeName = {updateNodeName}
					onEntryClick = {this.onEntryClick}
					loadTree = {loadTree}
					loadBalances = {loadBalances}
				/>
				{dialogForNode && currentTree?.id && (
					<UserRightsDialog
						key = {`uard-for__${dialogForNode.id}`}
						treeId = {currentTree.id}
						currentUser = {currentUser}
						securityProvider = {securityProvider}
						treeSecurityProvider = {currentTreeSecurityProvider}
						node = {dialogForNode}
						visible = {userActionRolesDialogVisible}
						onClose = {this.closeUserTreePermissionsDialog}
					/>
				)}
				{nodeAttributesDialogNode && (
					<AttributesDialog
						tree = {currentTree}
						node = {nodeAttributesDialogNode}
						visible = {nodeAttributesDialogNodeIdOrHidden !== null}
						onClose = {this.closeNodeAttributesDialog}
					/>
				)}
			</div>
		)
	}
}

export default TreeContainer;