import React from 'react';
import { Form, Input, Select } from 'antd';

import { Button } from '../../components';
import { ActionGroup, TreePermission } from '../../models';
import { localization, errors, SecurityProvider } from "../../settings";
import { Check, Plus } from 'react-feather';
import { filterByInnerText } from '../../tools';

const {Option} = Select;

export type SubmitActionGroupType = (actionGroup: ActionGroup, token: string, prevPermissionIds: string[], nextPermissionIds: string[]) => void;

interface EditActionGroupPermissionsProps{
	className: string,
	actionGroup?: ActionGroup,
	isNew?: boolean,
	permissions: TreePermission[] | null,
	token: string,
	disabled?: boolean;
	securityProvider: SecurityProvider,

	submitActionGroup: SubmitActionGroupType
}

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 12,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 12,
		},
	}
};

const titlelessItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 12,
			offset: 12,
		},
	},
}

const notEmptyRule = [
	{
		required: true,
		message: errors.fields_is_required,
		whitespace: true
	}];

const getSubmitActionGroup = (
	prevActionGroup: ActionGroup | undefined,
	token: string,
	submitActionGroup: SubmitActionGroupType
) => (
	formFields: any
) => {
	const updatedActionGroup: ActionGroup = {
		id:				formFields.id,
		name:			formFields.name,
		slug:			formFields.slug
	}
	const prevPermissionIds: string[] = (prevActionGroup?.permissions || [])
		.map(tp => (tp.id || '').toString())
		.filter(pId => pId !== '');

	submitActionGroup(updatedActionGroup, token, prevPermissionIds, formFields.permissions);
}

const EditActionGroupPermissions = (props: EditActionGroupPermissionsProps) => {
	const {
		actionGroup, isNew, permissions,
		token, submitActionGroup,
		disabled, className,
		securityProvider
	} = props;

	const assignedPermissions = (actionGroup?.permissions || [])
		.map((permission) => (permission.id || '').toString());

	const initialValues = {
		id: actionGroup?.id || '',
		name: actionGroup?.name || '',
		slug: actionGroup?.slug || '',
		permissions: assignedPermissions
	}

	const canSave = isNew ? securityProvider.actionGroups.canCreate : securityProvider.actionGroups.canEdit;

	return (
		<Form
			{...formItemLayout}	
			initialValues={initialValues}
			onFinish={getSubmitActionGroup(actionGroup, token, submitActionGroup)}
			className = {className}
		>
			<Form.Item
				name="id"
				hidden
			>
				<Input className = {`${className}__name`} disabled={disabled}/>
			</Form.Item>
			<Form.Item
				{...formItemLayout}
				name="name"
				label={localization.actionGroup_name}
				rules={notEmptyRule}
			>
				<Input disabled={disabled}/>
			</Form.Item>
			<Form.Item
				{...formItemLayout}
				name="slug"
				label={localization.slug}
				rules={notEmptyRule}
			>
				<Input className = {`${className}__slug`}/>
			</Form.Item>
			{securityProvider.treePermissions.canRead && (<Form.Item
				name="permissions"
				label={localization.permissions}
			>
				<Select
					disabled = {disabled || !securityProvider.treePermissions.canAssign}
					mode = "multiple"
					style = {{width: '100%'}}
					className = {`${className}__tree-permissions`}
					filterOption = {filterByInnerText}
				>
					{permissions?.map(p => (
						<Option
							key={p.id}
							value={(p.id || '').toString()}
							className = {`${className}__tree-permissions__option`}
							>
								{p.name}
							</Option>
					))}
				</Select>
			</Form.Item>)}
			<Form.Item {...titlelessItemLayout}>
				<Button
					type="primary"
					htmlType="submit"
					disabled={disabled || !canSave}
					icon = {isNew ? <Plus/> : <Check/>}
					className = {`${className}__save`}
				>
					{isNew ? localization.create : localization.save}
				</Button>
			</Form.Item>
		</Form>
	)
}

export default EditActionGroupPermissions;