const actionTypes = {
	BALANCE_ATTRIBUE_VALUE_CREATE: 'BALANCE_ATTRIBUE_VALUE\\CREATE',
	BALANCE_ATTRIBUE_VALUE_CREATE_SUCCESS: 'BALANCE_ATTRIBUE_VALUE\\CREATE_SUCCESS',
	BALANCE_ATTRIBUE_VALUE_CREATE_FAILED: 'BALANCE_ATTRIBUE_VALUE\\CREATE_FAILED',

	BALANCE_ATTRIBUE_VALUE_DELETE: 'BALANCE_ATTRIBUE_VALUE\\DELETE',
	BALANCE_ATTRIBUE_VALUE_DELETE_SUCCESS: 'BALANCE_ATTRIBUE_VALUE\\DELETE_SUCCESS',
	BALANCE_ATTRIBUE_VALUE_DELETE_FAILED: 'BALANCE_ATTRIBUE_VALUE\\DELETE_FAILED',

	BALANCE_ATTRIBUE_VALUE_ORDER: 'BALANCE_ATTRIBUE_VALUE\\ORDER',
	BALANCE_ATTRIBUE_VALUE_ORDER_SUCCESS: 'BALANCE_ATTRIBUE_VALUE\\ORDER_SUCCESS',
	BALANCE_ATTRIBUE_VALUE_ORDER_FAILED: 'BALANCE_ATTRIBUE_VALUE\\ORDER_FAILED',
}

const create = (name: string, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_CREATE,
	payload: {
		name,
		balanceAttributeId,
		balanceId,
		treeId,
		token
	}
});

const createSuccess = (balanceAttributeValueId: number, balanceAttributeId: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_CREATE_SUCCESS,
	payload: {
		balanceAttributeValueId,
		balanceAttributeId,
		balanceId,
		treeId
	}
});

const createFailed = (message: string, balanceAttributeId: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_CREATE_FAILED,
	payload: {
		message,
		balanceAttributeId,
		balanceId,
		treeId
	}
})

const deleteBalanceAttributeValue = (id: number, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_DELETE,
	payload: {
		id,
		balanceAttributeId,
		balanceId,
		treeId,
		token
	}
});

const deleteSuccess = (id: number, balanceAttributeId: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_DELETE_SUCCESS,
	payload: {
		id,
		balanceAttributeId,
		balanceId,
		treeId
	}
});

const deleteFailed = (message: string, id: number, balanceAttributeId: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_DELETE_FAILED,
	payload: {
		message,
		id,
		balanceAttributeId,
		balanceId,
		treeId
	}
})

const order = (
	idsWithOrder: {id: number, order: number}[], balanceAttributeId: number, balanceId: number, treeId: number, token: string
) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_ORDER,
	payload: {
		idsWithOrder,
		balanceAttributeId,
		balanceId,
		treeId,
		token
	}
})

const orderSuccess = (balanceAttributeId: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_ORDER_SUCCESS,
	payload: {
		balanceAttributeId,
		balanceId,
		treeId
	}
})

const orderFailed = (message: string, balanceAttributeId: number, balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_ATTRIBUE_VALUE_ORDER_FAILED,
	payload: {
		message,
		balanceAttributeId,
		balanceId,
		treeId
	}
})

export default {
	types: actionTypes,
	create,
	createSuccess,
	createFailed,
	delete: deleteBalanceAttributeValue,
	deleteSuccess,
	deleteFailed,
	order,
	orderSuccess,
	orderFailed
};