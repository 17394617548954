import Cookies from 'js-cookie';
import React, { useState } from 'react';

import { PageTitle, Select, Tabs, ZoomPanel } from '../../components';
import {
	NodesState, TransactionsPerTree, TreeInState, TreesHash, SingleEntityPerTree, Report, SingleEntityHolder, TransactionChangeLogsPerTree, TransactionChangeLogs
} from '../../models';
import { localization, site } from '../../settings';

interface BaseWorkspaceProps{
	leftPanel?: React.ReactNode,
	className?: string,

	title?: string,
	pageTitle?: React.ReactNode,
	backUrl?: string,
	trees: TreesHash,
	reports: SingleEntityPerTree<Report>,
	transactions: TransactionsPerTree,
	transactionsElastic: TransactionsPerTree,
	transactionChangeLogs: TransactionChangeLogsPerTree,

	nodes: NodesState,

	treeId?: number,
	transactionsListId?: number,
	transactionsElasticListId?: number,
	transactionsChangesListId?: number,
	reportNodeId?: number,

	unloadTree: (id: number) => void,
	unloadTransactions: (treeId: number) => void,
	unloadTransactionsElastic: (treeId: number) => void,
	unloadTransactionChangeLogs: (nodeId: number) => void,
	unloadReport: (nodeId: number) => void,
	navigateTo: (url: string) => void
}

interface WorkspaceWithChildrenProps extends BaseWorkspaceProps{
	children: React.ReactNode,
}

interface WorkspaceWithCustomRenderProps extends BaseWorkspaceProps{
	customRender: (zoom: number) => React.ReactNode,
}

type WorkspaceProps = WorkspaceWithChildrenProps | WorkspaceWithCustomRenderProps;

const ZOOM_COOKIE_NAME = 'tree-zoom';
const containerClassName = 'workspace';

const getZoomControlElement = (zoom: number, setZoom: (value: number) => void) => (
	<Select
		value = {zoom}
		onChange = {setZoom}
		className={`${containerClassName}__zoom-ctrl`}
		options = {[{
			text: '50%',
			value: 0.5
		},{
			text: '75%',
			value: 0.75
		},{
			text: '90%',
			value: 0.9
		},{
			text: '100%',
			value: 1
		},{
			text: '125%',
			value: 1.25
		},{
			text: '150%',
			value: 1.5
		},{
			text: '200%',
			value: 2
		}]}
	/>
);

type GetOnCloseType = (entity: 'tree' | 'transactions' | 'transactionsElastic' | 'report' | 'transactionChangeLogs', idToClose?: number) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;

const getTabs = (
	trees: TreesHash, reports: SingleEntityPerTree<Report>, nodesInfo: NodesState,
	transactions: TransactionsPerTree, transactionsElastic: TransactionsPerTree,
	transactionChangeLogs: TransactionChangeLogsPerTree,
	onTreeClose: GetOnCloseType | null
) => {
	const treesArray: TreeInState[] = Object.values(trees);
	const treeTabs = treesArray
		.filter(t => !!t?.tree?.id)
		.map(t => ({
			id: `tree ${t.tree?.id}`,
			title: t.tree?.name,
			url: `${site.trees.url}/${t.tree?.id}`,
			loadedAt: t.loadedAt,
			onClose: onTreeClose ? onTreeClose('tree', t.tree?.id) : () => {},
		}));
	const transactionIds = Object.keys(transactions);
	const transactionTabs = transactionIds
		.map(key => {
			const nodeId = parseInt(key);
			const transaction = transactions[nodeId];
			const nodeName = nodesInfo[nodeId]?.name;
			return {
				id: `transactions ${key}`,
				title: localization.operations_page_name_template.replace('{}', nodeName || ''),
				url: `${site.transactions.url.replace(':treeId', key)}`,
				loadedAt: transaction?.loadedAt,
				onClose: onTreeClose ? onTreeClose('transactions', nodeId) : () => {}
			}
		});
	const transactionsElasticIds = Object.keys(transactionsElastic);
	const transactionsElasticTabs = transactionsElasticIds
		.map(key => {
			const nodeId = parseInt(key);
			const transactionElastic = transactionsElastic[nodeId];
			const nodeName = nodesInfo[nodeId]?.name;
			return {
				id: `transactionsElastic ${key}`,
				title: localization.transactions_elastic_tab_name_template.replace('{}', nodeName || ''),
				url: `${site.transactionsElastic.url.replace(':treeId', key)}`,
				loadedAt: transactionElastic?.loadedAt,
				onClose: onTreeClose ? onTreeClose('transactionsElastic', nodeId) : () => {}
			}
		})
	const reportById = Object.entries(reports) as [string, SingleEntityHolder<Report>][];
	const reportTabs = reportById
		.map(([id, entry]) => {
			const safeId = parseInt(id);
			const nodeName = nodesInfo[safeId]?.name;
			return {
				id: `report ${id}`,
				title: localization.reports_page_name_template.replace('{}', `${nodeName}`),
				url: `${site.reports.url.replace(':treeId', id)}`,
				loadedAt: entry.entity?.loadedAt,
				onClose: onTreeClose ? onTreeClose('report', safeId) : () => {}
			}
		})
	const transactionChangesById = Object.entries(transactionChangeLogs) as [string, TransactionChangeLogs][];
	const transactionChangesTabs = transactionChangesById
		.map(([id, entry]) => {
			const safeId = parseInt(id);
			const nodeName = nodesInfo[safeId]?.name;
			return {
				id: `transactionChangeLogs ${id}`,
				title: localization.transaction_change_logs_tab_name_template.replace('{}', `${nodeName}`),
				url: `${site.transactionChangesLogs.url.replace(':treeId', id)}`,
				loadedAt: entry.loadedAt,
				onClose: onTreeClose ? onTreeClose('transactionChangeLogs', safeId) : () => {}
			}
		})

	const sortedTabs = [
		...treeTabs, ...reportTabs,
		...transactionTabs, ...transactionsElasticTabs,
		...transactionChangesTabs
	]
		.sort((a, b) => {
			const a_date = a.loadedAt;
			const b_date = b.loadedAt;
			if(a_date == null){
				return b_date ? 1 : 0;
			}
			if(b_date == null){
				return -1;
			}
			return a_date.valueOf() - b_date.valueOf();
		})
	return sortedTabs;
}

const Workspace = (props: WorkspaceProps) => {
	const {
		className, leftPanel,
		title, pageTitle, backUrl, trees, treeId, reportNodeId, transactionsListId, transactionsElasticListId,
		nodes,
		reports,
		transactions, transactionsElastic,
		transactionChangeLogs,
		unloadTree, unloadTransactions, unloadTransactionsElastic,
		unloadTransactionChangeLogs, unloadReport,
		navigateTo
	} = props;

	const [zoomValue, setZoomValue] = useState<number>(1)

	const getOnTabCloseEventHandler = (entity: 'tree' | 'transactions' | 'transactionsElastic' | 'report' | 'transactionChangeLogs', idToClose?: number) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		if(idToClose){
			const tabs = getTabs(trees, reports, nodes, transactions, transactionsElastic, transactionChangeLogs, null)
				.filter(t => t.id !== `${entity} ${idToClose}`);
			if(tabs.length < 1){
				navigateTo(site.trees.url);
			}else{
				navigateTo(tabs[0].url);
			}
			switch(entity){
				case 'tree': {
					unloadTree(idToClose);
					return;
				}
				case 'transactions':{
					unloadTransactions(idToClose);
					return;
				}
				case 'transactionsElastic':{
					unloadTransactionsElastic(idToClose);
					return
				}
				case 'report':{
					unloadReport(idToClose);
					return;
				}
				case 'transactionChangeLogs':{
					unloadTransactionChangeLogs(idToClose);
					return;
				}
			}
		}
	}

	// zoomValue and setZoomValue seems to be redundant here
	// However, they are used here to:
	// 1. force re render of the component after changing zoom
	// 2. use React's features, like memoizing
	const zoom = parseFloat(Cookies.get(ZOOM_COOKIE_NAME) || '1') || zoomValue;
	const setZoom = (value: number) => {
		setZoomValue(value);
		Cookies.set(ZOOM_COOKIE_NAME, value.toString())
	};
	const zoomControlElement = getZoomControlElement(zoom, setZoom);

	let activeTabId: string;
	if(treeId){
		activeTabId = `tree ${treeId}`;
	} else if(transactionsListId){
		activeTabId = `transactions ${transactionsListId}`;
	} else if(transactionsElasticListId){
		activeTabId = `transactionsElastic ${transactionsElasticListId}`;
	} else {
		activeTabId = `report ${reportNodeId}`;
	} 

	const tabs = getTabs(trees, reports, nodes, transactions, transactionsElastic, transactionChangeLogs, getOnTabCloseEventHandler);

	const effectiveClassName = className ? `${containerClassName} ${className}`: containerClassName;
	const zoomPanelContentElement = 'customRender' in props
		? (
			<ZoomPanel
				className = {`${containerClassName}__left-column__content`}
			>
				{props.customRender(zoom)}
			</ZoomPanel>
		) : (
			<ZoomPanel
				zoom = {zoom}
				className = {`${containerClassName}__left-column__content`}
			>
				{props.children}
			</ZoomPanel>
		)
	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__left-column`}>
					<Tabs
						className = {`${containerClassName}__left-column__tabs`}
						activeTabId = {activeTabId}
						tabs = {tabs}
					/>
					<PageTitle
						title = {pageTitle || title}
						backUrl = {backUrl}
						className = {`${containerClassName}__left-column__title`}
						button = {zoomControlElement}
						showButton = {true}
					/>
					{zoomPanelContentElement}
			</div>
			<div className = {`${containerClassName}__right-column`}>
				{leftPanel}
			</div>
		</div>
	);
}

export default Workspace;