import actions from '../actions';
import history from '../history';

export interface NavigationState{
}

const defaultState: NavigationState = {
}

const reducer = (state: NavigationState = defaultState, action: any): NavigationState => {
	switch(action.type){
		case actions.navigation.types.NAVIGATION_GO:
			const {url} = action.payload;
			history.push(url);
			return {...state};
	}
	return state;
}

export default reducer;