import React, { useEffect } from "react";
import { Skeleton } from "antd";
import {
	Switch,
	Route
} from "react-router-dom";

import {version} from '../../../package.json';

import {site, getSecurityProvider} from "../../settings";
import { CommonPageProps, UserAccount } from "../../models";

import PrivateLayout from '../PrivateLayout';
import LoginPage from '../LoginPage';
import Home from '../Home';
import Users from '../Users';
import EditUser from '../EditUser';
import NotFound from '../NotFound';
import Roles from "../Roles";
import EditRole from "../EditRole";
import ActionGroups from '../ActionGroups';
import EditActionGroup from '../EditActionGroup';
import AttributeGroups from '../AttributeGroups';
import Trees from '../Trees';
import Tree from '../Tree';
import Balances from '../Balances';
import Transactions from '../Transactions'
import PivotReport from '../PivotReport';
import TransactionChangeLogs from '../TransactionChangeLogs'
import TransactionsElastic from "../TransactionsElastic";

interface AppProps extends React.HTMLProps<HTMLElement>{
	token: string,
	currentUserId?: number | null,

	currentUser: UserAccount | null,
	isCurrentUserLoading: boolean,
	currentUserError: string | null,

	loadAccount: (userId: number, token: string) => void,
	onLogout: () => void
}

const App = (props: AppProps) => {
	const {
		currentUser, isCurrentUserLoading, currentUserError,
		token, currentUserId, loadAccount
	} = props;

	const securityProvider = getSecurityProvider(currentUser?.permissions || []);

	useEffect(() => {
		if(token && currentUserId && !currentUser && !isCurrentUserLoading && !currentUserError){
			loadAccount(currentUserId, token);
		}
	})

	// render version
	useEffect(() => {
		const versionElement = document.createComment(`version: ${version}`);
		document.body.appendChild(versionElement);
	}, [])

	if(token){
		if(currentUser){
			const propsForChildren: CommonPageProps = {
				currentUser: currentUser,
				securityProvider: securityProvider
			}

			return (
				<PrivateLayout
					currentUser = {currentUser}
					securityProvider = {securityProvider}
					onLogout = {props.onLogout}
				>
					<Switch>
						<Route exact path={'/'}>
							<Home/>
						</Route>
						<Route exact path={site.trees.url}>
							<Trees {...propsForChildren}/>
						</Route>
						<Route exact path={`${site.trees.url}/:id`}>
							<Tree {...propsForChildren}/>
						</Route>
						<Route exact path={site.transactions.url}>
							<Transactions {...propsForChildren}/>
						</Route>
						<Route path = {site.reports.url}>
							<PivotReport {...propsForChildren}/>
						</Route>
						{securityProvider.isAdmin && (
							<Route exact path={`${site.transactionChangesLogs.url}`}>
								<TransactionChangeLogs {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.users.canRead && (
							<Route exact path={site.users.url}>
								<Users  {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.users.canCreate && (
							<Route exact path={site.newUser.url}>
								<EditUser {...propsForChildren} isNew/>
							</Route>
						)}
						{securityProvider.users.canEdit && (
							<Route path={`${site.users.url}/:id`}>
								<EditUser {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.roles.canRead && (
							<Route exact path={site.roles.url}>
								<Roles {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.roles.canCreate && (
							<Route exact path={site.newRole.url}>
								<EditRole {...propsForChildren} isNew/>
							</Route>
						)}
						{securityProvider.roles.canEdit && (
							<Route path={`${site.roles.url}/:id`}>
								<EditRole {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.actionGroups.canRead && (
							<Route exact path={site.actionGroups.url}>
								<ActionGroups {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.actionGroups.canCreate && (
							<Route exact path={site.newActionGroup.url}>
								<EditActionGroup {...propsForChildren} isNew/>
							</Route>
						)}
						{securityProvider.actionGroups.canEdit && (
							<Route path={`${site.actionGroups.url}/:id`}>
								<EditActionGroup {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.isAdmin && (
							<Route path={`${site.attributeGroups.url}`}>
								<AttributeGroups {...propsForChildren}/>
							</Route>
						)}
						{securityProvider.isAdmin && (
							<Route path={`${site.balances.url}`}>
								<Balances {...propsForChildren}/>
							</Route>
						)}
						<Route path={`${site.transactionsElastic.url}`}>
							<TransactionsElastic {...propsForChildren}/>
						</Route>
						<Route>
							<NotFound/>
						</Route>
					</Switch>
				</PrivateLayout>
			);
		} else {
			return (<Skeleton/>);
		}
	}
	return (
		<LoginPage/>
	)
}

export default App;
