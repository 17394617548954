import { call, put, takeEvery} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import {TransactionFilterValueItem} from '../models';
import handleResponse from './handleResponse';

const {loadFilterSuccess, loadFilterFailed} = actions.transactionFilterValues;
const {raise} = actions.error;

type ResponseType = {status: number, body: any} | {error: any};

const tryLoad = (treeId: number, filterName: string, token: string): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const base_url = `${config.api_url}/tree/${treeId}/transactions/filters/${filterName}/values`;

		return fetch(base_url, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractAuthorId = (entry: any): TransactionFilterValueItem => ({
	id: parseInt(entry.id),
	text: entry.name
});

const loadSaga = function*(action: any){
	const {treeId, filterName, token} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoad, treeId, filterName, token);
		yield* handleResponse(
			response,
			(body: any) => loadFilterSuccess(treeId, filterName, body.data.map(extractAuthorId)),
			(message: any) => loadFilterFailed(treeId, filterName, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFilterFailed(treeId, filterName, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield takeEvery(actions.transactionFilterValues.types.TRANSACTION_FILTER_VALUES_LOAD, loadSaga)
}

export default watch;