import React from 'react';
import { Select, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusCircle } from 'react-feather';

import { AttributeGroup, TreeListEntry } from '../../models';
import { localization, TreeSecurityProvider } from '../../settings';
import { Button, ConfirmDelete, EditButtonsSet, SetNameDialog } from '../../components';
import { AttributeGroupsInfo } from '../../reducers/attributeGroups';

interface AttributeGroupInfo extends AttributeGroup{
	key: number,
	attributeNames: string[] | null
}

interface AttributeGroupsListProps{
	className?: string | null;
	treeInfo: TreeListEntry | null,
	attributeGroups?: AttributeGroupsInfo | null,
	token: string,
	isAttributesAvailable: boolean,

	treeSecurityProvider: TreeSecurityProvider,

	loadAttributeGroups: (treeId: number, token: string) => void,
	loadAttributeGroupsInfo: (treeId: number, token: string) => void,
	createAttributeGroup: (treeId: number, name: string, token: string) => void,
	updateAttributeGroup: (attributeGroupId: number, name: string, treeId: number, token: string) => void,
	deleteAttributeGroup: (attributeGroupId: number, treeId: number, token: string) => void,
}

const containerClassName = 'attribute-groups-list';

class AttributeGroupsList extends React.Component<AttributeGroupsListProps>{
	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			treeInfo, attributeGroups,
			isAttributesAvailable,
			token,
			loadAttributeGroups, loadAttributeGroupsInfo} = this.props;
		if(!attributeGroups ||
			(!attributeGroups.list && !attributeGroups.isLoading)
		){
			treeInfo?.id && loadAttributeGroups(treeInfo.id, token)
		}
		if(isAttributesAvailable){
			if(!attributeGroups ||
				(!attributeGroups.attributesInfo && !attributeGroups.isAttributesLoading)
			){
				treeInfo?.id && loadAttributeGroupsInfo(treeInfo.id, token)
			}
		}
	}

	onNewAttributeGroup = () => {
		const {
			treeInfo,
			token,
			createAttributeGroup
		} = this.props;
		return SetNameDialog.show({
			title: localization.attributeGroups_newAttributeTitle,
			inputPlaceholder: localization.attributeGroups_newAttributePlaceholder,
			onOk: (name) => treeInfo?.id && createAttributeGroup(treeInfo?.id, name, token)
		})
	}

	getOnEditHandler = (attributeGroupId: number, currentName: string) => () => {
		const {
			treeInfo,
			token,
			updateAttributeGroup
		} = this.props;
		return SetNameDialog.show({
			title: localization.attributeGroups_editAttributeTitle,
			inputPlaceholder: localization.attributeGroups_editAttributePlaceholder,
			initialValue: currentName,
			onOk: (name) => treeInfo?.id && updateAttributeGroup(attributeGroupId, name, treeInfo.id, token)
		})
	}

	getOnDeleteHandler = (attributeGroupId: number) => () => {
		const {
			treeInfo,
			token,
			deleteAttributeGroup
		} = this.props;
		return ConfirmDelete({
			title: localization.delete_action_group_title,
			content: localization.delete_action_group_content,
			deleteEntity: () => treeInfo?.id && deleteAttributeGroup(attributeGroupId, treeInfo?.id, token)
		})
	}

	getColumns = () => {
		const {treeSecurityProvider, isAttributesAvailable} = this.props;
		const getSorterFor = (fieldName: string) => (a:any, b:any) => {
			return (a[fieldName] || '').localeCompare(b[fieldName] || '')
		};
		const columns: ColumnsType<any> = [
			{
				title: localization.attributeGroups_name,
				dataIndex: 'name',
				sorter: getSorterFor('name'),
				showSorterTooltip: false
			}
		];

		if(isAttributesAvailable){
			columns.push({
				title: localization.attributeGroups_attributes,
				dataIndex: 'attributeNames',
				render: (names: string[] | null) => {
					if(names == null){
						return (
							<Skeleton.Input
								className = {`${containerClassName}__table__names`}
								active
							/>
						)
					}
					return (
						<Select
							disabled
							mode = 'multiple'
							defaultValue = {names}
							className = {`${containerClassName}__table__names`}
						/>
					)
				}
			});
		}

		if(treeSecurityProvider.node.attributeGroups.canEdit || treeSecurityProvider.node.attributeGroups.canDelete){
			columns.push({
				title: null,
				render: (agInfo: AttributeGroupInfo) => (
					<EditButtonsSet
						onEdit = {this.getOnEditHandler(agInfo.id, agInfo.name)}
						isEditAvailable = {treeSecurityProvider.node.attributeGroups.canEdit}
						onDelete = {this.getOnDeleteHandler(agInfo.id)}
						isDeleteAvailable = {treeSecurityProvider.node.attributeGroups.canDelete}
					/>
				)
			})		
		}
		
		return columns;
	}

	getData = (): AttributeGroupInfo[] => {
		const {attributeGroups} = this.props;
		return (attributeGroups?.list || []).map(g => {
			const attributesInfo = (attributeGroups?.attributesInfo && attributeGroups?.attributesInfo[g.id]);
			const attributeNames = attributesInfo?.sort((a,b) => a.id - b.id).map(e => e.name);
			return {
				...g,
				key: g.id,
				attributeNames: attributeNames || null
			}
		});
	}

	render(){
		const {className, treeInfo, attributeGroups, treeSecurityProvider} = this.props;
		if(!treeInfo){
			return null;
		}

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		const isLoading = attributeGroups ?
			attributeGroups.isLoading || attributeGroups.isAttributesLoading :
			true;
		return (
			<div className = {effectiveClassName}>
				<div className = {`${containerClassName}__title`}>
					<span className = {`${containerClassName}__title__text`}>
						{localization.attributeGroups_attributeGroupsTitle}
						&nbsp;
					</span>
					<span className = {`${containerClassName}__title__tree-name`}>
						{treeInfo.name}
					</span>

					{treeSecurityProvider.node.attributeGroups.canCreate && (
						<Button
							type = 'primary'
							icon = {<PlusCircle className = 'feather-ico'/>}
							className = {`${containerClassName}__title__new-button`}
							onClick = {this.onNewAttributeGroup}
						>
							{localization.attributeGroups_newAttributeGroup}
						</Button>
					)}
				</div>
				<Table<AttributeGroupInfo>
					columns = {this.getColumns()}
					dataSource = {this.getData()}
					loading = {isLoading}
					pagination = {false}
					className = {`${containerClassName}__table`}
				/>
			</div>
		);
	}
}

export default AttributeGroupsList;