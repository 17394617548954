import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

interface MenuEntryProps{
    className?: string | null,
    disabled?: boolean,
    nonInteractive?: boolean,
    selected?: boolean,
    children?:  React.ReactNode | React.ReactNode[],
    icon?: React.ReactNode,
    altIcon?: React.ReactNode,
    link?: string,
    size?: "small" | null,

    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const containerClassName = 'menu-entry';

const MenuEntry = (props: MenuEntryProps): JSX.Element => {
    const {
        className, children,
        disabled, nonInteractive, selected,
        icon, altIcon, link,
        size,
        onClick
    } = props;

    let effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
    if(disabled){
        effectiveClassName += ' disabled';
    }
    if(selected){
        effectiveClassName += ' selected';
    }
    if(nonInteractive){
        effectiveClassName += ' non-interactive';
    }
    if(size === "small"){
        effectiveClassName += ' small';
    }
    const contentElement = (
        <>
            {!!icon &&(<span className = {`${containerClassName}__icon`}>
                {icon}
            </span>)}
            <span className={`${containerClassName}__text`}>{children}</span>
            {!!altIcon && (<span className = {`${containerClassName}__alt-icon`}>{altIcon}</span>)}
        </>
    )

    if(link && !disabled){
        return (
            <NavLink className = {effectiveClassName} to = {link} onClick = {onClick}>
                {contentElement}
            </NavLink>
        );
    }
    return (
        <div className = {effectiveClassName} onClick = {onClick}>
            {contentElement}
        </div>
    );
}

export default memo(MenuEntry);