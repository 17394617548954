const defaultFirstPage = 1;
const defaultPageSize = 10;

const transactionTypes = [
  {
    "id": 1,
    "name": "Пополнение",
    "slug": "replenishment"
  },
  {
    "id": 2,
    "name": "Списание",
    "slug": "write-off"
  },
  {
    "id": 3,
    "name": "Приход",
    "slug": "income"
  },
  {
    "id": 4,
    "name": "Расход",
    "slug": "consumption"
  },
  {
    "id": 5,
    "name": "Перевод",
    "slug": "transfer"
  }
];

export {
	defaultFirstPage,
	defaultPageSize,
  transactionTypes
}