const actionTypes = {
	BALANCE_CREATE: 'BALANCE\\CREATE',
	BALANCE_CREATE_SUCCESS: 'BALANCE\\CREATE_SUCCESS',
	BALANCE_CREATE_FAILED: 'BALANCE\\CREATE_FAILED',

	BALANCE_UPDATE: 'BALANCE\\UPDATE',
	BALANCE_UPDATE_SUCCESS: 'BALANCE\\UPDATE_SUCCESS',
	BALANCE_UPDATE_FAILED: 'BALANCE\\UPDATE_FAILED',

	BALANCE_DELETE: 'BALANCE\\DELETE',
	BALANCE_DELETE_SUCCESS: 'BALANCE\\DELETE_SUCCESS',
	BALANCE_DELETE_FAILED: 'BALANCE\\DELETE_FAILED',

	BALANCE_ORDER: 'BALANCE\\ORDER',
	BALANCE_ORDER_SUCCESS: 'BALANCE\\ORDER_SUCCESS',
	BALANCE_ORDER_FAILED: 'BALANCE\\ORDER_FAILED',
}

const create = (balance: {name: string, typeId: number, creditLimitId: number | null, limit: number | null}, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_CREATE,
	payload: {
		name: balance.name,
		typeId: balance.typeId,
		creditLimitId: balance.creditLimitId,
		limit: balance.limit,
		treeId,
		token
	}
});

const createSuccess = (balanceId: number, treeId: number) => ({
	type: actionTypes.BALANCE_CREATE_SUCCESS,
	payload: {
		balanceId,
		treeId
	}
});

const createFailed = (message: string, treeId: number) => ({
	type: actionTypes.BALANCE_CREATE_FAILED,
	payload: {
		message,
		treeId
	}
})

const update = (id: number, balance: {name?: string, creditLimitId?: number, limit?: number | null}, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_UPDATE,
	payload: {
		id,
		name: balance.name,
		creditLimitId: balance.creditLimitId,
		limit: balance.limit,
		treeId,
		token
	}
});

const updateSuccess = (treeId: number) => ({
	type: actionTypes.BALANCE_UPDATE_SUCCESS,
	payload: {
		treeId
	}
});

const updateFailed = (message: string, treeId: number) => ({
	type: actionTypes.BALANCE_UPDATE_FAILED,
	payload: {
		message,
		treeId
	}
})

const deleteBalance = (id: number, treeId: number, token: string) => ({
	type: actionTypes.BALANCE_DELETE,
	payload: {
		id,
		treeId,
		token
	}
});

const deleteSuccess = (id: number, treeId: number) => ({
	type: actionTypes.BALANCE_DELETE_SUCCESS,
	payload: {
		id,
		treeId
	}
});

const deleteFailed = (message: string, id: number, treeId: number) => ({
	type: actionTypes.BALANCE_DELETE_FAILED,
	payload: {
		message,
		id,
		treeId
	}
})

const order = (idsWithOrder: {id: number, order: number}[], treeId: number, token: string) => ({
	type: actionTypes.BALANCE_ORDER,
	payload: {
		idsWithOrder,
		treeId,
		token
	}
})

const orderSuccess = (treeId: number) => ({
	type: actionTypes.BALANCE_ORDER_SUCCESS,
	payload: {
		treeId,
	}
})

const orderFailed = (message: string, treeId: number) => ({
	type: actionTypes.BALANCE_ORDER_FAILED,
	payload: {
		message,
		treeId
	}
})

export default {
	types: actionTypes,
	create,
	createSuccess,
	createFailed,
	update,
	updateSuccess,
	updateFailed,
	delete: deleteBalance,
	deleteSuccess,
	deleteFailed,
	order,
	orderSuccess,
	orderFailed
};