import React, { memo } from 'react';
import { Input, Form } from 'antd';

import { BalanceAttribute } from '../../../models';
import { errors } from '../../../settings';

interface TextBalanceAttributeProps{
	className?: string,
	path: string[],

	balanceAttribute: BalanceAttribute
}

const containerClassName = 'text-balance-attribute';

const TextBalanceAttribute = (props: TextBalanceAttributeProps) => {
	const {className, balanceAttribute, path} = props;

	const rules = balanceAttribute.required ? [{required: true, message: errors.fields_is_required}] : []

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<Form.Item
			name = {[...path, `${balanceAttribute.id}`]}
			label = {balanceAttribute.name}
			rules = {rules}
		>
			<Input.TextArea
				className = {effectiveClassName}
			/>
		</Form.Item>
	)
}

export default memo(TextBalanceAttribute);