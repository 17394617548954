import { combineReducers } from 'redux';

import auth, { AuthState } from './auth';
import users, { UsersState } from './users';
import roles, { RolesState } from './roles';
import permissions, { PermissionsState } from './permissions';
import version, { VersionState } from './version';
import actionGroups, { ActionGroupsState } from './actionGroups';
import treePermissions, { TreePermissionsState } from './treePermissions';
import trees, { TreesState } from './trees';
import nodes from './nodes';
import account, { AccountState } from './account';
import attributeVisibilities, {
  AttributeVisibilityState,
} from './attributeVisibilities';
import attributeGroups, { AttributeGroupsState } from './attributeGroups';
import nodeAttributesForTree, {
  NodeAttributesForTreeState,
} from './nodeAttributesForTree';
import balances from './balances';
import balanceTypes, { BalanceTypesState } from './balanceTypes';
import balanceLimits, { BalanceLimitsState } from './balanceLimits';
import balanceFormula, { BalanceFormulaState } from './balanceFormula';
import balanceAttributes, { BalanceAttributesState } from './balanceAttributes';
import balanceAttributeTypes from './balanceAttributeTypes';
import transactions from './transactions';
import transactionsElastic from './transactionsElastic';
import transactionChangeLogs from './transactionChangeLogs';
import transactionFilterValues, {
  TransactionFilterValuesState,
} from './transactionFilterValues';
import reports from './report';
import reportTemplates from './reportTemplates';
import transactionAttributes from './transactionAttributes';
import navigation, { NavigationState } from './navigation';
import treeContainerStates, { TreeContainerStates } from './treeContainerState';
import transactionsContainerStates, {
  TransactionsContainerStates,
} from './transactionsContainerState';
import editUserStates, { EditUserStates } from './editUserState';
import transactionsElasticContainerStates, {
  TransactionsElasticContainerStates,
} from './transactionsElasticContainerState';
import transactionsElasticAttributes, {
  TransactionsElasticAttributesState,
} from './transactionsElasticAttributes';
import passwordChange, {PasswordChangeState} from './passwordChange';

import preHandler from './_preHandler';
import {
  Balance,
  BalanceAttributeType,
  EntityHolder,
  EntityPerTree,
  NodesState,
  report,
  Report,
  SingleEntityPerTree,
  TransactionAttributes,
  TransactionChangeLogsPerTree,
  TransactionsElasticPerTree,
} from '../models';
import { TransactionsPerTree } from '../models';

export interface State {
  auth: AuthState;
  users: UsersState;
  roles: RolesState;
  permissions: PermissionsState;
  version: VersionState;
  trees: TreesState;
  actionGroups: ActionGroupsState;
  treePermissions: TreePermissionsState;
  nodes: NodesState;
  account: AccountState;
  attributeVisibilities: AttributeVisibilityState;
  attributeGroups: AttributeGroupsState;
  nodeAttributesForTree: NodeAttributesForTreeState;
  balances: EntityPerTree<Balance>;
  balanceLimits: BalanceLimitsState;
  balanceTypes: BalanceTypesState;
  balanceFormula: BalanceFormulaState;
  balanceAttributes: BalanceAttributesState;
  balanceAttributeTypes: EntityHolder<BalanceAttributeType>;
  transactions: TransactionsPerTree;
  transactionsElastic: TransactionsElasticPerTree;
  transactionFilterValues: TransactionFilterValuesState;
  transactionChangeLogs: TransactionChangeLogsPerTree;
  reports: SingleEntityPerTree<Report>;
  reportTemplates: EntityPerTree<report.Template>;
  transactionAttributes: TransactionAttributes;
  navigation: NavigationState;
  treeContainerStates: TreeContainerStates;
  transactionsContainerStates: TransactionsContainerStates;
  editUserStates: EditUserStates;
  transactionsElasticContainerStates: TransactionsElasticContainerStates;
  transactionsElasticAttributes: TransactionsElasticAttributesState;
  passwordChange: PasswordChangeState;
}

const combinedReducer = combineReducers<State>({
  auth,
  users,
  roles,
  permissions,
  version,
  trees,
  actionGroups,
  treePermissions,
  nodes,
  account,
  attributeVisibilities,
  attributeGroups,
  nodeAttributesForTree,
  balances,
  balanceTypes,
  balanceLimits,
  balanceFormula,
  balanceAttributes,
  balanceAttributeTypes,
  transactions,
  transactionsElastic,
  transactionFilterValues,
  reports,
  reportTemplates,
  transactionAttributes,
  transactionChangeLogs,
  navigation,
  treeContainerStates,
  transactionsContainerStates,
  editUserStates,
  transactionsElasticContainerStates,
  transactionsElasticAttributes,
  passwordChange,
});

const rootReducer = (state: State | undefined, action: any): State => {
  const intermediateState = preHandler(state, action);
  return combinedReducer(intermediateState, action);
};

export default rootReducer;
