import { connect } from 'react-redux';

import actions from '../../actions';
import { State } from '../../reducers';
import { CommonPageProps } from '../../models';

import Workspace from './Workspace';

import './Workspace.scss';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		trees: state.trees.trees,
		nodes: state.nodes,
		reports: state.reports,
		transactions: state.transactions,
		transactionsElastic: state.transactionsElastic,
		transactionChangeLogs: state.transactionChangeLogs,
		token: state.auth.token
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadTree: (id: string, token: string) => 
		dispatch(actions.tree.load(id, token)),
	unloadTree: (id: number) =>
		dispatch(actions.tree.unload(id.toString())),
	unloadTransactions: (treeId: number) =>
		dispatch(actions.transactions.unload(treeId)),
	unloadTransactionsElastic: (treeId: number) =>
		dispatch(actions.transactionsElastic.unload(treeId)),
	unloadTransactionChangeLogs: (nodeId: number) =>
		dispatch(actions.transactionChangeLogs.unload(nodeId)),
	unloadReport: (nodeId: number) => 
		dispatch(actions.report.unload(nodeId)),
	navigateTo: (url: string) => {
		dispatch(actions.navigation.go(url))
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(Workspace)