import actions from "../actions";

import {UserAccount} from '../models'

export interface AccountState{
    user: UserAccount | null,
    error: string  | null,
    isLoading: boolean
}

const defaultState: AccountState = {
    user: null,
    error: null,
    isLoading: false
}

const account = (state: AccountState = defaultState, action: any): AccountState => {
    switch(action.type){
        case actions.account.types.ACCOUNT_LOAD: {
            return {
                ...state,
                user: null,
                error: null,
                isLoading: true
            }
        }
        case actions.account.types.ACCOUNT_LOAD_SUCCESS: {
            const {user} = action.payload;
            const updatedAccount: UserAccount = {
                id: user.id || -1,
                email: user.email || '',
                firstname: user.firstname || '',
                lastname: user.lastname || '',
                name: user.name || '',
                permissions: user.permissions
            }
            return {
                ...state,
                user: updatedAccount,
                isLoading: false,
                error: null
            }
        }
        case actions.account.types.ACCOUNT_LOAD_FAILED: {
            console.error('ACCOUNT_LOAD_FAILED\n%o', action.payload);
            const {message} = action.payload;
            return {
                ...state,
                user: null,
                isLoading: false,
                error: message
            }
        }
    }
    return state;
}

export default account;