import { connect } from 'react-redux';

import actions from '../../actions'
import { CommonPageProps, EditUserState, User } from '../../models';
import { State } from '../../reducers';

import EditUser from './EditUser';

import './EditUser.scss';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		isUsersLoading: state.users.isLoading,
		isUserUpdating: state.users.isUserUpdating,
		isRolesLoading: state.roles.isLoading,
		users: state.users.list,
		roles: state.roles.list,
		userStates: state.editUserStates,
		token: state.auth.token
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadUsers: (token: string) => dispatch(actions.users.load(token)),
	loadRoles: (token: string) => dispatch(actions.roles.load(token)),
	updateUser: (user: User, token: string, prevRoleIds: string[],
		nextRoleIds: string[]) => dispatch(actions.user.update(user, token, prevRoleIds, nextRoleIds)),
	createUser: (user: User, token: string, prevRoleIds: string[],
		nextRoleIds: string[]) => dispatch(actions.user.create(user, token, prevRoleIds, nextRoleIds)),
	resetUsers: () => dispatch(actions.users.reset()),
	setUserState: (userId: number, userState: EditUserState) =>
		dispatch(actions.editUserState.save(userId, userState))
})

const connectedContainer = connect(mapStateToProps, mapDispatchToProps)(EditUser);

export default connectedContainer;