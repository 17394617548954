import { connect } from 'react-redux';

import actions from '../../actions';
import { State } from '../../reducers';
import { CommonPageProps, payloads } from '../../models';

import TransactionWizard, {
	SourceEndpoint as _SourceEndpoint,
	TargetEndpoint as _TargetEndpoint
} from './TransactionWizard';

import './TransactionWizard.scss';
import './NodeInTree.scss';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		token: state.auth.token,
		trees: state.trees.list,
		isTreesLoading: state.trees.isLoading,
		nodesInfo: state.nodes,
		balances: state.balances,
		balanceAttributes: state.balanceAttributes,
		balanceAttributeTypes: state.balanceAttributeTypes,
		balanceTypes: state.balanceTypes.list,
		isBalanceTypesLoading: state.balanceTypes.isLoading,
		currentUser: ownProps.currentUser,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadUsers: (token: string) => 
		dispatch(actions.users.load(token)),
	loadNodeAttributes: (nodeId: number, token: string) => 
		dispatch(actions.node.loadNodeAttributes(nodeId, token)),
	loadBalances: (treeId: number, token: string) =>
		dispatch(actions.balances.load(treeId, token)),
	loadBalanceTypes: (token: string) => 
		dispatch(actions.balanceTypes.load(token)),
	loadBalanceAttributes: (treeId: number, balanceId: number, token: string) => 
		dispatch(actions.balanceAttributes.load(balanceId, treeId, token)),
	loadBalanceAttributeTypes: (token: string) =>
		dispatch(actions.balanceAttributeTypes.load(token)),
	loadTrees: (token: string) =>
		dispatch(actions.trees.load(token)),
	createTransaction: (payload: payloads.CreateTransactionPayloadType, token: string) =>
		dispatch(actions.transactions.create(payload, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionWizard)
export type SourceEndpoint = _SourceEndpoint;
export type TargetEndpoint = _TargetEndpoint;