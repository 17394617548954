import React, { useRef } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { findNode } from '../../tools';
import { Tree } from '../../models';
import { Paperclip } from 'react-feather';
import { localization } from '../../settings';

type OnMouseWithRecord = (record: Tree, event: React.MouseEvent<HTMLElement, MouseEvent>, offset: {x: number, y: number}) => void;

interface SplitTableProps{
	getColumns: (isFixed?: boolean) => ColumnsType<Tree>,
	dataSource: Tree[],
	className?: string | null,
	mainTableClassName?: string | null,

	fixedRowIds?: React.ReactText[],
	onRowsFix: () => void,

	expandedRowKeys: React.ReactText[],
	onExpandRow: (expandedKeys: React.ReactText[]) => void,

	filteredRowIds: number[] | null,

	onRowClick?: OnMouseWithRecord,
	onRowContextMenu?: OnMouseWithRecord,
	getRowClassName: (record: Tree) => string
}

const containerClassName = 'split-table';

const getOffset = (ref: React.MutableRefObject<HTMLDivElement | null>): {x: number, y: number} => {
	const element = ref.current!;
	const rect = element.getBoundingClientRect();
	return {
		x: rect.left,
		y: rect.top
	}
}

const renderFixedRows = (rows: Tree[], containerRef: React.MutableRefObject<HTMLDivElement | null>, props: SplitTableProps) => {
	if(rows.length < 1){
		return null;
	}

	const {onRowClick, onRowContextMenu} = props;

	const rowsToShow = rows.map(r => ({
		...r,
		children: undefined
	}))

	const columns = props.getColumns(true);

	return (
		<div className = {`${containerClassName}__fixed-rows`}>
			<div className = {`${containerClassName}__fixed-rows__header`}>
				<Paperclip height={15} style = {{verticalAlign: 'middle', marginTop: 2}} />
				<p>{localization.fixed_rows}</p>
			</div>
			<div className = {`${containerClassName}__fixed-rows__table`}>
				<Table<Tree>
					columns = {columns}
					dataSource = {rowsToShow}
					pagination={false}
					onRow = {(record) => ({
						onClick: (event) => {
							event.preventDefault();
						},
						onContextMenu: (event) => {
							const offset = getOffset(containerRef);
							onRowClick && onRowClick(record, event, offset);
							onRowContextMenu && onRowContextMenu(record, event, offset);
						}
					})}
					scroll={{
						x: '100%',
						y: '100%'
					}}
					bordered
				/>
			</div>
		</div>
	);
}

const SplitTable = (props: SplitTableProps) => {
		const {
			dataSource, getColumns,
			filteredRowIds,
			fixedRowIds,
			expandedRowKeys, onExpandRow,
			onRowClick, onRowContextMenu, getRowClassName,
			className, mainTableClassName
		} = props;

		const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		const columns = getColumns();
		const fixedRows = (fixedRowIds || [])
			.map(id => findNode(id, dataSource[0]))
			.filter((t):t is Tree => !!t);

		const mainTableData = filteredRowIds
			? filteredRowIds
				.map(id => findNode(id, dataSource[0]))
				.filter((t):t is Tree => !!t)
				.map(node => ({
					...node,
					children: undefined
				}))
			: dataSource


		const effectiveMainTableClassName = mainTableClassName ?
			`${containerClassName}__main-table ${mainTableClassName}`:
			`${containerClassName}__main-table`;
		return (
			<div
				ref = {containerRef}
				className = {effectiveClassName}
			>
				{renderFixedRows(fixedRows, containerRef, props)}
				<div className = {effectiveMainTableClassName}>
					<Table<Tree>
						columns = {columns}
						dataSource = {mainTableData}
						pagination={false}
						expandable={{
							onExpandedRowsChange: onExpandRow,
							expandedRowKeys: expandedRowKeys
						}}
						onRow = {(record) => ({
							onClick: (event) => onRowClick && onRowClick(record, event, getOffset(containerRef)),
							onContextMenu: (event) => {
								const offset = getOffset(containerRef);
								onRowClick && onRowClick(record, event, offset);
								onRowContextMenu && onRowContextMenu(record, event, offset);
							},
							className: getRowClassName(record)
						})}
						scroll = {{
							x: '100%',
							y: '100%'
						}}
						bordered
					/>
				</div>
			</div>
		);
}

export default SplitTable;