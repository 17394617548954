import { all, call, put, takeEvery} from 'redux-saga/effects';

import actions from '../actions';
import { config } from '../settings';

import handleResponse from './handleResponse';

const {
	createSuccess, createFailed,
	updateSuccess, updateFailed,
	deleteSuccess, deleteFailed
} = actions.attributeValue;
const {raise} = actions.error;

type ResponsePromise = Promise<{status: number, body: any} | {error: any}>
type PostPayload = {nodeId: number, attributeId: number, token: string} & (
	{id: undefined, name: string, visibilityId: number} |
	{id: number, name?: string, visibilityId?: number}
)
type DeletePayload = {id: number, attributeId: number, nodeId: number, token: string}

const tryPostAttributeValue = (payload: PostPayload): ResponsePromise => {
	try{
		const {
			id, nodeId, attributeId,
			name, visibilityId
		} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		const formData = new FormData();
		if(name){
			formData.append("name", name);
		}
		if(visibilityId){
			formData.append("visibilityId", `${visibilityId}`);
		}

		const url = id ?
			`${config.api_url}/nodes/${nodeId}/attributes/${attributeId}/values/${id}` :
			`${config.api_url}/nodes/${nodeId}/attributes/${attributeId}/values/`;

		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryDeleteAttributeValue = (payload: DeletePayload): ResponsePromise => {
	try{
		const {
			id, nodeId, attributeId
		} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/nodes/${nodeId}/attributes/${attributeId}/values/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const createAttributeValueSaga = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response = yield call(tryPostAttributeValue, action.payload);
		yield* handleResponse(
			response,
			(body: {id: number}) => createSuccess(body.id, nodeId),
			(message: string) => createFailed(message, nodeId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed('An error occurred', nodeId));
		return;
	}
}

const updateAttributeValueSaga = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response = yield call(tryPostAttributeValue, action.payload);
		yield* handleResponse(
			response,
			() =>updateSuccess(nodeId),
			(message: string) => updateFailed(message, nodeId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed('An error occurred', nodeId));
		return;
	}
}

const deleteAttributeValueSaga = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response = yield call(tryDeleteAttributeValue, action.payload);
		yield* handleResponse(
			response,
			() => deleteSuccess(nodeId),
			(message: string) => deleteFailed(message, nodeId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed('An error occurred', nodeId));
		return;
	}
}

const watchAttribute = function*() {
	yield all([
		takeEvery(actions.attributeValue.types.ATTRIBUTE_VALUE_CREATE, createAttributeValueSaga),
		takeEvery(actions.attributeValue.types.ATTRIBUTE_VALUE_UPDATE, updateAttributeValueSaga),
		takeEvery(actions.attributeValue.types.ATTRIBUTE_VALUE_DELETE, deleteAttributeValueSaga)
	])
}

export default watchAttribute;