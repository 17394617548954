import React from 'react';
import { TreeSelector } from '../../components';

import { CommonPageProps, TreeListEntry, NodesState } from '../../models';
import { AttributeGroupsState as AttributeGroupsPerTree} from '../../reducers/attributeGroups'
import { getTreeSecurityProvider, localization } from '../../settings';
import AttributeGroupsList from './AttributeGroupsList';

interface AttributeGroupsProps extends CommonPageProps{
	isTreesListLoading: boolean,
	treesList: TreeListEntry[] | null,
	attributeGroupsPerTree: AttributeGroupsPerTree,
	token: string,
	nodesInfo: NodesState,

	loadTrees: (token: string) => void,
	loadAttributeGroups: (treeId: number, token: string) => void,
	loadAttributesPerGroups: (treeId: number, token: string) => void,
	createAttributeGroup: (treeId: number, name: string, token: string) => void,
	updateAttributeGroup: (attributeGroupId: number, name: string, treeId: number, token: string) => void,
	deleteAttributeGroup: (attributeGroupId: number, treeId: number, token: string) => void,
}

interface AttributeGroupsState{
	selectedTreeId: number | null
}

const defaultState: AttributeGroupsState = {
	selectedTreeId: null
}

const containerClassName = 'attribute-groups-page';

class AttributeGroups extends React.Component<AttributeGroupsProps, AttributeGroupsState>{
	constructor(props: AttributeGroupsProps){
		super(props)

		this.state = defaultState;
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			treesList, isTreesListLoading, loadTrees,
			token
		} = this.props;
		if(!treesList && !isTreesListLoading){
			loadTrees(token);
		}
	}

	selectTreeHandler = (treeId?: number, event?: React.MouseEvent) => {
		event && event.stopPropagation();
		if(treeId != null){
			this.setState((state) => ({
				...state,
				selectedTreeId: treeId
			}))
		}
	}

	render(){
		const {
			treesList,
			token, nodesInfo, currentUser, securityProvider,
			attributeGroupsPerTree,
			loadAttributeGroups, loadAttributesPerGroups,
			createAttributeGroup, updateAttributeGroup, deleteAttributeGroup
		}= this.props;
		const {selectedTreeId} = this.state;
		const treeInfo = treesList && selectedTreeId ? treesList.find(tree => tree.id === selectedTreeId) : null;
		const attributeGroups = selectedTreeId ? attributeGroupsPerTree[selectedTreeId] : null;
		const treeSecurityProvider = getTreeSecurityProvider(currentUser, selectedTreeId, nodesInfo);

		return ( 
			<div className = {containerClassName}>
					<TreeSelector
						treesList = {treesList}
						title = {localization.attributeGroups_trees}
						selectedTreeId = {selectedTreeId}
						onSelect = {this.selectTreeHandler}
						className = {`${containerClassName}__trees`}
					/>
				<AttributeGroupsList
					className = {`${containerClassName}__attribute-groups`}
					treeInfo = {treeInfo || null}
					attributeGroups = {attributeGroups}
					token = {token}
					loadAttributeGroups = {loadAttributeGroups}
					loadAttributeGroupsInfo = {loadAttributesPerGroups}
					isAttributesAvailable = {securityProvider.isAdmin}
					treeSecurityProvider = {treeSecurityProvider}
					createAttributeGroup = {createAttributeGroup}
					updateAttributeGroup = {updateAttributeGroup}
					deleteAttributeGroup = {deleteAttributeGroup}
				/>
			</div>
		)
	}
}

export default AttributeGroups;