import React from 'react';

interface SwitcherOption<TValue>{
	label: React.ReactText,
	value: TValue
}

type OnChangeClickHandler<TValue> = (value: TValue, event: React.MouseEvent<HTMLElement, MouseEvent>) => void

interface SwitcherProps<TValue>{
	className?: string | null,
	options?: SwitcherOption<TValue>[] | null,
	selectedId?: React.ReactText | null,
	disabled?: boolean | null,

	onChange?: OnChangeClickHandler<TValue>
}

const containerClassName = 'bra-switcher';

const renderSwitcherOption = <TValue extends unknown>(
	option: SwitcherOption<TValue>, isSelected: boolean, onChange?: OnChangeClickHandler<TValue>, disabled?: boolean | null
) => {
	const effectiveClassName = isSelected ? `${containerClassName}__option--selected` : `${containerClassName}__option`;
	const onClick = !disabled && onChange ?
		(event: React.MouseEvent<HTMLElement, MouseEvent>) => onChange(option.value, event) :
		undefined;
	return (
		<div
			className = {effectiveClassName}
			key = {`${option.value}`}
			onClick = {onClick}
		>
			<div className = {`${effectiveClassName}__text`}>
				{option.label}
			</div>
		</div>
	);
}

const Switcher = <TValue extends unknown>(props: SwitcherProps<TValue>) => {
	const {className, options, selectedId, onChange, disabled} = props;
	let effectiveContainerClassName = disabled ? `${containerClassName}--disabled` : containerClassName;
	if(className){
		effectiveContainerClassName += ` ${className}`;
	}
	const optionElements = options?.
		map((option) => renderSwitcherOption(option, selectedId === option.value, onChange, disabled));
	return (
		<div className = {effectiveContainerClassName}>
			{optionElements}
		</div>
	)
}

export default Switcher