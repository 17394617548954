import { connect } from 'react-redux';

import actions from '../../actions';
import { ActionGroup, CommonPageProps } from '../../models';
import { State } from '../../reducers';

import EditActionGroup from './EditActionGroup';

const mapStateToProps = (state: State, _: CommonPageProps) => {
	return {
		isActionGroupsLoading: state.actionGroups.isLoading,
		actionGroups: state.actionGroups.list,
		isActionGroupUpdating: state.actionGroups.isActionGroupUpdating,
		isTreePermissionsLoading: state.treePermissions.isLoading,
		treePermissions: state.treePermissions.list,
		token: state.auth.token
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadActionGroups: (token: string) => dispatch(actions.actionGroups.load(token)),
	loadTreePermissions: (token: string) => dispatch(actions.treePermissions.load(token)),
	updateActionGroup: (
		role: ActionGroup, token: string,
		prevPermissionIds: string[], nextPermissionIds: string[]
	) => dispatch(actions.actionGroup.update(role, token, prevPermissionIds, nextPermissionIds)),
	createActionGroup: (
		role: ActionGroup, token: string,
		prevPermissionIds: string[], nextPermissionIds: string[]
	) => dispatch(actions.actionGroup.create(role, token, prevPermissionIds, nextPermissionIds)),
	actionGroupsReset: () => dispatch(actions.actionGroups.reset())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditActionGroup)