import React, { memo } from 'react';
import { TreeListEntry } from '../../models';

export interface TreeSelectorProps{
	className?: string | null,
	title?: React.ReactNode,

	treesList: TreeListEntry[] | null,
	selectedTreeId?: number | null,
	onSelect?: (treeId: number, event: React.MouseEvent) => void
}

const containerClassName = 'tree-selector';

const TreeSelector = (props: TreeSelectorProps) => {
	const {title, className, treesList, selectedTreeId, onSelect} = props;

	const treesElements = (treesList || []).map(tree => {
		const treeClassName = `${containerClassName}__content__entry${selectedTreeId === tree.id ? ' selected' : ''}`;
		return (
			<div
				key = {tree.id}
				className = {treeClassName}
				onClick = {event => tree.id && onSelect && onSelect(tree.id, event)}
			>
				{tree.name}
			</div>
		)
	});

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__title`}>
				<span className = {`${containerClassName}__title__text`}>
					{title}
				</span>
			</div>
			<div className = {`${containerClassName}__content`}>
				{treesElements}
			</div>
		</div>
	);
}

export default memo(TreeSelector);