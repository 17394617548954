import { call, put, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { NodeAttributeShort } from '../models';
import handleResponse from './handleResponse';

type ResponseType = {status: number, body: any} | {error: any};

const {loadSuccess, loadFailed} = actions.nodeAttributesForTree;
const {raise} = actions.error;

const tryLoad = (payload: {treeId: number, token: string}): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${payload.treeId}/node-attributes/`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractAttribute = (entry: any): NodeAttributeShort => {
	return {
		id: entry.id,
		name: entry.name,
		authorId: entry.author_id,
		nodeId: entry.node_id,
	}
}

const extractAllAttributes = (data: any): NodeAttributeShort[] => {
	const result: NodeAttributeShort[] = []
	Object.values(data).forEach(unsafeAttributes => {
		if(Array.isArray(unsafeAttributes)){
			const attributes = unsafeAttributes
				.map(a => extractAttribute(a));
			result.push(...attributes);
		}
	})
	return result;
}

const loadSaga = function*(action: any){
	const {treeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoad, action.payload);

		yield* handleResponse(
			response,
			(body: any) => loadSuccess(treeId, extractAllAttributes(body.data || {})),
			(message: any) => loadFailed(treeId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(treeId, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield takeLeading(actions.nodeAttributesForTree.types.NODE_ATTRIBUTES_FOR_TREE_LOAD, loadSaga)
}

export default watch;