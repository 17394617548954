import { connect } from 'react-redux';

import actions from '../../actions';

import Trees from './Trees';

import './index.scss';
import { State } from '../../reducers';

const mapStateToProps = (state: State, ownProps: any) => {
	return {
		isLoading: state.trees.isLoading,
		trees: state.trees.list,
		nodesInfo: state.nodes,
		token: state.auth.token
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadTrees: (token: string) => dispatch(actions.trees.load(token)),
	createTree: (name: string, token: string) => dispatch(actions.tree.create(name, token)),
	renameTree: (id: number, name: string, token: string) => dispatch(actions.tree.update(id, id, name, token)),
	deleteTree: (id: number, token: string) => dispatch(actions.tree.delete(id, id, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Trees)