import React, { memo, ReactText } from 'react';
import { Menu } from 'antd';
import { Paperclip } from 'react-feather';

import { CustomPopup } from '../../components';
import {Tree} from '../../models';
import { TreeSecurityProvider } from '../../settings';

interface RowContextMenuProps{
	x: number,
	y: number,
	nodeIds: ReactText[],
	tree: Tree | null,
	token: string,
	children: string

	treeSecurityProvider: TreeSecurityProvider,

	fixNodes: () => void,
	
	onClose: () => void
}

const RowContextMenu = (props: RowContextMenuProps) => {
	const {
		nodeIds, x, y,
		children,
		fixNodes,
		onClose
	} = props;

	if(nodeIds.length < 1){
		return null
	}

	const closeModalHandler = () => {
		onClose();
	}

	return (
		<CustomPopup
			visible = {true}
			left = {x}
			top = {y}
			onClose = {closeModalHandler}
			transparentOverlay
		>
			<Menu className="tree-popup">
				<Menu.Item
					icon = {<Paperclip width={12} height={12} style = {{marginRight: 8, verticalAlign: 'middle'}} />}
					onClick={() => {
						fixNodes();
						closeModalHandler();
					}}
				>
					{children}
				</Menu.Item>
			</Menu>
		</CustomPopup>
	)

}

export default memo(RowContextMenu);