import actions from '../actions';
import { BalanceLimit } from '../models';

export interface BalanceLimitsState{
	list: BalanceLimit[] | null,
	isLoading: boolean
}

const defaultState: BalanceLimitsState = {
	list: null,
	isLoading: false
}

const reducer = (state: BalanceLimitsState = defaultState, action: any): BalanceLimitsState => {
	switch(action.type){
		case actions.balanceLimits.types.BALANCE_LIMITS_LOAD:
			return {...state, isLoading: true};
		case actions.balanceLimits.types.BALANCE_LIMITS_LOAD_SUCCESS:
			const { balanceLimits } = action.payload;
			return {...state, list: balanceLimits, isLoading: false}
		case actions.balanceLimits.types.BALANCE_LIMITS_LOAD_FAILED:
			console.error(`${action.type}\n%o`, action.payload);
			return {...state, list: [], isLoading: false}
	}
	return state;
}

export default reducer;