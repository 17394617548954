import { TransactionFilterValues as Model } from '../models';
import actions from '../actions';

export interface TransactionFilterValuesState{
	[treeId: number]: Model | undefined
}

const defaultState: TransactionFilterValuesState = {
}

const reducer = (state: TransactionFilterValuesState = defaultState, action: any): TransactionFilterValuesState => {
	switch(action.type){
		case actions.transactionFilterValues.types.TRANSACTION_FILTER_VALUES_LOAD:{
			const {treeId, filterName} = action.payload;
			return {
				...state,
				[treeId]: {
					authorId: null,
					nodeId: null,
					correspondentId: null,
					...state[treeId],
					[filterName]: {
						list: null,
						isLoading: true,
						error: null
					}
				}
			}
		}
		case actions.transactionFilterValues.types.TRANSACTION_FILTER_VALUES_LOAD_SUCCESS:{
			const {treeId, filterName, values} = action.payload;
			return {
				...state,
				[treeId]: {
					authorId: null,
					nodeId: null,
					correspondentId: null,
					...state[treeId],
					[filterName]: {
						list: values,
						isLoading: false,
						error: null
					}
				}
			}
		}
		case actions.transactionFilterValues.types.TRANSACTION_FILTER_VALUES_LOAD_FAILED:{
			const {treeId, filterName, message} = action.payload;
			return {
				...state,
				[treeId]: {
					authorId: null,
					nodeId: null,
					correspondentId: null,
					...state[treeId],
					[filterName]: {
						list: [],
						isLoading: false,
						error: message
					}
				}
			}
		}
	}
	return state;
}

export default reducer;