import { all, call, put, takeEvery} from 'redux-saga/effects';

import {config} from '../settings';
import actions from '../actions';

import handleResponse from './handleResponse';

type DeleteRequestPayload = {id: string, treeId: number, token: string};
type PostRequestPayload = {id?: number, name?: string, typeId?: number, creditLimitId?: number, limit?: number | null, treeId: number, token: string};
type ResponsePromise = Promise<{status: number, body: any} | {error: any}>

const {
	createSuccess, createFailed,
	updateSuccess, updateFailed,
	deleteSuccess, deleteFailed,
	orderSuccess, orderFailed
} = actions.balance;
const {raise} = actions.error;

const deleteBalance = (payload: DeleteRequestPayload): ResponsePromise => {
	try{
		const {id, treeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const postBalance = (payload: PostRequestPayload): ResponsePromise => {
	try{
		const {id, treeId, name, typeId, creditLimitId, limit} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const formData = new FormData();
		if(name){
			formData.append('name', name);
		}
		if(typeId){
			formData.append('typeId', `${typeId}`);
		}
		if(creditLimitId){
			formData.append('creditLimitId', `${creditLimitId}`);
		}
		if(limit){
			formData.append('creditLimit', `${limit}`);
		}

		const url = id ?
			`${config.api_url}/tree/${treeId}/balances/${id}` :
			`${config.api_url}/tree/${treeId}/balances`;

		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	} catch(ex) {
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryUpdateOrder = (id: number, order: number, treeId: number, token: string) => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const formData = new FormData();
		formData.append('order', `${order}`);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${id}`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	} catch(ex) {
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const createBalanceSaga = function*(action: any) {
	const {treeId} = action.payload;
	try{
		const response = yield call(postBalance, action.payload);
		yield* handleResponse(
			response,
			(body: any) => createSuccess(body.id, treeId),
			(message: string) => createFailed(message, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed('An error occurred', treeId));
		return;
	}
}

const updateBalanceSaga = function*(action: any) {
	const {treeId} = action.payload;
	try{
		const response = yield call(postBalance, action.payload);
		yield* handleResponse(
			response,
			() => updateSuccess(treeId),
			(message: string) => updateFailed(message, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed('An error occurred', treeId));
		return;
	}
}

const deleteBalanceSaga = function*(action: any){
	const {id, treeId} = action.payload;
	try{
		const response = yield call(deleteBalance, action.payload);
		yield* handleResponse(
			response,
			() => deleteSuccess(id, treeId),
			(message: string) => deleteFailed(message, id, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed('An error occurred', id, treeId));
		return;
	}
}

const orderSaga = function*(action: any){
	const {idsWithOrder, treeId, token} = action.payload;
	try{
		const requests = idsWithOrder
			.map((pair: {id: number, order: number}) => call(tryUpdateOrder, pair.id, pair.order, treeId, token))
		yield all(requests);
		yield put(orderSuccess(treeId))
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(orderFailed('An error occurred', treeId));
		return;
	}
}

const watch = function*() {
	yield all([
		takeEvery(actions.balance.types.BALANCE_CREATE, createBalanceSaga),
		takeEvery(actions.balance.types.BALANCE_UPDATE, updateBalanceSaga),
		takeEvery(actions.balance.types.BALANCE_DELETE, deleteBalanceSaga),
		takeEvery(actions.balance.types.BALANCE_ORDER, orderSaga)
	])
}

export default watch;