import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { Check, Filter } from 'react-feather';

import { TransactionsElasticAttribute, TransactionsElasticAttributesFilter, TransactionsElasticAttributeValue } from '../../../models';
import { Input } from '../../../components';

interface AttributesFilterProps{
	className?: string,
	children?: React.ReactNode,
	transactionsElasticAttributes: TransactionsElasticAttribute[] | null,
	transactionsElasticAttributesFilter: TransactionsElasticAttributesFilter | null,

	elasticAttributeSet: (attribute: TransactionsElasticAttribute, value: TransactionsElasticAttributeValue | string | null) => void
}

const containerClassName = 'attributes-filter'

class AttributesFilter extends React.Component<AttributesFilterProps>{
	onAttributeValueClick = (attribute: TransactionsElasticAttribute, value: TransactionsElasticAttributeValue) => {
		const {elasticAttributeSet} = this.props;
		elasticAttributeSet(attribute, value);
	}

	onTextAttributeChange = (attribute: TransactionsElasticAttribute, text: string | null) => {
		const {elasticAttributeSet} = this.props;
		elasticAttributeSet(attribute, text);
	}

	renderAttributeValues = (attribute: TransactionsElasticAttribute) => {
		const {transactionsElasticAttributesFilter} = this.props;
		if(attribute.allowValues){
			const attributeValue = transactionsElasticAttributesFilter
				&& transactionsElasticAttributesFilter[attribute.type][attribute.attributeId];
			const icon = (
				<Check className={'feather-ico'}/>
			);
			const valueBaseClassName = `${containerClassName}__menu__attribute__value`;
			return (
				<>
					{attribute.values.map(value => {
						const isValueSelected = attributeValue === value.id;
						const className = isValueSelected
							? `${valueBaseClassName} ${valueBaseClassName}--set`
							: valueBaseClassName;
						return (
							<Menu.Item
								key = {`tea-value ${attribute.attributeId} - ${attribute.type} - ${value.id}`}
								className = {className}
								onClick = {() => this.onAttributeValueClick(attribute, value)}
								icon = {isValueSelected ? icon : null}
							>
								{value.name}
							</Menu.Item>
						)
					})}
				</>
			)
		}
		const value = transactionsElasticAttributesFilter
			&& transactionsElasticAttributesFilter[attribute.type][attribute.attributeId];
		return (
			<div
				className = {`${containerClassName}__menu__attribute__text`}
			>
				<Input
					onChange = {(text) => this.onTextAttributeChange(attribute, text)}
					isEditable = {true}
				>
					{value ? `${value}` : undefined}
				</Input>
			</div>
		)
	}

	renderMenu = () => {
		const {
			transactionsElasticAttributes,
			transactionsElasticAttributesFilter
		} = this.props;
		const attributeClassName = `${containerClassName}__menu__attribute`;
		const submenus = transactionsElasticAttributes?.map(tea => {
			const isThereAnyValues = !! (
				transactionsElasticAttributesFilter &&
				transactionsElasticAttributesFilter[tea.type][tea.attributeId]
			);
			const className = isThereAnyValues
				? `${attributeClassName} ${attributeClassName}--set`
				: attributeClassName;
			return (
				<Menu.SubMenu
					key = {`tea - ${tea.attributeId} - ${tea.type}`}
					className = {className}
					title = {tea.attributeName}
				>
					{this.renderAttributeValues(tea)}
				</Menu.SubMenu>
			)
		})
		return (
			<Menu
				className = {`${containerClassName}__menu`}
			>
				{submenus}
			</Menu>
		)
	}

	render(){
		const {
			className,
			children,
			transactionsElasticAttributesFilter
		} = this.props;

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		const isThereAnyFilter = transactionsElasticAttributesFilter && (
			Object.keys(transactionsElasticAttributesFilter.balance).length > 0
			|| Object.keys(transactionsElasticAttributesFilter.node).length > 0
		)
		const contentClassName = isThereAnyFilter
			? `${containerClassName}__btn__content ${containerClassName}__btn__content--set`
			: `${containerClassName}__btn__content`;
		return (
			<div
				className = {effectiveClassName}
			>
				<Dropdown
					overlay = {this.renderMenu()}
				>
					<Button
						className = {`${containerClassName}__btn`}
					>
						<div
							className = {contentClassName}
						>
							<Filter className = {`feather-ico ${containerClassName}__btn__icon`}/>
							<span
								className = {`${containerClassName}__btn__text`}
							>
								{children}
							</span>
						</div>
					</Button>
				</Dropdown>
			</div>
		)
	}
}

export default AttributesFilter;