import { all, call, put, takeLatest} from 'redux-saga/effects';

import {site, config} from '../settings';
import actions from '../actions';
import { ActionGroup } from '../models';
import history from '../history';

import handleResponse from './handleResponse';

const {
	requestSuccess, requestFailed
} = actions.actionGroup;

const {raise} = actions.error;

type DeleteRequestPayload = {id: string, token: string};
type PostRequestPayload = {
	actionGroup: ActionGroup,
	prevPermissionIds?: string[],
	nextPermissionIds?: string[],
	token: string
};
type ResponsePromise = Promise<{status: number, body: any} | {error: any}>


const deleteActionGroup = (payload: DeleteRequestPayload): ResponsePromise => {
	try{
		const {id} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/action-groups/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const postActionGroup = (payload: PostRequestPayload): ResponsePromise => {
	try{
		const {actionGroup} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const formData = new FormData();
		if(actionGroup.name){
			formData.append('name', actionGroup.name);
		}
		if(actionGroup.slug){
			formData.append('slug', actionGroup.slug);
		}

		const url = actionGroup.id == null ?
			`${config.api_url}/tree/action-groups` :
			`${config.api_url}/tree/action-groups/${actionGroup.id}`;

		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	} catch(ex) {
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const addDeleteActionGroup = (isAdd: boolean, actionGroupId:string, permissionId: string, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		return fetch(`${config.api_url}/tree/action-groups/${actionGroupId}/permissions/${permissionId}`, {
			method: isAdd ? 'POST' : 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	} catch(ex) {
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const getUpdateActionGroupPermissionsSaga = (action: any) => function*(postResponse: any){
	const {actionGroup, token, prevPermissionIds, nextPermissionIds} = action.payload;
	const {id} = postResponse;
	const actionGroupId = id ? id : actionGroup.id;
	const toAdd = (nextPermissionIds || [])
		.filter((p: string) => !prevPermissionIds.includes(p));
	const addRequests = toAdd
		.map((permissionId: string) => call(addDeleteActionGroup, true, actionGroupId, permissionId, token))
	yield all(addRequests);
	const toRemove = (prevPermissionIds || [])
		.filter((p:string) => !nextPermissionIds.includes(p));
	const removeRequests = toRemove
		.map((permissionId: string) => call(addDeleteActionGroup, false, actionGroupId, permissionId, token))
	yield all(removeRequests);
	yield call(history.push, site.actionGroups.url)
}

const createUpdateActionGroupSaga = function*(action: any) {
	try{
		const response = yield call(postActionGroup, action.payload);
		yield* handleResponse(response, requestSuccess, requestFailed, getUpdateActionGroupPermissionsSaga(action));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(requestFailed('An error occurred'));
		return;
	}
}

const deleteActionGroupSaga = function*(action: any){
	try{
		const response = yield call(deleteActionGroup, action.payload);
		yield* handleResponse(response, requestSuccess, requestFailed);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(requestFailed('An error occurred'));
		return;
	}
}

const watchActionGroup = function*() {
	yield all([
		takeLatest([
			actions.actionGroup.types.ACTION_GROUP_CREATE,
			actions.actionGroup.types.ACTION_GROUP_UPDATE
		], createUpdateActionGroupSaga),
		takeLatest(actions.actionGroup.types.ACTION_GROUP_DELETE, deleteActionGroupSaga)
	])
}

export default watchActionGroup;