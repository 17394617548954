import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import actions from "../actions";
import { report } from "../models";
import { config } from "../settings";

import handleResponse from "./handleResponse";

type ResponseType = {status: number, body: any} | {error: any};

const {
	loadSuccess, loadFailed,
	saveSuccess, saveFailed,
	deleteSuccess, deleteFailed
} = actions.reportTemplates;
const {raise} = actions.error;

const tryLoadTemplates = (payload: {nodeId: number, token: string}): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/reports/${payload.nodeId}/templates`, {
			headers: headers,
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const trySaveTemplate = (payload: {nodeId: number, template: report.TemplateWithoutId, token: string}): Promise<ResponseType> => {
	try{
		const {template} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const formData = new FormData();
		formData.append("name", template.name);
		formData.append("template", JSON.stringify(template.content));

		return fetch(`${config.api_url}/reports/${payload.nodeId}/templates`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryDeleteTemplate = (payload: {nodeId: number, id: number, token: string}): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/reports/${payload.nodeId}/templates/${payload.id}`, {
			method: 'DELETE',
			headers: headers,
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractTemplateContent = (text: string): report.TemplateField[] => {
	try{
		const data = JSON.parse(text);
		if(!Array.isArray(data)){
			return [];
		}
		return data.map(record => ({
			id: record.id,
			name: record.name,
			area: record.area,
			areaIndex: record.areaIndex,
			filterType: record.filterType,
			filterValues: record.filterValues,
		}))
	}catch{
		return [];
	}
}

const extractTemplates = (body: any): report.Template[] => {
	const data = body.data;
	if(!Array.isArray(data)){
		return [];
	}
	return data.map(record => ({
		id: record.id,
		name: record.name,
		content: extractTemplateContent(record.template)
	}));
}

const loadReportTemplates = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoadTemplates, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(nodeId, extractTemplates(body)),
			(message: string) => loadFailed(nodeId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(nodeId, 'An error occurred'));
		return;
	}
}

const saveReportTemplate = function*(action: any){
	const {nodeId, template} = action.payload;
	try{
		const response: ResponseType = yield call(trySaveTemplate, action.payload);
		yield* handleResponse(
			response,
			() => saveSuccess(nodeId, template),
			(message: string) => saveFailed(nodeId, template, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(saveFailed(nodeId, template, 'An error occurred'));
		return;
	}
}

const deleteReportTemplate = function*(action: any){
	const {nodeId, id} = action.payload;
	try{
		const response: ResponseType = yield call(tryDeleteTemplate, action.payload);
		yield* handleResponse(
			response,
			() => deleteSuccess(nodeId, id),
			(message: string) => deleteFailed(nodeId, id, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed(nodeId, id, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield all([
		takeLatest(actions.reportTemplates.types.REPORT_TEMPLATE_LOAD, loadReportTemplates),
		takeEvery(actions.reportTemplates.types.REPORT_TEMPLATE_SAVE, saveReportTemplate),
		takeEvery(actions.reportTemplates.types.REPORT_TEMPLATE_DELETE, deleteReportTemplate)
	])
}

export default watch;