import { Moment } from "moment"
import {report} from '../models';

const actionTypes = {
	REPORT_LOAD: 'REPORT\\LOAD',
	REPORT_LOAD_SUCCESS: 'REPORT\\LOAD_SUCCESS',
	REPORT_LOAD_FAILED: 'REPORT\\LOAD_FAILED',

	REPORT_UNLOAD: 'REPORT\\UNLOAD',

	REPORT_FIELD_UPDATE: 'REPORT\\FIELD_UPDATE'
}

const load = (nodeId: number, date: [Moment, Moment], dateType: report.ReportDateType, token: string) => ({
	type: actionTypes.REPORT_LOAD,
	payload: {
		nodeId,
		date,
		dateType,
		token
	}
})

const loadSuccess = (nodeId: number, date: [Moment, Moment], dateType: report.ReportDateType, fields: report.Field[]) => ({
	type: actionTypes.REPORT_LOAD_SUCCESS,
	payload: {
		nodeId,
		date,
		dateType,
		fields
	}
})

const loadFailed = (nodeId: number, date: [Moment, Moment], dateType: report.ReportDateType, message: string) => ({
	type: actionTypes.REPORT_LOAD_FAILED,
	payload: {
		nodeId,
		date,
		dateType,
		message
	}
})

const unload = (nodeId: number) => ({
	type: actionTypes.REPORT_UNLOAD,
	payload: {
		nodeId
	}
})

const fieldUpdate = (nodeId: number, fieldId: string, update: report.ReportFieldUpdate) => ({
	type: actionTypes.REPORT_FIELD_UPDATE,
	payload: {
		nodeId,
		fieldId,
		update
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	unload,
	fieldUpdate
}