import React, {useCallback} from 'react';
import { Form, Input, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { Check, CornerUpLeft, Plus } from 'react-feather';

import { Button } from '../../components';
import { EditUserState, Role, User } from '../../models';
import { localization, errors, SecurityProvider } from "../../settings";
import { filterByInnerText } from '../../tools';
import { FieldData } from 'rc-field-form/lib/interface';


const { Option } = Select;

export type SubmitUserType = (
	user: User,
	token: string,
	prevRoleIds: string[],
	nextRoleIds: string[]
) => void;

interface EditUserGeneralProps{
	className: string,
	user?: User,
	token: string,
	isNew?: boolean,
	roles: Role[] | null,
	userState: EditUserState | null,
	securityProvider: SecurityProvider,

	submitUser: SubmitUserType,
	setUserState: (userState: EditUserState) => void,
}

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 8,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 16,
		},
	}
};

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const notEmptyRule = [
	{
		required: true,
		message: errors.fields_is_required,
		whitespace: true
	}];

const emailRules: Rule[] = [
	...notEmptyRule,
	{
		type: 'email',
		message: errors.invalid_email
	}];

const passwordRules: Rule[] = [
	{
		min: 8,
		message: errors.password_length_error
	}
]

const getSubmitUser = (
	prevUser: User | undefined,
	token: string,
	submitUser: SubmitUserType,
	cb: (state: any) => void
) => (
	formFields: any
) => {
	const user: User = {
		id:			formFields.id,
		name:		formFields.name,
		firstname:	formFields.firstname,
		lastname:	formFields.lastname,
		email:		formFields.email,
	}
	if(formFields.password){
		user.password = formFields.password
	}
	const prevRoleIds: string[] = (prevUser?.roles || [])
		.map(role => (role.id || '').toString())
		.filter(roleId => roleId !== '');
	cb(null);
	submitUser(user, token, prevRoleIds, formFields.roles);
}

const onFieldChange = (fields: FieldData[], setUserState: (userState: EditUserState) => void) => {
	setUserState(fields);
	return;
}

const EditUserGeneral = (props: EditUserGeneralProps) => {
	const {
		user, isNew, roles, className,
		securityProvider, token,
		userState,
		submitUser, setUserState
	} = props;

	const assignedRoleIds: string[] = (user?.roles || [])
		.filter(role => !!role)
		.map(role => (role.id || '').toString());

	const [form] = Form.useForm();

	const onResetFields = useCallback(() => {
		form.resetFields();
	}, [form])

	const canSave = isNew ? securityProvider.users.canCreate : securityProvider.users.canEdit;
	return (
		<Form
			{...formItemLayout}
			form = {form}
			scrollToFirstError
			initialValues = {{
				id: user?.id,
				name: user?.name,
				firstname: user?.firstname,
				lastname: user?.lastname,
				email: user?.email,
				roles: assignedRoleIds
			}}
			fields = {userState || []}
			onFieldsChange = {(_, fields) => onFieldChange(fields, setUserState)}
			onFinish = {getSubmitUser(user, token, submitUser, setUserState)}
		>
			<Form.Item
				name="id"
				hidden
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="name"
				label={localization.login}
				rules={notEmptyRule}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="firstname"
				label={localization.firstname}
				rules={notEmptyRule}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="lastname"
				label={localization.lastname}
				rules={notEmptyRule}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="email"
				label={localization.email}
				rules={emailRules}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="password"
				label={localization.password}
				rules={isNew ? [...notEmptyRule, ...passwordRules] : passwordRules}
			>
				<Input />
			</Form.Item>
			{securityProvider.roles.canRead && (<Form.Item
				name="roles"
				label={localization.roles}
			>
				<Select
					mode = "multiple"
					disabled = {!securityProvider.roles.canAssign}
					style = {{width: '100%'}}
					className = {`${className}__roles`}
					filterOption = {filterByInnerText}
				>
					{roles?.map(role => (
						<Option
							key={role.id}
							value={(role.id || '').toString()}
						>
							{role.name}
						</Option>
					))}
				</Select>
			</Form.Item>)}
			<Form.Item
				{...tailFormItemLayout}
			>
				<div
					className = {`${className}__footer`}
				>
					<Button
						type="primary"
						htmlType="submit"
						icon = {isNew ? <Plus/> : <Check/>}
						className = {`${className}__footer__save`}
						disabled = {!canSave}
					>
						{isNew ? localization.create : localization.save}
					</Button>
					<Button
						className = {`${className}__footer__cancel`}
						icon = {<CornerUpLeft/>}
						onClick = {onResetFields}
					>
						{localization.cancel}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default EditUserGeneral;