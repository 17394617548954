import React from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { Spin } from 'antd';

import FormulaEntry, {itemType as formulaEntryType} from './FormulaEntry';
import FormulaEntryModel from './FormulaEntryModel';
import DraggableEntryModel from './DraggableEntryModel';

interface FormulaPanelProps{
	className?: string;
	formula?: FormulaEntryModel[] | null,
	error?: string | null,
	getEntryName: (model: FormulaEntryModel) => string | null,

	onDrop?: (draggedItem: DraggableEntryModel<FormulaEntryModel>, hoveredItem?: DraggableEntryModel<FormulaEntryModel>) => void,
}

const containerClassName = 'bra-formula-panel'

const FormulaPanel = (props: FormulaPanelProps) => {
	const {
		className,
		formula, error,
		getEntryName,
		onDrop
	} = props;

	const [{ isOver }, drop] = useDrop({
		accept: formulaEntryType,
		drop: (item: DraggableEntryModel<FormulaEntryModel>, monitor: DropTargetMonitor) => {
			const alreadyDropped = monitor.didDrop();
			if(!alreadyDropped){
				onDrop && onDrop(item)
			}
		},
		collect: (monitor: DropTargetMonitor) => {
			return {
				isOver: monitor.isOver(),
			};
		}
	});

	let effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	if(isOver){
		effectiveClassName += ` ${containerClassName}--over`
	}
	if(error){
		effectiveClassName += ` ${containerClassName}--error`
	}
	if(!formula){
		return (
			<div
				className = {effectiveClassName}
			>
				<Spin/>
			</div>
		)
	}
	return (
		<div
			className = {effectiveClassName}
			ref = {drop}
		>
			{
				formula.map((entry, index) => (
					<FormulaEntry
						key = {index}
						model = {entry}
						index = {index}
						getName = {getEntryName}
						className = {`${containerClassName}__entry`}
						isInsertionSupported
						onDrop = {onDrop}
					/>))
			}
		</div>
	)
}

export default FormulaPanel;