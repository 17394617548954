const actionTypes = {
	AUTH_LOGIN: 'AUTH\\LOGIN',
	AUTH_LOGIN_SUCCESS: 'AUTH\\LOGIN_SUCCESS',
	AUTH_LOGIN_FAILURE: 'AUTH\\LOGIN_FAILURE',
	AUTH_LOGOUT: 'AUTH\\LOGOUT'
}

const login = (username: string, password: string/*, recaptchaToken: string*/) => ({
	type: actionTypes.AUTH_LOGIN,
	payload: {
		username,
		password,
		// recaptchaToken
	}
})

const loginSuccess = (payload: any) => ({
	type: actionTypes.AUTH_LOGIN_SUCCESS,
	payload
})

const loginFailed = (message: string) => ({
	type: actionTypes.AUTH_LOGIN_FAILURE,
	message
})

const logout = () => ({
	type: actionTypes.AUTH_LOGOUT
})

export default {
	types: actionTypes,
	login,
	loginSuccess,
	loginFailed,
	logout
}