import actions from "../actions";

import {Balance, EntityPerTree} from '../models'

const defaultState: EntityPerTree<Balance> = {
}

const state = (state: EntityPerTree<Balance> = defaultState, action: any): EntityPerTree<Balance> => {
	switch(action.type){
		case actions.balances.types.BALANCES_LOAD: {
			const {treeId} = action.payload
			return {
				...state,
				[treeId]: {
					list: null,
					isLoading: true
				}
			}
		}
		case actions.balances.types.BALANCES_LOAD_SUCCESS: {
			const {treeId, balances} = action.payload;
			return {
				...state,
				[treeId]: {
					list: balances,
					isLoading: false
				}
			}
		}
		case actions.balances.types.BALANCES_LOAD_FAILED: {
			console.error(`${action.type}\n%o`, action.payload);
			const {treeId} = action.payload;
			return {
				...state,
				[treeId]: {
					list: [],
					isLoading: false
				}
			}
		}

		case actions.balance.types.BALANCE_CREATE_SUCCESS:
		case actions.balance.types.BALANCE_UPDATE_SUCCESS:
		case actions.balance.types.BALANCE_DELETE_SUCCESS:
		case actions.balance.types.BALANCE_ORDER_SUCCESS:
		{
			const {treeId} = action.payload;
			return {
				...state,
				[treeId]: {
					list: null,
					isLoading: false
				}
			}
		}
		case actions.balance.types.BALANCE_CREATE_FAILED:
		case actions.balance.types.BALANCE_UPDATE_FAILED:
		case actions.balance.types.BALANCE_DELETE_FAILED:
		case actions.balance.types.BALANCE_ORDER_FAILED:
		{
			console.error(`${action.type}\n%o`, action.payload);
			const {treeId} = action.payload;
			return {
				...state,
				[treeId]:{
					list: null,
					isLoading: false
				}
			}
		}
	}
	return state;
}

export default state;