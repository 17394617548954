type OrderedIds = {id: number, order: number}[];

/**
 * Handles moving item from position `initialIndex` to `finalIndex` in an array
 * @param list inital array of items
 * @param initialIndex zero based index of the moved element before moving
 * @param finalIndex zero based index of the moved element after moving
 * @returns array of `{id: number, order: number}` where order starts with 1
 */
const orderForBackend = <T extends {id: number, order: number}>(list: T[], initialIndex: number, finalIndex: number): OrderedIds => {
	// removes element from `initialIndex` position and insert it at `finalIndex`
	const updatedList = [...list];
	const itemToMove = updatedList[initialIndex];
	updatedList.splice(initialIndex, 1);
	updatedList.splice(finalIndex, 0, itemToMove);

	const orderedIds: OrderedIds = updatedList
		.map((value, order) => ({value, order: order + 1})) // as it is starts from 1
		.filter((pair) => pair.value.order !== pair.order)
		.map((pair) => ({id: pair.value.id, order: pair.order}));
	return orderedIds;
}

export default orderForBackend;