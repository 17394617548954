import React from 'react';
import { Input, Radio, Skeleton } from 'antd';
import { Trash } from 'react-feather';

import { Button, ConfirmDelete } from '..';
import { AttributeValue, AttributeVisibility } from '../../models';
import { localization } from '../../settings';
import { RadioChangeEvent } from 'antd/lib/radio';

interface EditAttributeValuePanelProps{
	className?: string | null,
	value: AttributeValue | null,
	visibilities: AttributeVisibility[] | null,
	readOnly: boolean,

	addValue: (value: {name: string, visibilityId: number}) => void,
	editValue: (id: number, value: {name: string | null, visibilityId: number | null}) => void,
	deleteValue: (id: number) => void,
	close: () => void
}

interface EditAttributeValuePanelState{
	name: string | null,
	visibilityId: number | null
}

const containerClassName = 'edit-attribute-value-panel';

const onClickHandler = (event: React.MouseEvent) => {
	event.stopPropagation();
}

class EditAttributeValuePanel extends React.Component<EditAttributeValuePanelProps, EditAttributeValuePanelState>{
	constructor(props: EditAttributeValuePanelProps){
		super(props);
		this.state = {
			name: null,
			visibilityId: null
		}
	}

	onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState((state) => ({...state, name: event.target.value}));
	}

	onChangeVisibility = (event: RadioChangeEvent) => {
		this.setState((state) => ({...state, visibilityId: event.target.value}))
	}

	onClose = () => {
		const {close} = this.props;
		this.setState((state) => ({...state, name: null}))
		close();
	}

	onSave = () => {
		const {value, editValue, addValue, close} = this.props;
		const {name, visibilityId} = this.state;
		if(value){
			editValue(value.id, {name, visibilityId});
		} else {
			addValue({
				name: name || '',
				visibilityId: visibilityId || 1
			})
		}
		this.setState((state) => ({state, name: null, visibilityId: null}));
		close();
	}

	onDelete = () => {
		const {value, deleteValue, close} = this.props;
		if(value){
			ConfirmDelete({
				title: localization.delete_attribute_value_title,
				content: localization.delete_attribute_value_content,
				deleteEntity: () => {
					deleteValue(value.id)
					close();
				}
			})
		}
	}

	renderVisibilities = () => {
		const {value, visibilities} = this.props;
		const {visibilityId} = this.state;
		if(!visibilities){
			return <Skeleton/>
		}
		const visibilityElements = visibilities.map(v => {
			return (
				<Radio
					className = {`${containerClassName}__visibility-block__list__entry`}
					key = {v.id}
					value = {v.id}
				>
					{v.name}
				</Radio>
			)
		})
		return (
			<Radio.Group
				className = {`${containerClassName}__visibility-block__list`}
				value = {visibilityId != null ? visibilityId : value?.visibility.id || 1}
				size = 'small'
				onChange = {this.onChangeVisibility}
			>
				{visibilityElements}
			</Radio.Group>
		)
	}

	render() {
		const {className, value, readOnly} = this.props;
		const {name} = this.state;
		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		const nameElement = readOnly ? (
			<Input
				placeholder = {localization.newAttributeValuePlaceholder}
				value = {value?.name}
				readOnly
				bordered = {false}
			/>
		): (
			<Input
				placeholder = {localization.newAttributeValuePlaceholder}
				value = {name !== null ? name : value?.name}
				onChange = {this.onChangeName}
			/>
		)
		return (
			<div
				className = {effectiveClassName}
				onClick = {onClickHandler}
			>
				<div className = {`${containerClassName}__header`}>
					{value ? localization.newAttributeValueEditTitle : localization.newAttributeValueNewTitle}
				</div>
				<div className = {`${containerClassName}__name-block`}>
					{nameElement}
				</div>
				<div className = {`${containerClassName}__visibility-block`}>
					{this.renderVisibilities()}
				</div>
				<div className = {`${containerClassName}__footer`}>
					<Button
						size = 'small'
						type = 'primary'
						className ={`${containerClassName}__footer__button`}
						onClick = {this.onSave}
					>
						{localization.save}
					</Button>
					<Button
						size = 'small'
						borderless
						className ={`${containerClassName}__footer__button`}
						onClick = {this.onClose}
					>
						{localization.cancel}
					</Button>
					{value && <Button
						size = 'small'
						icon = {<Trash/>}
						borderless
						type = 'danger'
						className ={`${containerClassName}__footer__button--right`}
						onClick = {this.onDelete}
					/>}
				</div>
			</div>
		);
	}
}

export default EditAttributeValuePanel;