import { connect } from 'react-redux';

import actions from '../../actions';
import { State } from '../../reducers';
import { CommonPageProps, TreeContainerState } from '../../models';

import Tree from './Tree';

import './index.scss';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		trees: state.trees.trees,
		treeContainerStates: state.treeContainerStates,
		token: state.auth.token,
		nodesInfo: state.nodes,
		balances: state.balances,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	createTree: (name: string, token: string) =>
		dispatch(actions.tree.create(name, token)),
	loadTree: (id: string, token: string) => 
		dispatch(actions.tree.load(id, token)),
	loadNodeUsers: (nodeId: number, token: string) => 
		dispatch(actions.node.loadNodeUsers(nodeId, token)),
	loadNodeActionGroups: (nodeId: number, userId: number, token: string) =>
		dispatch(actions.node.loadActionGroups(userId, nodeId, token)),
	loadBalances: (treeId: number, token: string) =>
		dispatch(actions.balances.load(treeId, token)),
	updateActionGroup: (nodeId: number, userId: number, groups: any) => dispatch(actions.node.updateActionGroups(userId, nodeId, groups)),
	addNode: (treeId: number, parentId: number, name: string, token: string) => 
		dispatch(actions.tree.addNode(treeId, parentId, name, token)),
	updateNodeName: (treeId: number, nodeId: number, name: string, token: string) =>
		dispatch(actions.tree.update(treeId, nodeId, name, token)),
	deleteNode: (treeId: number, nodeId: number, token: string) => 
		dispatch(actions.tree.delete(treeId, nodeId, token)),
	saveState: (treeId: number, state: TreeContainerState) =>
		dispatch(actions.treeContainerState.save(treeId, state)),
	reset: () => dispatch(actions.trees.reset())
})

export default connect(mapStateToProps, mapDispatchToProps)(Tree)