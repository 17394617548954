import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Balance, CommonPageProps, EntityPerTree, TreesHash, NodesState, TreeContainerState } from "../../models";
import { TreeContainerStates } from "../../reducers/treeContainerState";

import TreeContainer from "./TreeContainer";

export interface TreeProps extends CommonPageProps{
	trees: TreesHash,
	treeContainerStates: TreeContainerStates,
	token: string,
	nodesInfo: NodesState,
	balances: EntityPerTree<Balance>,

	createTree: (name: string, token: string) => void,
	loadTree: (id: string, token: string) => void,

	loadNodeUsers: (nodeId: number, token: string) => void,
	loadNodeActionGroups: (nodeId: number, userId: number, token: string) => void,
	loadBalances: (treeId: number, token: string) => void,
	addNode: (treeId: number, parentId: number, name: string, token: string) => void,
	deleteNode: (treeId: number, nodeId: number, token: string) => void,
	updateNodeName: (treeId: number, nodeId: number, name: string, token: string) => void,
	updateActionGroup: (nodeId: number, userId: number, groups: any) => void,
	reset: () => void,
	saveState: (treeId: number, state: TreeContainerState) => void,
}

const Tree = (props: TreeProps) => {
	const {id} = useParams<{id: string}>();
	const {
		trees, nodesInfo, treeContainerStates,
		currentUser, token,
		balances, loadBalances,
		loadTree, loadNodeActionGroups
	} = props;

	const safeId = parseInt(id);
	const treeInfo = trees[safeId];
	const treeContainerState = treeContainerStates[safeId]?.entity || null;

	useEffect(() => {
		if(!treeInfo || (!treeInfo.tree && !treeInfo.isLoading && !treeInfo.error)){
			loadTree(id, token);
		}
		const currentBalances = balances[safeId]
		if(!currentBalances || (!currentBalances.list && !currentBalances.isLoading)){
			loadBalances(safeId, token)
		}

		const rootNode = nodesInfo[safeId];
		const rootNodeSelfInfo = rootNode && rootNode.users && rootNode.users[currentUser.id]
		if(treeInfo && treeInfo.tree && !rootNodeSelfInfo){
			loadNodeActionGroups(treeInfo.tree.id, currentUser.id, token);
		}
	})

	return (
		<TreeContainer
			treeInfo = {treeInfo}
			treeId = {safeId}
			treeContainerState = {treeContainerState}
			{...props}
		/>
	)
}

export default Tree;