import actions from '../actions';
import { BalanceType } from '../models/balance';

export interface BalanceTypesState{
	list: BalanceType[] | null,
	isLoading: boolean
}

const defaultState: BalanceTypesState = {
	list: null,
	isLoading: false
}

const reducer = (state: BalanceTypesState = defaultState, action: any): BalanceTypesState => {
	switch(action.type){
		case actions.balanceTypes.types.BALANCE_TYPES_LOAD:
			return {...state, isLoading: true};
		case actions.balanceTypes.types.BALANCE_TYPES_LOAD_SUCCESS:
			const { balanceTypes } = action.payload;
			return {...state, list: balanceTypes, isLoading: false}
		case actions.balanceTypes.types.BALANCE_TYPES_LOAD_FAILED:
			console.error(`${action.type}\n%o`, action.payload);
			return {...state, list: [], isLoading: false}
	}
	return state;
}

export default reducer;