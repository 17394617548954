import React from 'react'

interface CustomPopupProps{
	visible: boolean,
	left?: number,
	top?: number,
	right?: number,
	bottom?: number,
	height?: number,
	width?: number,
	children?: React.ReactNode,
	className?: string,
	transparentOverlay?: boolean,
	style?: React.CSSProperties,
	overlayStyle?: React.CSSProperties,

	onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const containerClassName = 'custom-popup-view';

const CustomPopup = (props: CustomPopupProps) => {
	const {
		visible, left, top, right, bottom, style, overlayStyle,
		className, children, transparentOverlay,
		onClose
	} = props;
	if(!visible){
		return null;
	}

	const containerContextHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		onClose(event);
	}

	const viewClassName = className ? `${containerClassName} ${className}` : containerClassName;
	const overlayClassName = transparentOverlay ? 'custom-popup-overlay--transparent' : 'custom-popup-overlay'

	return (
		<>
			<div className = {overlayClassName} onClick = {onClose} onContextMenu={containerContextHandler} style = {overlayStyle}>
			</div>
			<div
				style ={{...{
					left: left,
					top: top,
					right: right,
					bottom: bottom,
				}, ...style}}
				className = {viewClassName}
			>
				{children}
			</div>
		</>
	)
}

export default CustomPopup;