import { connect } from 'react-redux';

import actions from '../../actions';
import { CommonPageProps, Role } from '../../models';
import { State } from '../../reducers';

import EditRole from './EditRole';

const mapStateToProps = (state: State, _: CommonPageProps) => {
	return {
		isRolesLoading: state.roles.isLoading,
		roles: state.roles.list,
		isRoleUpdating: state.roles.isRoleUpdating,
		isPermissionsLoading: state.permissions.isLoading,
		permissions: state.permissions.list,
		token: state.auth.token
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadRoles: (token: string) => dispatch(actions.roles.load(token)),
	loadPermissions: (token: string) => dispatch(actions.permissions.load(token)),
	updateRole: (
		role: Role, token: string,
		prevPermissionIds: string[], nextPermissionIds: string[]
	) => dispatch(actions.role.update(role, token, prevPermissionIds, nextPermissionIds)),
	createRole: (
		role: Role, token: string,
		prevPermissionIds: string[], nextPermissionIds: string[]
	) => dispatch(actions.role.create(role, token, prevPermissionIds, nextPermissionIds)),
	rolesReset: () => dispatch(actions.roles.reset())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRole)