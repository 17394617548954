import actions from "../actions";

import { SingleEntityPerTree, Report, report } from "../models";

const defaultState: SingleEntityPerTree<Report> = {
}

const state = (state: SingleEntityPerTree<Report> = defaultState, action: any): SingleEntityPerTree<Report> => {
	switch(action.type){
		case actions.report.types.REPORT_LOAD:{
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					entity: state[nodeId]?.entity || null,
					isLoading: true,
					error: null
				}
			}
		}

		case actions.report.types.REPORT_LOAD_SUCCESS:{
			const {nodeId, date, dateType, fields} = action.payload;
			let newFields: report.Field[] | null = fields;
			const oldFields = state[nodeId]?.entity?.fields || null;
			if(oldFields){
				newFields = (newFields || []).map(field => {
					const oldField = oldFields.find(f => f.id === field.id);
					return oldField || field;
				})
			}
			const report: Report = {
				loadedAt: new Date(),
				fields: newFields,
				date: date,
				dateType: dateType
			}
			return {
				...state,
				[nodeId]: {
					entity: report,
					isLoading: false,
					error: null
				}
			}
		}

		case actions.report.types.REPORT_LOAD_FAILED:{
			const {nodeId, message} = action.payload;
			return {
				...state,
				[nodeId]: {
					entity: null,
					isLoading: false,
					error: message
				}
			};
		}

		case actions.report.types.REPORT_UNLOAD:{
			const {nodeId} = action.payload;
			const updatedState = {...state};
			delete updatedState[nodeId]
			return updatedState;
		}

		case actions.report.types.REPORT_FIELD_UPDATE:{
			const {nodeId, fieldId, update}: {nodeId: number, fieldId: string, update: report.ReportFieldUpdate} = action.payload;
			const oldReport = state[nodeId]?.entity;
			if(!oldReport || !oldReport.fields){
				return state;
			}

			const targetField = oldReport.fields
				.find(f => f.id === fieldId) || null;
			const relatedField = (targetField && 'originalId' in targetField  && oldReport.fields
				.find(f => 'originalId' in f && f.originalId === targetField.originalId && f.id !== targetField.id)) || null;

			const updatedFields = oldReport.fields.map((f): report.Field  => {
				if(f.id === fieldId){
					return {
						...f,
						area: update.area,
						areaIndex: update.areaIndex,
						filterType: update.filterType,
						filterValues: update.filterValues
					};
				}
				if(f.id === relatedField?.id){
					return {
						...f,
						readonly: !!update.area
					}
				}
				return f
			});

			return {
				...state,
				[nodeId]: {
					isLoading: false,
					error: null,
					...state[nodeId],
					entity: {
						...oldReport,
						fields: updatedFields
					},
				}
			};
		}

		case actions.reportTemplates.types.REPORT_TEMPLATE_APPLY: {
			const {nodeId, template} = action.payload as {nodeId: number, template: report.Template};
			const oldReport = state[nodeId]?.entity;
			if(!oldReport || !oldReport.fields){
				return state;
			}

			const updatedFields: report.Field[] = oldReport.fields.map(field => {
				const templateEntry = template.content.find(entry => entry.id === field.id);
				if(!templateEntry){
					return {
						...field,
						area: undefined,
						areaIndex: undefined,
						filterType: undefined,
						filterValues: undefined
					};
				}
				return {
					...field,
					area: templateEntry.area,
					areaIndex: templateEntry.areaIndex,
					filterType: templateEntry.filterType,
					filterValues: templateEntry.filterValues
				}
			})

			return {
				...state,
				[nodeId]: {
					isLoading: false,
					error: null,
					...state[nodeId],
					entity: {
						...oldReport,
						fields: updatedFields
					}
				}
			}
		}
	}
	return state;
}

export default state;