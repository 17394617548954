import { UserAccount } from "../models";

const actionTypes = {
	ACCOUNT_LOAD: 'ACCOUNT\\LOAD',
	ACCOUNT_LOAD_SUCCESS: 'ACCOUNT\\LOAD_SUCCESS',
	ACCOUNT_LOAD_FAILED: 'ACCOUNT\\LOAD_FAILED',
}

const load = (userId: number, token: string) => ({
	type: actionTypes.ACCOUNT_LOAD,
	payload: {
		token,
		userId
    }
})

const loadSuccess = (user: UserAccount) => ({
	type: actionTypes.ACCOUNT_LOAD_SUCCESS,
	payload: {
        user
    }
})

const loadFailed = (message: string) => ({
	type: actionTypes.ACCOUNT_LOAD_FAILED,
	payload: {
		message: message
	}
})

export default {
	types: actionTypes,
    load,
    loadSuccess,
	loadFailed,
};