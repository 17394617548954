import { memo } from 'react';
import { connect } from 'react-redux'

import actions from '../../actions';
import { CommonPageProps } from '../../models';
import { State } from '../../reducers';

import Roles from './Roles';

const mapStateToProps = (state: State, _: CommonPageProps) => {
	return {
		isLoading: state.roles.isLoading,
		roles: state.roles.list,
		permissions: state.permissions.list,
		isPermissionsLoading: state.permissions.isLoading,
		token: state.auth.token
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadRoles: (token: string) => dispatch(actions.roles.load(token)),
	deleteRole: (id: number, token: string) => dispatch(actions.role.delete(id, token)),
	loadPermissions: (token: string) => dispatch(actions.permissions.load(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(Roles));