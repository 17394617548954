import actions from '../actions';
import { SingleEntityPerTree, TreeContainerState } from '../models';

export interface TreeContainerStates extends SingleEntityPerTree<TreeContainerState>{
}

const defaultState: TreeContainerStates = {
}

const treePermissions = (state: TreeContainerStates = defaultState, action: any): TreeContainerStates => {
	switch(action.type){
		case actions.treeContainerState.types.TREE_CONTAINER_STATE_SAVE: {
			const {treeId, treeState} = action.payload;
			return {
				...state,
				[treeId]: {
					entity: treeState,
					error: null,
					isLoading: false
				}
			}
		}

		case actions.tree.types.TREE_UNLOAD: {
			const {id} = action.payload;
			const treeId = parseInt(id);
			const updatedState = {
				...state
			};
			delete updatedState[treeId];
			return updatedState;
		}
	}
	return state;
}

export default treePermissions;