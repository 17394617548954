import { connect } from 'react-redux'

import actions from '../../actions'
import LoginPage from './LoginPage';

import './LoginPage.scss';

const mapStateToProps = (state: any, ownProps: any) => {
	const { token, isLoading, error } = state.auth;
	return {token, isLoading, error}
}

const mapDispatchToProps = (dispatch: any) => ({
	onError: (message: string) =>
		dispatch(actions.error.raise(message)),
	onLogin: (username: string, password: string/*, recaptchaToken: string*/) =>
		dispatch(actions.auth.login(username, password/*, recaptchaToken*/))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)