import React from 'react';

interface PropertiesSectionProps{
    title?: string,
    className?: string,
    children?: JSX.Element | JSX.Element[] | null
    footer?: JSX.Element | JSX.Element[] | null
}

const PropertiesSection = (props: PropertiesSectionProps): JSX.Element => {
    const {title, className, children, footer} = props;
    const containerClassName = 'properties-section';
    const effectiveContainerClassName = className ? `${containerClassName} ${className}` : containerClassName;
    return (
        <div className={effectiveContainerClassName}>
            {title && (
                <div className={`${containerClassName}__title`}>
                    {title}
                </div>
            )}
            <div>
                {children}
            </div>
            {footer && (
				<div className={`${containerClassName}__footer`}>
					{footer}
				</div>
			)}
        </div>
    );
}

export default PropertiesSection;



