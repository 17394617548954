import { all, call, put, takeEvery} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { TransactionChangeLogEntry, TransactionChangeLogsFilters} from '../models';
import { SimpleValueChange, AttributeValueChange } from '../models/transactionChangeLogs';
import handleResponse from './handleResponse';

const {
	loadSuccess, loadFailed
} = actions.transactionChangeLogs;
const {raise} = actions.error;

type ResponseType = {status: number, body: any} | {error: any};
type ResponsePromiseType = Promise<ResponseType>;

const extractFiltersString = (filters: TransactionChangeLogsFilters): string => {
	const result = Object.entries(filters)
		.reduce((acc, cur) => {
			const newPart = cur[1] ? `&filter[${cur[0]}]=${encodeURIComponent(cur[1] || '')}` : null;
			return newPart ? acc + newPart : acc;
		}, '');
	return result;
}

const tryLoad = (payload: {nodeId: number, filters: TransactionChangeLogsFilters, currentPage: number, pageSize: number, token: string}): ResponsePromiseType => {
	try{
		const {nodeId, token, currentPage, pageSize, filters} = payload;
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const pagination = `page=${currentPage}&size=${pageSize}`;
		const queryFilter = extractFiltersString(filters);

		const params = queryFilter.length > 0 ? `${pagination}${queryFilter}` : pagination;
		const base_url = `${config.api_url}/tree/${nodeId}/transactions/log/`;

		return fetch(`${base_url}?${params}`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractValue = (entry: any): SimpleValueChange | AttributeValueChange => {
	let result;
	if(entry.type != null){
		result = {
			attributeId: entry.attributeId,
			attributeName: entry.attributeName,
			type: entry.type,
			value: entry.value,
			valueId: entry.valueId
		} as AttributeValueChange
	} else {
		result = {
			value: entry.value
		} as SimpleValueChange
	}
	return result;
}

const extractTransactionChangeLog = (entry: any): TransactionChangeLogEntry => {
	return {
		transactionId: entry.transactionId,
		operationId: entry.operationId,
		fieldName: entry.fieldName,
		ipAddress: entry.ipAddress,
		authorName: entry.author,
		changeDate: new Date(entry.changeDateAt),
		oldValue: extractValue(entry.oldValue),
		newValue: extractValue(entry.newValue)
	}
}

const loadSaga = function*(action: any){
	const {nodeId, currentPage, pageSize, filters} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => {
				const logs = (body.data.log || []).map(extractTransactionChangeLog)
				return loadSuccess(nodeId, currentPage, pageSize, filters, logs)
			},
			(message: any) => loadFailed(nodeId, currentPage, pageSize, filters, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(nodeId, currentPage, pageSize, filters, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield all([
		takeEvery(actions.transactionChangeLogs.types.TRANSACTION_CHANGE_LOGS_LOAD, loadSaga),
	]);
}

export default watch;