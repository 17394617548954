import React, { useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';

import { ActionGroup, CommonPageProps, TreePermission } from '../../models';

import NotFound from '../NotFound';
import EditActionGroupPermissions from './EditActionGroupPermissions';
import {SubmitActionGroupType} from './EditActionGroupPermissions';
import { site } from '../../settings';
import { PageTitle } from '../../components';

interface EditRoleProps extends CommonPageProps{
	isActionGroupsLoading: boolean,
	isActionGroupUpdating: boolean,
	isTreePermissionsLoading: boolean,
	actionGroups: ActionGroup[] | null,
	treePermissions: TreePermission[] | null,
	isNew?: boolean,
	token: string,

	loadActionGroups: (token: string) => void,
	loadTreePermissions: (token: string) => void
	updateActionGroup: SubmitActionGroupType,
	createActionGroup: SubmitActionGroupType,
	actionGroupsReset: () => void
}

const containerClassName = 'edit-action-group';

const EditRole = (props: EditRoleProps) => {
	const {id} = useParams<{id: string}>();

	const {
		actionGroups, isActionGroupsLoading, isActionGroupUpdating, isNew,
		treePermissions, isTreePermissionsLoading,
		token, securityProvider,
		loadActionGroups, updateActionGroup, createActionGroup,
		loadTreePermissions
	} = props;

	useEffect(() => {
		if(!isNew && actionGroups === null && !isActionGroupsLoading){
			loadActionGroups(token);
		}
		if(treePermissions === null && securityProvider.treePermissions.canRead && !isTreePermissionsLoading){
			loadTreePermissions(token);
		}
	});

	let submitActionGroup: SubmitActionGroupType;
	let actionGroup: ActionGroup | undefined;

	if(!isNew){
		if(!isActionGroupsLoading){
			actionGroup = actionGroups?.find(r => r.id?.toString() === id);

			if(!actionGroup){
				return (<NotFound/>);
			}
		}
		submitActionGroup = updateActionGroup;
	} else {
		submitActionGroup = createActionGroup;
	}

	let content: JSX.Element;
	if((actionGroup || isNew) && !isActionGroupsLoading && !isActionGroupUpdating && !isTreePermissionsLoading){
		content = (
			<EditActionGroupPermissions
				actionGroup = {actionGroup}
				isNew = {isNew}
				permissions = {treePermissions}
				token = {token}
				submitActionGroup = {submitActionGroup}
				securityProvider = {securityProvider}
				className = {`${containerClassName}__content`}
			/>
		)
	} else {
		content = <Skeleton active/>
	}

	return (
		<Row className={`${containerClassName} page-content`}>
			<Col md={{span: 12, offset: 2}}>
				<PageTitle
					title = {isNew ? site.newActionGroup.name : actionGroup?.name || ''}
					backUrl = {site.actionGroups.url}
				/>
				{content}
			</Col>
		</Row>
	);
}

export default EditRole;