import { call, put, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import {Permission, Role, User} from '../models';
import handleResponse from './handleResponse';

const {loadSuccess, loadFailed} = actions.users;
const {raise} = actions.error;

const tryLoadUsers = (payload: {token: string}): Promise<{status: number, body: any} | {error: any}> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/users`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractUser = (entry: any): User => {
	const roles: Role[] = entry.roles.map((role: any): Role => ({
		id: role.id,
		name: role.name
	}))
	const permissions: Permission[] = []
	entry.roles.forEach((role: Role) => {
		role?.permissions?.forEach(perm => {
			permissions.push(perm)
		})
	})
	return {
		id: entry.id,
		name: entry.name,
		firstname: entry.firstname,
		lastname: entry.lastname,
		email: entry.email,
		roles: roles,
		permissions
	}
}

const loadUsersSaga = function*(action: any){
	try{
		const response = yield call(tryLoadUsers, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(body?.data.map(extractUser)),
			(error: any) => loadFailed(error)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed('An error occurred'));
		return;
	}
}

const watchUsers = function*() {
	yield takeLeading(actions.users.types.USERS_LOAD, loadUsersSaga)
}

export default watchUsers;