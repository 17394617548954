import moment, { Moment } from 'moment';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import {Balance, CommonPageProps, EntityPerTree, NodesState, Report, report, SingleEntityPerTree} from '../../models';
import PivotReport from './PivotReport';

interface PivotReportContainerProps extends CommonPageProps{
	nodes: NodesState,
	reports: SingleEntityPerTree<Report>,
	reportTemplates: EntityPerTree<report.Template>,
	token: string,
	balances: EntityPerTree<Balance>,

	loadNodeName: (nodeId: number, token: string) => void,
	loadReport: (nodeId: number, date: [Moment, Moment], dateType: report.ReportDateType, token: string) => void,
	loadReportTemplates: (nodeId: number, token: string) => void,
	applyTemplate: (nodeId: number, template: report.Template) => void,
	saveTemplate: (nodeId: number, template: report.TemplateWithoutId, token: string) => void,
	deleteTemplate: (nodeId: number, id: number, token: string) => void,
	updateReportField: (nodeId: number, fieldId: string, update: report.ReportFieldUpdate) => void,
	loadBalances: (treeId: number, token: string) => void,
}

const PivotReportContainer = (props: PivotReportContainerProps) => {
		const {treeId} = useParams<{treeId: string}>();
		const {
			nodes, reports, reportTemplates,
			currentUser, securityProvider, token, balances,
			loadReport, loadNodeName,
			loadReportTemplates, applyTemplate, saveTemplate, deleteTemplate,
			updateReportField, loadBalances
		} = props;

		const safeId = parseInt(treeId);

		const updateReport = (options?: {dates?: [Moment, Moment], type?: report.ReportDateType}) => {
			const defaultDates: [Moment, Moment] = [moment().subtract(1, 'days'), moment()];
			loadReport(safeId, options?.dates || defaultDates, options?.type || 'customDate', token)
		}

		useEffect(() => {
			const report = reports[safeId];
			if(!report || (!report.entity && !report.isLoading && !report.error)){
				updateReport();
			}
			const node = nodes[safeId];
			if(!node || (!node.name && !node.isNameLoading && !node.nameError)){
				loadNodeName(safeId, token);
			}
			const templates = reportTemplates[safeId];
			if(!templates || (!templates.list && !templates.isLoading && !templates.error)){
				loadReportTemplates(safeId, token);
			}
			let balance = balances[safeId];
			if(!balance || (!balance.list && !balance.isLoading)) {
				loadBalances(safeId, token)
			}
		});

		const node = nodes[safeId];
		const report = reports[safeId];
		const templates = reportTemplates[safeId];

		return (
			<PivotReport
				key = {safeId}
				nodeId = {safeId}
				nodeName = {node?.name || null}
				report = {report?.entity || null}
				isReportLoading = {report?.isLoading || false}
				reportTemplates = {templates?.list || null}
				currentUser = {currentUser}
				securityProvider = {securityProvider}
				token = {token}
				balances = {balances}
				treeId={safeId}

				updateReport = {updateReport}
				updateReportField = {updateReportField}
				applyTemplate = {applyTemplate}
				saveTemplate = {saveTemplate}
				deleteTemplate = {deleteTemplate}
			/>
		)
}

export default PivotReportContainer;
