import React, { memo, useState } from 'react';
import { DatePicker } from 'antd';
import moment, {Moment} from 'moment';

const {RangePicker} = DatePicker;

interface RangeFilterProps{
	className?: string;
	startDate?: Moment | null;
	endDate?: Moment | null;

	onChange?: (startDate: Moment | null, endDate: Moment | null) => void,
	onReset?: () => void
}

const containerClassName = 'bra-range-filter';

const RangeFilter = (props: RangeFilterProps) => {
	const {
		className, startDate: startDateInitial, endDate: endDateInitial,
		onChange, onReset
	} = props;

	const [shownStartDate, setShownStartDate] = useState(startDateInitial);
	const [shownEndDate, setShownEndDate] = useState(endDateInitial);

	const onRangeChange = (values: [Moment | null, Moment | null] | null) => {
		if(!values){
			setShownStartDate(null);
			setShownEndDate(null);
			return onReset && onReset();
		}

		setShownStartDate(values[0]);
		setShownEndDate(values[1]);

		onChange && onChange(values[0], values[1]);
	}

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__content`}>
				<RangePicker
					value = {[
						shownStartDate ? moment(shownStartDate) : null,
						shownEndDate ? moment(shownEndDate) : null
					]}
					allowEmpty = {[true, true]}
					onChange = {onRangeChange}
				/>
			</div>
		</div>
	)
}

export default memo(RangeFilter);