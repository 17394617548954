import React, { memo } from 'react';
import { Dropdown, Menu } from 'antd';
import { Bookmark, Edit, MoreVertical, Trash } from 'react-feather';
import { NavLink } from 'react-router-dom';

import { localization, site } from '../../settings';
import { SetNameDialog, ConfirmDelete } from '../../components';

interface TreePreviewProps{
    id?: number,
    className?: string | null,
    title?: string | null,
    token?: string,

    rename?: ((id: number, name: string, token: string) => void) | null
    deleteTree?: ((id: number, token: string) => void) | null
}

const containerClassName = 'tree-preview';

const renderMenu = (props: TreePreviewProps) => {
    const {
        id, title,
        token,
        rename, deleteTree
    } = props;
    return (
        <Menu>
            {rename && (<Menu.Item
                icon = {<Edit className = {`feather-ico`}/>}
                onClick = {(info) => {
                    info.domEvent.stopPropagation();

                    SetNameDialog.show({
                        initialValue: title || undefined,
                        title: localization.renameNodeTitle,
                        onOk: (name) => id && token && rename(id, name, token)
                    })
                }}
            >
                {localization.edit}
            </Menu.Item>)}
            {deleteTree && (<Menu.Item
                danger
                icon = {<Trash className={`feather-ico`}/>}
                onClick = {(info) => {
                    info.domEvent.stopPropagation();
                    ConfirmDelete({
						title: localization.delete_node_title,
						content: localization.delete_node_content,
						deleteEntity: () => id && token && deleteTree(id, token)
                    })
                }}
            >
                {localization.delete}
            </Menu.Item>)}
        </Menu>
    );
}

const TreePreview = (props: TreePreviewProps) => {
    const {id, className, title, rename, deleteTree} = props;
    const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

    return (
        <NavLink className = {effectiveClassName} to = {id ? `${site.trees.url}/${id}` : ''}>
            <div className = {`${containerClassName}__header`}>
                <img
                    className = {`${containerClassName}__header__img`}
                    alt="preview"
                    src="/imgs/tree-preview.svg"
                />
                {(rename || deleteTree) && (<Dropdown overlay = {renderMenu(props)} trigger={['click']}>
                    <MoreVertical className = {`${containerClassName}__header__actions`}/>
                </Dropdown>)}
            </div>
            <div className = {`${containerClassName}__title`}>
                <div className={`${containerClassName}__title__text`}>
                    {title}
                </div>
                <div className = {`${containerClassName}__title__bookmark`}>
                    <Bookmark/>
                </div>
            </div>
            <div className = {`${containerClassName}__details`}>

            </div>
            <div className = {`${containerClassName}__footer`}>

            </div>
        </NavLink>
    );
}

export default memo(TreePreview);