import permissions from './permissions.json';

interface CRUD{
	canRead: boolean,
	canCreate: boolean,
	canEdit: boolean,
	canDelete: boolean
}

interface Permissions{
	canRead: boolean,
	canAssign: boolean
}

interface Cache{
	canClear: boolean
}

interface Elastic{
	canSync: boolean
}

interface Roles extends CRUD{
	canAssign: boolean
}

export interface SecurityProvider{
	users: CRUD,
	roles: Roles,
	permissions: Permissions
	actionGroups: CRUD,
	treePermissions: Permissions,
	cache: Cache,
	elastic: Elastic,
	node: {canCreate: boolean},
	attributeGroups: {canRead: boolean},
	balances: {canRead: boolean},
	isAdmin: boolean,
}

const getSecurityProvider = (currentPermissions: string[]): SecurityProvider => {
	const isAdmin = currentPermissions.indexOf(permissions.access_all_nodes) > -1;

	const canReadUsers	= isAdmin || currentPermissions.includes(permissions.read_users);
	const canCreateUser	= isAdmin || currentPermissions.includes(permissions.create_user);
	const canEditUser	= isAdmin || currentPermissions.includes(permissions.update_user);
	const canDeleteUser	= isAdmin || currentPermissions.includes(permissions.delete_user);

	const canReadRoles	= isAdmin || currentPermissions.includes(permissions.read_roles);
	const canCreateRole	= isAdmin || currentPermissions.includes(permissions.create_role);
	const canEditRole	= isAdmin || currentPermissions.includes(permissions.update_role);
	const canDeleteRole	= isAdmin || currentPermissions.includes(permissions.delete_role);
	const canAssignRole	= isAdmin || currentPermissions.includes(permissions.assign_role);

	const canReadPermissions	= isAdmin || currentPermissions.includes(permissions.read_permissions);
	const canAssignPermissions	= isAdmin || currentPermissions.includes(permissions.assign_permission);

	const canReadActionGroups	= isAdmin || currentPermissions.includes(permissions.read_action_groups);
	const canCreateActionGroups	= isAdmin || currentPermissions.includes(permissions.create_action_group);
	const canEditActionGroups	= isAdmin || currentPermissions.includes(permissions.update_action_groups);
	const canDeleteActionGroups	= isAdmin || currentPermissions.includes(permissions.delete_action_groups);

	const canClearCache	= isAdmin || currentPermissions.includes(permissions.clear_cache);
	const canSyncElastic	= isAdmin || currentPermissions.includes(permissions.sync_elastic);

	const canReadTreePermissions	= isAdmin || currentPermissions.includes(permissions.read_tree_permissions);
	const canAssigTreePermissions	= isAdmin || currentPermissions.includes(permissions.assign_action_groups_permissions);

	const canCreateNode	= isAdmin || currentPermissions.includes(permissions.create_node);

	return {
		isAdmin: isAdmin,
		users: {
			canRead: canReadUsers,
			canCreate: canCreateUser,
			canEdit: canEditUser,
			canDelete: canDeleteUser,
		},
		roles: {
			canRead: canReadRoles,
			canCreate: canCreateRole,
			canEdit: canEditRole,
			canDelete: canDeleteRole,
			canAssign: canAssignRole
		},
		permissions: {
			canRead: canReadPermissions,
			canAssign: canAssignPermissions
		},
		actionGroups: {
			canRead: canReadActionGroups,
			canCreate: canCreateActionGroups,
			canEdit: canEditActionGroups,
			canDelete: canDeleteActionGroups
		},
		treePermissions: {
			canRead: canReadTreePermissions,
			canAssign: canAssigTreePermissions
		},
		node: {
			canCreate: canCreateNode
		},
		attributeGroups: {
			canRead: isAdmin
		},
		balances: {
			canRead: isAdmin
		},
		cache: {
			canClear: canClearCache
		},
		elastic: {
			canSync: canSyncElastic
		}
	};
}

export default getSecurityProvider;