import { BalanceLimit } from "../models";

const actionTypes = {
	BALANCE_LIMITS_LOAD: 'BALANCE_LIMITS\\LOAD',
	BALANCE_LIMITS_LOAD_SUCCESS: 'BALANCE_LIMITS\\LOAD_SUCCESS',
	BALANCE_LIMITS_LOAD_FAILED: 'BALANCE_LIMITS\\LOAD_FAILED',
}

const load = (token: string) => ({
	type: actionTypes.BALANCE_LIMITS_LOAD,
	payload: {
		token
	}
})

const loadSuccess = (balanceLimits: BalanceLimit[]) => ({
	type: actionTypes.BALANCE_LIMITS_LOAD_SUCCESS,
	payload: {
		balanceLimits
	}
})

const loadFailed = (message: string) => ({
	type: actionTypes.BALANCE_LIMITS_LOAD_FAILED,
	payload: {
		message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
};