const actionTypes = {
    VERSION_LOAD: 'VERSION\\LOAD',
    VERSION_LOAD_SUCCESS: 'VERSION\\LOAD_SUCCESS',
    VERSION_LOAD_FAILED: 'VERSION\\LOAD_FAILED',
}

const load = (token: string) => ({
    type: actionTypes.VERSION_LOAD,
    payload: {
        token
    }
})

const loadSuccess = (version: any) => ({
    type: actionTypes.VERSION_LOAD_SUCCESS,
    payload: {
        version
    }
})

const loadFailed = (message: string) => ({
    type: actionTypes.VERSION_LOAD_FAILED,
    payload: {
        message: message
    }
})

export default {
    types: actionTypes,
    load,
    loadSuccess,
    loadFailed,
};