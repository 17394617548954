import React, { memo } from 'react';
import {DatePicker} from 'antd';
import dateFormat from 'dateformat';
import moment from 'moment';

interface InputProps{
	className?: string;
	isEditable?: boolean;

	children: Date;
	onChange?: (date: Date) => void
}

const containerClassName = 'bra-transaction-date-input';

const Input = (props: InputProps) => {
	const {
		className,
		isEditable,
		children,
		onChange
	} = props;

	const onChangeHandler = (date: moment.Moment | null) => {
		if(!date){
			return;
		}
		onChange && onChange(date.toDate());
	}

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	const contentElement = isEditable ? (
		<DatePicker
			value = {moment(children)}
			format = {'DD-MM-yyyy'}
			allowClear = {false}
			suffixIcon = {null}
			inputReadOnly = {true}
			onChange = {onChangeHandler}
		/>
	) : (
		<span className = {`${containerClassName}__content-text`}>
			{dateFormat(children, 'dd-mm-yyyy')}
		</span>
	);

	return (
		<div
			className = {effectiveClassName}
		>
			{contentElement}
		</div>
	)
}

export default memo(Input);
