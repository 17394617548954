import React, { memo, useState } from 'react';
import { X } from 'react-feather';

interface SubstringFilterProps{
	className?: string;
	pattern?: RegExp;
	value?: string | null;

	onChange?: (value: string) => void,
	onReset?: () => void
}

const containerClassName = 'bra-substring-filter';

const SubstringFilter = (props: SubstringFilterProps) => {
	const {
		className,
		value, pattern,
		onChange, onReset
	} = props;
	const [shownValue, setShownValue] = useState(value || '');

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;

		if(!pattern || pattern.test(newValue)){
			setShownValue(newValue);
		}
	}

	const onBlurHandler = () => {
		onChange && onChange(shownValue);
	}

	const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter'){
			onChange && onChange(shownValue);
		}
	}

	const onResetHandler = () => {
		setShownValue('');
		onReset && onReset();
	}

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__content`}>
				<input
					className = {`${containerClassName}__content__input`}
					value = {shownValue}
					onChange = {onChangeHandler}
					onBlur = {onBlurHandler}
					onKeyPress = {onKeyPressHandler}
				/>
				<X
					className = {`${containerClassName}__content__x`}
					onClick = {onResetHandler}
				/>
			</div>
		</div>
	)
}

export default memo(SubstringFilter);