import { slugs } from "../../settings";

type EntryOperator = '=' | '<>' | '>' | '>=' | '<' | '<=';

const isEntryOperator = (text: string): text is EntryOperator => {
	return ['=' , '<>' , '>' , '>=' , '<' , '<='].includes(text);
}

interface SimpleEntry{
	[0]: string,
	[1]: EntryOperator,
	[2]: string
}

interface SimpleEntryResult{
	[0]: string,
	[1]: EntryOperator,
	[2]: string | number
}

const isSimpleEntry = (entry: any): entry is SimpleEntry => {
	return Array.isArray(entry) &&
		entry.length === 3 &&
		typeof entry[0] === 'string' &&
		isEntryOperator(entry[1]) &&
		typeof entry[2] === 'string'
}

type ExpressionOperation = 'and' | 'or';

type Filter = (SimpleEntryResult | ExpressionOperation | Filter)[];

const processBranch = (branch: any, unhack: (text: string) => [string | null, string]): SimpleEntryResult | ExpressionOperation | Filter | null => {
	// check if ExpressionOperator
	if(typeof branch === 'string'){
		return branch === 'or' ? 'or' : 'and';
	}
	if(Array.isArray(branch)){
		// check if it is SimpleEntry
		if(isSimpleEntry(branch)){
			const [id, caption] = unhack(branch[2]);
			// Process NONE value for attributes explicitly
			if(id === '0'){
				return [branch[0], branch[1], 0];
			}
			// Process groupped text attributes and comment in their own way
			if(
				branch[0].indexOf(`.${slugs.balanceAttribute_type.grouped_text}`) > -1 ||
				branch[0] === 'comment'
			){
				return [branch[0], branch[1], caption];
			}
			return [branch[0], branch[1], id !== null ? `${id}` : caption];
		} else {
			const result: Filter = []
			for(const subBranch of branch){
				const processed = processBranch(subBranch, unhack);
				if(processed){
					result.push(processed)
				}
			}
			return result;
		}
	}
	return null;
}

const buildFilter = (filter: any, unhack: (text: string) => [string | null, string]): Filter | null => {
	if(!Array.isArray(filter)){
		return null;
	}
	const resultAcc: [string, string, string][] = [];
	const result = filter.reduce((processedFilter, branch) => {
		const processedBranch = processBranch(branch, unhack);
		if(processedBranch){
			processedFilter.push(processedBranch)
		}
		return processedFilter;
	}, resultAcc)
	return result;
}

export default buildFilter;