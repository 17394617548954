import { all, call, put, takeEvery} from 'redux-saga/effects';

import {config} from '../settings'

import actions from '../actions';
import handleResponse from './handleResponse';

const {
	createSuccess, createFailed,
	updateSuccess, updateFailed,
	deleteSuccess, deleteFailed,
	setValueSuccess, setValueFailed,
	setGroupsSuccess, setGroupsFailed
} = actions.attribute;
const {raise} = actions.error;

type ResponseType = {status: number, body: any} | {error: any};
type ResponsePromiseType = Promise<ResponseType>

const tryPostAttribute = (payload: {id: number, name: string, nodeId: number, visibilityId: number, token: string}): ResponsePromiseType => {
	try{
		const {id, nodeId, name, visibilityId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		
		const formData = new FormData();
		if(name){
			formData.append("name", name);
		}
		if(visibilityId){
			formData.append("visibilityId", `${visibilityId}`);
		}

		const url = `${config.api_url}/nodes/${nodeId}/attributes${id ? ('/' + id) : ''}`;
		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryDeleteAttribute = (payload: {id: number, nodeId: number, token: string}): ResponsePromiseType => {
	try{
		const {nodeId, id} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/nodes/${nodeId}/attributes/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

type SetAttributeValuePayload = {nodeId: number, attributeId: number, attributeValueId: number, propagateValue?: boolean, token: string}
const trySetAttributeValue = (payload: SetAttributeValuePayload): ResponsePromiseType => {
	try{
		const {nodeId, attributeId, attributeValueId, propagateValue} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const formData = new FormData();
		if(propagateValue  !== undefined){
			formData.append("propagate", `${propagateValue? 1: 0}`);
		}
		return fetch(`${config.api_url}/tree/${nodeId}/attributes/${attributeId}/values/${attributeValueId}`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

type SetAttributeGroupPayload = {nodeId: number, attributeId: number, groupId: number, token: string}
const trySetGroup = (groupId: number, payload: SetAttributeGroupPayload, attach: boolean): ResponsePromiseType => {
	try{
		const {nodeId, attributeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/nodes/${nodeId}/attributes/${attributeId}/groups/${groupId}`, {
			method: attach ? 'POST' : 'DELETE',
			headers: headers
		}).then(response => {
				return response.json().then(json => ({
					status: response.status,
					body: json
				}))
			});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const createAttributeSaga = function*(action: any){
	const {nodeId, treeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryPostAttribute, action.payload);
		yield* handleResponse(
			response,
			(body: any) => createSuccess(body?.data?.id, nodeId, treeId),
			(error: any) => createFailed(error, nodeId, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed('An error occurred', nodeId, treeId));
		return;
	}
}

const updateAttributeSaga = function*(action: any){
	const {nodeId, treeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryPostAttribute, action.payload);
		yield* handleResponse(
			response,
			(body: any) => updateSuccess(nodeId, treeId),
			(error: any) => updateFailed(error, nodeId, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed('An error occurred', nodeId, treeId));
		return;
	}
}

const deleteAttributeSaga = function*(action: any){
	const {nodeId, treeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryDeleteAttribute, action.payload);
		yield* handleResponse(
			response,
			() => deleteSuccess(nodeId, treeId),
			(error: any) => deleteFailed(error, nodeId, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed('An error occurred', nodeId, treeId));
		return;
	}
}

const setAttributeValueSaga = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response: ResponseType = yield call(trySetAttributeValue, action.payload);
		yield* handleResponse(
			response,
			() => setValueSuccess(nodeId),
			(message: string) => setValueFailed(message, nodeId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(setValueFailed('An error occurred', nodeId));
		return;
	}
}

const setAttributeGroupsSaga = function*(action: any){
	const {nodeId, treeId, newGroupIds, currentGroupIds} = action.payload;
	try{
		// attach
		const attachRequests = newGroupIds
			.filter((id: number) => !currentGroupIds.includes(id))
			.map((id: number) => call(trySetGroup, id, action.payload, true));
		// detach
		const detachRequests = currentGroupIds
			.filter((id: number) => !newGroupIds.includes(id))
			.map((id: number) => call(trySetGroup, id, action.payload, false));
		for(let request of [...attachRequests, ...detachRequests]){
			const response: ResponseType = yield request;
			yield* handleResponse(
				response, 
				() => setGroupsSuccess(nodeId, treeId),
				setGroupsFailed
			)
		}
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(setGroupsFailed(nodeId, 'An error occurred', treeId));
		return;
	}
}

const watchAccount = function*() {
	all([
		yield takeEvery(actions.attribute.types.ATTRIBUTE_CREATE, createAttributeSaga),
		yield takeEvery(actions.attribute.types.ATTRIBUTE_UPDATE, updateAttributeSaga),
		yield takeEvery(actions.attribute.types.ATTRIBUTE_DELETE, deleteAttributeSaga),
		yield takeEvery(actions.attribute.types.ATTRIBUTE_SET_VALUE, setAttributeValueSaga),
		yield takeEvery(actions.attribute.types.ATTRIBUTE_SET_GROUPS, setAttributeGroupsSaga)
	])
}

export default watchAccount;