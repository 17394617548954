import { memo } from 'react';
import { connect } from 'react-redux'

import actions from '../../actions';
import { State } from '../../reducers';
import { CommonPageProps } from '../../models';

import ActionGroups from './ActionGroups';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		isLoading: state.actionGroups.isLoading,
		actionGroups: state.actionGroups.list,
		treePermissions: state.treePermissions.list,
		isTreePermissionsLoading: state.treePermissions.isLoading,
		token: state.auth.token,
		userPermissions: ownProps.currentUser.permissions
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadActionGroups: (token: string) => dispatch(actions.actionGroups.load(token)),
	deleteActionGroup: (id: number, token: string) => dispatch(actions.actionGroup.delete(id, token)),
	loadTreePermissions: (token: string) => dispatch(actions.treePermissions.load(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(ActionGroups));