const actionTypes = {
	ERROR_RAISE: 'ERROR\\RAISE'
}

const raise = (message: string) => ({
	type: actionTypes.ERROR_RAISE,
	payload: {
		message
	}
})

export default {
	types: actionTypes,
	raise
}