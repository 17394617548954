import React from 'react';
import { Checkbox, Skeleton } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { User } from '../../models';
import { localization, TreeSecurityProvider } from '../../settings';

interface RightSideProps<T>{
	children?: React.ReactNode,

	entities: T[] | null,
	selectedIds: number[] | null,
	disabled?: boolean,
	selectedUser: User,
	nodeId: number | null,
	treeSecurityProvider: TreeSecurityProvider,

	assign: (id: number, isAdd: boolean) => void
}

const containerClassName = "right-side";

class RightSide<T extends {id?: number, name?: string}> extends React.Component<RightSideProps<T>>{
	getAssignmentChangeHandler = (id?: number) => {
		return (e: CheckboxChangeEvent) => {
			if(id){
				const {checked: isAdd} = e.target;
				const {nodeId, assign, selectedUser} = this.props;
				if(nodeId && selectedUser.id){
					assign(id, isAdd);
				}
			}
		}
	}

	renderEntitiesForUserNode = () => {
		const {
			entities, selectedIds, disabled = false,
			treeSecurityProvider
		} = this.props;

		if(
			!entities ||
			!selectedIds
		){
			return <Skeleton/>
		}

		return entities.map(entity =>
			<div
				className = {`${containerClassName}__list__elements`}
				key = {`uard-e-${entity.id}`}
			>
				<Checkbox
					checked = {!!selectedIds.find(id => id === entity.id)}
					disabled = {disabled || !treeSecurityProvider.node.users.canAssign}
					onChange = {this.getAssignmentChangeHandler(entity.id)}
				>
					{entity.name}
				</Checkbox>
			</div>
		)
	}


	render(){
		const { selectedUser, children } = this.props;
		if(!selectedUser){
			return null;
		}

		return (
			<div className = {containerClassName}>
				<div className = {`${containerClassName}__title`}>
					<span className = {`${containerClassName}__title__prefix`}>
						{`${localization.userActionGroups_permissions_for} `}
					</span>
					<span className = {`${containerClassName}__title__user-name`}>
						{selectedUser.name}
					</span>
				</div>
				<div className = {`${containerClassName}__switcher`}>
					{children}
				</div>
				<div className = {`${containerClassName}__list`}>
					{selectedUser.id && this.renderEntitiesForUserNode()}
				</div>
			</div>
		);

	}
}

export default RightSide;