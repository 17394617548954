import React from 'react'
import { TransactionNode } from '../../models';

export interface TwoRecordsPlateProps{
	className?: string,
	forwardRecord: TransactionNode | null,
	backwardRecord: TransactionNode | null,
	filter?: string,
}

const containerClassName = 'two-records-plate'

const getRecordClassName = (record: TransactionNode | null, filter?: string) => {
	let className = `${containerClassName}__record__text`;
	if(filter && (Number(filter) === record?.id)){
		className += ` ${containerClassName}__record__text--highlighted`
	}
	return className;
}

const TwoRecordsPlate = (props: TwoRecordsPlateProps) => {
	const {className, forwardRecord, backwardRecord, filter} = props;
	const effectiveClassName = className ? `${containerClassName} ${className}`: containerClassName;

	const forwardElement = forwardRecord
		? <span className = {getRecordClassName(forwardRecord, filter)}>{forwardRecord.name}</span>
		: (<span>—</span>);
	const backwardElement = backwardRecord
		? <span className = {getRecordClassName(backwardRecord, filter)}>{backwardRecord.name}</span>
		: (<span>—</span>);
	return (
		<div
			className = {effectiveClassName}
		>
			<div className = {`${containerClassName}__forward-record`}>
				{forwardElement}
			</div>
			<div className = {`${containerClassName}__backward-record`}>
				{backwardElement}
			</div>
		</div>
	);
}

export default TwoRecordsPlate;