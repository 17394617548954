import { all, call, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';


const tryClearCache = (payload: {token: string}): Promise<{status: number, body: any} | {error: any}> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/clear_cache`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const trySyncElastic = (payload: {token: string}): Promise<{status: number, body: any} | {error: any}> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/sync-elastic`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const clearCacheSaga = function*(action: any){
	yield call(tryClearCache, action.payload);
}

const syncElasticSaga = function*(action: any){
	yield call(trySyncElastic, action.payload);
}

const watch = function*() {
	yield all([
		takeLeading(actions.development.types.DEVELOPMENT_CLEAR_CACHE, clearCacheSaga),
		takeLeading(actions.development.types.DEVELOPMENT_SYNC_ELASTIC, syncElasticSaga)
	])
}

export default watch;