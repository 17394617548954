import { put } from "redux-saga/effects";

import actions from '../actions';

const hasError = (response: any): string | null => {
	// everything is okay
	if((response.status === 0 || response.status === 200) && response.error == null){
		return null;
	}
	let message = response.error;
	if(response.body?.error){
		message = response.body.error;
	}
	if(response.body?.error.message){
		message = response.body.error.message;
	}
	if(response.error?.message){
		message = response.error.message;
	}
	return message;
}

const handleResponse = function* (
	response: any,
	successHandler?: any | null,
	failureHandler?: any | null,
	onSuccess?: any | null,
	onError?: any | null
) {
	const error = hasError(response);
	if(!error){
		if(onSuccess){
			yield* onSuccess(response.body);
		}
		if(successHandler){
			yield put(successHandler(response.body));
		}
		return;
	}
	if(onError){
		yield* onError(response.body);
	}
	yield put(actions.error.raise(error));
	yield put(failureHandler(error));
}

export {hasError};
export default handleResponse;