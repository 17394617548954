import { all, call, put, takeEvery} from 'redux-saga/effects';

import actions from '../actions';
import { config } from '../settings';

import handleResponse from './handleResponse';

const {
	createSuccess, createFailed,
	deleteSuccess, deleteFailed,
	orderSuccess, orderFailed
} = actions.balanceAttributeValue;
const {raise} = actions.error;

type ResponsePromise = Promise<{status: number, body: any} | {error: any}>
type PostPayload = {name: string, balanceAttributeId: number, balanceId: number, treeId: number, token: string};
type DeletePayload = {id: number, balanceAttributeId: number, balanceId: number, treeId: number, token: string};

const DEFAULT_ATTRIBUTE_ORDER = 1

const tryPostAttributeValue = (payload: PostPayload): ResponsePromise => {
	try{
		const {
			name, balanceAttributeId, balanceId, treeId
		} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		const formData = new FormData();
		if(name){
			formData.append("name", name);
		}
		formData.append("order", `${DEFAULT_ATTRIBUTE_ORDER}`);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes/${balanceAttributeId}/values/`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryDeleteAttributeValue = (payload: DeletePayload): ResponsePromise => {
	try{
		const {
			id, balanceAttributeId, balanceId, treeId
		} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes/${balanceAttributeId}/values/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const trySetOrder = (
	order: number, balanceAttributeValueId: number, balanceAttributeId: number, balanceId: number, treeId: number, token: string
): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const formData = new FormData();
		formData.append('order', `${order}`);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes/${balanceAttributeId}/values/${balanceAttributeValueId}`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const createBalanceAttributeValueSaga = function*(action: any){
	const {balanceAttributeId, balanceId, treeId} = action.payload;
	try{
		const response = yield call(tryPostAttributeValue, action.payload);
		yield* handleResponse(
			response,
			(body: {id: number}) => createSuccess(body.id, balanceAttributeId, balanceId, treeId),
			(message: string) => createFailed(message, balanceAttributeId, balanceId, treeId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed('An error occurred', balanceAttributeId, balanceId, treeId));
		return;
	}
}

const deleteBalanceAttributeValueSaga = function*(action: any){
	const { balanceAttributeValueId, balanceAttributeId, balanceId, treeId } = action.payload;
	try{
		const response = yield call(tryDeleteAttributeValue, action.payload);
		yield* handleResponse(
			response,
			() => deleteSuccess(balanceAttributeValueId, balanceAttributeId, balanceId, treeId),
			(message: string) => deleteFailed(message, balanceAttributeValueId, balanceAttributeId, balanceId, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed('An error occurred', balanceAttributeValueId, balanceAttributeId, balanceId, treeId));
		return;
	}
}

const orderBalanceAttributeValuesSaga = function*(action: any){
	const {idsWithOrder, balanceAttributeId, balanceId, treeId, token } = action.payload;
	try{
		const requests = idsWithOrder.map((pair: {id: number, order: number}) => {
			return call(trySetOrder, pair.order, pair.id, balanceAttributeId, balanceId, treeId, token)
		})
		yield all(requests);
		yield put(orderSuccess(balanceAttributeId, balanceId, treeId));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(orderFailed('An error occurred', balanceAttributeId, balanceId, treeId));
		return;
	}
}

const watch = function*() {
	yield all([
		takeEvery(actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_CREATE, createBalanceAttributeValueSaga),
		takeEvery(actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_DELETE, deleteBalanceAttributeValueSaga),
		takeEvery(actions.balanceAttributeValue.types.BALANCE_ATTRIBUE_VALUE_ORDER, orderBalanceAttributeValuesSaga)
	])
}

export default watch;