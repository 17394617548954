import React from 'react'
import { Select } from 'antd';

import { TreeListEntry } from '../../models';
import { localization } from '../../settings';

interface NodeInTreeProps{
	className?: string,
	treeId?: number,
	trees?: TreeListEntry[] | null,
	nodeName?: string | null,
	treeIsSelectable?: boolean,

	onTreeChanged?: (treeId: number) => void
}

const containerClassName = 'node-in-tree';

const filter = (input: string, option?: {children?: React.ReactNode, label?: React.ReactNode}): boolean => {
	const child = option?.children;
	if(typeof child === 'string'){
		return child.toLowerCase().indexOf(input.toLowerCase()) > -1;
	}
	return false;
}

class NodeInTree extends React.Component<NodeInTreeProps>{
	render(){
		const {
			className,
			trees, treeId, treeIsSelectable,
			nodeName,
			onTreeChanged
		} = this.props;
		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		const treeOptions = trees?.map(t => {
			return (
				<Select.Option key = {t.id} value = {t.id || -1}>
					{t.name}
				</Select.Option>
			)
		})

		// hack to enable 'readonly' style for antd's dropdown
		let open: false | undefined
		let showArrow: false | undefined
		if(!treeIsSelectable){
			open = false
			showArrow = false
		}

		const key = (trees || [])
			.sort((a,b) => (a.id || 0) - (b.id || 0))
			.reduce((acc, cur) => `${acc}.${cur.id}`, '');

		return (
			<div className = {effectiveClassName}>
					<Select
						key = {key}
						loading = {!trees}
						className = {`${containerClassName}__tree-name`}
						defaultValue = {trees ? treeId : undefined}
						placeholder = {localization.newTransaction_transfer_tree_placeholder}
						open = {open}
						showSearch
						showArrow = {showArrow}
						filterOption = {(a,b) => filter(a,b)}
						bordered = {false}
						onChange = {(value) => onTreeChanged && onTreeChanged(value)}
					>
						{treeOptions}
					</Select>
					<div className = {`${containerClassName}__node-name`}>
						{nodeName || localization.newTransaction_transfer_node_placeholder}
					</div>
			</div>
		);
	}
}

export default NodeInTree;