import React from "react";
import { Skeleton } from "antd";
import { X } from "react-feather";

import {Balance, BalanceType, EntityPerTree, Tree, UserAccount, NodesState, BalanceAttribute, EntityPerBalance, BalanceAttributeType, EntityHolder, TransactionType} from "../../models";
import { SetNameDialog} from "../../components";
import { localization, SecurityProvider, slugs, TreeSecurityProvider } from "../../settings";

import ActionPanel from "./ActionPanel";
import {NodeInfo} from "./content";
import { Moment } from "moment";
import { removeCurrentUserFromAssignedList } from "../../tools";
import { transactionTypes } from "../../settings/transaction";

export interface TreePropertiesProps{
	className?: string,
	nodesInfo: NodesState,
	tree: Tree | null,
	node?: Tree | null,
	selectedBalanceId?: number | null,
	balances: EntityPerTree<Balance>,
	isBalanceTypesLoading: boolean,
	balanceTypes: BalanceType[] | null,
	balanceAttributes: EntityPerBalance<BalanceAttribute>,
	balanceAttributeTypes: EntityHolder<BalanceAttributeType>,
	isUsersLoading: boolean,
	token: string,
	currentUser: UserAccount,

	securityProvider: SecurityProvider,
	treeSecurityProvider: TreeSecurityProvider,

	onClose?: (event: React.MouseEvent) => void,
	addNode: (treeId: number, parentId: number, name: string, token: string) => void,
	confirmDeleteNode: (treeId: number, nodeId: number) => void,
	updateNodeName: (treeId: number, nodeId: number, name: string, token: string) => void,
	fixRows: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
	showPermissionsDialogFor: (treeId: number) => void,
	showAttributesDialogFor: (treeId: number) => void,
	loadNodeAttributes: (nodeId: number, token: string) => void,
	loadBalances: (treeId: number, token: string) => void,
	loadBalanceTypes: (token: string) => void,
	loadBalanceAttributes: (treeId: number, balanceId: number, token: string) => void,
	loadBalanceAttributeTypes: (token: string) => void,
	createTransaction: (transactionType: TransactionType) => void,
	loadReport: (nodeId: number, date: [Moment, Moment], token: string) => void,
	loadTransactions: (treeId: number, token: string) => void,
	loadTransactionsElastic: (treeId: number, token: string) => void,
	go: (url: string) => void
}

const containerClassName = "tree-properties";

class TreeProperties extends React.Component<TreePropertiesProps>{
	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	isBalanceAttributesAvailable = () => {
		const {tree, selectedBalanceId, balances, balanceTypes} = this.props;
		if(!tree || !balanceTypes){
			return false;
		}
		const selectedBalance = balances[tree.id]?.list
			?.find(b => b.id === selectedBalanceId);
		const selectedBalanceTypeSlug = balanceTypes
			.find(bt => bt.id === selectedBalance?.typeId)?.slug;
		return selectedBalanceTypeSlug !== slugs.balance_types.calculate;
	}

	loadData = () => {
		const {
			node, nodesInfo,
			tree,
			balances, selectedBalanceId, loadBalances,
			balanceAttributes, loadBalanceAttributes,
			balanceAttributeTypes, loadBalanceAttributeTypes,
			balanceTypes, isBalanceTypesLoading, loadBalanceTypes,
			treeSecurityProvider, token,
			loadNodeAttributes,
		} = this.props;

		if(!balanceAttributeTypes || (!balanceAttributeTypes.list && !balanceAttributeTypes.isLoading)){
			loadBalanceAttributeTypes(token);
		}
		// if node selected and nodesInfo loaded
		if(nodesInfo && node){
			// if no attributes are loaded and they aren't loading
			if(treeSecurityProvider.node.attributes.canView && !nodesInfo[node.id]?.attributes && !nodesInfo[node.id]?.isAttributesLoading){
				loadNodeAttributes(node.id, token);
			}
		}
		// if tree provided and there are info on it
		if(tree?.id){
			if(!balances[tree.id] || (!balances[tree.id]?.list && !balances[tree.id]?.isLoading)){
				loadBalances(tree.id, token);
			}
		}

		if(tree?.id && selectedBalanceId){
			const isSupportedBalanceType = this.isBalanceAttributesAvailable();
			if(isSupportedBalanceType){
				if(!balanceAttributes[selectedBalanceId] || (!balanceAttributes[selectedBalanceId]?.list && !balanceAttributes[selectedBalanceId]?.isLoading)){
					loadBalanceAttributes(tree.id, selectedBalanceId, token);
				}
			}
		}

		if(!balanceTypes && !isBalanceTypesLoading){
			loadBalanceTypes(token);
		}
	}

	onClose = (event: React.MouseEvent) => {
		const {onClose} = this.props;
		onClose && onClose(event);
	}

	deleteNodeClickHandler = () => {
		const {tree, node, confirmDeleteNode} = this.props;
		if(node != null && tree != null){
			confirmDeleteNode(tree.id, node.id);
		}
	}

	addNodeClickHandler = () => {
		const {tree, node, addNode, token} = this.props;
		if(node){
			SetNameDialog.show({
				title: localization.newNodeTitle,
				onOk: (name: string) => {
					tree && addNode(tree.id, node.id, name, token)
				}
			})
		}
	}

	renameNodeClickHandler = () => {
		const {tree, node, updateNodeName, token} = this.props;
		if(node){
			SetNameDialog.show({
				title: localization.renameNodeTitle,
				initialValue: node.name,
				onOk: (name: string) => {
					tree && updateNodeName(tree.id, node.id, name, token);
				}
			})
		}
	}

	getNodePermissionsHandler = (nodeId: number) => {
		return () => {
			const {showPermissionsDialogFor} = this.props;
			showPermissionsDialogFor(nodeId);
		}
	}

	getNodeAttributesHandler = (nodeId: number) => {
		return () => {
			const {showAttributesDialogFor} = this.props;
			showAttributesDialogFor(nodeId);
		}
	}

	renderNodeInfo = () => {
		const {
			tree, node, nodesInfo,
			balances, balanceTypes,
			selectedBalanceId,
			securityProvider, treeSecurityProvider, currentUser,
			createTransaction, loadReport, loadTransactions, loadTransactionsElastic,
			go,
			token
		} = this.props;

		if(!node){
			return null;
		}

		const attributes = nodesInfo[node.id]?.attributes;
		const assignedUsers = removeCurrentUserFromAssignedList(nodesInfo[node.id]?.assignedUsers, currentUser.id);

		const currentBalances = (tree && balances[tree.id]?.list) || null;
		const availableTransactionTypes = (tree && transactionTypes?.filter(
	    (t) =>
	      	treeSecurityProvider?.allowedTransactions[
	      	  	t.slug as keyof typeof treeSecurityProvider.allowedTransactions
	      	],
	  ))
			?.sort((a, b) => a.id - b.id) || [];

		return (
			<NodeInfo
				node = {node}
				selectedBalanceId = {selectedBalanceId}

				attributes = {attributes}
				balances = {currentBalances}
				balanceTypes = {balanceTypes}
				users = {assignedUsers}

				transactionTypes = {availableTransactionTypes}

				securityProvider = {securityProvider}
				treeSecurityProvider = {treeSecurityProvider}

				token = {token}

				onCreateTransaction = {createTransaction}
				getNodeAttributesHandler = {this.getNodeAttributesHandler}
				getNodePermissionsHandler = {this.getNodePermissionsHandler}
				loadReport = {loadReport}
				loadTransactions = {loadTransactions}
				loadTransactionsElastic = {loadTransactionsElastic}
				go = {go}
			/>
		)
	}

	render(){
		const {
			className,
			tree, node,
			treeSecurityProvider,
			securityProvider,
			fixRows
		} = this.props;

		if(!tree || !node){
			return (
				<Skeleton />
			);
		}

		const addNodeClickHandler =
    		treeSecurityProvider.node.canAdd || securityProvider.isAdmin
        		? this.addNodeClickHandler
        		: null;
		const renameNodeClickHandler =
			treeSecurityProvider.node.canEdit || securityProvider.isAdmin
				? this.renameNodeClickHandler
				: null;
		const deleteNodeClickHandler =
			treeSecurityProvider.node.canDelete || securityProvider.isAdmin
				? this.deleteNodeClickHandler
				: null;

		return (
			<div className = {`${containerClassName} ${className}`}>
				<div className = {`${containerClassName}__pre-header`}>
					<X
						onClick = {this.onClose}
						className = {`${containerClassName}__icon`}
					 />
				</div>
				<div className={`${containerClassName}__header`}>
					{localization.selected}
				</div>
				<div className = {`${containerClassName}__title`}>
					<span key={`properties__node-name__${node.id}`}>
						{node.name}
					</span>
				</div>
				{this.renderNodeInfo()}
				<ActionPanel
					className = {`${containerClassName}__buttons`}
					addNode = { addNodeClickHandler }
					renameNode = { renameNodeClickHandler }
					deleteNode = { deleteNodeClickHandler }
					fixRows = {fixRows}
				/>
			</div>
		)
	}
}

export default TreeProperties;
