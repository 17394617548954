import { Tree } from '../models';

const selectNode = (selector: (node: Tree | null) => boolean, tree: Tree | null): Tree | null => {
	if(selector(tree)){
		return tree
	}
	for(const subtree of tree?.children || []){
		const foundNode = selectNode(selector, subtree);
		if(foundNode){
			return foundNode;
		}
	}
	return null;
}

export default selectNode;