import { ActionGroup } from "../models";

const actionTypes = {
	ACTION_GROUPS_LOAD: 'ACTION_GROUPS\\LOAD',
	ACTION_GROUPS_LOAD_SUCCESS: 'ACTION_GROUPS\\LOAD_SUCCESS',
	ACTION_GROUPS_LOAD_FAILED: 'ACTION_GROUPS\\LOAD_FAILED',
	ACTION_GROUPS_RESET: 'ACTION_GROUPS\\RESET',
}

const load = (token: string) => ({
	type: actionTypes.ACTION_GROUPS_LOAD,
	payload: {
		token: token
	}
})

const loadSuccess = (actionGroups?: ActionGroup[]) => ({
	type: actionTypes.ACTION_GROUPS_LOAD_SUCCESS,
	payload: {
		actionGroups: actionGroups
	}
})

const loadFailed = (message: string) => ({
	type: actionTypes.ACTION_GROUPS_LOAD_FAILED,
	payload: {
		message: message
	}
})

const reset = () => ({
	type: actionTypes.ACTION_GROUPS_RESET
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	reset
};