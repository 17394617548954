import FormulaEntryModel from './FormulaEntryModel';

type Extractor = (text: string) => [string, FormulaEntryModel] | null;

const operationExtractors: Extractor[] = ['+', '-', '*', '/', '(', ')'].map(char =>
	(text) => {
		if(text.startsWith(char)){
			return [text.substr(char.length), {
				id: char,
				kind: 'operation',
				title: char
			}]
		}
		return null;
	}
);

const variablesExtractors: Extractor[] = ['balance.', 'balanceAttribute.', 'nodeAttribute.'].map(prefix =>
	(text) => {
		if(text.startsWith(prefix)){
			const withoutPrefix = text.substr(prefix.length);
			const id = parseInt(withoutPrefix, 10);
			const restOfTheText = text.substr(prefix.length + `${id}`.length);
			if(prefix === 'balance.'){
				return [restOfTheText, {
					id: id,
					kind: 'balance'
				}]
			} else if (prefix === 'balanceAttribute.'){
				return [restOfTheText, {
					id: id,
					kind: 'balanceAttribute',
				}]
			} else if (prefix === 'nodeAttribute.'){
				return [restOfTheText, {
					id: id,
					kind: 'nodeAttribute',
				}]
			}
		}
		return null;
	}
);

const extractors: Extractor[] = [
	...operationExtractors,
	...variablesExtractors
]

const parseFormula = (text: string | null): FormulaEntryModel[] => {
	if(!text){
		return [];
	}

	let tmpText = text;
	const result: FormulaEntryModel[] = [];
	while(tmpText && (tmpText.length > 0)){
		let isHandled = false;
		for(const extractor of extractors){
			const extraction = extractor(tmpText);
			if(extraction){
				result.push(extraction[1]);
				tmpText = extraction[0];
				isHandled = true;
				break;
			}
		}
		if(!isHandled){
			return [];
		}
	}
	return result;
}

const makeFormula = (entries: FormulaEntryModel[] | null): string => {
	if(!entries){
		return '';
	}
	return entries
		.reduce((result, entry) => {
			const patch = entry.kind !== 'operation' ?
				`${entry.kind}.${entry.id}` :
				entry.id;
			return `${result}${patch}`;
		}, "");
}

export {
	parseFormula,
	makeFormula
};