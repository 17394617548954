import { all, call, put, takeEvery} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { BalanceAttribute, BalanceAttributeValue } from '../models';
import handleResponse from './handleResponse';

const {
	loadSuccess, loadFailed,
	createSuccess, createFailed,
	updateSuccess, updateFailed,
	deleteSuccess, deleteFailed,
	orderSuccess, orderFailed
} = actions.balanceAttributes;

const {raise} = actions.error;

type PostPayloadType = {name: string, typeId : number, isRequired: boolean, balanceId: number, treeId: number, token: string};
type DeletePayloadType = {id: number, balanceId: number, treeId: number, token: string};
type ResponsePromise = Promise<{status: number, body: any} | {error: any}>;

const tryLoad = (payload: {treeId: number, balanceId: number, token: string}): ResponsePromise => {
	try{
		const {treeId, balanceId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes/`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryPost = (payload: PostPayloadType, id?: number): ResponsePromise => {
	try{
		const {treeId, balanceId, name, typeId, isRequired} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const formData = new FormData();
		formData.append('name', name);
		formData.append('typeId', `${typeId}`);
		formData.append('required', isRequired ? '1': '0');
		formData.append('order', '1');

		const url = id ?
			`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes/${id}`:
			`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes`

		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryDelete = (payload: DeletePayloadType): ResponsePromise => {
	try{
		const {treeId, balanceId, id} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryUpdateOrder = (id: number, order: number, balanceId: number, treeId: number, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const formData = new FormData();
		formData.append('order', `${order}`);
		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/attributes/${id}`,{
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractValue = (entry: any): BalanceAttributeValue => {
	return {
		id: entry.id,
		name: entry.name,
		order: entry.order
	}
}

const extractBalanceAttributes = (entry: any): BalanceAttribute => {
	return {
		id: entry.id,
		name: entry.name,
		order: entry.order,
		required: entry.required,
		typeId: entry.typeId,
		values: entry.values
			.map((v: any) => extractValue(v))
	}
}

const loadSaga = function*(action: any){
	const {treeId, balanceId} = action.payload;
	try{
		const response = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(balanceId, treeId, body.data.map(extractBalanceAttributes)),
			(message: any) => loadFailed(balanceId, treeId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(balanceId, treeId, 'An error occurred'));
		return;
	}
}

const createSaga = function*(action: any){
	const {balanceId, treeId} = action.payload;
	try{
		const response = yield call(tryPost, action.payload);
		yield* handleResponse(
			response,
			(body: {id: number}) => createSuccess(body.id, balanceId, treeId),
			(message: string) => createFailed(message, balanceId, treeId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed('An error occurred', balanceId, treeId));
		return;
	}
}

const updateSaga = function*(action: any){
	const {balanceAttributeId, balanceId, treeId} = action.payload;
	try{
		const response = yield call(tryPost, action.payload, balanceAttributeId);
		yield* handleResponse(
			response,
			(body: {id: number}) => updateSuccess(body.id, balanceId, treeId),
			(message: string) => updateFailed(message, balanceId, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed('An error occurred', balanceId, treeId));
		return;
	}
}

const deleteSaga = function*(action: any){
	const {id, balanceId, treeId} = action.payload;
	try{
		const response = yield call(tryDelete, action.payload);
		yield* handleResponse(
			response,
			() => deleteSuccess(id, balanceId, treeId),
			(message: string) => deleteFailed(message, id, balanceId, treeId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed('An error occurred', id, balanceId, treeId));
		return;
	}
}

const orderSaga = function*(action: any){
	const {idsWithOrder, balanceId, treeId, token} = action.payload;
	try{
		const requests = idsWithOrder.map((pair: any) => {
			return call(tryUpdateOrder, pair.id, pair.order, balanceId, treeId, token)
		})
		yield all(requests)
		yield put(orderSuccess(balanceId, treeId));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(orderFailed('An error occurred', balanceId, treeId));
		return;
	}
}

const watch = function*() {
	yield all([
		takeEvery(actions.balanceAttributes.types.BALANCE_ATTRIBUTE_LOAD, loadSaga),
		takeEvery(actions.balanceAttributes.types.BALANCE_ATTRIBUTE_CREATE, createSaga),
		takeEvery(actions.balanceAttributes.types.BALANCE_ATTRIBUTE_UPDATE, updateSaga),
		takeEvery(actions.balanceAttributes.types.BALANCE_ATTRIBUTE_DELETE, deleteSaga),
		takeEvery(actions.balanceAttributes.types.BALANCE_ATTRIBUTE_ORDER, orderSaga)
	])
}

export default watch;