import actions from "../actions";
import history from '../history';
import { TransactionsElasticPerTree } from "../models";

const defaultState: TransactionsElasticPerTree = {
}

const state = (state: TransactionsElasticPerTree = defaultState, action: any): TransactionsElasticPerTree => {
	switch(action.type){
		case actions.transactionsElastic.types.TRANSACTIONS_ELASTIC_LOAD: {
			const {treeId, currentPage, pageSize, filters, attributes} = action.payload
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					list: null,
					isLoading: true,
					error: null,
					currentPage: currentPage,
					pageSize: pageSize,
					attributes: attributes,
					filters
				}
			}
		}
		case actions.transactionsElastic.types.TRANSACTIONS_ELASTIC_LOAD_SUCCESS: {
			const {treeId, transactions, currentPage, pageSize, filters, attributes} = action.payload;
			return {
				...state,
				[treeId]: {
					loadedAt: new Date(),
					list: transactions,
					isLoading: false,
					error: null,
					currentPage: currentPage,
					pageSize: pageSize,
					attributes: attributes,
					filters,
				}
			}
		}
		case actions.transactionsElastic.types.TRANSACTIONS_ELASTIC_LOAD_FAILED: {
			console.error(`${action.type}\n%o`, action.payload);
			const {treeId, message, currentPage, pageSize, filters, attributes} = action.payload;
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					list: [],
					isLoading: false,
					error: message,
					currentPage: currentPage,
					pageSize: pageSize,
					attributes: attributes,
					filters
				}
			}
		}

		case actions.transactionsElastic.types.TRANSACTIONS_ELASTIC_UNLOAD: {
			const {treeId, redirectTo} = action.payload;
			if(redirectTo){
				history.push(redirectTo);
			}
			const updatedState = {...state}
			delete updatedState[treeId];
			return updatedState;
		}
	}
	return state;
}

export default state;