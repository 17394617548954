import React, {useEffect, useState} from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { ChevronDown, User } from 'react-feather';

import {localization, SecurityProvider, site, config} from '../../settings';
import {UserAccount, VersionInfo} from '../../models';
import {version} from '../../../package.json';
import {TopMenuDialog} from "../TopMenuDialog";
import { PasswordChangeModal } from './Components';

interface TopMenuProps extends RouteComponentProps {
	className?: string,

	token: string,
	version: VersionInfo,
	currentUser: UserAccount,
	securityProvider: SecurityProvider,

	clearCache: (token: string) => void,
	syncElastic: (token: string) => void,
	getVersion: (token: string) => void,
	onLogout: () => void,
}

const containerClassName = 'bra-top-menu';

type DefaltSubmenuPropsType = {
	popupOffset: [number, number],
	className: string,
	popupClassName: string
}

const getSubmenuProps = (keys: string[], selectedKey: string): DefaltSubmenuPropsType => {
	const className = keys.indexOf(selectedKey) > -1 ?
		`${containerClassName}__menu__submenu bra-top-menu__menu__submenu--selected` :
		`${containerClassName}__menu__submenu`
	return {
		popupOffset: [-21, 0],
		className: className,
		popupClassName: `${containerClassName}__menu__popup`
	}
}

const TopMenu = (props: TopMenuProps) => {
	const {
		className,
		location,
		token, currentUser, securityProvider,
		clearCache, syncElastic, onLogout, getVersion, version: backendVersion
	} = props;

	useEffect(() => {
		getVersion(token);
	}, [getVersion, token])

	const [isModalVisible, setIsModalVisible] = useState(false);

	const [isPasswordChangeModalVisible, setIsPasswordChangeModalVisible] = useState<boolean>(false);
	const togglePasswordChangeModal = () => setIsPasswordChangeModalVisible(!isPasswordChangeModalVisible);

	const levels = location.pathname.split('/');
	const currentKey = `/${levels[1]}`;

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

	const is_tree_management_available =
		securityProvider.attributeGroups.canRead ||
		securityProvider.balances.canRead;
	const is_permissions_management_available =
		securityProvider.users.canRead ||
		securityProvider.roles.canRead ||
		securityProvider.actionGroups.canRead;

	return (
		<>
			<TopMenuDialog
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				version={backendVersion}
			/>
			<div className = {effectiveClassName}>
				<div className = {`${containerClassName}__logo`}>
					<span className = {`${containerClassName}__logo__text`}>
						{localization.logo_text}
					</span>
				</div>
				<Menu
					mode = 'horizontal'
					theme = 'dark'
					className = {`${containerClassName}__menu`}
					selectedKeys = {[currentKey]}
				>
					<Menu.SubMenu
						{...getSubmenuProps([site.trees.url], currentKey)}
						key = 'g1'
						popupOffset = {[0, 0]}
						title = {(
							<NavLink
								className = {`${containerClassName}__menu__title`}
								to={site.trees.url}
							>
								{localization.menu_main}
							</NavLink>
						)}
					/>
					{is_tree_management_available && (
						<Menu.SubMenu
							{...getSubmenuProps([site.attributeGroups.url, site.balances.url], currentKey)}
							key = 'g2'
							title = {(
								<span
									className = {`${containerClassName}__menu__title`}
								>
									{localization.menu_trees_management}
									<ChevronDown className = {`${containerClassName}__menu__title__ico feather-ico`}/>
								</span>
							)}
						>
							{securityProvider.attributeGroups.canRead && (
								<Menu.Item
									key = {site.attributeGroups.url}
								>
									<NavLink
										to = {site.attributeGroups.url}
										className = {`${containerClassName}__menu__entry`}
									>
										{localization.menu_attributeGroups}
									</NavLink>
								</Menu.Item>
							)}
							{securityProvider.balances.canRead && (
								<Menu.Item
									key = {site.balances.url}
								>
									<NavLink
										to = {site.balances.url}
										className = {`${containerClassName}__menu__entry`}
									>
										{localization.menu_balances}
									</NavLink>
								</Menu.Item>
							)}
						</Menu.SubMenu>
					)}
					{is_permissions_management_available && (
						<Menu.SubMenu
							{...getSubmenuProps([site.users.url, site.roles.url, site.actionGroups.url], currentKey)}
							key = 'g3'
							title = {(
								<span
									className = {`${containerClassName}__menu__title`}
								>
									{localization.menu_permissions_management}
									<ChevronDown className = {`${containerClassName}__menu__title__ico feather-ico`}/>
								</span>
							)}
						>
							{securityProvider.users.canRead && (
								<Menu.Item
									key = {site.users.url}
								>
									<NavLink
										to = {site.users.url}
										className = {`${containerClassName}__menu__entry`}
									>
										{localization.menu_users}
									</NavLink>
								</Menu.Item>
							)}
							{securityProvider.roles.canRead && (
								<Menu.Item
									key = {site.roles.url}
								>
									<NavLink
										to = {site.roles.url}
										className = {`${containerClassName}__menu__entry`}
									>
										{localization.menu_roles}
									</NavLink>
								</Menu.Item>
							)}
							{securityProvider.actionGroups.canRead && (
								<Menu.Item
									key = {site.actionGroups.url}
								>
									<NavLink
										to = {site.actionGroups.url}
										className = {`${containerClassName}__menu__entry`}
									>
										{localization.menu_actionGroups}
									</NavLink>
								</Menu.Item>
							)}
						</Menu.SubMenu>
					)}
					<Menu.SubMenu
						{...getSubmenuProps([], currentKey)}
						className = {`${containerClassName}__menu__submenu pull-rigth`}
						key = 'g4'
						icon = {<User className = {`${containerClassName}__menu__title feather-ico`}/>}
						title = {(
							<span
								className = {`${containerClassName}__menu__title`}
							>
								{currentUser.name}
							</span>
						)}
					>
						<Menu.Item
							onClick = {onLogout}
						>
							{localization.logout}
						</Menu.Item>
						<Menu.Item
							onClick = {togglePasswordChangeModal}
						>
							Сменить пароль
						</Menu.Item>
						<Menu.Item
							onClick = {() => {
								setIsModalVisible(true)
							}}
						>
							version: {backendVersion?.version} / {version}
						</Menu.Item>
						{config.app_env !== config.environments.PRODUCTION && (
							<>
								{securityProvider.cache.canClear && (
									<Menu.Item
										onClick = {() => {
											clearCache(token);
										}}
									>
										Очистить кэш
									</Menu.Item>
								)}
								{
									securityProvider.elastic.canSync && (
										<Menu.Item
											onClick = {() => {
												syncElastic(token);
											}}
										>
											Синхронизировать эластик
										</Menu.Item>
									)
								}
							</>
						)}
					</Menu.SubMenu>
				</Menu>
			</div>
			<PasswordChangeModal toggleModal={togglePasswordChangeModal} visible={isPasswordChangeModalVisible}/>
		</>
	)
}

export default withRouter<TopMenuProps & RouteComponentProps, React.FC<TopMenuProps>>(TopMenu);