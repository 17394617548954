import { call, put, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import handleResponse from './handleResponse';

const {loadSuccess, loadFailed} = actions.version;
const {raise} = actions.error;

const tryLoad = (payload: {token: string}): Promise<{status: number, body: any} | {error: any}> => {
    try{
        const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

        return fetch(`${config.api_url}/version`, {
            method: 'GET',
            headers: headers
        }).then(response => {
            return response.json().then(json => ({
                status: response.status,
                body: json
            }))
        });
    }catch(ex){
        return new Promise((resolve) => resolve({error: ex}));
    }
}

const loadVersionSaga = function*(action: any){
    try{
        const response = yield call(tryLoad, action.payload);
        return yield* handleResponse(
            response,
            (body: any) => {
                return loadSuccess(body.data)
            },
            (message: any) => loadFailed(message)
        )
    }catch(ex){
        yield put(raise(`${ex}`));
        console.error((ex as any)?.stack);
        yield put(loadFailed('An error occurred'));
        return;
    }
}

const watch = function*() {
    yield takeLeading(actions.version.types.VERSION_LOAD, loadVersionSaga)
}

export default watch;