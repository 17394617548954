import { Balance } from "../models";

const actionTypes = {
	BALANCES_LOAD: 'BALANCES\\LOAD',
	BALANCES_LOAD_SUCCESS: 'BALANCES\\LOAD_SUCCESS',
	BALANCES_LOAD_FAILED: 'BALANCES\\LOAD_FAILED',
}

const load = (treeId: number, token: string) => ({
	type: actionTypes.BALANCES_LOAD,
	payload: {
		treeId,
		token
	}
})

const loadSuccess = (treeId: number, balances: Balance[]) => ({
	type: actionTypes.BALANCES_LOAD_SUCCESS,
	payload: {
		treeId,
		balances
	}
})

const loadFailed = (treeId: number, message: string) => ({
	type: actionTypes.BALANCES_LOAD_FAILED,
	payload: {
		treeId,
		message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
};