import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";

import actions from "../actions";
import { ActionGroup, Attribute, AttributeVisibility, AttributeValue, AttributeGroup, AssignedUser, User } from "../models";
import { config } from "../settings";
import handleResponse from "./handleResponse";

const {
	loadNameSuccess, loadNameFailed,
	loadActionGroupsSuccess, loadActionGroupsFailed,
	assignActionGroupSuccess, assignActionGroupFailed,
	loadNodeUsersSuccess, loadNodeUsersFailed,
	loadNodeAttributesSuccess, loadNodeAttributesFailed
} = actions.node;

const {raise: raiseError} = actions.error;

type ResponseType = {status: number, body: any} | {body: {error: any}};

type AssignActionGroupPayload = {
	add: boolean,
	userId: number,
	actionGroupId: number,
	nodeId: number,
	token: string
};

const tryAssignActionGroup = (payload: AssignActionGroupPayload): Promise<ResponseType> => {
	try{
		const {add, userId, actionGroupId, nodeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const method = add ? 'POST' : 'DELETE';

		return fetch(`${config.api_url}/nodes/${nodeId}/users/${userId}/action-groups/${actionGroupId}`, {
			method: method,
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({body: {error: ex}}));
	}
}

const tryLoadActionGroups = (payload: {nodeId: number, userId: number, token: string}): Promise<ResponseType> => {
	try{
		const {nodeId, userId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		return fetch(`${config.api_url}/nodes/${nodeId}/users/${userId}/action-groups`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({body: {error: ex}}));
	}
}

const tryLoadUsers = (payload: {nodeId: number, token: string}): Promise<ResponseType> => {
	try{
		const {nodeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/nodes/${nodeId}/users`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({body: {error: ex}}));
	}
}

const tryLoadAttributes = (payload: {nodeId: number, token: string}): Promise<ResponseType> => {
	try{
		const {nodeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		return fetch(`${config.api_url}/nodes/${nodeId}/attributes`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({body: {error: ex}}));
	}
}

const tryLoadName = (payload: {nodeId: number, token: string}): Promise<ResponseType> => {
	try{
		const {nodeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		return fetch(`${config.api_url}/nodes/${nodeId}`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({body: {error: ex}}));
	}
}

const assignActionGroupSaga = function*(action: any){
	const {add, userId, actionGroupId, nodeId, token} = action.payload;
	try{
		const response: ResponseType = yield call(tryAssignActionGroup, action.payload);
		yield* handleResponse(
			response,
			() => assignActionGroupSuccess(add, userId, actionGroupId, nodeId),
			(error: any) => assignActionGroupFailed(add, userId, actionGroupId, nodeId, error),
			function*(){
				yield put(actions.node.loadNodeUsers(nodeId, token))
			}
		);
	}catch(ex){
		yield put(raiseError(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(assignActionGroupFailed(add, userId, actionGroupId, nodeId, 'Couldn\'t assign action group'));
		return;
	}
}


export const extractActionGroups = function*(action: any) {
	const {userId, nodeId, groups} = action.payload
	if (!groups) return;
	const actionGroups = Object.keys(groups)
		.reduce((acc: number[], cur) => [...acc, parseInt(cur)], []);

	let treePermissions: string[] = [];
	for(const actionGroup of Object.values<ActionGroup>(groups)){
		const permissions_slugs = actionGroup.permissions?.map(p => p.slug);
		treePermissions = [...treePermissions, ...(permissions_slugs || [])]
	}

	yield put(loadActionGroupsSuccess(userId, nodeId, actionGroups, treePermissions));
}
export const loadActionGroups = function*(action: any){
	const {userId, nodeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoadActionGroups, action.payload);
		if(response.body.data){
			extractActionGroups(response.body.data)

			const actionGroups = Object.keys(response.body.data)
				.reduce((acc: number[], cur) => [...acc, parseInt(cur)], []);

			const actionGroupIds: number[] = [];
			let treePermissions: string[] = [];
			for(const actionGroup of Object.values<ActionGroup>(response.body.data)){
				actionGroupIds.push(actionGroup.id || -1);
				const permissions_slugs = actionGroup.permissions?.map(p => p.slug);
				treePermissions = [...treePermissions, ...(permissions_slugs || [])]
			}

			yield put(loadActionGroupsSuccess(userId, nodeId, actionGroups, treePermissions));
			return;
		}
		yield loadActionGroupsFailed(userId, nodeId, response?.body?.error);
	}catch(ex){
		yield put(raiseError(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadActionGroupsFailed(userId, nodeId, 'No action groups loaded'));
		return;
	}
}

const extractAssignedUsers = (body: any) => {
	const accInit: AssignedUser[] = [];
	const assignedUsers = Object.entries(body.data).reduce((acc, cur) => {
		const nodeId = Number(cur[0]);
		const users = cur[1] as User[];
		const assignedOnNodeUsers: AssignedUser[] = users.map(u => ({
			...u,
			inheritedFromNodeId: nodeId
		}))
		acc.push(...assignedOnNodeUsers);
		return acc;
	}, accInit)
	return assignedUsers;
}

const loadAssignedUsersSaga = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoadUsers, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadNodeUsersSuccess(nodeId, extractAssignedUsers(body)),
			(error: any) => loadNodeUsersFailed(nodeId, error)
		)
	}catch(ex){
		yield put(raiseError(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadNodeUsersFailed(nodeId, 'No users loaded'));
		return;
	}
}

const extractVisibility = (entry: any): AttributeVisibility => ({
	id: entry.id,
	name: entry.name,
	slug: entry.slug
})

const extractValue = (entry: any): AttributeValue => {
	return {
		id: entry.id,
		name: entry.name,
		isSelected: entry.is_selected,
		propagate: entry.propagate,
		attributeId: entry.attribute_id,
		authorId: entry.author_id,
		nodeId: entry.node_id,
		isBlocked: entry.isBlocked,
		blockedBy: entry.blockedBy,
		visibility: extractVisibility(entry.visibility)
	}
}

const extractGroup = (entry: any): AttributeGroup => {
	return {
		id: entry.id,
		name: entry.name,
		authorId: entry.author_id,
		nodeId: entry.node_id
	}
}

const extractAttribute = (entry: any): Attribute => {
	return {
		id: entry.id,
		name: entry.name,
		authorId: entry.author_id,
		nodeId: entry.node_id,
		values: entry.values.map((v: any) => extractValue(v)),
		visibility: extractVisibility(entry.visibility),
		groups: entry.groups.map((g: any) => extractGroup(g))
	}
}

const loadAttributesSaga = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoadAttributes, action.payload);
		if(response.body.data){
			const attributes = (response.body.data || []).map((a: any) => extractAttribute(a));
			yield put(loadNodeAttributesSuccess(nodeId, attributes));
			return;
		}
		yield put(loadNodeAttributesFailed(nodeId, response?.body?.error));
	}catch(ex){
		yield put(raiseError(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadNodeAttributesFailed(nodeId, 'No attributes loaded'));
		return;
	}
}

const loadNameSaga = function*(action: any){
	const {nodeId} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoadName, action.payload);
		return yield* handleResponse(
			response,
			(body: any) => loadNameSuccess(body.data.name || '', nodeId),
			(error: any) => loadNameFailed(error, nodeId)
		)
	}catch(ex){
		yield put(raiseError(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadNameFailed('Couldn\'t load node name', nodeId));
		return;
	}
}

const watchNode = function*() {
	yield all([
		takeEvery(actions.node.types.NODE_LOAD_NAME, loadNameSaga),
		takeEvery(actions.node.types.NODE_ACTION_GROUP_ASSIGN, assignActionGroupSaga),
		takeEvery(actions.node.types.NODE_LOAD_USERS, loadAssignedUsersSaga),
		takeLeading(actions.node.types.NODE_LOAD_ACTION_GROUPS, loadActionGroups),
		takeLeading(actions.node.types.NODE_UPDATE_ACTION_GROUPS, extractActionGroups),
		takeEvery(actions.node.types.NODE_LOAD_ATTRIBUTES, loadAttributesSaga)
	]);
}

export default watchNode;