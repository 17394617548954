const actionPrefix = 'PASSWORD_CHANGE';

const actionTypes = {
  SAVE: `${actionPrefix}\\SAVE`,
  SAVE_SUCCESS: `${actionPrefix}\\SAVE_SUCCESS`,
  SAVE_FAILED: `${actionPrefix}\\SAVE_FAILED`,
};

const save = (token: string, userId: number, password: string) => ({
  type: actionTypes.SAVE,
  payload: {
    token,
    userId,
    password,
  },
});

const saveSuccess = () => ({
  type: actionTypes.SAVE_SUCCESS,
});

const saveFailed = (message: string) => ({
  type: actionTypes.SAVE_FAILED,
  payload: {
    message: message,
  },
});

export default {
  types: actionTypes,
  save,
  saveSuccess,
  saveFailed,
};
