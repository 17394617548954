import { TransactionFilterValueItem } from "../models";

const actionTypes = {
	TRANSACTION_FILTER_VALUES_LOAD: 'TRANSACTION_FILTER_VALUES\\LOAD',
	TRANSACTION_FILTER_VALUES_LOAD_SUCCESS: 'TRANSACTION_FILTER_VALUES\\LOAD_SUCCESS',
	TRANSACTION_FILTER_VALUES_LOAD_FAILED: 'TRANSACTION_FILTER_VALUES\\LOAD_FAILED',
}

const loadFilter = (treeId: number, filterName: string, token: string) => ({
	type: actionTypes.TRANSACTION_FILTER_VALUES_LOAD,
	payload: {
		treeId,
		filterName,
		token,
	}
})

const loadFilterSuccess = (treeId: number, filterName: string, values: TransactionFilterValueItem[]) => ({
	type: actionTypes.TRANSACTION_FILTER_VALUES_LOAD_SUCCESS,
	payload: {
		treeId,
		filterName,
		values
	}
})

const loadFilterFailed = (treeId: number, filterName: string, message: string) => ({
	type: actionTypes.TRANSACTION_FILTER_VALUES_LOAD_FAILED,
	payload: {
		treeId,
		filterName,
		message,
	}
})

export default {
	types: actionTypes,
	loadFilter,
	loadFilterSuccess,
	loadFilterFailed
};