import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { localization } from '../../settings';
import { Edit, Eye, MoreVertical, Trash } from 'react-feather';

interface EditButtonsSetProps{
	className?: string | null,
	menuClassName?: string | null,
	isEditAvailable?: boolean,
	editUrl?: string,

	isDeleteAvailable?: boolean,

	readOnly?: boolean,

	onDelete?: () => void,
	onEdit?: () => void
}

const containerClassName = 'edit-buttons-set';

const renderMenu = (props: EditButtonsSetProps) => {
	const {
		isEditAvailable, onEdit, editUrl,
		isDeleteAvailable, onDelete,
		readOnly, menuClassName
	} = props;

	const editButtonText = readOnly ? localization.view : localization.edit;
	const effectiveClassName = menuClassName ? `${containerClassName}__menu ${menuClassName}` : `${containerClassName}__menu`;
	return (
		<Menu className = {effectiveClassName}>
			{isEditAvailable && (editUrl || onEdit) && (
				<Menu.Item
					className = {`${containerClassName}__menu__item`}
					icon = {readOnly ? (
						<Eye className = 'feather-ico' />): (<Edit className = {`feather-ico`} />
					)}
					onClick = {onEdit}
				>
					{editUrl ? (
						<NavLink to = {editUrl}>
							{editButtonText}
						</NavLink>
					): editButtonText}
				</Menu.Item>
			)}
			{isDeleteAvailable && !readOnly && (
				<Menu.Item
					className = {`${containerClassName}__menu__item`}
					danger
					icon = {<Trash className = 'feather-ico' />}
					onClick={onDelete}
				>
					{localization.delete}
				</Menu.Item>
			)}
		</Menu>
	)
}

const EditButtonsSet = (props: EditButtonsSetProps) => {
	const { className } = props;
	const effectiveClassName = className ? `${containerClassName} ${className}`: containerClassName;

	return (
		<div className = {effectiveClassName}>
			<Dropdown overlay = {renderMenu(props)} trigger={['click']}>
				<MoreVertical className = 'feather-ico' />
			</Dropdown>
		</div>
	)
}

export default memo(EditButtonsSet);