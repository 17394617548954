const actionTypes = {
	BALANCE_FORMULA_LOAD: 'BALANCE_FORMULA\\LOAD',
	BALANCE_FORMULA_LOAD_SUCCESS: 'BALANCE_FORMULA\\LOAD_SUCCESS',
	BALANCE_FORMULA_LOAD_FAILED: 'BALANCE_FORMULA\\LOAD_FAILED',

	BALANCE_FORMULA_SET: 'BALANCE_FORMULA\\SET',
	BALANCE_FORMULA_SET_SUCCESS: 'BALANCE_FORMULA\\SET_SUCCESS',
	BALANCE_FORMULA_SET_FAILED: 'BALANCE_FORMULA\\SET_FAILED',
}

const load = (treeId: number, balanceId: number, token: string) => ({
	type: actionTypes.BALANCE_FORMULA_LOAD,
	payload: {
		balanceId,
		treeId,
		token
	}
});

const loadSuccess = (treeId: number, balanceId: number, formula: string) => ({
	type: actionTypes.BALANCE_FORMULA_LOAD_SUCCESS,
	payload: {
		balanceId,
		treeId,
		formula
	}
});

const loadFailed = (treeId: number, balanceId: number, message: string) => ({
	type: actionTypes.BALANCE_FORMULA_SET_FAILED,
	payload: {
		message,
		treeId,
		balanceId
	}
})

const set = (treeId: number, balanceId: number, formula: string, token: string) => ({
	type: actionTypes.BALANCE_FORMULA_SET,
	payload: {
		balanceId,
		treeId,
		formula,
		token
	}
});

const setSuccess = (treeId: number, balanceId: number) => ({
	type: actionTypes.BALANCE_FORMULA_SET_SUCCESS,
	payload: {
		balanceId,
		treeId
	}
});

const setFailed = (message: string, treeId: number, balanceId: number) => ({
	type: actionTypes.BALANCE_FORMULA_SET_FAILED,
	payload: {
		message,
		treeId,
		balanceId
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	set,
	setSuccess,
	setFailed,
};