import { all, call, put, takeLatest } from 'redux-saga/effects';

import { site, config } from '../settings';
import actions from '../actions';
import { User } from '../models';
import history from '../history';

import handleResponse from './handleResponse';
import { notification } from 'antd';

type DeleteRequestPayload = { id: string; token: string };
type PostRequestPayload = {
  user: User;
  prevRoleIds?: string[];
  nextRoleIds?: string[];
  token: string;
};
type ResponsePromise = Promise<{ status: number; body: any } | { error: any }>;

const { requestSuccess, requestFailed, types } = actions.user;
const { raise } = actions.error;

const deleteUser = (payload: DeleteRequestPayload): ResponsePromise => {
  try {
    const { id } = payload;
    const headers = new Headers([['Authorization', `Bearer ${payload.token}`]]);

    return fetch(`${config.api_url}/users/${id}`, {
      method: 'DELETE',
      headers: headers,
    }).then((response) => {
      return response.json().then((json) => ({
        status: response.status,
        body: json,
      }));
    });
  } catch (ex) {
    return new Promise((resolve) => resolve({ error: ex }));
  }
};

const postUser = (payload: PostRequestPayload): ResponsePromise => {
  try {
    const { user } = payload;
    const headers = new Headers([['Authorization', `Bearer ${payload.token}`]]);
    const formData = new FormData();
    if (user.name) {
      formData.append('name', user.name);
    }
    if (user.firstname) {
      formData.append('firstname', user.firstname);
    }
    if (user.lastname) {
      formData.append('lastname', user.lastname);
    }
    if (user.email) {
      formData.append('email', user.email);
    }
    if (user.password) {
      formData.append('password', user.password);
    }

    const url =
      user.id == null
        ? `${config.api_url}/users`
        : `${config.api_url}/users/${user.id}`;

    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: formData,
    }).then((response) => {
      return response.json().then((json) => ({
        status: response.status,
        body: json,
      }));
    });
  } catch (ex) {
    return new Promise((resolve) => resolve({ error: ex }));
  }
};

const addDeleteRole = (
  isAdd: boolean,
  userId: string,
  roleId: string,
  token: string,
): ResponsePromise => {
  try {
    const headers = new Headers([['Authorization', `Bearer ${token}`]]);
    return fetch(`${config.api_url}/users/${userId}/roles/${roleId}`, {
      method: isAdd ? 'POST' : 'DELETE',
      headers: headers,
    }).then((response) => {
      return response.json().then((json) => ({
        status: response.status,
        body: json,
      }));
    });
  } catch (ex) {
    return new Promise((resolve) => resolve({ error: ex }));
  }
};

const getUpdateUserRolesSaga = (action: any) =>
  function* (postResponse: any) {
    const { user, token, prevRoleIds, nextRoleIds } = action.payload;
    const { id } = postResponse;
    const userId = id ? id : user.id;
    const toAdd = (nextRoleIds || []).filter(
      (r: string) => !prevRoleIds.includes(r),
    );
    const addRequests = toAdd.map((roleId: string) =>
      call(addDeleteRole, true, userId, roleId, token),
    );
    yield all(addRequests);
    const toRemove = (prevRoleIds || []).filter(
      (r: string) => !nextRoleIds.includes(r),
    );
    const removeRequests = toRemove.map((roleId: string) =>
      call(addDeleteRole, false, userId, roleId, token),
    );
    yield all(removeRequests);
    yield call(history.push, site.users.url);
  };

const createUpdateUserSaga = function* (action: any) {
  try {
    const { id } = action.payload;
    const response: ResponseType = yield call(postUser, action.payload);
    yield* handleResponse(
      response,
      () => {
        notification.success({
          message:
            action.type === types.USER_CREATE
              ? 'Профиль создан!'
              : 'Профиль изменён!',
        });
        return requestSuccess(id);
      },
      (message: string) => requestFailed(id, message),
      getUpdateUserRolesSaga(action),
    );
  } catch (ex) {
    yield put(raise(`${ex}`));
    console.error((ex as any)?.stack);
    yield put(requestFailed(-1, 'An error occurred'));
    return;
  }
};

const deleteUserSaga = function* (action: any) {
  try {
    const { id } = action.payload;
    const response: ResponseType = yield call(deleteUser, action.payload);
    yield* handleResponse(
      response,
      () => {
        notification.success({
          message: 'Профиль удалён!',
        });
        return requestSuccess(id);
      },
      (message: string) => requestFailed(id, message),
    );
  } catch (ex) {
    yield put(raise(`${ex}`));
    console.error((ex as any)?.stack);
    yield put(requestFailed(-1, 'An error occurred'));
    return;
  }
};

const watchUser = function* () {
  yield all([
    takeLatest(
      [actions.user.types.USER_CREATE, actions.user.types.USER_UPDATE],
      createUpdateUserSaga,
    ),
    takeLatest(actions.user.types.USER_DELETE, deleteUserSaga),
  ]);
};

export default watchUser;
