import { call, put, takeLeading } from 'redux-saga/effects';
import actions from '../actions';
import { config } from '../settings';
import handleResponse from './handleResponse';
import { notification } from 'antd';

const { saveSuccess, saveFailed } = actions.passwordChange;
const { raise } = actions.error;

type PasswordChangeResponseType =
  | { status: number; body: any }
  | { error: any };

type ResponsePromiseType = Promise<PasswordChangeResponseType>;

const tryPostNewPassword = (payload: {
  password: string;
  token: string;
  userId: number;
}): ResponsePromiseType => {
  try {
    const { password, token, userId } = payload;
    const headers = new Headers([['Authorization', `Bearer ${token}`]]);

    const formData = new FormData();

    formData.append('password', password);

    const url = `${config.api_url}/users/${userId}/password`;
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: formData,
    }).then((response) => {
      return response.json().then((json) => ({
        status: response.status,
        body: json,
      }));
    });
  } catch (ex) {
    return new Promise((resolve) => resolve({ error: ex }));
  }
};

const passwordChangeSaga = function* (action: any) {
  try {
    const response: ResponseType = yield call(
      tryPostNewPassword,
      action.payload,
    );
    yield* handleResponse(
      response,
      () => {
        notification.success({
          message: 'Пароль изменён!',
        });
        return saveSuccess();
      },
      (error: any) => saveFailed(error),
    );
  } catch (ex) {
    yield put(raise(`${ex}`));
    console.error((ex as any)?.stack);
    yield put(saveFailed('An error occurred'));
    return;
  }
};

const watch = function* () {
  yield takeLeading(actions.passwordChange.types.SAVE, passwordChangeSaga);
};

export default watch;
