import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ChevronDown, ChevronUp, Clock, FileText, GitPullRequest, LogOut, Search, Settings, User, Users } from 'react-feather';

import {localization, SecurityProvider, site} from '../../settings';
import { UserAccount } from '../../models';
import {version} from '../../../package.json';

import MenuEntry from './MenuEntry';
import SubMenu from './SubMenu';

interface SideMenuProps extends RouteComponentProps {
	currentUser: UserAccount,
	securityProvider: SecurityProvider,

	onLogout: () => void
}

const SideMenu = (props: SideMenuProps) => {
	const {currentUser, securityProvider, onLogout} = props;
	const [profileExpanded, setProfileExpanded] = useState(false);
	const {pathname} = props.location;
	const levels = pathname.split('/');
	const currentKey = `/${levels[1]}`;

	const containerClassName = "sider-menu";

	return (
		<div className = {containerClassName}>
			<div className = {`${containerClassName}__section`}>
				<MenuEntry
					className = {`${containerClassName}__title`}
					icon = {<User/>}
					altIcon = {profileExpanded ? <ChevronDown/> : <ChevronUp/>}
					onClick = {() => setProfileExpanded(!profileExpanded)}
					nonInteractive
				>
					{`${currentUser.firstname} ${currentUser.lastname}`}
				</MenuEntry>
				<SubMenu
					className = {`${containerClassName}__submenu`}
					expanded = {profileExpanded}
				>
					<MenuEntry icon={<Settings/>} disabled size = "small">
						{localization.menu_settings}
					</MenuEntry>
					<MenuEntry icon={<LogOut/>} onClick = {onLogout} size = "small">
						{localization.logout}
					</MenuEntry>
				</SubMenu>
				<MenuEntry disabled size="small" icon={<Search/>}>
					{localization.menu_search}
				</MenuEntry>
				<MenuEntry disabled size="small" icon={<Clock/>}>
					{localization.menu_updates}
				</MenuEntry>
			</div>
			<div className = {`${containerClassName}__section`}>
				<MenuEntry
					className = {`${containerClassName}__title`}
					icon = {<GitPullRequest/>}
					selected = {currentKey === site.trees.url}
					link = {site.trees.url}
				>
					{localization.menu_trees}
				</MenuEntry>
				{securityProvider.attributeGroups.canRead && (
					<MenuEntry
						selected = {currentKey === site.attributeGroups.url}
						link = {site.attributeGroups.url}
					>
						{localization.menu_attributeGroups}
					</MenuEntry>
				)}
				{securityProvider.balances.canRead && (
					<MenuEntry
						selected = {currentKey === site.balances.url}
						link = {site.balances.url}
					>
						{localization.menu_balances}
					</MenuEntry>
				)}
			</div>
			<div className = {`${containerClassName}__section`}>
				<MenuEntry disabled className = {`${containerClassName}__title`} icon={<FileText/>}>
					{localization.menu_reports}
				</MenuEntry>
			</div>
			<div className = {`${containerClassName}__section`}>
				<MenuEntry
					disabled = {!securityProvider.users.canRead}
					className = {`${containerClassName}__title`}
					icon = {<Users/>}
					selected = {currentKey === site.users.url}
					link = {site.users.url}
				>
					{localization.menu_users}
				</MenuEntry>
				<MenuEntry
					disabled = {!securityProvider.roles.canRead}
					selected = {currentKey === site.roles.url}
					link = {site.roles.url}
				>
					{localization.menu_roles}
				</MenuEntry>
				<MenuEntry
					disabled = {!securityProvider.actionGroups.canRead}
					selected = {currentKey === site.actionGroups.url}
					link = {site.actionGroups.url}
				>
					{localization.menu_actionGroups}
				</MenuEntry>
			</div>
			<div className={`${containerClassName}__footer`}>
				version: {version}
			</div>
		</div>
	);
}

export default withRouter<SideMenuProps & RouteComponentProps, React.FC<SideMenuProps>>(SideMenu);