import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { PlusCircle } from 'react-feather';

import { Balance, BalanceAttribute, BalanceAttributeInfo, BalanceAttributeType, BalanceInfo, BalanceLimit, BalanceType, EntityHolder, EntityPerBalance, TreeListEntry, UserAccount } from '../../models';
import { localization, SecurityProvider, slugs } from '../../settings';
import { Button, ConfirmDelete, DndTable, EditButtonsSet } from '../../components';
import orderForBackend from '../../tools/dndOrder';
import BalanceDialog from '../BalanceDialog';

interface KeyedBalance extends Balance{
	key: number,
}

interface BalancesListProps{
	className?: string | null;
	treeInfo: TreeListEntry | null,
	balances?: EntityHolder<Balance> | null,
	balanceTypes: BalanceType[] | null,
	isBalanceTypeLoading: boolean,
	balanceLimits: BalanceLimit[] | null,
	isBalanceLimitsLoading: boolean,
	balanceAttributes: EntityPerBalance<BalanceAttribute>,
	balanceAttributeTypes: EntityHolder<BalanceAttributeType>,

	token: string,
	currentUser: UserAccount,
	securityProvider: SecurityProvider,

	loadBalances: (treeId: number, token: string) => void,
	loadBalanceTypes: (token: string) => void,
	loadBalanceLimits: (token: string) => void,
	loadBalanceAttributes: (balanceId: number, treeId: number, token: string) => void,
	loadBalanceAttributeTypes: (token: string) => void,
	createBalance: (name: string, typeId: number, creditLimitId: number | null, limit: number | null, treeId: number, token: string) => void,
	updateBalance: (balanceId: number, balance: {name?: string, creditLimitId?: number, limit?: number | null}, treeId: number, token: string) => void,
	deleteBalance: (balanceId: number, treeId: number, token: string) => void,
	reorderBalances: (orderedIds: {id: number, order: number}[], treeId: number, token: string) => void,
	createBalanceAttribute: (info: BalanceAttributeInfo, balanceId: number, treeId: number, token: string) => void,
	updateBalanceAttribute: (info: BalanceAttributeInfo, balanceId: number, treeId: number, token: string) => void,
	deleteBalanceAttribute: (balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void,
	orderBalanceAttributes: (idsWithOrder: {id: number, order: number}[], balanceId: number, treeId: number, token: string) => void,
	createBalanceAttributeValue: (name: string, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void,
	deleteBalanceAttributeValue: (id: number, balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void,
	orderBalanceAttributeValues: (orderedIds: {id: number, order: number}[], balanceAttributeId: number, balanceId: number, treeId: number, token: string) => void,
	resetTree: (id: number) => void,
}

interface BalancesListState{
	isEditingDialogShown: boolean,
	editingValue: Balance | null,
}

const defaultState: BalancesListState = {
	isEditingDialogShown: false,
	editingValue: null
}

const containerClassName = 'balances-list';

class BalancesList extends React.Component<BalancesListProps, BalancesListState>{
	constructor(props: BalancesListProps){
		super(props);

		this.state = defaultState;
	
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			treeInfo, token,
			balanceTypes, isBalanceTypeLoading, loadBalanceTypes,
			balanceLimits, isBalanceLimitsLoading, loadBalanceLimits,
			balances, loadBalances,
			balanceAttributes, loadBalanceAttributes,
			balanceAttributeTypes, loadBalanceAttributeTypes
		} = this.props;

		const {editingValue} = this.state;

		// if any tree is selected
		if(treeInfo?.id){
			if(!balances || (!balances.list && !balances.isLoading)){
				treeInfo?.id && loadBalances(treeInfo.id, token)
			}
			if(!balanceTypes && !isBalanceTypeLoading){
				loadBalanceTypes(token);
			}
			if(!balanceLimits && !isBalanceLimitsLoading){
				loadBalanceLimits(token);
			}

			// if any existing balance is edited and it is not calculated
			// load attributes!
			if(balanceTypes && editingValue){
				const balanceTypeSlug = balanceTypes.find(bt => bt.id === editingValue.typeId)?.slug;
				if(balanceTypeSlug !== slugs.balance_types.calculate){
					const battributes = balanceAttributes[editingValue.id];
					// load balance attributes if neccessary
					if(!battributes || (!battributes.list && !battributes.isLoading)){
						loadBalanceAttributes(editingValue.id, treeInfo.id, token);
					}
					if(!balanceAttributeTypes.list && !balanceAttributeTypes.isLoading){
						loadBalanceAttributeTypes(token);
					}
				}
			}
		}
	}

	getOrderedBalances = () => {
		const {balances} = this.props;
		const unfoldedBalances = balances?.list || [];
		return unfoldedBalances.sort((b1, b2) => b1.order - b2.order);
	}

	onNewBalance = () => {
		this.setState((state) => ({
			...state,
			editingValue: null,
			isEditingDialogShown: true
		}))
	}

	getOnEditHandler = (balance: KeyedBalance) => () => {
		this.setState((state) => ({
			...state,
			editingValue: balance,
			isEditingDialogShown: true,
		}))
	}

	onEditingDialogClose = () => {
		this.setState((state) => ({
			...state,
			editingValue: null,
			isEditingDialogShown: false
		}))
	}

	getOnDeleteHandler = (balanceId: number) => () => {
		const {treeInfo, token, deleteBalance, resetTree} = this.props;
		ConfirmDelete({
			title: localization.delete_balance_title,
			content: localization.delete_balance_content,
			deleteEntity: () => {
				if(treeInfo?.id){
					deleteBalance(balanceId, treeInfo?.id, token)
					resetTree(treeInfo?.id)
				}
			}
		})
	}

	reorderBalances = (_: Balance, initialIndex: number, finalIndex: number) => {
		const {
			treeInfo, token,
			reorderBalances, resetTree
		} = this.props;
		const originalList = this.getOrderedBalances() || [];
		const orderedBalanceAttributeIds = orderForBackend(originalList, initialIndex, finalIndex);
		if(treeInfo?.id){
			reorderBalances(orderedBalanceAttributeIds, treeInfo.id, token);
			resetTree(treeInfo.id)
		}
	}

	loadBalanceAttributes = (balanceId: number) => {
		const {
			treeInfo,
			token,
			loadBalanceAttributes
		} = this.props;
		if(treeInfo && treeInfo.id){
			loadBalanceAttributes(balanceId, treeInfo.id, token);
		}
	}

	createBalanceAttribute = (value: BalanceAttributeInfo) => {
		const {
			treeInfo,
			token,
			createBalanceAttribute
		} = this.props;
		const {editingValue} = this.state;

		treeInfo?.id && editingValue?.id && createBalanceAttribute(value, editingValue.id, treeInfo.id, token);
	}

	updateBalanceAttribute = (id: number, value: BalanceAttributeInfo) => {
		const {
			treeInfo,
			token,
			updateBalanceAttribute
		} = this.props;
		const {editingValue} = this.state;

		treeInfo?.id && editingValue?.id && updateBalanceAttribute(value, editingValue.id, treeInfo.id, token);
	}

	deleteBalanceAttribute = (id: number) => {
		const {
			treeInfo, token,
			deleteBalanceAttribute
		} = this.props;
		const {editingValue} = this.state;

		treeInfo?.id && editingValue?.id && deleteBalanceAttribute(id, editingValue.id, treeInfo.id, token);
	}

	orderBalanceAttributes = (idsWithOrder: {
		id: number;
		order: number;
	}[]) => {
		const {
			treeInfo, token,
			orderBalanceAttributes
		} = this.props;
		const {editingValue} = this.state;

		treeInfo?.id && editingValue?.id && orderBalanceAttributes(idsWithOrder, editingValue.id, treeInfo.id, token);
	}

	orderBalanceAttributeValues = (idsWithOrder: {id: number, order: number}[], balanceAttributeId: number) => {
		const {
			treeInfo, token,
			orderBalanceAttributeValues
		} = this.props;
		const {editingValue} = this.state;

		treeInfo?.id && editingValue?.id && orderBalanceAttributeValues(idsWithOrder, balanceAttributeId, editingValue.id, treeInfo.id, token);
	}

	createBalanceAttributeValue = (name: string, balanceAttributeId: number) => {
		const {
			treeInfo, token,
			createBalanceAttributeValue
		} = this.props;
		const {editingValue} = this.state;

		editingValue?.id && treeInfo?.id && createBalanceAttributeValue(name, balanceAttributeId, editingValue.id, treeInfo.id, token);
	}

	deleteBalanceAttributeValue = (id: number, balanceAttributeId: number) => {
		const {
			treeInfo, token,
			deleteBalanceAttributeValue
		} = this.props;
		const {editingValue} = this.state;

		editingValue?.id && treeInfo?.id && deleteBalanceAttributeValue(id, balanceAttributeId, editingValue.id, treeInfo.id, token);
	}

	getColumns = () => {
		const {balanceTypes, balanceLimits} = this.props;
		const getSorterFor = (fieldName: string) => (a:any, b:any) => {
			return (a[fieldName] || '').localeCompare(b[fieldName] || '');
		};
		const getNumericSorterFor = (fieldName: string) => (a: any, b: any) => {
			return a[fieldName] - b[fieldName];
		}
		const columns: ColumnsType<KeyedBalance> = [
			{
				title: localization.balances_nameColumn,
				dataIndex: 'name',
				sorter: getSorterFor('name'),
				showSorterTooltip: false
			},{
				title: localization.balance_balanceTypeColumn,
				dataIndex: 'typeId',
				sorter: getNumericSorterFor('typeId'),
				showSorterTooltip: false,
				render: (value) => {
					return balanceTypes?.find(bt => bt.id === value)?.name;
				}
			},{
				title: localization.balance_balanceLimitColumn,
				dataIndex: 'creditLimitId',
				sorter: getNumericSorterFor('creditLimitId'),
				showSorterTooltip: false,
				render: (value, record) => {
					const typeSlug = balanceTypes?.find(bt => bt.id === record.typeId)?.slug;
					if(typeSlug === slugs.balance_types.calculate){
						return null;
					}
					const selectedLimitType = balanceLimits?.find(bl => bl.id === value);
					if(selectedLimitType?.slug === slugs.balance_limit.limited){
						return record.limit;
					}
					return selectedLimitType?.name;
				}
			},{
				title: null,
				render: (i: KeyedBalance) => (
					<EditButtonsSet
						onEdit = {this.getOnEditHandler(i)}
						isEditAvailable = {true}
						onDelete = {this.getOnDeleteHandler(i.id)}
						isDeleteAvailable = {true}
					/>
				)
			}
		];
		return columns;
	}

	getData = (): KeyedBalance[] => {
		return this.getOrderedBalances()
			.map(g => ({
				...g,
				key: g.id
			}));
	}

	onSaveBalance = (balanceInfo: BalanceInfo) => {
		const {
			treeInfo, token, balanceTypes,
			createBalance, updateBalance, resetTree
		} = this.props;
		const {id, name, typeId, creditLimitId, limit} = balanceInfo;
		const isCalculatedType = balanceTypes?.find(t => t.id === typeId)?.slug === slugs.balance_types.calculate;
		if(treeInfo?.id){
			if(id){
				const changes = isCalculatedType ? {
					name
				} : {
					name, creditLimitId, limit
				}
				updateBalance(id, changes, treeInfo.id, token);
				resetTree(treeInfo.id)
			} else {
				createBalance(
					name,
					typeId,
					isCalculatedType ? null : creditLimitId,
					isCalculatedType ? null : limit,
					treeInfo.id,
					token
				);
				resetTree(treeInfo.id)
			}
		}
	}

	render(){
		const {
			className,
			treeInfo, currentUser, securityProvider,
			balances, balanceLimits, balanceTypes,
			balanceAttributes, balanceAttributeTypes,
			
		} = this.props;
		const {editingValue, isEditingDialogShown} = this.state;

		if(!treeInfo){
			return null;
		}

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		
		return (
			<div className = {effectiveClassName}>
				<div className = {`${containerClassName}__title`}>
					<span className = {`${containerClassName}__title__text`}>
						{localization.balances_balancesTitle}
						&nbsp;
					</span>
					<span className = {`${containerClassName}__title__tree-name`}>
						{treeInfo.name}
					</span>

					<Button
						type = 'primary'
						icon = {<PlusCircle className = 'feather-ico'/>}
						className = {`${containerClassName}__title__new-button`}
						onClick = {this.onNewBalance}
					>
						{localization.balances_newBalanceButton}
					</Button>
				</div>
				<DndTable<KeyedBalance>
					columns = {this.getColumns()}
					dataSource = {this.getData()}
					onMoveItem = {this.reorderBalances}
					loading = {balances ? balances?.isLoading : true}
					className = {`${containerClassName}__table`}
				/>
				<BalanceDialog
					key = {editingValue?.id}
					visible = {isEditingDialogShown}
					title = {editingValue ? editingValue.name : localization.balance_editBalance_newTitle}
					onClose = {this.onEditingDialogClose}
					onSave = {this.onSaveBalance}

					treeId = {treeInfo.id}
					currentUser = {currentUser}
					securityProvider = {securityProvider}

					initialValue = {editingValue}
					balances = {balances?.list || null}
					balanceAttributes = {balanceAttributes}
					balanceAttributeTypes = {balanceAttributeTypes.list}
					balanceTypes = {balanceTypes}
					balanceLimits = {balanceLimits}
					loadBalanceAttributes = {this.loadBalanceAttributes}
					createBalanceAttribute = {this.createBalanceAttribute}
					updateBalanceAttribute = {this.updateBalanceAttribute}
					deleteBalanceAttribute = {this.deleteBalanceAttribute}
					reorderBalanceAttributes = {this.orderBalanceAttributes}
					createBalanceAttributeValue = {this.createBalanceAttributeValue}
					deleteBalanceAttributeValue = {this.deleteBalanceAttributeValue}
					reorderBalanceAttributeValues = {this.orderBalanceAttributeValues}
				/>
			</div>
		);
	}
}

export default BalancesList;