import actions from "../actions";

import {AttributeVisibility} from '../models'

export interface AttributeVisibilityState{
    visibilities: AttributeVisibility[] | null,
    error: string  | null,
    isLoading: boolean
}

const defaultState: AttributeVisibilityState = {
    visibilities: null,
    error: null,
    isLoading: false
}

const account = (state: AttributeVisibilityState = defaultState, action: any): AttributeVisibilityState => {
    switch(action.type){
        case actions.attributeVisibilities.types.ATTRIBUTE_VISIBILITIES_LOAD: {
            return {
                ...state,
                visibilities: null,
                error: null,
                isLoading: true
            }
        }
        case actions.attributeVisibilities.types.ATTRIBUTE_VISIBILITIES_LOAD_SUCCESS: {
            const {visibilities} = action.payload;
            return {
                ...state,
                visibilities,
                isLoading: false,
                error: null
            }
        }
        case actions.attributeVisibilities.types.ATTRIBUTE_VISIBILITIES_LOAD_FAILED: {
            console.error('ATTRIBUTE_VISIBILITIES_LOAD_FAILED\n%o', action.payload);
            const {message} = action.payload;
            return {
                ...state,
                visibilities: [],
                isLoading: false,
                error: message
            }
        }
    }
    return state;
}

export default account;