import { Moment } from 'moment';
import { call, put, takeLatest} from 'redux-saga/effects';

import actions from '../actions';
import {report} from '../models';
import { config } from '../settings';

import handleResponse from './handleResponse';
import defaultFields from './report.defaultFields';

const {loadSuccess, loadFailed} = actions.report;
const {raise} = actions.error;

type ResponseType = {status: number, body: any} | {error: any};

const tryLoadFields = (payload: {nodeId: number, date: [Moment, Moment], dateType: report.ReportDateType, token: string}): Promise<ResponseType> => {
	try{
		const {date, dateType, nodeId} = payload;
		const headers = new Headers([
			["Authorization", `Bearer ${payload.token}`],
			['content-type', 'application/json']
		]);
		const startDateComponent = encodeURIComponent(date[0].format('YYYY-MM-DD'));
		const endDateComponent = encodeURIComponent(date[1].format('YYYY-MM-DD'))
		const fieldComponent = encodeURIComponent(dateType);
		const params = `startDate=${startDateComponent}&endDate=${endDateComponent}&dateType=${fieldComponent}`;
		const url = `${config.api_url}/reports/${nodeId}/fields?${params}`;
		return fetch(url, {
			method: 'GET',
			headers: headers,
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}).catch((reason) => {
			return {
				error: `${reason}`
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

type ResponseAttributeTargetType = 'node' | 'correspondent';
const responseAttributeTargets: ResponseAttributeTargetType[] = ['node', 'correspondent']
type ResponseAttributeType = {
	id: number,
	name: string,
	relates: ResponseAttributeTargetType[]
};

const extractFields = (body: any, nodeId: number, date: [Moment, Moment], dateType: report.ReportDateType) => {
	const balances: report.Field[] = ((body?.data?.balances ||[]) as {id: number, name: string}[])
		.map(({id, name}): report.Field => ({
			id: `balance.${id}`,
			name,
			type: 'balance',
		}));

	const calculatedBalances: report.Field[] = ((body?.data?.calculateBalances ||[]) as {id: number, name: string}[])
		.map(({id, name}): report.Field => ({
			id: `calculatedBalance.${id}`,
			name,
			type: 'calculatedBalance',
		}));

	const nodeAttributes: report.Field[] = ((body?.data?.nodeAttributes ||[]) as ResponseAttributeType[])
		.reduce((acc, cur) => {
			responseAttributeTargets.forEach(target => {
				if(cur.relates.includes(target)){
					acc.push({
						id: `nodeAttributes.${target}.${cur.id}`,
						originalId: cur.id,
						name: cur.name,
						type: 'nodeAttribute',
						relates: target
					})
				}
			})
			return acc;
		}, [] as report.Field[]);

	const balanceAttributes: report.Field[] = ((body?.data?.balanceAttributes ||[]) as (ResponseAttributeType & {type: string})[])
		.reduce((acc, cur) => {
			responseAttributeTargets.forEach(target => {
				if(cur.relates.includes(target)){
					acc.push({
						id: `balanceAttributes.${target}.${cur.id}.${cur.type}`,
						originalId: cur.id,
						name: cur.name,
						type: 'balanceAttribute',
						relates: target
					})
				}
			})
			return acc;
		}, [] as report.Field[]);
	
	return loadSuccess(nodeId, date, dateType, [
		...defaultFields,
		...balances,
		...calculatedBalances,
		...balanceAttributes,
		...nodeAttributes
	]);
}

const loadReport = function*(action: any){
	const {nodeId, date, dateType} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoadFields, action.payload);
		yield* handleResponse(
			response,
			(body: any) => extractFields(body, nodeId, date, dateType),
			(message: string) => loadFailed(nodeId, date, dateType, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(nodeId, date, dateType, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield takeLatest(actions.report.types.REPORT_LOAD, loadReport)
}

export default watch;