import React, { memo } from 'react';
import { Select, Form } from 'antd';

import { BalanceAttribute } from '../../../models';
import { errors } from '../../../settings';

interface SelectBalanceAttributeProps{
	className?: string,
	path: string[],

	balanceAttribute: BalanceAttribute
}

const containerClassName = 'select-balance-attribute';

const SelectBalanceAttribute = (props: SelectBalanceAttributeProps) => {
	const {className, balanceAttribute, path} = props;

	const options: {label: string, value: number}[] = balanceAttribute.values
		.sort((a, b) => a.order - b.order)
		.map(v => ({
			value: v.id,
			label: v.name
		}));

	const rules = balanceAttribute.required ? [{required: true, message: errors.fields_is_required}] : []

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<Form.Item
			name = {[...path, `${balanceAttribute.id}`]}
			label = {balanceAttribute.name}
			rules = {rules}
		>

			<Select
				className = {effectiveClassName}
				options = {options}
			/>
		</Form.Item>
	)
}

export default memo(SelectBalanceAttribute);