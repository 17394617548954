import React, { ReactText } from 'react';
import { Filter } from 'react-feather';
import { Skeleton} from 'antd';
import { ColumnType } from 'antd/lib/table';
import scrollIntoView from 'scroll-into-view';

import { Balance,  Tree } from '../../models';
import { localization, TreeSecurityProvider } from '../../settings';
import { TableHeader, SplitTable, Filters } from '../../components';

import RowContextMenu from './RowContextMenu';
import HeaderContextMenu from './HeaderContextMenu';
import search from './search';
import { formatters } from '../../tools';

type TreeColumnType = ColumnType<Tree> & {id: number}
const isTreeColumnType = (col: ColumnType<Tree>): col is TreeColumnType => {
	return 'id' in col;
}

type HighlightedCell = {nodeId: React.ReactText, balanceId: React.ReactText}

interface TreeViewProps{
	className?:  string | null,
	tree: Tree | null,
	balances: Balance[] | null,
	token: string,
	selectedRowId: React.ReactText | null,
	selectedBalanceId: React.ReactText | null,
	fixedRowIds?: React.ReactText[],
	expandedRows: React.ReactText[],

	highlightedCells?: HighlightedCell[] | null,
	availableBalanceTypeIds?: number[] | null,

	treeSecurityProvider: TreeSecurityProvider,

	toggleFixRows: () => void,
	setExpandedRows: (keys: React.ReactText[]) => void,

	onEntryClick: (rowId: React.ReactText, balance?:  React.ReactText) => void;
}

interface TreeViewState{
	fixedColumnIds: number[],
	rowContextMenu: {
		nodeIds: ReactText[],
		balanceId?: number,
		x: number,
		y: number
	},
	headerContextMenu: {
		columnIdOrHidden: number | null,
		x: number,
		y: number
	},
	filterText: string,
}

const defaultState: TreeViewState = {
	fixedColumnIds: [],
	rowContextMenu: {
		nodeIds: [],
		x: 0,
		y: 0
	},
	headerContextMenu: {
		columnIdOrHidden: null,
		x: 0,
		y: 0
	},
	filterText: '',
}

const containerClassName = "tree-view";

const renderEmptyCell = () => {
  const className = `${containerClassName}__table__cell-content`;
  return (
    <div className={`${className} ${className}--balance`}>
      <span title="—">—</span>
    </div>
  );
};

class TreeView extends React.Component<TreeViewProps, TreeViewState>{
	constructor(props: TreeViewProps){
		super(props);

		this.state = {...defaultState};
	}

	onHeaderCell = (col: ColumnType<Tree>): React.HTMLAttributes<HTMLElement> => {
		const result: React.HTMLAttributes<HTMLElement> = {};
		if(isTreeColumnType(col)){
			result.onContextMenu = this.getHeaderContextMenuHandler(col.id);
			result.className = 'header-cell-container'
		}
    return result;
	}

	getSearchedNameTextElement = (value: string, search: string) => {
		const beginOfSubstring = value.toLowerCase().indexOf(search.toLowerCase());
		const textBefore = value.substring(0, beginOfSubstring);
		const searchedText = value.substring(beginOfSubstring, beginOfSubstring + search.length)
		const textAfter = value.substring(beginOfSubstring + search.length);
		return (
			<span className = {`${containerClassName}__table__cell-content__searched`}>
				<span className = {`${containerClassName}__table__cell-content__searched__before`}>
					{textBefore}
				</span>
				<span className = {`${containerClassName}__table__cell-content__searched__text`}>
					{searchedText}
				</span>
				<span className = {`${containerClassName}__table__cell-content__searched__after`}>
					{textAfter}
				</span>
			</span>
		)
	}

	getRenderNameCell = (isFixed?: boolean) => (value: any, record: Tree, index: number) => {
		const {
			selectedRowId
		} = this.props;

		const {filterText} = this.state;

		let effectiveClassName = `${containerClassName}__table__cell-content`;
		if(!isFixed && selectedRowId === record.id){
			effectiveClassName += ' highlight'
		}

		let visibleValue = value;

		if(filterText !== '' && value.indexOf(filterText) > -1){
			visibleValue =
				this.getSearchedNameTextElement(value, filterText)
		}

		return (
			<div className = {effectiveClassName} data-id = {record.id} onClick = {!isFixed ? this.getOnCellClickHandler(record) : undefined}>
				<span title={value}>
					{visibleValue}
				</span>
			</div>
		)
	}

	getRenderCell = (balance: Balance, isFixed?: boolean, ...rest: any) => (value: any, record: Tree, index: number) => {
		if(record?.unifying){
			return renderEmptyCell();
		}

		const renderCell = (value: string) => {
			const {
				selectedRowId, selectedBalanceId,
				highlightedCells,
				availableBalanceTypeIds,
			} = this.props;
			let selectable: boolean = true;
			let effectiveClassName = `${containerClassName}__table__cell-content`;


			// Forget fixed rows
			if (!isFixed){
				if(selectedRowId === record.id){
					if(balance?.id && selectedBalanceId === balance.id){
						selectable = false;
						effectiveClassName += ' strong-highlight';
					} else {
						effectiveClassName += ' highlight';
					}
				}
				if(highlightedCells?.find(hc => hc.nodeId === record.id && hc.balanceId === balance?.id)){
					effectiveClassName += ' highlighted-cell'
				}

				if(balance){
					//todo
					if(availableBalanceTypeIds && (availableBalanceTypeIds.indexOf(balance.typeId) < 0)){
						selectable = false;
						effectiveClassName += ' faded'
					}
					effectiveClassName += ` ${containerClassName}__table__cell-content--balance`
				}
			}
			const visibleValue = formatters.isNumberString(value)
				? formatters.formatNumber(parseFloat(value), balance?.typeId === 1 ? 0 : 2)
				: value;

			return (
				<div className = {effectiveClassName} onClick = {!isFixed && selectable ? this.getOnCellClickHandler(record, balance) : undefined}>
					<span title={visibleValue}>
						{visibleValue}
					</span>
				</div>
			)
		}
		const visibleBalance = record.balances?.find(v => v.balanceId === balance.id);
		const visibleValue = visibleBalance?.sum === null ? 'NONE' : `${visibleBalance?.sum || 0}`
		return renderCell(visibleValue);
	}

	expandAll = () => {
		const {
			tree,
			setExpandedRows,
		} = this.props;
		const idsToExpand: number[] = [];
		const addIdsToExpand = (node: Tree | null) => {
			if(node && node.children){
				idsToExpand.push(node.id)
				node.children.forEach(subnode => addIdsToExpand(subnode))
			}
		}
		addIdsToExpand(tree);
		tree && setExpandedRows(idsToExpand);
	}

	renderHeaderCell = (colId: number, name?: string) => {
		const {setExpandedRows} = this.props;
		return () => {
			if(colId === 0){
				return (
					<TableHeader
						title = {localization.tree_name}
						onExpand = {() => {
							this.expandAll();
						}}
						onCollapse = {() => {
							setExpandedRows([])
						}}
					/>
				)
			}
			return (
				<TableHeader
					title = {name || ''}
					isFixed = {this.isColumnFixed(colId)}
				/>
			);
		}
	}

	onFilterChanged = (text: string) => {
		this.setState((state) => ({
			...state,
			filterText: text
		}))
	}

	filterNodesDropdown = () => {
		const {
			filterText
		} = this.state;

		return (
			<Filters.AdvancedSearch
				className = {`${containerClassName}__table__header-filter-dropdown`}
				value = {filterText}
				onChange = {this.onFilterChanged}
			/>
		)
	}

	isColumnFixed = (colId: number) => {
		const {fixedColumnIds} = this.state;
		return (fixedColumnIds.indexOf(colId) > -1) || colId === 0;
	}

	getColumns = (isFixed?: boolean): TreeColumnType[] => {
		const {balances} = this.props;
		const {filterText} = this.state;

		let searchFiltersClassName = `${containerClassName}__table__header-cell-filter`;
		if(filterText !== ''){
			searchFiltersClassName += ` ${containerClassName}__table__header-cell-filter--active`
		}
		const searchFilters = !isFixed ? {
			filterIcon: (
				<div className = {searchFiltersClassName}>
					<Filter/>
				</div>
			),
			filterDropdown: (
				<Filters.SubstringFilter
					value = {filterText}
					onChange = {this.onFilterChanged}
					onReset = {() => this.onFilterChanged('')}
				/>
			),
			filteredValue: filterText !== '' ? [] : null,
			onFilter: () => true
		} : null;

		const nameColumn: TreeColumnType = {
			id: 0,
			dataIndex: 'name',
			title: this.renderHeaderCell(0),
			showSorterTooltip: false,
			width: 258,
			fixed: 'left',
			ellipsis: true,
			render: this.getRenderNameCell(isFixed),
			...searchFilters
		}
		const columns: TreeColumnType[] = (balances || [])
			.sort((a, b) => a.order - b.order)
			.map(balance => {
				return {
					id: balance.id,
					title: this.renderHeaderCell(balance.id, balance.name),
					dataIndex: balance.id,
          width: 168,
					showSorterTooltip: false,
					render: this.getRenderCell(balance, isFixed),
					onHeaderCell: this.onHeaderCell,
				};
			})

		const leftFixedColumns: TreeColumnType[] = [];
		const unfixedColumns: TreeColumnType[] = [];
		columns.forEach((col) => {
			if(this.isColumnFixed(col.id)){
				leftFixedColumns.push({
					...col,
					fixed: 'left'
				})
			} else {
				unfixedColumns.push({...col});
			}
		})
		return [nameColumn, ...leftFixedColumns, ...unfixedColumns];
	}

	scrollToRow = (rowId: number) => {
		const targetElement = document
			.querySelector(`.${containerClassName}__table__main div[data-id='${rowId}']`) as HTMLElement;

		if(!targetElement){
			return;
		}
		scrollIntoView(targetElement, {
			align: {
				topOffset: 20
			}
		})
	}

	selectRow = (recordId: number, balanceId?: number) => {
		const {
			highlightedCells,
			onEntryClick
		} = this.props;

		// avoid setting the same node/balance as source and target of the same transaction
		if(highlightedCells?.find(cell => cell.nodeId === recordId && cell.balanceId === balanceId)){
			return onEntryClick(recordId);
		}
		return onEntryClick(recordId, balanceId || undefined);
	}

	getOnCellClickHandler = (record: Tree, balance?: Balance) => {
		return (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
			event.preventDefault();
			event.stopPropagation();

			this.selectRow(record.id, balance?.id);
		}
	}

	onRowContextMenu = (entry: Tree, event: React.MouseEvent<HTMLElement, MouseEvent>, offset: {x: number, y: number}) => {
		event.preventDefault();

		const {onEntryClick} = this.props;

		onEntryClick(entry.id);

		this.setState((state) => ({
			...state,
			rowContextMenu: {
				nodeIds: [entry.id],
				x: event.clientX - offset.x,
				y: event.clientY - offset.y
			}
		}));
	}

	getHeaderContextMenuHandler = (id: number) => {
		return (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
			event.preventDefault();

			const parentElement = event.currentTarget.parentElement;
			const offset = parentElement?.getBoundingClientRect()

			this.setState({
				...this.state,
				headerContextMenu: {
					columnIdOrHidden: id,
					x: event.clientX - (offset?.x || 0),
					y: event.clientY - (offset?.y || 0)
				}
			})
		}
	}

	renderHeaderContextMenu = () => {
		const {x, y, columnIdOrHidden} = this.state.headerContextMenu;
		const {fixedColumnIds} = this.state
		return (
			<HeaderContextMenu
				columnId = {columnIdOrHidden}
				fixedColumnIds = {fixedColumnIds}
				x = {x}
				y = {y}
				isColumnFixed = {this.isColumnFixed}
				updateFixedColumns = {(ids: number[]) => {
					this.setState((state) => ({
						...state,
						headerContextMenu: {
							columnIdOrHidden: null,
							x: 0,
							y: 0
						},
						fixedColumnIds: ids
					}))
				}}
				onClose = {() => this.setState((state) => ({
					...state,
					headerContextMenu: {
						columnIdOrHidden: null,
						x: 0,
						y: 0
					}
				}))}
			/>
		);
	}

 

	renderRowContextMenu = () => {
		const {
			tree, token,
			fixedRowIds,
			treeSecurityProvider,
			toggleFixRows
		} = this.props;

		const {nodeIds, x, y} = this.state.rowContextMenu;

		const text = fixedRowIds?.find(e => e === nodeIds[0]) ? (
				localization.unfix_row
			): (
				localization.fix_row
			)

		return (
			<RowContextMenu
				nodeIds = {nodeIds}
				tree = {tree}
				token = {token}
				x = {x}
				y = {y}
				treeSecurityProvider = {treeSecurityProvider}
				fixNodes = {toggleFixRows}
				onClose = {() => this.setState((state) => ({
					...state,
					rowContextMenu: {
						nodeIds: [],
						x: 0,
						y: 0
					}
				}))}
			>
				{text}
			</RowContextMenu>
		);
	}

	render(){
		const {
			tree, balances, selectedRowId, className,
			expandedRows, setExpandedRows,
			fixedRowIds, toggleFixRows
		} = this.props;

		const {filterText} = this.state;

		const filteredRowIds = filterText !== ''
			? search(filterText, tree)
				.map(path => path[path.length - 1])
			: null;


		if(!tree || !balances){
			return <Skeleton/>
		}

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		return (
      <div className={effectiveClassName}>
        <SplitTable
          className = {`${containerClassName}__table`}
          mainTableClassName = {`${containerClassName}__table__main`}
          getColumns = {this.getColumns}
          dataSource = {[tree]}
          filteredRowIds = {filteredRowIds}
          onRowsFix = {toggleFixRows}
          fixedRowIds = {fixedRowIds}
          onExpandRow = {setExpandedRows}
          expandedRowKeys = {expandedRows}
          onRowContextMenu = {this.onRowContextMenu}
          getRowClassName = {(record) => getRowClassName(
            (function findSelectedRow(record: Tree): boolean {
              if (record.id === selectedRowId) {
                return true;
              }
              if (record.children) {
                return record.children.some(findSelectedRow);
              }
              return false;
            })(record)
          )}
        />
        {this.renderRowContextMenu()}
        {this.renderHeaderContextMenu()}
      </div>
		)
	}
}

const getRowClassName = (selected?: boolean) => {
	let className = 'row'
	if(selected){
		className += ` selected-row`
	}
	return className
}

export default TreeView;