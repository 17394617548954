import { ActionGroup } from "../models";

const actionTypes = {
	ACTION_GROUP_CREATE: 'ACTION_GROUP\\CREATE',
	ACTION_GROUP_UPDATE: 'ACTION_GROUP\\UPDATE',
	ACTION_GROUP_DELETE: 'ACTION_GROUP\\DELETE',
	ACTION_GROUP_REQUEST_SUCCESS: 'ACTION_GROUP\\REQUEST_SUCCESS',
	ACTION_GROUP_REQUEST_FAILED: 'ACTION_GROUP\\REQUEST_FAILED',
}

const create = (actionGroup: ActionGroup, token: string, prevPermissionIds?: string[], nextPermissionIds?: string[]) => ({
	type: actionTypes.ACTION_GROUP_CREATE,
	payload: {
		token: token,
		actionGroup: actionGroup,
		prevPermissionIds: prevPermissionIds,
		nextPermissionIds: nextPermissionIds
	}
})

const update = (actionGroup: ActionGroup, token: string, prevPermissionIds?: string[], nextPermissionIds?: string[]) => ({
	type: actionTypes.ACTION_GROUP_UPDATE,
	payload: {
		token: token,
		actionGroup: actionGroup,
		prevPermissionIds: prevPermissionIds,
		nextPermissionIds: nextPermissionIds
	}
})

const deleteRole = (id: number, token: string) => ({
	type: actionTypes.ACTION_GROUP_DELETE,
	payload: {
		token: token,
		id: id
	}
})

const requestSuccess = () => ({
	type: actionTypes.ACTION_GROUP_REQUEST_SUCCESS,
	payload: null
})

const requestFailed = (message: string) => ({
	type: actionTypes.ACTION_GROUP_REQUEST_FAILED,
	payload: {
		message: message
	}
})

export default {
	types: actionTypes,
	create,
	update,
	delete: deleteRole,
	requestSuccess,
	requestFailed,
};