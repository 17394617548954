import actions from '../actions';
import { EditUserState } from '../models';

export interface EditUserStates{
	[userId: number]: EditUserState | null | undefined
}

const defaultState: EditUserStates = {
}

const editUserStates = (state: EditUserStates = defaultState, action: any): EditUserStates => {
	switch(action.type){
		case actions.editUserState.types.EDIT_USER_STATE_SAVE: {
			const {userState, userId} = action.payload as {userState: EditUserState, userId: number};
			return {
				...state,
				[userId]: userState
			}
		}
		case actions.user.types.USER_REQUEST_SUCCESS: {
			const {id} = action.payload;
			return {
				...state,
				[id]: undefined
			}
		}
	}
	return state;
}

export default editUserStates;