import { connect } from 'react-redux'

import actions from '../../actions'
import { State } from '../../reducers';

import App from './App';

const mapStateToProps = (state: State, _: any) => {
	return {
		token: state.auth.token,
		currentUserId: state.auth.userId,
		isLoading: state.auth.isLoading,

		currentUser: state.account.user,
		version: state.version.version,
		isCurrentUserLoading: state.account.isLoading,
		currentUserError: state.account.error
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadAccount: (userId: number, token: string) => 
		dispatch(actions.account.load(userId, token)),
	onLogout: () => 
		dispatch(actions.auth.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)