import actions from "../actions";

import history from '../history';
import { TransactionsPerTree, UpdateAttributeLocatorType } from "../models";
import { transactions } from "../settings";

const defaultState: TransactionsPerTree = {
}

const state = (state: TransactionsPerTree = defaultState, action: any): TransactionsPerTree => {
	switch(action.type){
		case actions.transactions.types.TRANSACTIONS_LOAD: {
			const {treeId, currentPage, pageSize, filters} = action.payload
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					list: null,
					isLoading: true,
					error: null,
					currentPage: currentPage,
					pageSize: pageSize,
					filters
				}
			}
		}
		case actions.transactions.types.TRANSACTIONS_LOAD_SUCCESS: {
			const {treeId, transactions, currentPage, pageSize, filters} = action.payload;
			return {
				...state,
				[treeId]: {
					loadedAt: new Date(),
					list: transactions,
					isLoading: false,
					error: null,
					currentPage: currentPage,
					pageSize: pageSize,
					filters,
				}
			}
		}
		case actions.transactions.types.TRANSACTIONS_LOAD_FAILED: {
			console.error(`${action.type}\n%o`, action.payload);
			const {treeId, message, currentPage, pageSize, filters} = action.payload;
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					list: [],
					isLoading: false,
					error: message,
					currentPage: currentPage,
					pageSize: pageSize,
					filters
				}
			}
		}

		case actions.transactions.types.TRANSACTIONS_UNLOAD: {
			const {treeId, redirectTo} = action.payload;
			if(redirectTo){
				history.push(redirectTo);
			}
			const updatedState = {...state}
			delete updatedState[treeId];
			return updatedState;
		}

		case actions.transactions.types.TRANSACTION_UPDATE_SUCCESS:{
			const {treeId} = action.payload;
			const transactionsState = state[treeId];
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					currentPage: transactions.defaultFirstPage,
					filters: {},
					pageSize: transactions.defaultPageSize,
					...transactionsState,
					list: null,
					isLoading: false,
					error: null
				}
			}
		}

		case actions.transactions.types.TRANSACTION_UPDATE_FAILED: {
			console.error(`${action.type}\n%o`, action.payload);
			const {treeId, message} = action.payload;
			const transactionsState = state[treeId];
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					currentPage: transactions.defaultFirstPage,
					filters: {},
					pageSize: transactions.defaultPageSize,
					...transactionsState,
					list: null,
					isLoading: false,
					error: message
				}
			}
		}

		case actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_UPDATE_SUCCESS:
		case actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_UPDATE_FAILED:{
			const {attribute} = action.payload as {attribute: UpdateAttributeLocatorType};
			const transactionsState = state[attribute.treeId];
			const updatedTransactionState = {
				loadedAt: null,
				currentPage: transactions.defaultFirstPage,
				filters: {},
				pageSize: transactions.defaultPageSize,
				...transactionsState,
				list: null,
				isLoading: false,
				error: null
			}
			return {
				...state,
				[attribute.treeId]: updatedTransactionState
			}
		}

		case actions.transactions.types.TRANSACTION_CANCEL: {
			const {treeId} = action.payload as {treeId: number};
			const transactionsState = state[treeId];
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					currentPage: transactions.defaultFirstPage,
					filters: {},
					pageSize: transactions.defaultPageSize,
					...transactionsState,
					isLoading: true,
					list: null,
					error: null
				}
			}
		}
		case actions.transactions.types.TRANSACTION_CANCEL_SUCCESS:
		case actions.transactions.types.TRANSACTION_CANCEL_FAILED:{
			const {treeId} = action.payload as {treeId: number};
			const transactionsState = state[treeId];
			return {
				...state,
				[treeId]: {
					loadedAt: null,
					currentPage: transactions.defaultFirstPage,
					filters: {},
					pageSize: transactions.defaultPageSize,
					...transactionsState,
					isLoading: false,
					list: null,
					error: null
				}
			}
		}
	}
	return state;
}

export default state;