import actions from '../actions';

export interface PasswordChangeState {}

const defaultState = {};

const passwordChange = (
  state: PasswordChangeState = defaultState,
  action: any,
): PasswordChangeState => {
  switch (action.type) {
    case actions.passwordChange.types.SAVE:
      return { ...state, isLoading: true };
    case actions.passwordChange.types.SAVE_SUCCESS:
      return { ...state, isLoading: false };
    case actions.passwordChange.types.SAVE_FAILED:
      console.error(`${action.type}\n%o`, action.payload);
      return { ...state, isLoading: false };
  }
  return state;
};

export default passwordChange;
