import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import actions from '../../actions';
import { State } from '../../reducers';

import PrivateLayout from './PrivateLayout';

import './index.scss';

const mapStateToProps = (state: State) => {
	return {
		token: state.auth.token,
		version: state.version.version
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	clearCache: (token: string) =>
		dispatch(actions.development.clearCache(token)),
	syncElastic: (token: string) =>
		dispatch(actions.development.syncElastic(token)),
	getVersion: (token: string) =>
		dispatch(actions.version.load(token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateLayout));