import React from 'react';
import { Skeleton } from 'antd';

import { User } from '../../models';

import { Empty, UserPanel } from '..';

interface UserListProps{
    users?: User[] | null,
    isLoading?: boolean;
}

const containerClassName = "user-list";

const UserList = (props: UserListProps) => {
    const {users, isLoading} = props;
    let content: JSX.Element | JSX.Element[];
    if(!users || isLoading){
        content = (
            <Skeleton/>
        )    
    } else if(users.length < 1){
        content = (
            <div className = {`${containerClassName}__empty`}>
                <Empty/>
            </div>
        )
    } else {
        content = users
            .map(user => <UserPanel key={user?.id} user = {user || {}}/>)
    }

    return (
        <div className = {containerClassName}>
            {content}
        </div>
    )
}

export default UserList;