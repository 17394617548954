import { Role } from "../models";

const actionTypes = {
	ROLE_CREATE: 'ROLE\\CREATE',
	ROLE_UPDATE: 'ROLE\\UPDATE',
	ROLE_DELETE: 'ROLE\\DELETE',
	ROLE_REQUEST_SUCCESS: 'ROLE\\REQUEST_SUCCESS',
	ROLE_REQUEST_FAILED: 'ROLE\\REQUEST_FAILED',
}

const create = (role: Role, token: string, prevPermissionIds?: string[], nextPermissionIds?: string[]) => ({
	type: actionTypes.ROLE_CREATE,
	payload: {
		token: token,
		role: role,
		prevPermissionIds: prevPermissionIds,
		nextPermissionIds: nextPermissionIds
	}
})

const update = (role: Role, token: string, prevPermissionIds?: string[], nextPermissionIds?: string[]) => ({
	type: actionTypes.ROLE_UPDATE,
	payload: {
		token: token,
		role: role,
		prevPermissionIds: prevPermissionIds,
		nextPermissionIds: nextPermissionIds
	}
})

const deleteRole = (id: number, token: string) => ({
	type: actionTypes.ROLE_DELETE,
	payload: {
		token: token,
		id: id
	}
})

const requestSuccess = () => ({
	type: actionTypes.ROLE_REQUEST_SUCCESS,
	payload: null
})

const requestFailed = (message: string) => ({
	type: actionTypes.ROLE_REQUEST_FAILED,
	payload: {
		message: message
	}
})

export default {
	types: actionTypes,
	create,
	update,
	delete: deleteRole,
	requestSuccess,
	requestFailed
};