import React from 'react';
import { Skeleton } from 'antd';

import { localization } from '../../../settings';
import { Empty } from '../../../components';

interface RecordsListProps<TRecord>{
	records: TRecord[];
	className?: string,
	isLoading?: boolean;

	getKey: (record: TRecord) => React.ReactText,
	renderTitle: (record: TRecord) => React.ReactNode,
	renderValue: (record: TRecord) => React.ReactNode
}

interface RecordsListState{
	isExpanded: boolean;
}

const defaultState: RecordsListState = {
	isExpanded: false
}

const containerClassName = "records-list";

class RecordsList<TRecord> extends React.Component<RecordsListProps<TRecord>, RecordsListState>{
	constructor(props: RecordsListProps<TRecord>){
		super(props);
		this.state = defaultState;
	}

	renderRecord = (record: TRecord) => {
		const {getKey, renderTitle, renderValue} = this.props;
		const titleElement = renderTitle(record);
		const valueElement = renderValue(record);
		return (
			<div
				key = {`record-${getKey(record)}`}
				className = {`${containerClassName}__entry`}
			>
				{titleElement && (
					<div className = {`${containerClassName}__entry__name`}>
						{titleElement}
					</div>
				)}
				{valueElement && (
					<div className = {`${containerClassName}__entry__value`}>
						{valueElement}
					</div>
				)}
			</div>
		)
	}
	

	triggerCollpaser = () => {
		this.setState((state) => ({
			...state,
			isExpanded: !state.isExpanded
		}))
	}

	renderCollapser = () => {
		const {isExpanded} = this.state;
		return (
			<div className = {`${containerClassName}__collapser`}>
				<span
					className = {`${containerClassName}__collapser__text`}
					onClick = {this.triggerCollpaser}
				>
					{isExpanded ? localization.show_less : localization.show_more }
				</span>
			</div>
		)
	}

	render() {
		const {records, isLoading, className} = this.props;
		const {isExpanded} = this.state;
		const shownRecords = isExpanded ? records : records.slice(0, 3);
		let content: JSX.Element | JSX.Element[];
		const effectiveClassName = className ? `${containerClassName} ${className}`: containerClassName;
		if(isLoading){
			content = (<Skeleton className = {effectiveClassName}/>);
		} else if(shownRecords.length < 1){
			content = (
				<div className = {`${containerClassName}__empty`}>
					<Empty/>
				</div>
			)
		} else {
			content = shownRecords.map(record => this.renderRecord(record))
		}
		return (
			<div className = {effectiveClassName}>
				{content}
				{records.length > 3 && this.renderCollapser()}
			</div>
		)
	}
}

export default RecordsList;