import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowLeft, Plus } from 'react-feather';

import {Button} from '..';

interface PageHeaderProps{
	title?: React.ReactNode,
	button?: JSX.Element,
	buttonUrl?: string,
	buttonTitle?: string,
	buttonClassName?: string,
	showButton?: boolean | null,
	backUrl?: string,
	className?: string | null
}

const PageHeader = (props: PageHeaderProps) => {
	const {
		title,
		button, buttonTitle, buttonUrl, showButton, buttonClassName,
		backUrl,
		className
	} = props;
	const containerClassName = 'page-title';
	const effectiveContainerClassName = className ? `${containerClassName} ${className}`: containerClassName;

	return(
		<div className = {effectiveContainerClassName}>
			<div className = {`${containerClassName}__title`}>
				<h1 className = {`${containerClassName}__title__text`}>
					{backUrl && (
						<NavLink to={backUrl} className = {`${containerClassName}__title__button-back`}>
							<ArrowLeft height={20} width={20}/>
						</NavLink>
					)}
					{title}
				</h1>
			</div>
			{showButton &&(button || (
				<div className = {`${containerClassName}__button`}>
					<Button
						type = "primary"
						to = {buttonUrl}
						icon = {<Plus/>}
						className = {buttonClassName}
					>
						{buttonTitle}
					</Button>
				</div>
			))}
		</div>
	);
}

export default memo(PageHeader);