import React from 'react';
import { Form, Input, Select } from 'antd';
import { Check, Plus } from 'react-feather';

import { Button } from '../../components';
import { Role, Permission } from '../../models';
import { localization, errors, SecurityProvider } from "../../settings";
import { filterByInnerText } from '../../tools';

const {Option} = Select;

export type SubmitRoleType = (role: Role, token: string, prevPermissionIds: string[], nextPermissionIds: string[]) => void;

interface EditRolePermissionsProps{
	className: string,
	role?: Role,
	isNew?: boolean,
	permissions: Permission[] | null,
	token: string,
	securityProvider: SecurityProvider,

	submitRole: SubmitRoleType
}

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 12,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 12,
		},
	}
};

const titlelessItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 12,
			offset: 12,
		},
	},
}

const notEmptyRule = [
	{
		required: true,
		message: errors.fields_is_required,
		whitespace: true
	}];

const getSubmitRole = (
	prevRole: Role | undefined,
	token: string,
	submitRole: SubmitRoleType
) => (
	formFields: any
) => {
	const updatedRole: Role = {
		id:				formFields.id,
		name:			formFields.name,
		slug:			formFields.slug
	}
	const prevPermissionIds: string[] = (prevRole?.permissions || [])
		.map(permission => (permission.id || '').toString())
		.filter(permissionId => permissionId !== '');

	submitRole(updatedRole, token, prevPermissionIds, formFields.permissions);
}

const EditRolePermissions = (props: EditRolePermissionsProps) => {
	const {
		role, isNew, permissions, token, className,
		securityProvider,
		submitRole 
	} = props;

	const assignedPermissions = (role?.permissions || [])
		.map((permission) => (permission.id || '').toString());

	const initialValues = {
		id: role?.id || '',
		name: role?.name || '',
		slug: role?.slug || '',
		permissions: assignedPermissions
	}

	const canSave = isNew ? securityProvider.roles.canCreate : securityProvider.roles.canEdit;

	return (
		<Form
			{...formItemLayout}
			initialValues={initialValues}
			onFinish={getSubmitRole(role, token, submitRole)}
			className = {className}
		>
			<Form.Item
				name="id"
				hidden
			>
				<Input/>
			</Form.Item>
			<Form.Item
				{...formItemLayout}
				name="name"
				label={localization.role_name}
				rules={notEmptyRule}
			>
				<Input
					readOnly = {role?.is_default}
				/>
			</Form.Item>
			<Form.Item
				{...formItemLayout}
				name="slug"
				label={localization.slug}
				rules={notEmptyRule}
			>
				<Input
					readOnly = {role?.is_default}
				/>
			</Form.Item>
			{securityProvider.permissions.canRead && (<Form.Item
				name="permissions"
				label={localization.permissions}
			>
				<Select
					mode = "multiple"
					style = {{width: '100%'}}
					disabled = {!securityProvider.permissions.canAssign || role?.is_default}
					className = {`${className}__permissions`}
					filterOption = {filterByInnerText}
				>
					{permissions?.map(p => (
						<Option key={p.id} value={(p.id || '').toString()}>{p.name}</Option>
					))}
				</Select>
			</Form.Item>)}
			{!role?.is_default && (<Form.Item {...titlelessItemLayout}>
				<Button
					type="primary"
					htmlType="submit"
					icon = {isNew ? <Plus/> : <Check/>}
					className = {`${className}__save`}
					disabled = {!canSave}
				>
					{isNew ? localization.create : localization.save}
				</Button>
			</Form.Item>)}
		</Form>
	)
}

export default EditRolePermissions;