import auth from './auth';
import users from './users';
import user from './user';
import roles from './roles';
import role from './role';
import permissions from './permissions';
import trees from './trees';
import tree from './tree';
import treePermissions from './treePermissions';
import actionGroups from './actionGroups';
import actionGroup from './actionGroup';
import node from './node';
import error from './error';
import account from './account';
import attribute from './attribute';
import attributeValue from './attributeValue'
import attributeVisibilities from './attributeVisibilities';
import attributeGroup from './attributeGroup';
import attributeGroups from './attributeGroups';
import nodeAttributesForTree from './nodeAttributesForTree';
import balances from './balances';
import balance from './balance';
import balanceLimits from './balanceLimits';
import balanceTypes from './balanceTypes';
import balanceFormula from './balanceFormula';
import balanceAttributes from './balanceAttributes';
import balanceAttributeTypes from './balanceAttributeTypes';
import balanceAttributeValue from './balanceAttributeValue';
import transactions from './transactions';
import transactionsElastic from './transactionsElastic';
import transactionFilterValues from './transactionFilterValues';
import transactionAttributes from './transactionAttributes';
import transactionChangeLogs from './transactionChangeLogs';
import report from './report';
import reportTemplates from './reportTemplates';
import navigation from './navigation';
import development from './development';
import treeContainerState from './treeContainerState';
import transactionsContainerState from './transactionsContainerState';
import version from './version';
import editUserState from './editUserState';import transactionsElasticContainerState from './transactionsElasticContainerState';
import transactionsElasticAttributes from './transactionsElasticAttributes';
import passwordChange from './passwordChange';

export default {
	auth,
	users,
	user,
	roles,
	role,
	permissions,
	trees,
	tree,
	treePermissions,
	actionGroups,
	actionGroup,
	node,
	error,
	account,
	attribute,
	attributeVisibilities,
	attributeGroup,
	attributeGroups,
	attributeValue,
	nodeAttributesForTree,
	balances,
	balance,
	balanceLimits,
	balanceTypes,
	balanceFormula,
	balanceAttributes,
	balanceAttributeTypes,
	balanceAttributeValue,
	transactionsElastic,
	transactions,
	transactionFilterValues,
	transactionAttributes,
	transactionChangeLogs,
	report,
	reportTemplates,
	navigation,
	development,
	treeContainerState,
	transactionsContainerState,
	version,
	editUserState,
	transactionsElasticContainerState,
	transactionsElasticAttributes,
	passwordChange
}
