import React from "react";
import { RefreshCw } from "react-feather";

import { TransactionsTable } from "../../components";
import { AttributeLocator, TransactionAttributes, TransactionFilterValueItem, TransactionsTableState, TransactionsFilters, TransactionType, UserAccount, TransactionsElasticAttribute, TransactionsElasticList, TransactionsElasticAttributesFilter, TransactionsElasticAttributeValue } from "../../models";
import { getSecurityProvider, localization, TreeSecurityProvider } from "../../settings";

import Workspace from "../Workspace";

import AttributesFilter from "./attributesFilter";

interface TransactionsElasticContainerProps{
	className?: string,

	treeId: number,
	treeName: string | null,
	authorIdFilterValues: TransactionFilterValueItem[] | null,
	nodeIdFilterValues: TransactionFilterValueItem[] | null,
	correspondentIdFilterValues: TransactionFilterValueItem[] | null,
	containerState: TransactionsTableState | null,

	currentUser: UserAccount,
	treeSecurityProvider: TreeSecurityProvider,
	token: string,

	transactionTypes: TransactionType[] | null,
	transactions: TransactionsElasticList | null,
	transactionAttributes: TransactionAttributes,
	transactionsElasticAttributes: TransactionsElasticAttribute[] | null,
	loadTransactions: (treeId: number, page: number, size: number, filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter | null, token: string) => void,
	loadTransactionAttributes?: (attributes: AttributeLocator[], token: string) => void,
	loadTransactionFilterValues: (treeId: number, fieldName: string, token: string) => void,
	saveContainerState: (treeId: number, state: TransactionsTableState) => void,
}

const containerClassName = 'transactions-elastic-container';

class TransactionsElasticContainer extends React.Component<TransactionsElasticContainerProps>{
	onRefresh = () => {
		const {
			treeId, transactions,
			token,
			loadTransactions,
			loadTransactionFilterValues
		} = this.props;
		transactions && loadTransactions(treeId, transactions.currentPage, transactions.pageSize, transactions.filters, transactions.attributes, token);
		loadTransactionFilterValues(treeId, "authorId", token);
		loadTransactionFilterValues(treeId, "nodeId", token);
		loadTransactionFilterValues(treeId, "correspondentId", token);
	}

	loadTransactions4Table = (treeId: number, page: number, size: number, filters: TransactionsFilters, token: string) => {
		const {
			transactions,
			loadTransactions
		} = this.props;
		transactions && loadTransactions(treeId, page, size, filters, transactions.attributes, token);
	}

	onAttributeValueSet = (attribute: TransactionsElasticAttribute, value: TransactionsElasticAttributeValue | string | null) => {
		const {
			treeId,
			transactions,
			token,
			loadTransactions
		} = this.props;

		const updatedAttributesFilter: TransactionsElasticAttributesFilter = {
			node: {},
			balance: {},
			...transactions?.attributes
		}

		const currentValue = updatedAttributesFilter[attribute.type][attribute.attributeId];
		if(value && typeof value !== 'string'){
			if(!currentValue || currentValue !== value.id){
				updatedAttributesFilter[attribute.type][attribute.attributeId] = value.id;
			}else{
				delete updatedAttributesFilter[attribute.type][attribute.attributeId];
			}
		}else{
			if(value){
				updatedAttributesFilter[attribute.type][attribute.attributeId] = value;
			}else{
				delete updatedAttributesFilter[attribute.type][attribute.attributeId];
			}
		}

		transactions && loadTransactions(
			treeId, transactions.currentPage,
			transactions.pageSize, transactions.filters,
			updatedAttributesFilter,
			token
		);
	}

	renderPageTitle = (title: string) => {
		const {
			transactions,
			transactionsElasticAttributes
		} = this.props;
		return (
			<div className = {`${containerClassName}__header`}>
				<span
					className = {`${containerClassName}__header__text`}
				>
					{title}
				</span>
				<span
					className = {`${containerClassName}__header__icon`}
				>
					<RefreshCw
						className = {`feather-ico`}
						onClick = {() => this.onRefresh()}
					/>
				</span>
				<AttributesFilter
					className = {`${containerClassName}__header__attributes`}
					transactionsElasticAttributes = {transactionsElasticAttributes}
					transactionsElasticAttributesFilter = {transactions?.attributes || null}
					elasticAttributeSet = {this.onAttributeValueSet}
				>
					{localization.transactions_elastic_attributes_filter}
				</AttributesFilter>
			</div>
		);
	}

	render(){
		const {
			className,
			treeId, treeName,
			currentUser, token, treeSecurityProvider,
			authorIdFilterValues, nodeIdFilterValues, correspondentIdFilterValues,
			transactions, transactionTypes, transactionAttributes,
			containerState,
			loadTransactionAttributes,
			saveContainerState
		} = this.props;

		const title = localization.transactions_elastic_page_title.replace('{}', `${treeName}`);

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		return (
			<div className = {effectiveClassName}>
				<Workspace
					title = {title}
					pageTitle = {this.renderPageTitle(title)}
					transactionsElasticListId = {treeId}
					currentUser = {currentUser}
					securityProvider = {getSecurityProvider(currentUser.permissions)}
				>
					<TransactionsTable
						treeId = {treeId}

						authorIdFilterValues = {authorIdFilterValues}
						nodeIdFilterValues = {nodeIdFilterValues}
						correspondentIdFilterValues = {correspondentIdFilterValues}

						transactions = {transactions}
						transactionTypes = {transactionTypes}
						transactionAttributes = {transactionAttributes}

						containerState = {containerState}

						treeSecurityProvider = {treeSecurityProvider}
						token = {token}

						loadTransactions = {this.loadTransactions4Table}
						loadTransactionAttributes = {loadTransactionAttributes}

						saveContainerState = {saveContainerState}
					/>
				</Workspace>
			</div>
		)
	}
}

export default TransactionsElasticContainer;