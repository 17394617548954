import React from 'react';
import { Modal, Table, Tag } from 'antd';
import { Trash } from 'react-feather';

import { ActionGroup, CommonPageProps, TreePermission } from '../../models';
import { site, localization } from '../../settings';
import { EditButtonsSet, PageTitle } from '../../components';

interface ActionGroupsProps extends CommonPageProps{
	actionGroups: ActionGroup[] | null,
	isLoading: boolean,
	treePermissions: TreePermission[] | null,
	isTreePermissionsLoading: boolean,
	token: string,
	userPermissions?: string[],

	loadActionGroups: (token: string) => void,
	deleteActionGroup: (id: number, token: string) => void,
	loadTreePermissions: (token: string) => void
}

const containerClassName = 'action-groups-page';

class ActionGroups extends React.Component<ActionGroupsProps>{
	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			actionGroups, isLoading, loadActionGroups,
			treePermissions, isTreePermissionsLoading, loadTreePermissions,
			securityProvider, token
		} = this.props;
		if(!actionGroups && !isLoading){
			loadActionGroups(token)
		}
		if(!treePermissions && securityProvider.treePermissions.canRead && !isTreePermissionsLoading){
			loadTreePermissions(token)
		}
	}

	getColumns(){
		const {treePermissions, securityProvider} = this.props;

		const getSorterFor = (fieldName: string) => (a:any, b:any) => {
			return (a[fieldName] || '').localeCompare(b[fieldName] || '')
		};
	
		const treePermissionFilters = treePermissions?.map(p => ({
			text: p.name,
			value: p.id || ''
		}));
	
		const onTreePermissionFilter = (value: any, actionGroup: any) => {
			return actionGroup.permissions.findIndex((p: any) => p.id === value) > -1;
		};
	
		const columns: any[] = [{
			title: localization.actionGroup,
			dataIndex: 'name',
			sorter: getSorterFor('name'),
			showSorterTooltip: false
		}];

		if(securityProvider.treePermissions.canRead){
			columns.push({
				title: localization.treePermissions,
				dataIndex: 'permissions',
				render: this.renderTreePermissions,
				filterMultiple: false,
				filters: treePermissionFilters,
				onFilter: onTreePermissionFilter
			});
		}

		if(securityProvider.actionGroups.canEdit|| securityProvider.actionGroups.canDelete){
			columns.push({
				title: null,
				render: (actionGroup: ActionGroup) => (
					<EditButtonsSet
						editUrl = {`${site.actionGroups.url}/${actionGroup.id}`}
						isEditAvailable = {securityProvider.actionGroups.canEdit}
						onDelete = {this.getDeleteActionGroupClickHandler(actionGroup.id)}
						isDeleteAvailable = {securityProvider.actionGroups.canDelete}
						className = {`${containerClassName}__action-groups-actions`}
						menuClassName = {`${containerClassName}__action-groups-actions__menu`}
					/>
				)
			});
		}

		return columns;
	}

	getDeleteActionGroupClickHandler(id?: number){
		return () => {
			const {deleteActionGroup, token} = this.props;
			Modal.confirm({
				title: localization.delete_action_group_title,
				icon: <Trash style = {{float: 'left', marginRight: 10}} />,
				content: localization.delete_action_group_content,
				okText: localization.yes,
				okType: 'danger',
				cancelText: localization.no,
				onOk() {
					if(id !== undefined){
						deleteActionGroup(id, token);
					}
				}
			})
		}
	}

	renderTreePermissions(treePermissions: TreePermission[]){
		return (
			<>
				{treePermissions.map((tp => {
					return (
						<Tag key={tp.id} style={{margin: 5}}>
							{tp.name}
						</Tag>
					);
				}))}
			</>
		)
	}

	render(){
		const {actionGroups, isLoading, securityProvider} = this.props;
	
		const dataSource = actionGroups?.map((tr: ActionGroup) => ({
			...tr,
			key: tr.id
		}));

		return (
			<div className = {`page-content ${containerClassName}`}>
				<PageTitle
					title = {site.actionGroups.name}
					buttonTitle = {localization.newActionGroupButton}
					buttonUrl = {site.newActionGroup.url}
					buttonClassName = {`${containerClassName}__new-action-group`}
					showButton = {securityProvider.actionGroups.canCreate}
				/>
				<Table
					columns = {this.getColumns()}
					dataSource = {dataSource}
					loading = {isLoading}
					pagination = {false}
					className = {`${containerClassName}__table`}
				/>
			</div>
		)
	}
}

export default ActionGroups;