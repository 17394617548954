import React from 'react';
import { Checkbox, Input, Select } from 'antd';

import { BalanceAttribute, BalanceAttributeInfo, BalanceAttributeType } from '../../models';
import { localization } from '../../settings';

import { Button, CustomPopup } from '..';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface BalanceAttributeDialogProps{
	className?: string | null,
	visible: boolean,
	balanceAttribute: BalanceAttribute | null,
	balanceAttributeTypes: BalanceAttributeType[] | null

	onClose: () => void,
	onSave: (info: BalanceAttributeInfo) => void,
}

interface BalanceAttributeDialogState{
	name: string | null,
	typeId: number | null,
	isRequired: boolean | null,
}

const defaultState: BalanceAttributeDialogState = {
	name: null,
	typeId: null,
	isRequired: null
}

const containerClassName = 'balance-attribute-dialog';

class BalanceAttributeDialog extends React.Component<BalanceAttributeDialogProps, BalanceAttributeDialogState>{
	constructor(props: BalanceAttributeDialogProps){
		super(props);

		this.state = defaultState;
	}

	changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState((state) => ({
			...state,
			name: event.target.value
		}))
	}

	changeType = (typeId: number) => {
		this.setState((state) => ({
			...state,
			typeId: typeId
		}))
	}

	changeIsRequired = (event: CheckboxChangeEvent) => {
		this.setState((state) => ({
			...state,
			isRequired: event.target.checked
		}))
	}

	getName = () => {
		const {balanceAttribute} = this.props;
		const {name} = this.state;
		return name === null ? balanceAttribute?.name : name;
	}

	getTypeId = () => {
		const {balanceAttribute} = this.props;
		const {typeId} = this.state;
		return typeId === null ? balanceAttribute?.typeId : typeId;
	}

	getIsRequired = () => {
		const {balanceAttribute} = this.props;
		const {isRequired} = this.state;
		return isRequired === null ? !!balanceAttribute?.required : isRequired;
	}

	getValue = (): BalanceAttributeInfo | null => {
		const {balanceAttribute} = this.props;
		const resultName = this.getName();
		const resultTypeId = this.getTypeId();
		const isRequired = this.getIsRequired();
		if (resultName == null || resultTypeId == null){
			return null;
		}
		return {
			id: balanceAttribute?.id || undefined,
			name: resultName,
			typeId: resultTypeId,
			isRequired
		}
	}

	onSaveClick = () => {
		const {onSave, onClose} = this.props;
		const value = this.getValue();
		if(value){
			onSave(value);
			this.setState(() => ({
				name: null,
				typeId: null,
				isRequired: null
			}));
			onClose();
		}
	}

	render() {
		const {
			className, visible,
			balanceAttribute, balanceAttributeTypes,
			onClose
		} = this.props;

		const name = this.getName();
		const typeId = this.getTypeId();
		const isRequired = this.getIsRequired();

		const title = balanceAttribute ? balanceAttribute.name : localization.editBalanceAttribute_dialog_newTitle;
		const effectiveClassName = className ? `${containerClassName} ${className}`: containerClassName;
		return (
			<CustomPopup
				className = {effectiveClassName}
				visible = {visible}
				onClose = {onClose}
			>
				<div className = {`${containerClassName}__title`} title = {title}>
					{title}
				</div>
				<div className = {`${containerClassName}__field`}>
					<Input
						className = {`${containerClassName}__field__name`}
						value = {name}
						onChange = {this.changeName}
					/>
				</div>
				<div className = {`${containerClassName}__field`}>
					<Select
						value = {typeId}
						onChange = {this.changeType}
						className = {`${containerClassName}__field__type`}
						disabled = {!!balanceAttribute}
						dropdownStyle = {{zIndex: 10010}}
					>
						{balanceAttributeTypes?.map(bat => (
							<Select.Option key = {bat.id} value = {bat.id}>
								{bat.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<div className = {`${containerClassName}__field`}>
					<Checkbox
						checked = {isRequired}
						onChange = {this.changeIsRequired}
						className = {`${containerClassName}__field__is-required`}
					>
						{localization.editBalanceAttribute_dialog_isRequiredText}
					</Checkbox>
				</div>
				<div className = {`${containerClassName}__footer`}>
					<Button
						type = "primary"
						disabled = {!this.getValue()}
						onClick = {this.onSaveClick}
					>{localization.save}</Button>
					<Button
						onClick = {onClose}
					>{localization.cancel}</Button>
				</div>
			</CustomPopup>
		)
	}
}

export default BalanceAttributeDialog;