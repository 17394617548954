import { Transaction, TransactionsElasticAttributesFilter, TransactionsFilters } from "../models";

const actionTypes = {
	TRANSACTIONS_ELASTIC_LOAD: 'TRANSACTIONS_ELASTIC\\LOAD',
	TRANSACTIONS_ELASTIC_LOAD_SUCCESS: 'TRANSACTIONS_ELASTIC\\LOAD_SUCCESS',
	TRANSACTIONS_ELASTIC_LOAD_FAILED: 'TRANSACTIONS_ELASTIC\\LOAD_FAILED',

	TRANSACTIONS_ELASTIC_UNLOAD: 'TRANSACTIONS_ELASTIC\\UNLOAD',
}

const load = (treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter | null, token: string) => ({
	type: actionTypes.TRANSACTIONS_ELASTIC_LOAD,
	payload: {
		treeId,
		currentPage,
		pageSize,
		filters,
		attributes,
		token
	}
})

const loadSuccess = (treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter | null, transactions: Transaction[]) => ({
	type: actionTypes.TRANSACTIONS_ELASTIC_LOAD_SUCCESS,
	payload: {
		treeId,
		currentPage,
		pageSize,
		filters,
		attributes,
		transactions
	}
})

const loadFailed = (treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter | null, message: string) => ({
	type: actionTypes.TRANSACTIONS_ELASTIC_LOAD_FAILED,
	payload: {
		treeId,
		currentPage,
		pageSize,
		filters,
		attributes,
		message
	}
})

const unload = (treeId: number) => ({
	type: actionTypes.TRANSACTIONS_ELASTIC_UNLOAD,
	payload: {
		treeId
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	unload
};