import React from 'react';
import { X } from 'react-feather';
import { BalanceAttributeValue } from '../../models';

interface BalanceAttributeValueElementProps{
	value: BalanceAttributeValue,
	handlerRef: React.RefObject<HTMLDivElement>,

	onClose?: (value: BalanceAttributeValue) => void
}

const containerClassName = 'bav-element';

const BalanceAttributeValueElement = (props: BalanceAttributeValueElementProps) => {
	const {
		value, handlerRef,
		onClose
	} = props;
	return (
		<div
			key = {value.id}
			ref = {handlerRef}
			className = {containerClassName}
		>
			<div className = {`${containerClassName}__text`}>
				{value.name}
			</div>
			<div
				className = {`${containerClassName}__close`}
				onClick = {() => onClose && onClose(value)}
			>
				<X className = {`${containerClassName}__close__ico`}/>
			</div>
		</div>
	);
}

export default BalanceAttributeValueElement;