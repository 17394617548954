import { EditUserState } from "../models";

const actionTypes = {
	EDIT_USER_STATE_SAVE: 'EDIT_USER_STATE\\SAVE',
}

const save = (userId: number, userState: EditUserState) => ({
	type: actionTypes.EDIT_USER_STATE_SAVE,
	payload: {
		userId,
		userState
	}
})

export default {
	types: actionTypes,
	save
};