import { call, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { Transaction, TransactionAuthor, TransactionNode, TransactionOperation, TransactionOperationAttribute, TransactionsElasticAttributesFilter, TransactionsFilters, TransactionType } from '../models';
import handleResponse from './handleResponse';

const {
	loadSuccess, loadFailed,
} = actions.transactionsElastic;

type ResponseType = {status: number, body: any} | {error: any};
type ResponsePromiseType = Promise<ResponseType>;

const extractFiltersString = (filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter | null): string => {
	let result = Object.entries(filters)
		.reduce((acc, cur) => {
			const newPart = cur[1] ? `&filter[${cur[0]}]=${encodeURIComponent(cur[1] || '')}` : null;
			return newPart ? acc + newPart : acc;
		}, '');
	if(attributes){
		for(let type of ['node', 'balance']){
			const attributesFilter = Object.entries(attributes[type as 'node' | 'balance']).reduce((acc, cur) => {
				const [attrIdStr, value] = cur;
				if(value){
					const attributeId = Number(attrIdStr);
					return `${acc}&filter[attributes][${type}][${attributeId}]=${encodeURIComponent(value)}`
				}
				return acc;
			}, '');
			result += attributesFilter;
		}
	}
	return result;
}

const tryLoad = (payload: {treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter, token: string}): ResponsePromiseType => {
	try{
		const {treeId, currentPage, pageSize, filters, attributes, token} = payload;
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const pagination = `page=${currentPage}&size=${pageSize}`;
		const queryFilter = extractFiltersString(filters, attributes);

		const params = queryFilter.length > 0 ? `${pagination}${queryFilter}` : pagination;
		const base_url = `${config.api_url}/reports/linear/${treeId}`;

		return fetch(`${base_url}?${params}`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractAttribute = (entry: any): TransactionOperationAttribute => {
	return {
		attributeId: entry.attributeId,
		attributeName: entry.attributeName,
		value: entry.value,
		valueId: entry.valueId || null,
		type: entry.type
	}
}

const extractNode = (entry: any): TransactionNode | null => {
	if(!entry){
		return null
	}
	return {
		id: entry.id,
		name: entry.name
	}
}

const extractOperation = (entry: any): TransactionOperation => {
	return {
		id: entry.id,
		node: extractNode(entry.node),
		correspondent: extractNode(entry.correspondent),
		value: entry.value,
		attributes: (entry.attributes || []).map(extractAttribute)
	}
}

const extractTransactionType = (entry: any): TransactionType => {
	return {
		id: entry.id,
		name: entry.name,
		slug: entry.slug
	}
}

const extractDate = (entry: any): Date => {
	const dateStr = entry.date == null ? entry : entry.date.replace(' ', 'T');
	const result = new Date(dateStr);
	return result;
}

const extractAuthor = (entry: any): TransactionAuthor => {
	return {
		id: entry.id,
		name: entry.name
	}
}

const extractTransaction = (entry: any): Transaction => {
	return {
		id: entry.id,
		author: extractAuthor(entry.author),
		node: extractNode(entry.node),
		correspondent: extractNode(entry.correspondent),
		comment: entry.comment,
		createdAt: extractDate(entry.createdAt),
		customDate: extractDate(entry.customDate),
		type: extractTransactionType(entry.type),
		operations: (entry.operations || []).map(extractOperation)
	}
}

const loadSaga = function*(action: any){
	const {treeId, currentPage, pageSize, filters, attributes} = action.payload;
	const response: ResponseType = yield call(tryLoad, action.payload);
	yield* handleResponse(
		response,
		(body: any) => loadSuccess(treeId, currentPage, pageSize, filters, attributes, body.data.map(extractTransaction)),
		(message: any) => loadFailed(treeId, currentPage, pageSize, filters, attributes, message)
	)
}

const watch = function*() {
	yield takeLeading(actions.transactionsElastic.types.TRANSACTIONS_ELASTIC_LOAD, loadSaga)
}

export default watch;
