import React, { useState } from 'react';
import { Select } from 'antd';

import { UserPanel } from '..';
import { localization } from '../../settings';
import { User } from '../../models';
import { LabeledValue } from 'antd/lib/select';

export interface AddUserPanelProps{
    users?: User[] | null,

    addUser: (userId: number) => void
}

const AddUserPanel = (props: AddUserPanelProps) => {
    const [isSelecting, setIsSelecting] = useState(false);

    if(!isSelecting){
        return (
            <UserPanel
                user = "add"
                onClick = {() => setIsSelecting(true)}
            />
        )    
    }

    const {users, addUser} = props;

    const onNewUserSelected = (value: string | number | LabeledValue) => {
        if(typeof value === "number"){
            addUser(value);
        }
        setIsSelecting(false);
    }

    const renderSelectingUserPanel = () => {
        const options = users?.map(u => <Select.Option key={u.id} value={u.id || ''}>{u.name}</Select.Option>)
        return (
            <Select
                showSearch
                placeholder = {localization.userSearch_placeholder}
                onSelect = {onNewUserSelected}
                optionFilterProp="children"
                filterOption={(input,option: any ) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
            >
                {options}
            </Select>
        );
    }    

    return (
        <UserPanel
            user = "custom"
            render = {renderSelectingUserPanel}
        />
    );
}

export default AddUserPanel