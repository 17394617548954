import actions from "../actions";

import {AttributeGroup, EntityHolder} from '../models'

export interface AttributeGroupsInfo extends EntityHolder<AttributeGroup>{
	attributesInfo: {[groupId: number]: {id: number, name: string}[] | undefined} | null,
	isAttributesLoading: boolean
}

export interface AttributeGroupsState {
	[treeId: number]: AttributeGroupsInfo | undefined
}

const defaultState: AttributeGroupsState = {
}

const state = (state: AttributeGroupsState = defaultState, action: any): AttributeGroupsState => {
	switch(action.type){
		case actions.attributeGroups.types.ATTRIBUTE_GROUPS_LOAD: {
			const {treeId} = action.payload;
			return {
				...state,
				[treeId]: {
					list: null,
					isLoading: true,
					attributesInfo: null,
					isAttributesLoading: false
				}
			}
		}
		case actions.attributeGroups.types.ATTRIBUTE_GROUPS_LOAD_SUCCESS: {
			const {treeId, groups} = action.payload;
			const previousInfo = state[treeId];
			return {
				...state,
				[treeId]: {
					attributesInfo: null,
					isAttributesLoading: false,
					...previousInfo,
					list: groups,
					isLoading: false
				}
			}
		}
		case actions.attributeGroups.types.ATTRIBUTE_GROUPS_LOAD_FAILED: {
			console.error('ATTRIBUTE_GROUPS_LOAD_FAILED\n%o', action.payload);
			const {treeId, message} = action.payload;
			const previousInfo = state[treeId];
			return {
				...state,
				[treeId]: {
					attributesInfo: {},
					isAttributesLoading: false,
					...previousInfo,
					list: [],
					isLoading: false,
					error: message
				}
			}
		}

		case actions.attributeGroup.types.ATTRIBUTE_GROUP_CREATE_SUCCESS:
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_UPDATE_SUCCESS:
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_DELETE_SUCCESS:
		{
			const {treeId} = action.payload;
			const previousInfo = state[treeId];
			return {
				...state,
				[treeId]: {
					attributesInfo: null,
					isAttributesLoading: false,
					...previousInfo,
					list: null,
					isLoading: false
				}
			}
		}
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_CREATE_FAILED:
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_UPDATE_FAILED:
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_DELETE_FAILED:
		{
			console.error(`${action.type}\n%o`, action.payload);
			const {treeId} = action.payload;
			const previousInfo = state[treeId];
			return {
				...state,
				[treeId]:{
					attributesInfo: {},
					isAttributesLoading: false,
					...previousInfo,
					list: null,
					isLoading: false
				}
			}
		}

		case actions.attributeGroups.types.ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD:{
			const {treeId} = action.payload;
			const previousInfo  = state[treeId];
			return {
				...state,
				[treeId]: {
					list: null,
					isLoading: false,
					...previousInfo,
					attributesInfo: null,
					isAttributesLoading: true
				}
			}
		}
		case actions.attributeGroups.types.ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD_SUCCESS:{
			const {treeId, attributes} = action.payload;
			const previousInfo  = state[treeId];
			return {
				...state,
				[treeId]: {
					list: null,
					isLoading: false,
					...previousInfo,
					attributesInfo: attributes,
					isAttributesLoading: false
				}
			}
		}
		case actions.attributeGroups.types.ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD_FAILED:{
			const {treeId} = action.payload;
			const previousInfo  = state[treeId];
			return {
				...state,
				[treeId]: {
					list: null,
					isLoading: false,
					...previousInfo,
					attributesInfo: {},
					isAttributesLoading: false
				}
			}
		}

		case actions.attribute.types.ATTRIBUTE_CREATE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_UPDATE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_DELETE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_SET_GROUPS_SUCCESS:{
			const {treeId} = action.payload;
			return {
				...state,
				[treeId]: undefined
			}
		}
	}
	return state;
}

export default state;