import { call, takeLeading, all, put} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { TreePermission, ActionGroup } from '../models';
import handleResponse from './handleResponse';

const {
	loadSuccess, loadFailed
} = actions.actionGroups;
const {raise} = actions.error;

const tryLoad = (payload: {token: string}): Promise<{status: number, body: any} | {error: any}> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/action-groups`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractActionGroup = (entry: any): ActionGroup => {
	const treePermissions: TreePermission[] = entry.permissions.map((permission: any): TreePermission => ({
		id: permission.id,
		name: permission.name,
		slug: permission.slug
	}));

	return {
		id: entry.id,
		name: entry.name,
		slug: entry.slug,
		permissions: treePermissions
	}
}

const loadActionGroupsSaga = function*(action: any){
	try{
		const response = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(body.data.map(extractActionGroup)),
			(message: any) => loadFailed(message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed('An error occurred'));
		return;
	}
}

const watchActionGroups = function*() {
	yield all([
		takeLeading(actions.actionGroups.types.ACTION_GROUPS_LOAD, loadActionGroupsSaga)
	])
}

export default watchActionGroups;