import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { CommonPageProps, NodesState, TransactionAttributes, TransactionsTableState, TransactionsFilters, TransactionsElasticPerTree, TransactionsElasticAttributesFilter } from "../../models";
import {getTreeSecurityProvider, transactions as transactionsSettings} from "../../settings";
import { Error } from '../../components';

import { TransactionFilterValuesState } from "../../reducers/transactionFilterValues";
import { TransactionsElasticContainerStates } from "../../reducers/transactionsElasticContainerState";
import { TransactionsElasticAttributesState } from "../../reducers/transactionsElasticAttributes";

import TransactionsElasticContainer from "./TransactionsElasticContainer";
import { transactionTypes } from "../../settings/transaction";

interface TransactionsElasticProps extends CommonPageProps{
	nodes: NodesState,
	transactionsPerTree: TransactionsElasticPerTree,
	transactionsElasticContainerStates: TransactionsElasticContainerStates,
	transactionsElasticAttributes: TransactionsElasticAttributesState,
	transactionFilterValues: TransactionFilterValuesState,
	transactionAttributes: TransactionAttributes,
	token: string,

	loadTransactions: (treeId: number, page: number, size: number, filters: TransactionsFilters, attributes: TransactionsElasticAttributesFilter | null, token: string) => void,
	loadNodeName: (nodeId: number, token: string) => void,
	loadTransactionFilterValues: (treeId: number, fieldName: string, token: string) => void,
	loadNodeActionGroups: (nodeId: number, userId: number, token: string) => void,

	unloadTransactions: (treeId: number) => void,

	saveContainerState: (treeId: number, state: TransactionsTableState) => void,
	loadTransactionsElasticAttributes: (treeId: number, token: string) => void,
}

const TransactionsElastic = (props: TransactionsElasticProps) => {
	const {treeId} = useParams<{treeId: string}>();

	const {
		nodes,
		transactionsPerTree,
		currentUser, token,
		transactionFilterValues,
		transactionsElasticContainerStates, saveContainerState,
		loadTransactionFilterValues,
		loadTransactions, loadNodeName,
		unloadTransactions,
		transactionAttributes,
		loadNodeActionGroups,
		transactionsElasticAttributes,
		loadTransactionsElasticAttributes
	} = props;

	const safeId = parseInt(treeId);
	const node = nodes && nodes[safeId];
	const transactions = transactionsPerTree[safeId];
	const filterValuesOnTree = transactionFilterValues[safeId];
	const currentTransactionsElasticAttributes = transactionsElasticAttributes[safeId];

	useEffect(() => {
		if(!transactions || (!transactions.list && !transactions.isLoading && !transactions.error)){
			const currentPage = transactions?.currentPage || transactionsSettings.defaultFirstPage;
			const pageSize = transactions?.pageSize || transactionsSettings.defaultPageSize;
			const filters = transactions?.filters || {};
			const attributesFilter = transactions?.attributes || null;
			loadTransactions(safeId, currentPage, pageSize, filters, attributesFilter, token)
		}
		if(!node || (!node.name && !node.isNameLoading && !node.nameError)){
			loadNodeName(safeId, token);
		}
		if(!filterValuesOnTree || !filterValuesOnTree.authorId ||
			(!filterValuesOnTree.authorId.list && !filterValuesOnTree.authorId.isLoading && !filterValuesOnTree.authorId.error)
		){
			loadTransactionFilterValues(safeId, "authorId", token);
		}
		if(!filterValuesOnTree || !filterValuesOnTree.nodeId ||
			(!filterValuesOnTree.nodeId.list && !filterValuesOnTree.nodeId.isLoading && !filterValuesOnTree.nodeId.error)
		){
			loadTransactionFilterValues(safeId, "nodeId", token);
		}
		if(!filterValuesOnTree || !filterValuesOnTree.correspondentId ||
			(!filterValuesOnTree.correspondentId.list && !filterValuesOnTree.correspondentId.isLoading && !filterValuesOnTree.correspondentId.error)
		){
			loadTransactionFilterValues(safeId, "correspondentId", token);
		}
		const rootNode = nodes[safeId];
		const rootNodeSelfInfo = rootNode && rootNode.users && rootNode.users[currentUser.id]
		if(!rootNodeSelfInfo){
			loadNodeActionGroups(safeId, currentUser.id, token);
		}
		if(!currentTransactionsElasticAttributes || (
			!currentTransactionsElasticAttributes.list && currentTransactionsElasticAttributes?.isLoading && currentTransactionsElasticAttributes.error
		)){
			loadTransactionsElasticAttributes(safeId, token);
		}
	});

	if(transactions?.error){
		return (
			<Error
				reset = {() => unloadTransactions(safeId)}
				message = {transactions.error}
			/>
		)
	}

	const authorIdFilterValues = transactionFilterValues[safeId]?.authorId?.list || [];
	const nodeIdFilterValues = transactionFilterValues[safeId]?.nodeId?.list || [];
	const correspondentIdFilterValues = transactionFilterValues[safeId]?.correspondentId?.list || [];
	const containerState = transactionsElasticContainerStates[safeId]?.entity || null;

	const treeSecurityProvider = getTreeSecurityProvider(currentUser, safeId, nodes);

  const transactionTypesOnTree = transactionTypes?.filter(
    (t) =>
        treeSecurityProvider?.allowedTransactions[
            t.slug as keyof typeof treeSecurityProvider.allowedTransactions
        ],
  );

	return (
		<TransactionsElasticContainer
			treeId = {safeId}
			treeName = {node?.name || null}
			transactionTypes = {transactionTypesOnTree || null}
			transactions = {transactions || null}
			authorIdFilterValues = {authorIdFilterValues || null}
			nodeIdFilterValues = {nodeIdFilterValues || null}
			correspondentIdFilterValues = {correspondentIdFilterValues || null}
			transactionAttributes = {transactionAttributes}
			containerState = {containerState}
			currentUser = {currentUser}
			token = {token}
			treeSecurityProvider = {treeSecurityProvider}
			transactionsElasticAttributes = {currentTransactionsElasticAttributes?.list || null}
			loadTransactions = {loadTransactions}
			loadTransactionFilterValues = {loadTransactionFilterValues}
			saveContainerState = {saveContainerState}
		/>
	)
}

export default TransactionsElastic