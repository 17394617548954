const actionTypes = {
	ATTRIBUTE_VALUE_CREATE: 'ATTRIBUTE_VALUE\\CREATE',
	ATTRIBUTE_VALUE_CREATE_SUCCESS: 'ATTRIBUTE_VALUE\\CREATE_SUCCESS',
	ATTRIBUTE_VALUE_CREATE_FAILED: 'ATTRIBUTE_VALUE\\CREATE_FAILED',

	ATTRIBUTE_VALUE_UPDATE: 'ATTRIBUTE_VALUE\\UPDATE',
	ATTRIBUTE_VALUE_UPDATE_SUCCESS: 'ATTRIBUTE_VALUE\\UPDATE_SUCCESS',
	ATTRIBUTE_VALUE_UPDATE_FAILED: 'ATTRIBUTE_VALUE\\UPDATE_FAILED',

	ATTRIBUTE_VALUE_DELETE: 'ATTRIBUTE_VALUE\\DELETE',
	ATTRIBUTE_VALUE_DELETE_SUCCESS: 'ATTRIBUTE_VALUE\\DELETE_SUCCESS',
	ATTRIBUTE_VALUE_DELETE_FAILED: 'ATTRIBUTE_VALUE\\DELETE_FAILED',
}

const create = (value: {name: string, visibilityId: number}, nodeId: number, attributeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_VALUE_CREATE,
	payload: {
		name: value.name,
		visibilityId: value.visibilityId,
		nodeId,
		attributeId,
		token
	}
})

const createSuccess = (id: number, nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_VALUE_CREATE_SUCCESS,
	payload: {
		id,
		nodeId
	}
})

const createFailed = (message: string, nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_VALUE_CREATE_FAILED,
	payload: {
		message,
		nodeId
	}
})

const update = (id: number, value: {name: string | null, visibilityId: number | null}, nodeId: number, attributeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_VALUE_UPDATE,
	payload: {
		id,
		name: value.name,
		visibilityId: value.visibilityId,
		nodeId,
		attributeId,
		token
	}
})

const updateSuccess = (nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_VALUE_CREATE_SUCCESS,
	payload: {
		nodeId
	}
})

const updateFailed = (message: string, nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_VALUE_CREATE_FAILED,
	payload: {
		message,
		nodeId
	}
})

const deleteAttributeValue = (id: number, nodeId: number, attributeId: number, token: string) =>({
	type: actionTypes.ATTRIBUTE_VALUE_DELETE,
	payload: {
		id,
		nodeId,
		attributeId,
		token

	}
})

const deleteSuccess = (nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_VALUE_DELETE_SUCCESS,
	payload: {
		nodeId
	}
})

const deleteFailed = (message: string, nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_VALUE_DELETE_FAILED,
	payload: {
		message,
		nodeId
	}
})

export default {
	types: actionTypes,
	create,
	createSuccess,
	createFailed,
	update,
	updateSuccess,
	updateFailed,
	delete: deleteAttributeValue,
	deleteSuccess,
	deleteFailed
};