import { Tree } from "../../models";

const is_match = (text: string, name: string) => {
	return name.toLowerCase().indexOf(text) > -1;
}

const search_internal = (text: string, tree: Tree, base_path: number[]): number[][] => {
	const results = [];
	if(is_match(text, tree.name || '')){
		results.push([...base_path]);
	}

	(tree.children || []).reduce((acc, cur) => {
		acc.push(...search_internal(text, cur, [...base_path, cur.id]))
		return acc;
	}, results);

	return results;
}

const search = (text: string, tree: Tree[] | Tree | null): number[][] => {
	if(!tree){
		return [];
	}
	if(Array.isArray(tree)){
		const result = tree
			.reduce((acc, cur) => {
				const subsearch = search_internal(text.toLocaleLowerCase(), cur, [cur.id]);
				acc.push(...subsearch);
				return acc;
			}, [] as number[][]);
		return result;
	}
	return search_internal(text.toLowerCase(), tree, [tree.id]);
}

export default search;