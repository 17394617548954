import React from 'react';

interface ZoomPanelProps{
    zoom?: number,
    className?: string | null,
    children?: React.ReactNode,
}

const ZoomPanel = (props: ZoomPanelProps) => {
    const {className, children, zoom = 1} = props;
    const containerClassName = 'zoom-panel';
    const effectiveContainerClassName = className ? `${containerClassName} ${className}` : containerClassName;

    const sizePercents = Math.round(10000/zoom) / 100;
    const canvasStyle = {
        transform: `scale(${zoom})`,
        height: `${sizePercents}%`,
        width: `${sizePercents}%`
    }

    return (
        <div className = {effectiveContainerClassName}>
            <div className = {`${containerClassName}__canvas`} style={canvasStyle}>
                {children}
            </div>
        </div>
    );
}

export default ZoomPanel;