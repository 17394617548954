import { TreeContainerState } from "../models";

const actionTypes = {
	TREE_CONTAINER_STATE_SAVE: 'TREE_CONTAINER_STATE\\SAVE',
}

const save = (treeId: number, treeState: TreeContainerState) => ({
	type: actionTypes.TREE_CONTAINER_STATE_SAVE,
	payload: {
		treeId,
		treeState
	}
})

export default {
	types: actionTypes,
	save
};