import { User } from "../models";

const actionTypes = {
	USER_CREATE: 'USER\\CREATE',
	USER_UPDATE: 'USER\\UPDATE',
	USER_DELETE: 'USER\\DELETE',
	USER_REQUEST_SUCCESS: 'USER\\REQUEST_SUCCESS',
	USER_REQUEST_FAILED: 'USER\\REQUEST_FAILED',
}

const create = (user: User, token: string, prevRoleIds?: string[], nextRoleIds?: string[]) => ({
	type: actionTypes.USER_CREATE,
	payload: {
		token: token,
		user: user,
		prevRoleIds: prevRoleIds,
		nextRoleIds: nextRoleIds
	}
})

const update = (user: User, token: string, prevRoleIds?: string[], nextRoleIds?: string[]) => ({
	type: actionTypes.USER_UPDATE,
	payload: {
		token: token,
		user: user,
		prevRoleIds: prevRoleIds,
		nextRoleIds: nextRoleIds
	}
})

const deleteUser = (id: number, token: string) => ({
	type: actionTypes.USER_DELETE,
	payload: {
		token: token,
		id: id
	}
})

const requestSuccess = (id: number) => ({
	type: actionTypes.USER_REQUEST_SUCCESS,
	payload: {
		id
	}
})

const requestFailed = (id: number, message: string) => ({
	type: actionTypes.USER_REQUEST_FAILED,
	payload: {
		id,
		message: message
	}
})

export default {
	types: actionTypes,
	create,
	update,
	delete: deleteUser,
	requestSuccess,
	requestFailed
};