import { call, put, takeLeading} from 'redux-saga/effects';

import {config} from '../settings'

import actions from '../actions';
import { UserAccount, User } from '../models';
import handleResponse from './handleResponse';

type ResponseType = {status: number, body: any} | {error: any}

const {loadSuccess, loadFailed} = actions.account;
const {raise} = actions.error;

const tryLoadAccount = (payload: {userId: number, token: string}): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/users/${payload.userId}`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractAccount = (user: User): UserAccount => {
	const permissions = (user.roles || [])
		.reduce((acc: string[], cur) => {
			const permissionSlugs = cur.permissions?.map(p => p.slug);
			if(permissionSlugs && permissionSlugs?.length > 0){
				return [...acc, ...permissionSlugs];
			}
			return acc;
		}, []);
	return {
		id: user.id || -1,
		name: user.name || '',
		firstname: user.firstname || '',
		lastname: user.lastname || '',
		email: user.email || '',
		permissions: permissions
	}
}

const loadAccountSaga = function*(action: any){
	try{
		const response: ResponseType = yield call(tryLoadAccount, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(extractAccount(body?.data)),
			(error: any) => loadFailed(error),
			null,
			function*(){
				yield put(actions.auth.logout())
			}
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed('An error occurred'));
		return;
	}
}

const watchAccount = function*() {
	yield takeLeading(actions.account.types.ACCOUNT_LOAD, loadAccountSaga)
}

export default watchAccount;