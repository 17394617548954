import actions from "../actions";

import { TransactionChangeLogsPerTree } from "../models";

const defaultState: TransactionChangeLogsPerTree = {
}

const state = (state: TransactionChangeLogsPerTree = defaultState, action: any): TransactionChangeLogsPerTree => {
	switch(action.type){
		case actions.transactionChangeLogs.types.TRANSACTION_CHANGE_LOGS_LOAD: {
			const {nodeId, currentPage, pageSize, filters} = action.payload;
			const updatedState: TransactionChangeLogsPerTree = {
				...state,
				[nodeId]: {
					loadedAt: null,
					list: null,
					isLoading: true,
					error: null,
					currentPage,
					pageSize,
					filters,
				}
			}
			return updatedState;
		}
		case actions.transactionChangeLogs.types.TRANSACTION_CHANGE_LOGS_LOAD_SUCCESS: {
			const {nodeId, transactionChangeLogs, currentPage, pageSize, filters} = action.payload;
			const updatedState: TransactionChangeLogsPerTree = {
				...state,
				[nodeId]: {
					loadedAt: new Date(),
					list: transactionChangeLogs,
					isLoading: false,
					error: null,
					currentPage,
					pageSize,
					filters,
				}
			}
			return updatedState;
		}
		case actions.transactionChangeLogs.types.TRANSACTION_CHANGE_LOGS_LOAD_FAILED: {
			const {nodeId, message, currentPage, pageSize, filters} = action.payload;
			const updatedState: TransactionChangeLogsPerTree = {
				...state,
				[nodeId]: {
					loadedAt: new Date(),
					list: null,
					isLoading: false,
					error: message,
					currentPage,
					pageSize,
					filters,
				}
			}
			return updatedState;
		}
		case actions.transactionChangeLogs.types.TRANSACTION_CHANGE_LOGS_UNLOAD: {
			const {nodeId} = action.payload;
			const updatedState = {...state}
			delete updatedState[nodeId];
			return updatedState;
		}
	}
	return state;
}

export default state;