import { TreeListEntry } from "../models";

const actionTypes = {
	TREES_LOAD: 'TREES\\LOAD',
	TREES_LOAD_SUCCESS: 'TREES\\LOAD_SUCCESS',
	TREES_LOAD_FAILED: 'TREES\\LOAD_FAILED',
	TREES_RESET: 'TREES\\RESET'
}

const load = (token: string) => ({
	type: actionTypes.TREES_LOAD,
	payload: {
		token: token
	}
})

const loadSuccess = (trees?: TreeListEntry[]) => ({
	type: actionTypes.TREES_LOAD_SUCCESS,
	payload: {
		trees: trees
	}
})

const loadFailed = (message: string) => ({
	type: actionTypes.TREES_LOAD_FAILED,
	payload: {
		message: message
	}
})

const reset = () => ({
	type: actionTypes.TREES_RESET
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	reset
};