import React from 'react';
import { Checkbox, Select, Dropdown, Menu, Spin } from 'antd';
import { Edit, GitPullRequest, MoreVertical, Trash } from 'react-feather';

import { Attribute, AttributeGroup, AttributeVisibility, Tree } from '../../models';
import { localization } from '../../settings';
import { findNode } from '../../tools';
import { AttributeValues, ConfirmDelete, SetNameDialog } from '../../components';

interface AttributesTableProps{
	className?: string | null,

	mode: 'own' | 'inherited',
	attributes: Attribute[],
	attributeVisibilities: AttributeVisibility[] | null,
	attributeGroups: AttributeGroup[] | null,
	attributeGroupsIsLoading: boolean;
	tree?: Tree | null,

	updateAttribute: (id: number, name?: string, visibilitId?: number) => void,
	deleteAttribute: (id: number) => void

	createAttributeValue: (value: {name: string, visibilityId: number}, attributeId: number) => void,
	editAttributeValue: (id: number, value: {name: string | null, visibilityId: number | null}, attributeId: number) => void,
	deleteAttributeValue: (id: number, attributeId: number) => void,
	onSetAttributeValue: (attributeId: number, attributeValueId: number, propagateValue?: boolean) => void,

	onChangeGroups: (attributeId: number, newGroupIds: number[], currentGroupIds: number[]) => void
}

const containerClassName = 'attributes-table';

interface ColumnDescriptor{
	id: React.ReactText,
	title: React.ReactNode,
	modes: ('own' | 'inherited')[],
	render: (attribute: Attribute, props: AttributesTableProps) => React.ReactNode
}

const columns: ColumnDescriptor[] = [{
	id: 0,
	title: (
		<div className = {`${containerClassName}__v-centered`}>
			{localization.nodeAttributes_table_attribute}
		</div>
	),
	modes: ['own', 'inherited'],
	render: (attribute) => (
		<div className = {`${containerClassName}__v-centered title`}>
			{attribute.name}
		</div>
	)
},{
	id: 'value',
	title: (
		<div className = {`${containerClassName}__v-centered`}>
			{localization.nodeAttributes_table_value}
		</div>
	),
	modes: ['own', 'inherited'],
	render: (attribute, props) => {
		const {
			attributeVisibilities,
			onSetAttributeValue, createAttributeValue, editAttributeValue, deleteAttributeValue
		} = props;
		return (
			<AttributeValues
				values = {attribute.values}
				visibilities = {attributeVisibilities}
				onSelectValue = {(attributeValueId) => onSetAttributeValue(attribute.id, attributeValueId)}
				addValue = {(value) => createAttributeValue(value, attribute.id)}
				editValue = {(id, value) => editAttributeValue(id, value, attribute.id)}
				deleteValue = {(id) => deleteAttributeValue(id, attribute.id)}
			/>
		)
	}
},{
	id: 'propagate',
	title: (
		<div className = {`${containerClassName}__centered`}>
			{localization.nodeAttributes_table_propagate}
		</div>
	),
	modes: ['own', 'inherited'],
	render: (attribute, props) => {
		const {onSetAttributeValue} = props;
		const value = attribute.values.find(a => a.isSelected);
		if(!value){
			return null;
		}
		return (
			<div className = {`${containerClassName}__centered`}>
				<Checkbox
					disabled = {value.isBlocked}
					checked = {value.propagate || value.isBlocked}
					onChange = {(event) => onSetAttributeValue(attribute.id, value.id, event.target.checked)}
				/>
			</div>
		)
	}
},{
	id: 'inherited',
	title: (
		<div className = {`${containerClassName}__v-centered`}>
			{localization.nodeAttributes_table_inherited}
		</div>
	),
	modes: ['inherited'],
	render: (attribute, props) => {
		const {tree} = props
		const inheritedFrom = findNode(attribute.nodeId, tree || null);
		return (
			<div className = {`${containerClassName}__v-centered`}>
				<GitPullRequest className = {`${containerClassName}__ico`}/>
				<span>{inheritedFrom?.name}</span>
			</div>
		)
	}
},{
	id: 'groups',
	title: (
		<div className = {`${containerClassName}__v-centered`}>
			{localization.nodeAttributes_table_groups}
		</div>
	),
	modes: ['own', 'inherited'],
	render: (attribute, props) => {
		const {attributeGroups, attributeGroupsIsLoading, onChangeGroups} = props;
		if(attributeGroupsIsLoading){
			return <Spin/>
		}
		const value = attribute.groups
			.map(g => g.id);
		const optionElements = attributeGroups?.map(g => {
			return (
				<Select.Option key = {g.id} value = {g.id}>
					{g.name}
				</Select.Option>
			)
		});
		const onChangeHandler = (values: number[]) => {
			onChangeGroups(attribute.id, values, attribute.groups.map(g => g.id));
		}

		return (
			<Select
				mode = 'multiple'
				value = {value}
				className = {`${containerClassName}__attribute-groups`}
				onChange = {onChangeHandler}
				style = {{fontSize: '11px', lineHeight: '15px'}}
			>
				{optionElements}
			</Select>
		);
	}
},{
	id: 'visibility',
	title: (
		<div className = {`${containerClassName}__v-centered`}>
			{localization.nodeAttributes_table_visibility}
		</div>
	),
	modes: ['own', 'inherited'],
	render: (attribute, props) => {
		const {attributeVisibilities, mode, updateAttribute} = props
		return (
			<Select
				className = {`${containerClassName}__wide-block`}
				value = {attribute.visibility.id}
				onChange = {(value) => updateAttribute(attribute.id, undefined, value)}
				disabled = {mode !== 'own'}
				style = {{fontSize: '11px', lineHeight: '15px'}}
			>
				{(attributeVisibilities || []).map(av => (
					<Select.Option value = {av.id} key = {av.id}>
						{av.name}
					</Select.Option>
				))}
			</Select>
		)
	}
},{
	id: 'actions',
	title: null,
	modes: ['own'],
	render: (attribute, props) => {
		const {updateAttribute, deleteAttribute} = props;
		const menu = (
			<Menu>
				<Menu.Item
					icon = {<Edit className = 'feather-ico' />}
					onClick = {() => {
						SetNameDialog.show({
							initialValue: attribute.name,
							title: localization.rename_attribute_title,
							onOk: (name) => updateAttribute(attribute.id, name)
						})
					}}
				>
					{localization.edit}
				</Menu.Item>
				<Menu.Item
					danger
					icon = {<Trash className = 'feather-ico' />}
					onClick = {() => {
						ConfirmDelete({
							title: localization.delete_attribute_title,
							content: localization.delete_attribute_content,
							deleteEntity: () => deleteAttribute(attribute.id)
						})
					}}
				>
					{localization.delete}
				</Menu.Item>
			</Menu>
		);
		return (
			<div className = {`${containerClassName}__actions`}>
				<Dropdown overlay = {menu} trigger={['click']}>
					<MoreVertical className = 'feather-ico' />
				</Dropdown>
			</div>
		)
	}
}]

class AttributesTable extends React.Component<AttributesTableProps>{
	renderHeaders = () => {
		const {mode} = this.props;
		const cellElements = columns
			.filter((col) => col.modes.indexOf(mode) > -1)
			.map((col) => {
				return (
					<th className = {`${containerClassName}__tr__th`} key = {col.id}>
						{col.title}
					</th>
				);
			});
		return (
			<tr className = {`${containerClassName}__tr`}>
				{cellElements}
			</tr>
		);
	}

	renderAttribute = (attribute: Attribute) => {
		const {mode} = this.props;
		const cellElements = columns
			.filter((col) => col.modes.indexOf(mode) > -1)
			.map((col) => {
				return (
					<td className = {`${containerClassName}__tr__td`} key={col.id}>
						{col.render(attribute, this.props)}
					</td>
				);
			});
		return (
			<tr className = {`${containerClassName}__tr`} key = {attribute.id}>
				{cellElements}
			</tr>
		);
	}

	render(){
		const {className, attributes} = this.props;
		const effectiveContainerClassName = className ? `${containerClassName} ${className}` : containerClassName;

		return(
			<table className = {effectiveContainerClassName}>
				<thead>
					{this.renderHeaders()}
				</thead>
				<tbody>
					{
						attributes
							.sort((a1, a2) => a1.id - a2.id)
							.map(a => this.renderAttribute(a))
					}
				</tbody>
			</table>
		)
	}
}

export default AttributesTable;