import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { localization, slugs } from '../../settings';
import { Balance, BalanceAttribute, EntityPerBalance, EntityPerTree, NodeAttributeShort } from '../../models';
import { BalanceTypesState } from '../../reducers/balanceTypes';
import { Button } from '../../components';

import FormulaEntryModel from './FormulaEntryModel';
import DraggableEntryModel from './DraggableEntryModel';
import FormulaPanel from './FormulaPanel';
import {parseFormula, makeFormula} from './formula';
import FormulaLegend, { FormulaLegendBlock } from './FormulaLegend';
import { BalanceFormulaState } from '../../reducers/balanceFormula';

export interface FormulaEditorProps{
	className?: string | null,

	treeId: number,
	balanceId: number,
	balancesPerTree: EntityPerTree<Balance>,
	formulaPerBalance: BalanceFormulaState,
	balanceAttributes: EntityPerBalance<BalanceAttribute>,
	balanceTypes: BalanceTypesState,
	nodeAttributesPerTree: EntityPerTree<NodeAttributeShort>
	token: string,

	setBalanceFormula: (treeId: number, balanceId: number, formula: string, token: string) => void,

	loadBalances: (treeId: number, token: string) => void,
	loadBalanceFormula: (treeId: number, balanceId: number, token: string) => void,
	loadBalanceAttributes: (treeId: number, balanceId: number, token: string) => void,
	loadNodeAttributes: (treeId: number, token: string) => void,
	loadBalanceTypes: (token: string) => void,
	resetTree: (treeId: number) => void,
}

interface FormulaEditorState{
	formulaValues: FormulaEntryModel[] | null,
}

const containerClassName = 'bra-formula-editor'

class FormulaEditor extends React.Component<FormulaEditorProps, FormulaEditorState> {
	constructor(props: FormulaEditorProps){
		super(props);

		this.state = {
			formulaValues: null
		}
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	getEntryName = (model: FormulaEntryModel): string | null => {
		const {treeId, balancesPerTree, balanceAttributes, nodeAttributesPerTree} = this.props;

		switch(model.kind){
			case 'operation': {
				return `${model.id}`
			}
			case 'balance': {
				const balance = balancesPerTree[treeId]?.list
					?.find(b => b.id === model.id);
				return balance?.name || null;
			}
			case 'nodeAttribute': {
				const currentNodeAttributes = nodeAttributesPerTree[treeId]?.list || [];
				const nodeAttribute = currentNodeAttributes
					.find(na => na.id === model.id);
				return nodeAttribute?.name || null;
			}
			case 'balanceAttribute': {
				const idStrings = Object.keys(balanceAttributes);
				for(const balanceIdStr of idStrings){
					const balanceId = parseInt(balanceIdStr);
					if(isNaN(balanceId)){
						continue;
					}
					const foundBalanceAttribute = (balanceAttributes[balanceId]?.list || [])
						.find(ba => ba.id === model.id);
					if(foundBalanceAttribute){
						const balance = (balancesPerTree[treeId]?.list || [])
							.find(b => b.id === balanceId);
						return `${foundBalanceAttribute.name} [${balance?.name}]`;
					}
				}
				return null;
			}
		}
		return null;
	}

	getBalances = () => {
		const {treeId, balancesPerTree} = this.props;
		return balancesPerTree[treeId]?.list || [];
	}

	getFormulaBalanceIds = () => {
		const {formulaValues} = this.state;
		const result = formulaValues
			?.filter(e => e.kind === 'balance')
			.map(e => e.id) || [];
		return result;
	}

	loadData = () => {
		const {
			treeId, balanceId, token,
			balancesPerTree, loadBalances,
			formulaPerBalance, loadBalanceFormula,
			balanceAttributes, loadBalanceAttributes,
			balanceTypes, loadBalanceTypes,
			nodeAttributesPerTree, loadNodeAttributes
		} = this.props;

		const {formulaValues} = this.state;

		if(!balanceTypes || (!balanceTypes.list && !balanceTypes.isLoading)){
			loadBalanceTypes(token);
		}

		if(!treeId || !balanceId){
			return;
		}

		const currentNodeAttributes = nodeAttributesPerTree[treeId];
		if(!currentNodeAttributes || (
			!currentNodeAttributes.list &&
			!currentNodeAttributes.isLoading &&
			!currentNodeAttributes.error
		)){
			loadNodeAttributes(treeId, token);
		}

		const currentBalances = balancesPerTree[treeId];
		if(!currentBalances || (
			!currentBalances.list && !currentBalances.isLoading && !currentBalances.error
		)){
			loadBalances(treeId, token);
		}

		const formulaHolder = formulaPerBalance[balanceId];
		if(!formulaHolder || (
			!formulaHolder.entity && !formulaHolder.isLoading
		)){
			loadBalanceFormula(treeId, balanceId, token);
		}
		if(formulaHolder && formulaHolder.entity && !formulaHolder.isLoading) {
			// if formula has been loaded, but state hasn't been initialized yet
			if(!formulaValues){
				this.setState((state) => ({
					...state,
					formulaValues: parseFormula(formulaHolder.entity?.text || null)
				}));
			}
		}

		if(formulaValues){
			const usedBalanceIds = formulaValues
				.filter(e => e.kind === 'balance')
				.map(e => e.id)
				.filter((id): id is number => typeof id === 'number')
				.filter((id, index, acc) => acc.indexOf(id) === index);

			usedBalanceIds.forEach(usedBalanceId => {
				const usedBalance = balancesPerTree[treeId]
					?.list?.find(b => b.id === usedBalanceId);
				const usedBalanceType = balanceTypes.list
					?.find(bt => bt.id === usedBalance?.typeId);
				if(!usedBalanceType || usedBalanceType.slug === slugs.balance_types.calculate){
					return;
				}
				const currentBalanceAttributes = balanceAttributes[usedBalanceId];
				if(!currentBalanceAttributes || (
					!currentBalanceAttributes.list && !currentBalanceAttributes.isLoading && !currentBalanceAttributes.error
				)){
					loadBalanceAttributes(treeId, usedBalanceId, token);
				}
			})
		}
	}

	onFormulaChanged = () => {
		const {balanceAttributes} = this.props;
		const {formulaValues} = this.state;
		// Collect all balance ids
		const availableBalanceIds: number[] = [];
		formulaValues?.forEach(entry => {
			if(entry.kind === 'balance' && typeof entry.id === 'number'){
				availableBalanceIds.push(entry.id)
			}
		})
		const allowedBalanceAttributeIds = availableBalanceIds
			.reduce((acc, cur) => {
				const balanceAtrributeIds = (balanceAttributes[cur]?.list || []).map(b => b.id);
				acc.push(...balanceAtrributeIds);
				return acc;
			}, [] as number[])
		//remove unavailable balance attributes
		const updatedFormulaValues: FormulaEntryModel[] | null = formulaValues
			?.filter(e => !(e.kind === 'balanceAttribute' && allowedBalanceAttributeIds.findIndex(id => id === e.id) < 0)) || null;
		this.setState((state) => ({
			...state,
			formulaValues: updatedFormulaValues
		}))
		
	}

	getLegend = (): FormulaLegendBlock[] => {
		const {balancesPerTree, balanceAttributes, treeId, balanceId, nodeAttributesPerTree} = this.props;
		const {formulaValues} = this.state;
		if(!treeId){
			return [];
		}

		const nodeAttributesLegend: FormulaEntryModel[] = (nodeAttributesPerTree[treeId]?.list || [])
			.map((e): FormulaEntryModel => ({
				id: e.id,
				kind: 'nodeAttribute'
			}))

		const currentBalances = balancesPerTree[treeId]?.list || [];

		const balancesLegend: FormulaEntryModel[] = currentBalances
			.filter(b => b.id !== balanceId)
			.map(b => ({
				id: b.id,
				title: b.name,
				kind: 'balance'
			})) || []

		const formulaBalanceIds = formulaValues
			?.filter(e => e.kind === 'balance')
			.map(e => e.id) || [];

		const balanceAttributesLegend = formulaBalanceIds
			.reduce((acc, curBalanceId) => {
				if(typeof curBalanceId !== 'number'){
					return acc;
				}
				const currentBalanceAttributes = balanceAttributes[curBalanceId];
				if(currentBalanceAttributes && currentBalanceAttributes.list){
					const attributesToShow: FormulaEntryModel[] = currentBalanceAttributes.list.map(a => ({
						id: a.id,
						kind: 'balanceAttribute'
					}))
					acc.push(...attributesToShow);
				}
				return acc;
			}, [] as FormulaEntryModel[])
			.filter((ba, index, acc) => index === acc.findIndex(tmp => tmp.id === ba.id));

		const operations: FormulaEntryModel[] = ['+', '-', '*', '/', '(', ')']
			.map((e) => ({
				id: e,
				title: e,
				kind: 'operation'
			}))

		return [{
			id: 'operations',
			title: localization.balance_editBalance_tabs_formula_legend_operations,
			values: operations
		},{
			id: 'balances',
			title: localization.balance_editBalance_tabs_formula_legend_balances,
			values: balancesLegend
		},{
			id: 'nodeAttributes',
			title: localization.balance_editBalance_tabs_formula_legend_nodeAttributes,
			values: nodeAttributesLegend,
		},{
			id: 'balanceAttributes',
			title: localization.balance_editBalance_tabs_formula_legend_balanceAttributes,
			values: balanceAttributesLegend || []
		}]
	}

	onItemDroppedInLegend = (draggedItem: DraggableEntryModel<FormulaEntryModel>, targetItem?: DraggableEntryModel<FormulaEntryModel>) => {
		const {formulaValues} = this.state;
		if(draggedItem.index != null){
			const updatedValues = formulaValues
				?.filter((_, i) => i !== draggedItem.index) || null;
			this.setState(state => ({
				...state,
				formulaValues: updatedValues
			}), () => {
				this.onFormulaChanged();
			});
		}
	}

	onItemDroppedInFormula = (draggedItem: DraggableEntryModel<FormulaEntryModel>, targetItem?: DraggableEntryModel<FormulaEntryModel>) => {
		this.setState(state => {
			const updatedFormulaValues = [...state.formulaValues || []]
				.filter((_, i) => i !== draggedItem.index);
			if(!targetItem){
				return {
					...state,
					formulaValues: [...updatedFormulaValues, draggedItem.originalItem]
				}
			}
			if(targetItem.index != null){
				const insertionIndex = targetItem.index <= (draggedItem.index || 0) ?
					targetItem.index :
					targetItem.index - 1;
				updatedFormulaValues.splice(insertionIndex, 0, draggedItem.originalItem)
			}
			return {
				...state,
				formulaValues: updatedFormulaValues
			}
		}, () => {
			this.onFormulaChanged();
		})
	}

	onSave = () => {
		const {
			treeId, balanceId,
			token,
			setBalanceFormula, resetTree
		} = this.props;
		const {formulaValues} = this.state;

		if(!treeId || !balanceId){
			return;
		}
		const formulaText = makeFormula(formulaValues);
		setBalanceFormula(treeId, balanceId, formulaText, token);
		resetTree(treeId);
	}

	render(){
		const {
			className,
			balanceId, formulaPerBalance
		} = this.props;

		const {
			formulaValues
		} = this.state;

		const legend = this.getLegend();

		const currentFormula = formulaPerBalance[balanceId];

		const {error} = currentFormula || {};

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		return (
			<div
				className = {effectiveClassName}
			>
				<DndProvider backend={HTML5Backend}>
					<FormulaLegend
						className = {`${containerClassName}__legend`}
						legend = {legend}
						getEntryName = {this.getEntryName}
						onDrop = {this.onItemDroppedInLegend}
					/>
					<div
						className = {`${containerClassName}__content`}
					>
						<FormulaPanel
							className = {`${containerClassName}__content__formula`}
							formula = {formulaValues}
							error = {error}
							getEntryName = {this.getEntryName}
							onDrop = {this.onItemDroppedInFormula}
						/>
						<Button
							type = 'primary'
							className = {`${containerClassName}__content__save`}
							onClick = {this.onSave}
						>
							{localization.balance_editBalance_tabs_formula_save}
						</Button>
					</div>
				</DndProvider>
			</div>
		)
	}
}

export default FormulaEditor;