import React, { useRef } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { Spin } from 'antd';

import FormulaEntryModel from './FormulaEntryModel';
import DraggableEntryModel from './DraggableEntryModel';

export interface FormulaEntryProps{
	model: FormulaEntryModel,

	index?: number,
	className?: string,
	isInsertionSupported?: boolean,
	getName: (model: FormulaEntryModel) => string | null,
	onDrop?: (draggedItem: DraggableEntryModel<FormulaEntryModel>, hoveredItem?: DraggableEntryModel<FormulaEntryModel>) => void
}

export const itemType = 'formula-entry-9x!Mr%N>q5][wepLFN.%[d&'

const containerClassName = 'bra-formula-entry';

const FormulaEntry = (props: FormulaEntryProps) => {
	const {
		className,
		model, index, getName,
		isInsertionSupported,
		onDrop
	} = props;

	const ref = useRef<HTMLDivElement>(null);

	const item: DraggableEntryModel<FormulaEntryModel> = {
		originalItem: model,
		type: itemType,
		index: index, // number in the list
	}

	const [{ isDragging }, drag] = useDrag({
		item: item,
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	const [{ isOver }, drop] = useDrop({
		accept: itemType,
		drop: (activeItem: DraggableEntryModel<FormulaEntryModel>, monitor: DropTargetMonitor) => {
			const alreadyDropped = monitor.didDrop();
			if(!alreadyDropped){
				onDrop && onDrop(activeItem, item);
			}
		},
		collect: (monitor: DropTargetMonitor) => {
			const isOver = monitor.isOver();
			return {
				isOver: isOver
			};
		}
	})

	drag(drop(ref));

	let effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	if(isDragging){
		effectiveClassName += ` ${containerClassName}--dragging`;
	}
	if(isOver){
		effectiveClassName += ` ${containerClassName}--over`;
	}
	if(isInsertionSupported){
		effectiveClassName += ` ${containerClassName}__insertable`;
	}
	
	let coloredClassName = `${containerClassName}__colored`;
	const content: React.ReactNode = getName(model) || (<Spin/>);
	switch(model.kind){
		case 'balanceAttribute':{
			coloredClassName += ` ${containerClassName}__colored--orange`;
			break;
		}
		case 'nodeAttribute':{
			coloredClassName += ` ${containerClassName}__colored--orange`;
			break;
		}
		case 'balance':{
			coloredClassName += ` ${containerClassName}__colored--blue`;
			break;
		}
		default:{
			coloredClassName += ` ${containerClassName}__colored--black`;
		}
	}
	return (
		<div
			className = {effectiveClassName}
			ref = {ref}
		>
			<div
				className = {coloredClassName}
			>
				{content}
			</div>
		</div>
	)
}

export default FormulaEntry