import React, { memo } from 'react';
import { ChevronDown, ChevronUp, Search, X } from 'react-feather';

import { localization } from '../../settings';

interface AdvancedSearchProps{
	className?: string;
	pattern?: RegExp;
	value?: string | null;
	navigation?: {
		current: number | null,
		total: number
	} | null;

	onChange?: (value: string) => void,
	onNavigate?: (offset: number) => void,
	onReset?: () => void
}

const containerClassName = 'bra-advanced-search';

const AdvancedSearch = (props: AdvancedSearchProps) => {
	const {
		className,
		value, pattern, navigation,
		onChange, onReset, onNavigate
	} = props;

	const stubHandler = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		
		if(!pattern || pattern.test(newValue)){
			onChange && onChange(newValue);
		}
	}

	const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter'){
			onNavigate && onNavigate(1);
		}
	}

	const onResetHandler = () => {
		onReset && onReset();
	}

	const onNavigateHandler = (e: React.MouseEvent<SVGElement, MouseEvent>, offset: number) => {
		e.preventDefault();
		e.stopPropagation();
		onNavigate && onNavigate(offset);
	}

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

	const buttonInInput = (value == null || value === '') ? (
		<Search
			className = {`${containerClassName}__content__search`}
		/>
	) : (
		<X
			className = {`${containerClassName}__content__x`}
			onClick = {onResetHandler}
		/>
	)

	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__content`}>
				<input
					className = {`${containerClassName}__content__input`}
					value = {value || ''}
					placeholder = {localization.search}
					onChange = {onChangeHandler}
					onKeyPress = {onKeyPressHandler}
				/>
				{navigation && (
					<div
						className = {`${containerClassName}__content__label`}
					>
						<span
							className = {`${containerClassName}__content__label__current`}
						>
							{navigation.current != null ? navigation.current : '-'}
						</span>
						<span
							className = {`${containerClassName}__content__label__separator`}
						>
							/
						</span>
						<span
							className = {`${containerClassName}__content__label__total`}
						>
							{navigation.total}
						</span>
					</div>
				)}
				{
					buttonInInput
				}
			</div>
			<div
				className = {`${containerClassName}__navigation`}
			>
				<div
					className = {`${containerClassName}__navigation__button`}
				>
					<ChevronUp
						className = {`${containerClassName}__navigation__button__ico`}
						onMouseDown = {stubHandler}
						onMouseUp = {stubHandler}
						onDoubleClick = {stubHandler}
						onClick = {(e) => {
							onNavigateHandler(e, -1)
						}}
					/>
				</div>
				<div
					className = {`${containerClassName}__navigation__button`}
				>
					<ChevronDown
						className = {`${containerClassName}__navigation__button__ico`}
						onMouseDown = {stubHandler}
						onMouseUp = {stubHandler}
						onDoubleClick = {stubHandler}
						onClick = {(e) => {
							onNavigateHandler(e, 1)
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default memo(AdvancedSearch);