import actions from "../actions";

import { EntityPerTree, report } from "../models";

const defaultState: EntityPerTree<report.Template> = {
}

const state = (state: EntityPerTree<report.Template> = defaultState, action: any): EntityPerTree<report.Template> => {
	switch(action.type){
		//LOAD
		case actions.reportTemplates.types.REPORT_TEMPLATE_LOAD: {
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: true,
					error: null,
				}
			};
		}
		case actions.reportTemplates.types.REPORT_TEMPLATE_LOAD_SUCCESS: {
			const {nodeId, templates} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: templates,
					isLoading: false,
					error: null,
				}
			};
		}
		case actions.reportTemplates.types.REPORT_TEMPLATE_LOAD_FAILED: {
			const {nodeId, message} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: false,
					error: message,
				}
			};
		}
		//SAVE
		case actions.reportTemplates.types.REPORT_TEMPLATE_SAVE: {
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: true
				}
			};
		}
		case actions.reportTemplates.types.REPORT_TEMPLATE_SAVE_SUCCESS: {
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: false
				}
			};
		}
		case actions.reportTemplates.types.REPORT_TEMPLATE_SAVE_FAILED: {
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: false
				}
			};
		}
		//DELETE
		case actions.reportTemplates.types.REPORT_TEMPLATE_DELETE: {
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: true
				}
			};
		}
		case actions.reportTemplates.types.REPORT_TEMPLATE_DELETE_SUCCESS: {
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: false
				}
			};
		}
		case actions.reportTemplates.types.REPORT_TEMPLATE_DELETE_FAILED: {
			const {nodeId} = action.payload;
			return {
				...state,
				[nodeId]: {
					list: null,
					isLoading: false
				}
			};
		}
	}
	return state;
}

export default state;