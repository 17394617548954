const envs = {
	DEVELOPMENT: "development",
	TEST: "test",
	STAGE: "stage",
	PRODUCTION: "production"
}

const current_environment = process.env.NODE_ENV;
const api_url = process.env.REACT_APP_API_URL;

const config = {
	"app_env": current_environment,
	"environments": envs,
	"api_url": api_url,
	"recaptcha_site_key": "6Lc0xwMcAAAAALh1qnfvtDC18u-5vPNugOkl8F9j",
//	"recaptcha_site_key": "6LcTgr4aAAAAAEEnLmfGcnENGAnWaSmW8lyHFxIb",
	"refresh_interval": 600,
	"max_credit_limit": 2000000000,
	"max_credit_limit_length": 10,
	"balance_attribute_values_separators": [","]
}

export default config;