import { AttributeGroup } from "../models";

const actionTypes = {
	ATTRIBUTE_GROUPS_LOAD: 'ATTRIBUTE_GROUPS\\LOAD',
	ATTRIBUTE_GROUPS_LOAD_SUCCESS: 'ATTRIBUTE_GROUPS\\LOAD_SUCCESS',
	ATTRIBUTE_GROUPS_LOAD_FAILED: 'ATTRIBUTE_GROUPS\\LOAD_FAILED',

	ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD: 'ATTRIBUTE_GROUPS\\ATTRIBUTES_LOAD',
	ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD_SUCCESS: 'ATTRIBUTE_GROUPS\\ATTRIBUTES_LOAD_SUCCESS',
	ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD_FAILED: 'ATTRIBUTE_GROUPS\\ATTRIBUTES_LOAD_FAILED',
}

const load = (treeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_GROUPS_LOAD,
	payload: {
		treeId,
		token
	}
})

const loadSuccess = (treeId: number, groups: AttributeGroup[]) => ({
	type: actionTypes.ATTRIBUTE_GROUPS_LOAD_SUCCESS,
	payload: {
		treeId,
		groups
	}
})

const loadFailed = (treeId: number, message: string) => ({
	type: actionTypes.ATTRIBUTE_GROUPS_LOAD_FAILED,
	payload: {
		treeId,
		message
	}
})

const loadAttributes = (treeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD,
	payload: {
		treeId,
		token
	}
})

const loadAttributesSuccess = (treeId: number, attributes: {}) => ({
	type: actionTypes.ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD_SUCCESS,
	payload: {
		treeId,
		attributes
	}
})

const loadAttributesFailed = (treeId: number, message: string) => ({
	type: actionTypes.ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD_FAILED,
	payload: {
		treeId,
		message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	loadAttributes,
	loadAttributesSuccess,
	loadAttributesFailed
};