import React, { memo } from 'react';
import { Dropdown } from 'antd';

import history from '../../history';

type OnClickHandlerType = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

interface ButtonProps{
	children?: React.ReactNode,
	overlay?: React.ReactElement,

	className?: string | null,
	type?: 'primary' | 'danger',
	htmlType?: 'submit',
	icon?: React.ReactNode | null,
	to?: string | null,
	disabled?: boolean,
	block?: boolean,
	size?: 'small',
	borderless?: boolean | null,

	onClick?: OnClickHandlerType,
}

const containerClassName = 'bra-button';

const getOnClickHandler = (onClick?: OnClickHandlerType, to?:string | null) => {
	if(onClick){
		return onClick;
	}
	if(to){
		return () => {
			history.push(to)
		}
	}
}

const renderOverlayHolder = (content: React.ReactNode, overlay?: React.ReactElement) => {
	if(overlay){
		return (
			<Dropdown
				className = {`${containerClassName}__wrapper`}
				overlay = {overlay}
			>
				{content}
			</Dropdown>
		)
	}
	return (
		<div
			className = {`${containerClassName}__wrapper`}
		>
			{content}
		</div>
	)
}

const Button = (props: ButtonProps) => {
	const {
		className, children, type, icon, to, overlay,
		disabled, block, htmlType, size, borderless,
		onClick
	} = props;

	let effectiveClassName = containerClassName;
	switch(type){
		case "primary": {
			effectiveClassName += '--primary';
			break;
		}
		case "danger": {
			effectiveClassName += '--danger';
			break;
		}
	}
	if(className){
		effectiveClassName += ` ${className}`
	}
	if(block){
		effectiveClassName += ` block`;
	}
	if(size){
		effectiveClassName += ` ${size}`;
	}
	if(borderless){
		effectiveClassName += ` borderless`;
	}

	return (
		<button
			className = {effectiveClassName}
			onClick = {getOnClickHandler(onClick, to)}
			disabled = {disabled}
			type = {htmlType}
		>
			{renderOverlayHolder((
				<div className = {`${containerClassName}__wrapper__content`}>
					{icon && <div className = {`${containerClassName}__wrapper__content__icon`}>
						{icon}
					</div>}
					{children && <div className = {`${containerClassName}__wrapper__content__text`}>
						{children}
					</div>}
				</div>
			), overlay)}
		</button>
	)
}

export default memo(Button);