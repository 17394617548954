import { Moment } from 'moment';
import { connect } from 'react-redux';

import actions from '../../actions';
import { CommonPageProps, report } from '../../models';
import { State } from '../../reducers';

import PivotReportContainer from './PivotReportContainer';
import './PivotReport.scss';
import './PivotReportTable.scss';
import './PivotReportOptions.scss';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

const mapStateToProps = (state: State, ownProps: CommonPageProps) => {
	return {
		nodes: state.nodes,
		reports: state.reports,
		reportTemplates: state.reportTemplates,
		token: state.auth.token,
		balances: state.balances
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadReport: (nodeId: number, date: [Moment, Moment], dateType: report.ReportDateType, token: string) =>
		dispatch(actions.report.load(nodeId, date, dateType, token)),
	loadNodeName: (nodeId: number, token: string) =>
		dispatch(actions.node.loadName(nodeId, token)),
	loadReportTemplates: (nodeId: number, token: string) =>
		dispatch(actions.reportTemplates.load(nodeId, token)),
	applyTemplate: (nodeId: number, template: report.Template) =>
		dispatch(actions.reportTemplates.apply(nodeId, template)),
	saveTemplate: (nodeId: number, template: report.TemplateWithoutId, token: string) => 
		dispatch(actions.reportTemplates.save(nodeId, template, token)),
	deleteTemplate: (nodeId: number, id: number, token: string) =>
		dispatch(actions.reportTemplates.delete(nodeId, id, token)),
	updateReportField: (nodeId: number, fieldId: string, update: report.ReportFieldUpdate) =>
		dispatch(actions.report.fieldUpdate(nodeId, fieldId, update)),
	loadBalances: (treeId: number, token: string) =>
		dispatch(actions.balances.load(treeId, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(PivotReportContainer);