import React from 'react';
import { PlusCircle } from 'react-feather';
import { Input, InputNumber, Radio, Select, Skeleton } from 'antd';

import { Balance, BalanceAttribute, BalanceAttributeInfo, BalanceAttributeType, BalanceInfo, BalanceLimit, BalanceType, EntityPerBalance, UserAccount } from '../../models';
import { config, localization, SecurityProvider, slugs } from '../../settings';
import { BalanceAttributeDialog, Button, CustomPopup, DndList, Switcher } from '../../components';

import BalanceAttributeView from './BalanceAttributeView';
import orderForBackend from '../../tools/dndOrder';
import BalanceFormulaEditor from '../BalanceFormulaEditor';

type TabIds = "main" | "options";

interface BalanceDialogProps{
	className?: string | null,
	visible: boolean,
	title?: React.ReactNode,

	treeId?: number,
	securityProvider: SecurityProvider,
	currentUser: UserAccount,
	
	initialValue: Balance | null,
	balances: Balance[] | null,
	balanceTypes: BalanceType[] | null,
	balanceLimits: BalanceLimit[] | null,
	balanceAttributeTypes: BalanceAttributeType[] | null,

	balanceAttributes: EntityPerBalance<BalanceAttribute>,
	loadBalanceAttributes: (balanceId: number) => void,

	onClose: () => void,
	onSave?: (balanceInfo: BalanceInfo) => void,

	createBalanceAttribute: (info: BalanceAttributeInfo) => void,
	updateBalanceAttribute: (balanceAttributeId: number, info: BalanceAttributeInfo) => void,
	deleteBalanceAttribute: (balanceAttributeId: number) => void,
	reorderBalanceAttributes: (orderedIds: {id: number, order: number}[]) => void,
	createBalanceAttributeValue: (name: string, balanceAttributeId: number) => void,
	deleteBalanceAttributeValue: (balanceAttributeValueId: number, balanceAttributeId: number) => void,
	reorderBalanceAttributeValues: (idsWithOrder: {id: number, order: number}[], balanceAttributeId: number) => void
}

interface BalanceDialogState{
	selectedTabId: TabIds,

	name: string | null,
	typeId: number | null,
	limitTypeId: number | null,
	limit: number | null

	isAttributeDialogShow: boolean,
	attributeDialogEntity: BalanceAttribute | null
}

const defaultState: BalanceDialogState = {
	selectedTabId: "main",
	name: null,
	typeId: null,
	limitTypeId: null,
	limit: null,

	isAttributeDialogShow: false,
	attributeDialogEntity: null
}

const containerClassName = 'balance-dialog';

class BalanceDialog extends React.Component<BalanceDialogProps, BalanceDialogState>{
	constructor(props: BalanceDialogProps){
		super(props);

		this.state = defaultState;
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			initialValue,
			balanceTypes, balanceAttributes,
			loadBalanceAttributes
		} = this.props;

		if(initialValue && balanceTypes){
			const balanceTypeSlug = balanceTypes.find(bt => bt.id === initialValue.typeId)?.slug;
			if(balanceTypeSlug !== slugs.balance_types.calculate){
				const attributesHolder = balanceAttributes[initialValue.id];
				if(!attributesHolder || (!attributesHolder.list && !attributesHolder.isLoading && !attributesHolder.error)){
					loadBalanceAttributes(initialValue.id);
				}	
			}
		}
	}

	getName = () => {
		const {initialValue} = this.props;
		const {name} = this.state;
		return (name === null ? initialValue?.name : name) || '';
	}

	getTypeId = () => {
		const {initialValue} = this.props;
		const {typeId} = this.state;
		return (typeId === null ? initialValue?.typeId : typeId) || 1;
	}

	getLimitTypeId = () => {
		const {initialValue} = this.props;
		const {limitTypeId} = this.state;
		return (limitTypeId === null ? initialValue?.creditLimitId : limitTypeId) || 1;
	}

	getLimit = () => {
		const {initialValue, balanceLimits} = this.props;
		const {limit} = this.state;
		const limitTypeId = this.getLimitTypeId();
		const limitType = balanceLimits?.find(lt => lt.id === limitTypeId);

		if(limitType?.slug === slugs.balance_limit.limited){
			return limit || initialValue?.limit || null;
		}
		return null;
	}

	onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState((state) => ({
			...state,
			name: event.target.value
		}))
	}

	onBalanceTypeChange = (typeId: number) => {
		this.setState((state) => ({
			...state,
			typeId: typeId
		}))
	}

	getOnLimitTypeClickHandler = (limitTypeId: number) => (_: React.MouseEvent) => {
		this.setState((state) => ({
			...state,
			limitTypeId: limitTypeId
		}))
	}

	onCreditLimitChange = (value: string | number | undefined) => {
		if(typeof value === 'number'){
			this.setState((state) => ({
				...state,
				limit: value
			}))
		}
	}

	onSwitchTab = (tabId: TabIds) => {
		this.setState((state) => ({
			...state,
			selectedTabId: tabId
		}))
	}

	getCreateBalanceAttributeValueHandler = (balanceAttributeId: number) => {
		const {createBalanceAttributeValue} = this.props;
		return (name: string) => createBalanceAttributeValue(name, balanceAttributeId)
	}

	getDeleteBalanceAttributeValueHandler = (balanceAttributeId: number) => {
		const {deleteBalanceAttributeValue} = this.props;
		return (id: number) => deleteBalanceAttributeValue(id, balanceAttributeId)
	}

	orderBalanceAttributeValues = (idsWithOrder: {id: number, order: number}[], balanceAttributeId: number) => {
		const {reorderBalanceAttributeValues} = this.props;
		reorderBalanceAttributeValues(idsWithOrder, balanceAttributeId);
	}

	openAttributeDialog = (attribute: BalanceAttribute | null) => {
		this.setState((state) => ({
			...state,
			isAttributeDialogShow: true,
			attributeDialogEntity: attribute
		}))
	}

	closeAttributeDialog = () => {
		this.setState((state) => ({
			...state,
			isAttributeDialogShow: false,
			attributeDialogEntity: null
		}))
	}

	moveBalanceAttributeHandler = (_: BalanceAttribute, initialIndex: number, finalIndex: number) => {
		const {reorderBalanceAttributes} = this.props;
		const originalList = this.getOrderedBalanceAttributes() || [];
		const orderedBalanceAttributeIds = orderForBackend(originalList, initialIndex, finalIndex);
		reorderBalanceAttributes(orderedBalanceAttributeIds);
	}
	
	onSaveAttribute = (info: BalanceAttributeInfo) => {
		const {createBalanceAttribute, updateBalanceAttribute} = this.props;
		const {attributeDialogEntity} = this.state;
		if(attributeDialogEntity){
			updateBalanceAttribute(attributeDialogEntity.id, info);
		}else{
			createBalanceAttribute(info);
		}
	}

	getValue = (): BalanceInfo | null => {
		const {initialValue} = this.props;
		const name = this.getName();
		if(!name){
			return null;
		}
		return {
			id: initialValue?.id,
			name,
			typeId: this.getTypeId(),
			creditLimitId: this.getLimitTypeId(),
			limit: this.getLimit()
		}
	}

	onSaveValue = () => {
		const {onSave, onClose} = this.props;
		const value = this.getValue();
		if(value){
			onSave && onSave(value);
			this.setState({
				name: null,
				typeId: null,
				limitTypeId: null,
				limit: null
			});
			onClose();
		}
	}

	getOrderedBalanceAttributes = () => {
		const {balanceAttributes, initialValue} = this.props;
		if(initialValue){
			return balanceAttributes[initialValue.id]?.list?.sort((ba1, ba2) => ba1.order - ba2.order);
		}
		return []
	}

	renderBalanceTypes = (balanceTypes: BalanceType[] | null) => {
		if(!balanceTypes){
			return (
				<Skeleton/>
			);
		}
		return balanceTypes.map(t => {
			return (
				<Select.Option
					key = {t.id}
					value = {t.id}
				>
					{t.name}
				</Select.Option>
			)
		})
	}

	renderLimitTypes = (balanceLimits: BalanceLimit[] | null) => {
		const selectedLimitTypeId = this.getLimitTypeId();
		const limit = this.getLimit();
		if(!balanceLimits){
			return (
				<Skeleton/>
			);
		}
		
		const getContent = (limitType: BalanceLimit) => {
			if(limitType.slug === slugs.balance_limit.limited){
				return (
					<>
						{localization.balance_editBalance_limitedBalanceOption}
						<InputNumber
							disabled = {selectedLimitTypeId !== limitType.id}
							min = {0}
							max = {config.max_credit_limit}
							maxLength = {config.max_credit_limit_length}
							style = {{marginLeft: 10, width: 120}}
							value = {limit || undefined}
							onChange = {this.onCreditLimitChange}
						/>
					</>
				)
			} else {
				return limitType.name
			}
		}
		return balanceLimits.map(l => {
			return (
				<Radio
					key = {l.id}
					value = {l.id}
					className = {`${containerClassName}__field__value__radio`}
					onClick = {this.getOnLimitTypeClickHandler(l.id)}
				>
					{getContent(l)}
				</Radio>
			);
		});
	}

	renderMainContent = () => {
		const {
			initialValue, balanceLimits, balanceTypes
		} = this.props;

		const typeId = this.getTypeId();
		const isLimitsEnabled = balanceTypes?.find(t => t.id === typeId)?.slug !== slugs.balance_types.calculate;

		return (
			<>
				<div className = {`${containerClassName}__field`}>
					<div className = {`${containerClassName}__field__title`}>
						{localization.balance_editBalance_balanceName}
					</div>
					<Input
						value = {this.getName()}
						onChange = {this.onNameChange}
						className = {`${containerClassName}__field__value`}
					/>
				</div>
				<div className = {`${containerClassName}__field`}>
					<div className = {`${containerClassName}__field__title`}>
						{localization.balance_editBalance_balanceType}
					</div>
					<Select
						disabled = {!!initialValue}
						value = {typeId}
						onChange = {this.onBalanceTypeChange}
						className = {`${containerClassName}__field__value`}
						dropdownStyle = {{zIndex: 10001}}
					>
						{this.renderBalanceTypes(balanceTypes)}
					</Select>
				</div>
				<div className = {`${containerClassName}__field`}>
					<div className = {`${containerClassName}__field__title`}>
						{localization.balance_editBalance_balanceLimitType}
					</div>
					<Radio.Group
						className = {`${containerClassName}__field__value`}
						value = {isLimitsEnabled ? this.getLimitTypeId() : undefined}
						disabled = {!isLimitsEnabled}
					>
						{this.renderLimitTypes(balanceLimits)}
					</Radio.Group>
				</div>
			</>
		);
	}

	renderBalanceAttribute = (value: BalanceAttribute, ref: React.RefObject<HTMLDivElement>) => {
		const {balanceAttributeTypes, deleteBalanceAttribute} = this.props;
		return (
			<BalanceAttributeView
				key = {value.id}
				handlerRef = {ref}
				balanceAttribute = {value}
				balanceAttributeTypes = {balanceAttributeTypes || []}
				createBalanceAttributeValue = {this.getCreateBalanceAttributeValueHandler(value.id)}
				deleteBalanceAttributeValue = {this.getDeleteBalanceAttributeValueHandler(value.id)}
				orderBalanceAttributeValues = {this.orderBalanceAttributeValues}
				editBalanceAttribute = {(ba) => this.openAttributeDialog(ba)}
				deleteBalanceAttribute = {deleteBalanceAttribute}
			/>
		)
	}

	renderAttributes = () => {
		const {
			balanceAttributeTypes
		} = this.props;
		const balanceAttributes = this.getOrderedBalanceAttributes();
		const content = balanceAttributes && balanceAttributeTypes ? (
			<DndList
				onMoveItem = {this.moveBalanceAttributeHandler}
				items = {balanceAttributes}
				render = {this.renderBalanceAttribute}
			/>
		) : (
			<Skeleton
				active = {true}
			/>
		)
		return (
			<div className = {`${containerClassName}__container`}>
				{content}
			</div>
		);
	}

	renderFormulaEditor = () => {
		const {treeId, securityProvider, currentUser, initialValue} = this.props;
		const balanceId = initialValue?.id;
		if(treeId == null || balanceId == null){
			return null;
		}

		return (
			<div className = {`${containerClassName}__container`}>
				<BalanceFormulaEditor
					treeId = {treeId}
					balanceId = {balanceId}
					securityProvider = {securityProvider}
					currentUser = {currentUser}
				/>
			</div>
		)
	}

	renderOptions = (isCalculated: boolean) => {
		if(isCalculated){
			return this.renderFormulaEditor();
		}
		return this.renderAttributes();
	}

	renderNewButton = () => {
		const {selectedTabId} = this.state;
		switch(selectedTabId){
			case 'options':
				return (
					<Button
						type = "primary"
						className ={`${containerClassName}__switcher__new`}
						icon = {<PlusCircle className = 'feather-ico'/>}
						onClick = {() => this.openAttributeDialog(null)}
					>
						{localization.balance_editBalance_tabs_attributes_newAttributeButtonText}
					</Button>
				)
		}
		return null;
	}

	render(){
		const {
			className, visible,
			title, initialValue,
			balanceAttributeTypes, balanceTypes,
			onClose
		} = this.props;

		const {
			selectedTabId,
			isAttributeDialogShow, attributeDialogEntity,
			typeId
		} = this.state;

		const typeSlug = balanceTypes?.find(t => t.id === (typeId || initialValue?.typeId))?.slug;
		const isCalculated = typeSlug === slugs.balance_types.calculate

		const availableTabs: {label: string, value: TabIds}[] = [
			{ label: localization.balance_editBalance_tabs_main, value: 'main' }
		]

		if(isCalculated){
			availableTabs.push({ label: localization.balance_editBalance_tabs_formula, value: 'options' })
		} else {
			availableTabs.push({ label: localization.balance_editBalance_tabs_attributes, value: 'options' })
		}

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		return (
			<CustomPopup
				className = {effectiveClassName}
				visible = {visible}
				onClose = {onClose}
			>
				<div className = {`${containerClassName}__title`}>
					{title}
				</div>
				<div className = {`${containerClassName}__switcher`}>
					<Switcher<TabIds>
						disabled = {!initialValue}
						selectedId = {selectedTabId}
						options = {availableTabs}
						className = {`${containerClassName}__switcher__element`}
						onChange = {this.onSwitchTab}
					/>
					{!isCalculated && this.renderNewButton()}
				</div>
				{selectedTabId === 'main'? this.renderMainContent(): this.renderOptions(isCalculated)}
				<div className = {`${containerClassName}__footer`}>
					{selectedTabId === 'main' && (
						<Button
							type = "primary"
							disabled = {!this.getValue()}
							onClick = {this.onSaveValue}
						>{localization.save}</Button>
					)}
					<Button
						className = {`${containerClassName}__footer__cancel`}
						onClick = {onClose}
					>{localization.close}</Button>
				</div>
				<BalanceAttributeDialog
					key = {attributeDialogEntity?.id}
					visible = {isAttributeDialogShow}
					balanceAttribute = {attributeDialogEntity}
					balanceAttributeTypes = {balanceAttributeTypes}
					onClose = {this.closeAttributeDialog}
					onSave = {this.onSaveAttribute}
				/>
			</CustomPopup>
		);
	}
}

export default BalanceDialog;