import actions from '../actions';
import { NodeAttributeShort, EntityPerTree } from '../models';

export interface NodeAttributesForTreeState extends EntityPerTree<NodeAttributeShort>{

}

const defaultState: NodeAttributesForTreeState = {}

const nodeAttributesForTree = (state: NodeAttributesForTreeState = defaultState, action: any)
	: NodeAttributesForTreeState =>
{
	switch(action.type){
		case actions.nodeAttributesForTree.types.NODE_ATTRIBUTES_FOR_TREE_LOAD:{
			const {treeId} = action.payload;
			const updatedState: NodeAttributesForTreeState = {
				...state,
				[treeId]: {
					isLoading: true,
					list: null,
					error: null
				}
			}
			return updatedState;
		}
		case actions.nodeAttributesForTree.types.NODE_ATTRIBUTES_FOR_TREE_LOAD_SUCCESS:{
			const {treeId, nodeAttributes} = action.payload;
			const updatedState: NodeAttributesForTreeState = {
				...state,
				[treeId]: {
					isLoading: false,
					list: nodeAttributes,
					error: null
				}
			}
			return updatedState;
		}
		case actions.nodeAttributesForTree.types.NODE_ATTRIBUTES_FOR_TREE_LOAD_FAILED:{
			const {treeId, message} = action.payload;
			const updatedState: NodeAttributesForTreeState = {
				...state,
				[treeId]: {
					isLoading: false,
					list: null,
					error: message
				}
			}
			return updatedState;
		}
	}
	return state;
}

export default nodeAttributesForTree;