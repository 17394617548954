import React from 'react';
import { Modal, Table, Tag } from 'antd';
import { Trash } from 'react-feather';

import { Role, Permission, CommonPageProps } from '../../models';
import { site, localization } from '../../settings'
import { ColumnsType } from 'antd/lib/table';
import { EditButtonsSet, PageTitle } from '../../components';

interface RolesProps extends CommonPageProps{
	roles: Role[] | null,
	isLoading: boolean,
	permissions: Permission[] | null,
	isPermissionsLoading: boolean,
	token: string,

	loadRoles: (token: string) => void,
	deleteRole: (id: number, token: string) => void,
	loadPermissions: (token: string) => void
}

const containerClassName = 'roles-page';

class Roles extends React.Component<RolesProps>{
	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			roles, isLoading, loadRoles,
			permissions, isPermissionsLoading, loadPermissions,
			securityProvider, token
		} = this.props;

		if(roles === null && !isLoading){
			loadRoles(token)
		}
		if(permissions === null && !isPermissionsLoading && securityProvider.permissions.canRead){
			loadPermissions(token)
		}
	}

	getColumns = (): ColumnsType<any> => {
		const {permissions, securityProvider} = this.props;

		const getSorterFor = (fieldName: string) => (a:any, b:any) => {
			return (a[fieldName] || '').localeCompare(b[fieldName] || '')
		};
	
		const permissionFilters = permissions?.map(p => ({
			text: p.name,
			value: p.id || ''
		}));
	
		const onPermissionFilter = (value: any, role: any) => {
			return role.permissions.findIndex((p: any) => p.id === value) > -1;
		};
	
		const columns: any[] = [{
			title: localization.role,
			dataIndex: 'name',
			sorter: getSorterFor('name'),
			showSorterTooltip: false
		}];

		if(securityProvider.permissions.canRead){
			columns.push({
				title: localization.permissions,
				dataIndex: 'permissions',
				render: this.renderPermissions,
				filterMultiple: false,
				filters: permissionFilters,
				onFilter: onPermissionFilter
			});
		}

		if(securityProvider.roles.canEdit || securityProvider.roles.canDelete){
			columns.push({
				title: null,
				render: (role: Role) => (
					<EditButtonsSet
						editUrl = {`${site.roles.url}/${role.id}`}
						isEditAvailable = {securityProvider.roles.canEdit}
						onDelete = {this.getDeleteRoleClickHandler(role.id)}
						isDeleteAvailable = {securityProvider.roles.canDelete}
						readOnly = {role.is_default}
						className = {`${containerClassName}__actions`}
					/>
				)
			});	
		}

		return columns;
	}

	getDeleteRoleClickHandler(id?: number){
		const{ token, deleteRole } = this.props;
		return () => {
			Modal.confirm({
				title: localization.delete_role_title,
				icon: <Trash style = {{float: 'left', marginRight: 10}} />,
				content: localization.delete_role_content,
				okText: localization.yes,
				okType: 'danger',
				cancelText: localization.no,
				onOk() {
					if(id !== undefined){
						deleteRole(id, token);
					}
				}
			})
		}
	}

	renderPermissions(permissions: Permission[]){
		return (
			<>
				{permissions.map((permission => {
					return (
						<Tag key={permission.id} style={{margin: 5}}>
							{permission.name}
						</Tag>
					);
				}))}
			</>
		)
	}

	render(){
		const { roles, isLoading, securityProvider } = this.props;
			
		const dataSource = roles?.map((role: Role) => ({
			...role,
			key: role.id
		}));
		
		return (
			<div className = {`${containerClassName} page-content`}>
				<PageTitle
					title = {site.roles.name}
					buttonTitle = {localization.newRoleButton}
					buttonUrl = {site.newRole.url}
					buttonClassName = {`${containerClassName}__new`}
					showButton = {securityProvider.roles.canCreate}
				/>
				<Table
					columns = {this.getColumns()}
					dataSource = {dataSource}
					loading = {isLoading}
					pagination = {false}
					className = {`${containerClassName}__table`}
				/>
			</div>
		)
	}
}


export default Roles;