import React, { memo } from 'react';
import { Menu } from 'antd';
import { Lock, Unlock } from 'react-feather';

import { CustomPopup } from '../../components';
import { localization } from '../../settings';


interface HeaderContextMenuProps{
	x: number,
	y: number,
	columnId: number | null,
	fixedColumnIds: number[],

	isColumnFixed: (id: number) => boolean,
	updateFixedColumns: (ids: number[]) => void,
	
	onClose: () => void
}

const HeaderContextMenu = (props: HeaderContextMenuProps) => {
	const {
		columnId, fixedColumnIds, x, y,
		isColumnFixed, updateFixedColumns, onClose
	} = props;

	if(columnId == null || columnId === 0){
		return null;
	}

	const fixUnfixColumn = (columnId: number) => {			
		const updatedFixedColumnsIds = fixedColumnIds.indexOf(columnId) > -1 ?
			[...fixedColumnIds.filter(id => id !== columnId)] :
			[...fixedColumnIds, columnId];
		updateFixedColumns(updatedFixedColumnsIds)
	}

	return (
		<CustomPopup
			visible = {true}
			left = {x}
			top = {y}
			onClose = {onClose}
			transparentOverlay
		>
			<Menu className="tree-popup">
				<Menu.Item
					icon = {isColumnFixed(columnId) ?
						<Unlock width={12} height={12} style = {{marginRight: 8, verticalAlign: 'middle'}}/> :
						<Lock width={12} height={12} style = {{marginRight: 8, verticalAlign: 'middle'}} />
					}
					onClick={() => fixUnfixColumn(columnId)}
				>
					{isColumnFixed(columnId) ? localization.column_unfix : localization.column_fix}
				</Menu.Item>
			</Menu>
		</CustomPopup>
	)

}

export default memo(HeaderContextMenu);