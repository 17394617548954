import { all, call, put, takeEvery} from 'redux-saga/effects'

import {config} from '../settings'
import { AttributeInfoEntry, AttributeInfoValue, AttributeLocator, AttributeType, UpdateAttributeLocatorType } from '../models';
import actions from '../actions';

import handleResponse from './handleResponse';

type ResponseType = {status: number, body: any} | {error: any};

const {
	loadSuccess, loadFailed,
	updateSuccess, updateFailed
} = actions.transactionAttributes;
const {raise} = actions.error;

const tryLoad = (ids: number[], type: AttributeType, token: string): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);

		const endpoint = type === 'node' ? 'node-attributes' : 'balance-attributes';
		const queryParams = ids
			.reduce((acc, cur) => `${acc}&id[]=${cur}`, '');
		return fetch(`${config.api_url}/${endpoint}?${queryParams}`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractAttributeValues= (data: any): AttributeInfoValue[] => {
	if(!Array.isArray(data)){
		return [];
	}
	return data.map(entry => ({
		id: entry.id,
		name: entry.name
	}))
}

const extractAttributes = (data: any, type: AttributeType, allIds: number[]): AttributeInfoEntry[] => {
	if(!Array.isArray(data)){
		return [];
	}
	const result = allIds.map((id) => {
		const entry = data.find(e => e.id === id);
		if(entry){
			return {
				id: Number(entry.id),
				type: type,
				name: entry.name,
				allowValues: entry.allowValues,
				values: extractAttributeValues(entry.values)
			}
		}
		return {
			id: id,
			name: '',
			type: type,
			allowValues: true,
			values: [],
			readOnly: true
		}
	})
	return result;
}

const tryUpdate = (attribute: UpdateAttributeLocatorType, token: string) => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const formData = new FormData();
		formData.append("attributes[0][attributeId]", `${attribute.id}`);
		formData.append("attributes[0][type]", attribute.type === 'node' ? 'node' : 'balance');
		if('valueId' in attribute){
			formData.append("attributes[0][valueId]", `${attribute.valueId}`);
		} else {
			formData.append("attributes[0][value]", attribute.value);
		}
		return fetch(`${config.api_url}/operations/${attribute.operationId}`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const loadSaga = function*(action: any){
	const {attributes, token}: {attributes: AttributeLocator[], token: string} = action.payload;
	try{
		const nodeAttributeIds = attributes
			.filter(a => a.type === 'node')
			.map(a => a.id)
		const balanceAttributeIds = attributes
			.filter(a => a.type === 'balance')
			.map(a => a.id);

		const [nodeAttributesResponse, balanceAttributesResponse]: ResponseType[] = yield all([
			call(tryLoad, nodeAttributeIds, 'node', token),
			call(tryLoad, balanceAttributeIds, 'balance', token)
		]);

		yield* handleResponse(
			nodeAttributesResponse,
			(body: any) => loadSuccess(extractAttributes(body.data, 'node', nodeAttributeIds)),
			(error: string) => loadFailed(nodeAttributeIds.map(id => ({
				id: id,
				type: 'node'
			})), error)
		)

		yield* handleResponse(
			balanceAttributesResponse,
			(body: any) => loadSuccess(extractAttributes(body.data, 'balance', balanceAttributeIds)),
			(error: string) => loadFailed(balanceAttributeIds.map(id => ({
				id: id,
				type: 'balance'
			})), error)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(attributes, 'An error occurred'));
		return;
	}
}

const updateSaga = function*(action: any){
	const {attribute, token} = action.payload as {attribute: UpdateAttributeLocatorType, token: string};
	try{
		const response: ResponseType = yield call(tryUpdate, attribute, token);
		yield* handleResponse(
			response,
			() => updateSuccess(attribute),
			(message: string) => updateFailed(attribute, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed(attribute, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield all([
		takeEvery(actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_LOAD, loadSaga),
		takeEvery(actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_UPDATE, updateSaga),
	]);
}

export default watch;