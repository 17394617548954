import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {Table} from 'antd';
import { ColumnsType } from 'antd/lib/table';

interface DndTableProps<TEntry>{
	className?: string | null,
	columns?: ColumnsType<TEntry>,
	dataSource?: TEntry[],
	loading?: boolean,

	onMoveItem?: (source: TEntry, initialIndex: number, finalIndex: number) => void
}
const containerClassName = 'dnd-table';
const type = 'DragableBodyRow';

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }: any) => {
	const ref = React.useRef();
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: monitor => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
				return {};
			}
			return {
				isOver: monitor.isOver(),
				dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
			};
		},
		drop: (item: any) => {
			moveRow(item.index, index);
		},
	});
	const [, drag] = useDrag({
		item: { type, index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drop(drag(ref));
	return (
		<tr
			ref = {ref}
			className = {`${className}${isOver ? dropClassName : ''}`}
			style = {style}
			{...restProps}
		/>
	);
};

const DndTable = <TEntry extends {}>(props: DndTableProps<TEntry>) => {
	const {
		columns, dataSource, loading, className,
		onMoveItem
	} = props;

	const components = {
		body: {
			row: DragableBodyRow,
		},
	};

	const getMoveRow = (record: TEntry) => (dragIndex: number, hoverIndex: number) => {
		dragIndex !== hoverIndex && onMoveItem && onMoveItem(record, dragIndex, hoverIndex);
	};

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<DndProvider backend = {HTML5Backend}>
			<Table<TEntry>
				className = {effectiveClassName}
				columns = {columns}
				dataSource = {dataSource}
				loading = {loading}
				pagination = {false}
				components = {components}
				onRow = {(record, index): any => ({
					index,
					moveRow: getMoveRow(record)
				})}
			/>
		</DndProvider>
	)
}

export default DndTable;