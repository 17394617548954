import actions from '../actions';
import { User } from '../models';

export interface UsersState{
	list: User[] | null,
	isLoading: boolean,
	isUserUpdating: boolean
}

const defaultState: UsersState = {
	list: null,
	isLoading: false,

	isUserUpdating: false
}

const users = (state: UsersState = defaultState, action: any): UsersState => {
	switch(action.type){
		case actions.users.types.USERS_LOAD:
			return {...state, isLoading: true};
		case actions.users.types.USERS_LOAD_SUCCESS:
			const { users } = action.payload;
			return {...state, list: users, isLoading: false}
		case actions.users.types.USERS_LOAD_FAILED:
			console.error('USERS_LOAD_FAILED\n%o', action.payload);
			return {...state, list: [], isLoading: false};

		case actions.users.types.USERS_RESET:
			return {...defaultState};
			
		case actions.user.types.USER_CREATE:
			return {...state, isUserUpdating: true};
		case actions.user.types.USER_UPDATE:
			return {...state, isUserUpdating: true};
		case actions.user.types.USER_DELETE:
			return {...state, isUserUpdating: true};
		case actions.user.types.USER_REQUEST_SUCCESS:
			return {...state, isUserUpdating: false, list: null};
		case actions.user.types.USER_REQUEST_FAILED:
			console.error('USER_REQUEST_FAILED\n%o', action.payload);
			return {...state, isUserUpdating: false, list: null};
	}
	return state;
}

export default users