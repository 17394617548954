import { BalanceAttributeType } from "../models";

const actionTypes = {
	BALANCE_ATTRIBUTE_TYPES_LOAD: 'BALANCE_ATTRIBUTE_TYPES\\LOAD',
	BALANCE_ATTRIBUTE_TYPES_LOAD_SUCCESS: 'BALANCE_ATTRIBUTE_TYPES\\LOAD_SUCCESS',
	BALANCE_ATTRIBUTE_TYPES_LOAD_FAILED: 'BALANCE_ATTRIBUTE_TYPES\\LOAD_FAILED',
}

const load = (token: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_TYPES_LOAD,
	payload: {
		token
	}
})

const loadSuccess = (balanceAttributeTypes: BalanceAttributeType[]) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_TYPES_LOAD_SUCCESS,
	payload: {
		balanceAttributeTypes
	}
})

const loadFailed = (message: string) => ({
	type: actionTypes.BALANCE_ATTRIBUTE_TYPES_LOAD_FAILED,
	payload: {
		message: message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
};