import { all, call, put, takeEvery, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'
import actions from '../actions';

import handleResponse from './handleResponse';

type ResponseType = {status: number, body: any} | {error: any};

const {
	loadSuccess, loadFailed,
	setSuccess, setFailed
} = actions.balanceFormula;
const {raise} = actions.error;

const tryLoad = (payload: {treeId: number, balanceId: number, token: string}): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const {treeId, balanceId} = payload;

		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/expression`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const loadSaga = function*(action: any){
	const {treeId, balanceId} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(treeId, balanceId, body.data),
			(message: any) => loadFailed(treeId, balanceId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(treeId, balanceId, 'An error occurred'));
		return;
	}
}

const trySet = (payload: {treeId: number, balanceId: number, formula: string, token: string}): Promise<ResponseType> => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const {treeId, balanceId, formula} = payload;

		const formData = new FormData();
		formData.append("expression", `${formula}`);

		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/expression`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const setSaga = function*(action: any){
	const {treeId, balanceId} = action.payload;
	try{
		const response: ResponseType = yield call(trySet, action.payload);
		yield* handleResponse(
			response,
			() => setSuccess(treeId, balanceId),
			(message: any) => setFailed(message, treeId, balanceId)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(setFailed('An error occurred', treeId, balanceId));
		return;
	}
}

const watch = function*() {
	yield all([
		takeLeading(actions.balanceFormula.types.BALANCE_FORMULA_LOAD, loadSaga),
		takeEvery(actions.balanceFormula.types.BALANCE_FORMULA_SET, setSaga)
	]);
}

export default watch;