import actions from '../actions';
import { AttributeLocator, TransactionAttributes, UpdateAttributeLocatorType } from '../models';
import { AttributeInfoEntry } from '../models/attributeInfo';

const defaultState: TransactionAttributes = {
	nodeAttributes: {},
	balanceAttributes: {}
}

const updateAttribute = (state: TransactionAttributes, locator: AttributeLocator, entry: AttributeInfoEntry | null, isLoading: boolean, error?: string): TransactionAttributes => {
	const updatedNodeAttributes = {...state.nodeAttributes};
	const updatedBalanceAttributes = {...state.balanceAttributes};
	const storage = locator.type === 'node' ?
		updatedNodeAttributes :
		updatedBalanceAttributes;
	storage[locator.id] = {
		entry: entry,
		isLoading: isLoading,
		error: error || null
	}
	return {
		...state,
		nodeAttributes: updatedNodeAttributes,
		balanceAttributes: updatedBalanceAttributes
	}
}

const reducer = (state: TransactionAttributes = defaultState, action: any): TransactionAttributes => {
	switch(action.type){
		// Load
		case actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_LOAD: {
			const {attributes}: {attributes: AttributeLocator[]} = action.payload;
			const nodeAttributeIds = attributes
				.filter(a => a.type === 'node')
				.map(a => a.id);
			const updatedNodeAttributes = {...state.nodeAttributes};
			for(let id of nodeAttributeIds){
				updatedNodeAttributes[id] = {
					isLoading: true,
					entry: null,
					error: null,
				}
			}

			const balanceAttributeIds = attributes
				.filter(a => a.type === 'balance')
				.map(a => a.id);
			const updatedBalanceAttributes = {...state.balanceAttributes};
			for(let id of balanceAttributeIds){
				updatedBalanceAttributes[id] = {
					isLoading: true,
					entry: null,
					error: null
				}
			}
			return {
				...state,
				nodeAttributes: updatedNodeAttributes,
				balanceAttributes: updatedBalanceAttributes
			}
		}
		case actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_LOAD_SUCCESS: {
			const {attributesInfo}: {attributesInfo: AttributeInfoEntry[]} = action.payload;
			const updatedNodeAttributes = {...state.nodeAttributes};
			const updatedBalanceAttributes = {...state.balanceAttributes};
			for(let attributeInfo of attributesInfo){
				const storage = attributeInfo.type === 'node' ?
					updatedNodeAttributes :
					updatedBalanceAttributes;
				storage[attributeInfo.id] = {
					entry: attributeInfo,
					isLoading: false,
					error: null
				}
			};

			return {
				...state,
				nodeAttributes: updatedNodeAttributes,
				balanceAttributes: updatedBalanceAttributes
			}
		}
		// Update
		case actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_UPDATE:{
			const {attribute} = action.payload as {attribute: UpdateAttributeLocatorType};
			return updateAttribute(state, attribute, null, true);
		};
		case actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_UPDATE_SUCCESS:{
			const {attribute} = action.payload as {attribute: UpdateAttributeLocatorType};
			return updateAttribute(state, attribute, null, false);
		}
		case actions.transactionAttributes.types.TRANSACTION_ATTRIBUTES_UPDATE_FAILED:{
			const {attribute, message} = action.payload as {attribute: UpdateAttributeLocatorType, message: string};
			return updateAttribute(state, attribute, null, false, message);
		}
	}
	return state;
}

export default reducer;