import React, { memo } from 'react';

interface SubMenuProps{
    className?: string | null,
    children?: React.ReactNode | React.ReactNode[],
    expanded?: boolean
}

const containerClassName = 'submenu';

const SubMenu = (props: SubMenuProps):JSX.Element | null => {
    const {expanded, children, className} = props;
    let effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
    if(expanded){
        effectiveClassName += ' expanded';
    }
    return (
        <div className = {effectiveClassName}>
            <div className = {`${containerClassName}__content`}>
                {children}
            </div>
        </div>
    )
}

export default memo(SubMenu);