import { Dropdown, Menu } from 'antd';
import React, { memo, useState } from 'react';
import { AlignJustify, CheckCircle, Edit, Frown, Layers, MoreVertical, PlusCircle, Trash, Type } from 'react-feather';

import { ConfirmDelete, DndList } from '../../components';
import { BalanceAttribute, BalanceAttributeType, BalanceAttributeValue } from '../../models';
import { localization, slugs } from '../../settings';
import {dndOrder} from '../../tools';
import AttributeValueDialog from './AttributeValueDialog';

import BalanceAttributeValueElement from './BalanceAttributeValueElement';

interface BalanceAttributeViewProps{
	className?: string | null,
	balanceAttribute: BalanceAttribute,
	balanceAttributeTypes: BalanceAttributeType[],
	handlerRef: React.RefObject<HTMLDivElement>,

	editBalanceAttribute: (balanceAttribute: BalanceAttribute) => void,
	deleteBalanceAttribute: (balanceAttributeId: number) => void,
	createBalanceAttributeValue: (name: string) => void,
	deleteBalanceAttributeValue: (balanceAttributeValueId: number) => void,
	orderBalanceAttributeValues: (idsWithOrder: {id: number, order: number}[], balanceAttributeId: number) => void
}

const containerClassName = 'balance-attribute';

const renderType = (typeSlug?: string) => {
	switch(typeSlug){
		case slugs.balanceAttribute_type.radio:
			return (<CheckCircle className = {`${containerClassName}__type__ico`}/>);
		case slugs.balanceAttribute_type.select:
			return (<AlignJustify className = {`${containerClassName}__type__ico`}/>);
		case slugs.balanceAttribute_type.text:
			return (<Type className = {`${containerClassName}__type__ico`}/>);
		case slugs.balanceAttribute_type.grouped_text:
			return (<Layers className = {`${containerClassName}__type__ico`}/>);
	}
	return <Frown className = {`${containerClassName}__type__ico`}/>
}

const renderActions = (props: BalanceAttributeViewProps) => {
	const {
		balanceAttribute,
		editBalanceAttribute, deleteBalanceAttribute
	} = props;
	const menu = (
		<Menu>
			<Menu.Item
				icon = {<Edit className = 'feather-ico' />}
				onClick = {() => {
					editBalanceAttribute(balanceAttribute);
				}}
			>
				{localization.edit}
			</Menu.Item>
			<Menu.Item
				danger
				icon = {<Trash className = 'feather-ico' />}
				onClick = {() => {
					ConfirmDelete({
						title: localization.delete_attribute_title,
						content: localization.delete_attribute_content,
						zIndex: 10010,
						deleteEntity: () => deleteBalanceAttribute(balanceAttribute.id)
					})
				}}
			>
				{localization.delete}
			</Menu.Item>
		</Menu>
	);
	return (
		<div className = {`${containerClassName}__actions`}>
			<Dropdown
				overlay = {menu}
				overlayStyle = {{zIndex: 10020}}
				trigger={['click']}
			>
				<MoreVertical className = {`${containerClassName}__actions__ico`} />
			</Dropdown>
		</div>
	)
}

const BalanceAttributeView = (props: BalanceAttributeViewProps) => {
	const {
		className, handlerRef,
		balanceAttribute, balanceAttributeTypes,
		createBalanceAttributeValue, deleteBalanceAttributeValue, orderBalanceAttributeValues
	} = props;

	const [isNewValueDialogShown, setIsNewValueDialogShown] = useState(false);

	const moveHandler = (_: BalanceAttributeValue, initialIndex: number, finalIndex: number) => {
		const idsWithOrder = dndOrder(balanceAttribute.values, initialIndex, finalIndex);
		orderBalanceAttributeValues(idsWithOrder, balanceAttribute.id)
	}

	const renderValueElement = (value: BalanceAttributeValue, ref: React.RefObject<HTMLDivElement>) => (
		<BalanceAttributeValueElement
			key = {value.id}
			value = {value}
			handlerRef = {ref}
			onClose = {(value) => deleteBalanceAttributeValue(value.id)}
		/>
	)

	const attributeType = balanceAttributeTypes
		.find(bat => bat.id === balanceAttribute.typeId);

	const hasValues = [slugs.balanceAttribute_type.text, slugs.balanceAttribute_type.grouped_text]
		.indexOf(attributeType?.slug || '') === -1;
	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__type`} ref = {handlerRef}>
				{renderType(attributeType?.slug)}
			</div>
			<div className = {`${containerClassName}__name`}>
				{balanceAttribute.name}
			</div>
			<div className = {`${containerClassName}__values`}>
				{hasValues && (
					<DndList
						className = {`${containerClassName}__values__list`}
						entryClassName = {`${containerClassName}__values__list__entry`}
						items = {balanceAttribute.values}
						render = {renderValueElement}
						onMoveItem = {moveHandler}
					>
						<div
							className = {`${containerClassName}__values__list__add`}
						>
							<PlusCircle
								className = {`${containerClassName}__values__list__add__icon`}
								onClick = {() => setIsNewValueDialogShown(true)}
							/>
						</div>
					</DndList>
				)}
			</div>
			{renderActions(props)}
			<AttributeValueDialog
				visible = {isNewValueDialogShown}
				onClose = {() => setIsNewValueDialogShown(false)}
				onSave = {createBalanceAttributeValue}
			/>
		</div>
	);
}

export default memo(BalanceAttributeView);