import actions from "../actions";

// import {Version} from '../models'

export interface VersionState{
    version: any,
    error: string  | null,
    isLoading: boolean
}

const defaultState: VersionState = {
    version: null,
    error: null,
    isLoading: false
}

const account = (state: VersionState = defaultState, action: any): VersionState => {
    switch(action.type){
        case actions.version.types.VERSION_LOAD: {
            return {
                ...state,
                version: null,
                error: null,
                isLoading: true
            }
        }
        case actions.version.types.VERSION_LOAD_SUCCESS: {
            const {version} = action.payload;
            return {
                ...state,
                version,
                isLoading: false,
                error: null
            }
        }
        case actions.version.types.VERSION_LOAD_FAILED: {
            console.error('ATTRIBUTE_VISIBILITIES_LOAD_FAILED\n%o', action.payload);
            const {message} = action.payload;
            return {
                ...state,
                version: {},
                isLoading: false,
                error: message
            }
        }
    }
    return state;
}

export default account;