import { call, put, takeLatest} from 'redux-saga/effects';

import {config} from '../settings';
import actions from '../actions';

import handleResponse from './handleResponse';

const {loginFailed, loginSuccess} = actions.auth;
const {raise} = actions.error;

type ResponseType = {status: number, body: any} | {error: any};

const tryLogin = (payload: {username: string, password: string/*, recaptchaToken: string*/}): Promise<ResponseType> => {
	try{
		const formData = new FormData();
		formData.append('email', payload.username);
		formData.append('password', payload.password);
		// formData.append('reCaptchaToken', payload.recaptchaToken);

		return fetch(`${config.api_url}/auth/login`, {
			method: 'POST',
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const loginSaga = function*(action: any){
	try{
		const response: ResponseType = yield call(tryLogin, action.payload);
		yield* handleResponse(response, loginSuccess, loginFailed);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loginFailed('An error occurred'));
		return;
	}
}

const watchLogin = function*() {
	yield takeLatest(actions.auth.types.AUTH_LOGIN, loginSaga)
}

export default watchLogin;
