import { connect } from 'react-redux'

import actions from '../../actions'
import { CommonPageProps } from '../../models';
import { State } from '../../reducers';

import AttributeGroups from './AttributeGroups';

import './AttributeGroups.scss';
import './AttributeGroupsList.scss';

interface AttributeGroupsPageProps extends CommonPageProps{
	selectedTreeId?: number
}

const mapStateToProps = (state: State, ownProps: AttributeGroupsPageProps) => {
	return {
		selectedTreeId: ownProps.selectedTreeId,
		attributeGroupsPerTree: state.attributeGroups,
		treesList: state.trees.list,
		isTreesListLoading: state.trees.isLoading,
		token: state.auth.token,
		nodesInfo: state.nodes
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadTrees: (token: string) =>
		dispatch(actions.trees.load(token)),
	loadAttributeGroups: (treeId: number, token: string) =>
		dispatch(actions.attributeGroups.load(treeId, token)),
	loadAttributesPerGroups: (treeId: number, token: string) => 
		dispatch(actions.attributeGroups.loadAttributes(treeId, token)),
	createAttributeGroup: (treeId: number, name: string, token: string) =>
		dispatch(actions.attributeGroup.create(name, treeId, token)),
	updateAttributeGroup: (attributeGroupId: number, name: string, treeId: number, token: string) => 
		dispatch(actions.attributeGroup.update(attributeGroupId, name, treeId, token)),
	deleteAttributeGroup: (attributeGroupId: number, treeId: number, token: string) => 
		dispatch(actions.attributeGroup.delete(attributeGroupId, treeId, token)),
	
})

export default connect(mapStateToProps, mapDispatchToProps)(AttributeGroups)