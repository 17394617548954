import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { TopMenu } from "../../components";

import {CommonPageProps, UserAccount, VersionInfo} from "../../models";

export interface PrivateLayoutProps extends React.HTMLProps<HTMLElement>, RouteComponentProps, CommonPageProps{
	token: string,
	currentUser: UserAccount,

	clearCache: (token: string) => void,
	syncElastic: (token: string) => void,
	getVersion: (token: string) => void,
	onLogout: () => void,
	version: VersionInfo
}

const PrivateLayout = (props: PrivateLayoutProps) => {
	const {
		currentUser, securityProvider, token,
		clearCache, syncElastic, onLogout, getVersion, version
	} = props;

	const containerClassName = 'bra-layout';
	return (
		<div className={containerClassName}>
			<div
				className = {`${containerClassName}__header`}
			>
				<TopMenu
					currentUser = {currentUser}
					securityProvider = {securityProvider}
					token = {token}
					clearCache = {clearCache}
					syncElastic = {syncElastic}
					onLogout = {onLogout}
					getVersion = {getVersion}
					version = {version}
				/>
			</div>
			<main className={`${containerClassName}__body`}>
				{props.children}
			</main>
		</div>
	)
}

export default PrivateLayout;