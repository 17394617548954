interface SummaryEntry{
	selector: string,
	summaryType: string,
	calculated?: boolean
}

const CALCULATED_BALANCE_PREFIX = 'calculatedBalance.';

const buildSummary = (summary: any): SummaryEntry[] => {
	if(!Array.isArray(summary)){
		return [];
	}
	const result = summary
		.filter(e => e.selector)
		.map((e): SummaryEntry => {
			const selector = `${e.selector}`;
			if(selector.startsWith(CALCULATED_BALANCE_PREFIX)){
				const id = selector.substring(CALCULATED_BALANCE_PREFIX.length);
				return {
					selector: `balance.${id}`,
					summaryType: 'sum',
					calculated: true
				}
			}
			return {
				selector: e.selector,
				summaryType: 'sum'
			}
		});
	return result;
}

export default buildSummary;