import React from 'react';
import { Modal } from 'antd';
import { localization } from '../../settings';
import { Trash } from 'react-feather';

interface ConfirmDeleteProps{
	title: string,
	content: string,
	className?: string,
	zIndex?: number,
	deleteEntity: () => void
}

const ConfirmDelete = (props: ConfirmDeleteProps) => {
	const { deleteEntity, title, content, className, zIndex } = props;
	return Modal.confirm({
		title,
		icon: <Trash style = {{float: 'left', marginRight: 10}} />,
		className: className,
		zIndex: zIndex,
		content,
		maskClosable: true,
		okText: localization.yes,
		okType: 'danger',
		cancelText: localization.no,
		onOk() {
			deleteEntity();
		}
	})
}

export default ConfirmDelete;