import { Permission } from "../models";

const actionTypes = {
	PERMISSIONS_LOAD: 'PERMISSIONS\\LOAD',
	PERMISSIONS_LOAD_SUCCESS: 'PERMISSIONS\\LOAD_SUCCESS',
	PERMISSIONS_LOAD_FAILED: 'PERMISSIONS\\LOAD_FAILED'
}

const load = (token: string) => ({
	type: actionTypes.PERMISSIONS_LOAD,
	payload: {
		token: token
	}
})

const loadSuccess = (permissions?: Permission[]) => ({
	type: actionTypes.PERMISSIONS_LOAD_SUCCESS,
	payload: {
		permissions: permissions
	}
})

const loadFailed = (message: string) => ({
	type: actionTypes.PERMISSIONS_LOAD_FAILED,
	payload: {
		message: message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed
};