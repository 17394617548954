import React/*, { createRef, RefObject, useState }*/ from "react";
import { Layout, Form, Input, Button, Row, Col, Card } from 'antd';
import { Lock, User } from "react-feather";
// import ReCAPTCHA from "react-google-recaptcha";

import {localization, errors/*, config*/} from '../../settings';

interface LoginPageProps{
	isLoading: boolean,
	error: string | null,
	onLogin: (username: string, password: string /*, recaptchaToken: string*/) => void,
	onError: (message: string) => void,
}

const notEmptyRules = [
	{
		required: true,
		message: errors.fields_is_required,
	},
];

const LoginPage = (props: LoginPageProps) => {
	const { error, /*onError, */onLogin } = props;
	// const recaptcha_ref: RefObject<ReCAPTCHA> = createRef();
	// const [recaptchaToken, setRecaptchaToken] = useState('');

	const onFinish = async (values: {login: string, password: string}) => {
		// if(!recaptchaToken){
		// 	return onError('Something went wrong, please try again later');
		// }
		// recaptcha_ref.current?.reset();
		onLogin(values.login, values.password /*, recaptchaToken*/);
	}

	// const onRecaptchaChanged = (token: string | null) => {
	// 	setRecaptchaToken(token || '');
	// }

	const errorAttributes: {validateStatus: 'error', help: string} | null = error !== null ? {
		validateStatus: 'error',
		help: error
	} : null;

	return (
		<Layout>
			<Row justify="center" align="middle" style={{minHeight: '100vh'}}>
				<Col>
					<Card
						title={localization.log_in}
						style={{minWidth: 300}}
						bordered
					>
						<Form onFinish={onFinish}>
							<Form.Item
								name="login"
								rules={notEmptyRules}
								{...errorAttributes}
							>
								<Input prefix={<User className="site-form-item-icon" />} placeholder={localization.login} />
							</Form.Item>
							<Form.Item
								name="password"
								rules={notEmptyRules}
							>
								<Input
									prefix={<Lock className="site-form-item-icon" />}
									type="password"
									placeholder={localization.password}
								/>
							</Form.Item>
							{/*<Form.Item>*/}
							{/*	<ReCAPTCHA*/}
							{/*		ref = {recaptcha_ref}*/}
							{/*		sitekey = {config.recaptcha_site_key}*/}
							{/*		onChange = {onRecaptchaChanged}*/}
							{/*	/>*/}
							{/*</Form.Item>*/}
							<Form.Item>
								<Button
									type="primary"
									block htmlType="submit"
									className="login-form-button"
									// disabled = {!recaptchaToken}
								>
									{localization.log_in}
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</Layout>
	);
}

export default LoginPage;