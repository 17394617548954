import React, { memo } from 'react';
import { Form, Radio } from 'antd';

import { BalanceAttribute } from '../../../models';
import { errors } from '../../../settings';

interface RadioBalanceAttributeProps{
	className?: string,
	path: string[],

	balanceAttribute: BalanceAttribute
}

const containerClassName = 'radio-balance-attribute';

const RadioBalanceAttribute = (props: RadioBalanceAttributeProps) => {
	const {className, balanceAttribute, path} = props;

	const optionElements = balanceAttribute.values
		.sort((a, b) => a.order - b.order)
		.map(v => {
			return (
				<Radio
					key = {v.id}
					value = {`${v.id}`}
					className = {`${containerClassName}__option`}
				>
					{v.name}
				</Radio>
			)
		});

	const rules = balanceAttribute.required ? [{required: true, message: errors.fields_is_required}] : []

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<Form.Item
			label = {balanceAttribute.name}
			name = {[...path, `${balanceAttribute.id}`]}
			rules = {rules}
		>
			<Radio.Group
				className = {effectiveClassName}
			>
				{optionElements}
			</Radio.Group>
		</Form.Item>
		)
}

export default memo(RadioBalanceAttribute);