import { notification } from 'antd';
import actions from '../actions';
import { BalanceFormula, SingleEntityPerBalance } from '../models';
import { localization } from '../settings';

export interface BalanceFormulaState extends SingleEntityPerBalance<BalanceFormula> {};

const defaultState: BalanceFormulaState = {};

const reducer = (state: BalanceFormulaState = defaultState, action: any): BalanceFormulaState => {
	switch(action.type){
		case actions.balanceFormula.types.BALANCE_FORMULA_LOAD:{
			const {balanceId} = action.payload;
			return {
				...state,
				[balanceId]: {
					isLoading: true,
					entity: null,
					error: null,
				}
			}
		}
		case actions.balanceFormula.types.BALANCE_FORMULA_LOAD_SUCCESS:{
			const {balanceId, formula} = action.payload;
			return {
				...state,
				[balanceId]: {
					isLoading: false,
					entity: {
						text: formula
					},
					error: null,
				}
			}
		}
		case actions.balanceFormula.types.BALANCE_FORMULA_LOAD_FAILED:{
			const {balanceId, message} = action.payload;
			return {
				...state,
				[balanceId]: {
					isLoading: false,
					entity: null,
					error: message,
				}
			}
		}

		case actions.balanceFormula.types.BALANCE_FORMULA_SET:{
			const {balanceId} = action.payload;
			const currentFormula = state[balanceId]?.entity;

			return {
				...state,
				[balanceId]: {
					isLoading: true,
					entity: currentFormula !== undefined ? currentFormula : null,
					error: null,
				}
			}
		}
		case actions.balanceFormula.types.BALANCE_FORMULA_SET_SUCCESS:{
			const {balanceId} = action.payload;
			notification.success({
				message: localization.saved
			})
			return {
				...state,
				[balanceId]: {
					isLoading: false,
					entity: null,
					error: null,
				}
			}
		}
		case actions.balanceFormula.types.BALANCE_FORMULA_SET_FAILED:{
			const {balanceId, message} = action.payload;
			const currentFormula = state[balanceId]?.entity;

			return {
				...state,
				[balanceId]: {
					isLoading: false,
					entity: currentFormula !== undefined ? currentFormula : null,
					error: message,
				}
			}
		}
	}
	return state;
}

export default reducer;