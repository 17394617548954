import { all, call, put, takeEvery} from 'redux-saga/effects';

import {config} from '../settings';
import actions from '../actions';

import handleResponse from './handleResponse';

const {
	createSuccess, createFailed,
	updateSuccess, updateFailed,
	deleteSuccess, deleteFailed
} = actions.attributeGroup;
const {raise} = actions.error;

type DeleteRequestPayload = {id: number, treeId: number, token: string};
type PostRequestPayload = {id?: number, name: string, treeId: number, token: string};
type ResponsePromise = Promise<{status: number, body: any} | {error: any}>

const deleteAttributeGroup = (payload: DeleteRequestPayload): ResponsePromise => {
	try{
		const {id, treeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/nodes/${treeId}/attributes/groups/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const postActionGroup = (payload: PostRequestPayload): ResponsePromise => {
	try{
		const {id, name, treeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);
		const formData = new FormData();
		formData.append('name', name);

		const url = id != null ?
			`${config.api_url}/nodes/${treeId}/attributes/groups/${id}` :
			`${config.api_url}/nodes/${treeId}/attributes/groups`;

		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	} catch(ex) {
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const createAttributeGroupSaga = function*(action: any) {
	const {treeId} = action.payload;
	try{
		const response = yield call(postActionGroup, action.payload);
		yield* handleResponse(
			response,
			(id: number) => createSuccess(id, treeId),
			(message: string) => createFailed(message, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed('An error occurred', treeId));
		return;
	}
}

const updateAttributeGroupSaga = function*(action: any) {
	const {treeId} = action.payload;
	try{
		const response = yield call(postActionGroup, action.payload);
		yield* handleResponse(
			response,
			() => updateSuccess(treeId),
			(message: string) => updateFailed(message, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed('An error occurred', treeId));
		return;
	}
}

const deleteAttributeGroupSaga = function*(action: any){
	const {treeId} = action.payload;
	try{
		const response = yield call(deleteAttributeGroup, action.payload);
		yield* handleResponse(
			response,
			() => deleteSuccess(treeId),
			(message: string) => deleteFailed(message, treeId)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed('An error occurred', treeId));
		return;
	}
}

const watch = function*() {
	yield all([
		takeEvery(actions.attributeGroup.types.ATTRIBUTE_GROUP_CREATE, createAttributeGroupSaga),
		takeEvery(actions.attributeGroup.types.ATTRIBUTE_GROUP_UPDATE, updateAttributeGroupSaga),
		takeEvery(actions.attributeGroup.types.ATTRIBUTE_GROUP_DELETE, deleteAttributeGroupSaga),
	])
}

export default watch;