import { TransactionChangeLogs, TransactionChangeLogsFilters } from "../models";

const actionTypes = {
	TRANSACTION_CHANGE_LOGS_LOAD: 'TRANSACTION_CHANGE_LOGS\\LOAD',
	TRANSACTION_CHANGE_LOGS_LOAD_SUCCESS: 'TRANSACTION_CHANGE_LOGS\\LOAD_SUCCESS',
	TRANSACTION_CHANGE_LOGS_LOAD_FAILED: 'TRANSACTION_CHANGE_LOGS\\LOAD_FAILED',

	TRANSACTION_CHANGE_LOGS_UNLOAD: 'TRANSACTION_CHANGE_LOGS\\UNLOAD',
}

const load = (nodeId: number, currentPage: number, pageSize: number, filters: TransactionChangeLogsFilters, token: string) => ({
	type: actionTypes.TRANSACTION_CHANGE_LOGS_LOAD,
	payload: {
		nodeId,
		currentPage,
		pageSize,
		filters,
		token
	}
})

const loadSuccess = (nodeId: number, currentPage: number, pageSize: number, filters: TransactionChangeLogsFilters, transactionChangeLogs: TransactionChangeLogs[]) => ({
	type: actionTypes.TRANSACTION_CHANGE_LOGS_LOAD_SUCCESS,
	payload: {
		nodeId,
		currentPage,
		pageSize,
		filters,
		transactionChangeLogs
	}
})

const loadFailed = (nodeId: number, currentPage: number, pageSize: number, filters: TransactionChangeLogsFilters, message: string) => ({
	type: actionTypes.TRANSACTION_CHANGE_LOGS_LOAD_FAILED,
	payload: {
		nodeId,
		currentPage,
		pageSize,
		filters,
		message
	}
})

const unload = (nodeId: number) => ({
	type: actionTypes.TRANSACTION_CHANGE_LOGS_UNLOAD,
	payload: {
		nodeId
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
	unload
};