import { all, call, put, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { AttributeGroup } from '../models';
import handleResponse from './handleResponse';

const {
	loadSuccess, loadFailed,
	loadAttributesSuccess, loadAttributesFailed
} = actions.attributeGroups;
const {raise} = actions.error;

type PayloadType = {treeId: number, token: string};
type ResponseType = Promise<{status: number, body: any} | {error: any}>;

const tryLoad = (payload: PayloadType): ResponseType => {
	try{
		const {treeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/attributes/groups/`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryLoadAttributesInfo = (payload: PayloadType): ResponseType => {
	try{
		const {treeId} = payload;
		const headers = new Headers([["Authorization", `Bearer ${payload.token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/attribute-groups/attributes/`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractGroup = (entry: any): AttributeGroup => {
	return {
		id: entry.id,
		name: entry.name,
		authorId: entry.author_id,
		nodeId: entry.node_id
	}
}

const extractAttributesInfo = (data: any) => {
	const result: {[key: number]: {id: number, name: string}[] | undefined} = {};
	Object.keys(data).forEach((key: string) => {
		const safeKey = parseInt(key);
		result[safeKey] = data[key].map((info: any): {id: number, name: string} => ({
			id: info.id,
			name: info.name
		}))
	});
	return result;
}

const loadAttributeGroupsSaga = function*(action: any){
	const {treeId} = action.payload;
	try{
		const response = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(treeId, body.data.map(extractGroup)),
			(message: any) => loadFailed(treeId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(treeId, 'An error occurred'));
		return;
	}
}

const loadAttributesInfoSaga = function*(action: any){
	const {treeId} = action.payload;
	try{
		const response = yield call(tryLoadAttributesInfo, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadAttributesSuccess(treeId, extractAttributesInfo(body.data)),
			(message: string) => loadAttributesFailed(treeId, message)
		);
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadAttributesFailed(treeId, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	all([
		yield takeLeading(actions.attributeGroups.types.ATTRIBUTE_GROUPS_LOAD, loadAttributeGroupsSaga),
		yield takeLeading(actions.attributeGroups.types.ATTRIBUTE_GROUPS_ATTRIBUTES_LOAD, loadAttributesInfoSaga)
	]);
}

export default watch;