import React from 'react';
import { Skeleton } from 'antd';
import { Moment } from 'moment';

import {Balance, CommonPageProps, EntityPerTree, report, Report} from '../../models';
import { localization } from '../../settings';

import Workspace from '../Workspace';

import PivotReportOptions from './PivotReportOptions';
import PivotReportTable from './PivotReportTable';

interface PivotReportProps extends CommonPageProps{
	className?: string,

	nodeId: number,
	nodeName: string | null,
	treeId: number,

	balances: EntityPerTree<Balance>,

	report: Report | null,
	isReportLoading: boolean,

	reportTemplates: report.Template[] | null,

	applyTemplate: (nodeId: number, template: report.Template) => void,
	saveTemplate: (nodeId: number, template: report.TemplateWithoutId, token: string) => void,
	deleteTemplate: (nodeId: number, id: number, token: string) => void,

	updateReport: (options?: {dates?: [Moment, Moment], type?: report.ReportDateType}) => void,
	updateReportField: (nodeId: number, fieldId: string, update: report.ReportFieldUpdate) => void,

	token: string
}

interface PivotReportState{
	isOptionsCollapsed: boolean,
}

const defaultState: PivotReportState = {
	isOptionsCollapsed: false,
}

const containerClassName = 'pivot-report';

class PivotReport extends React.Component<PivotReportProps, PivotReportState>{
	constructor(props: PivotReportProps){
		super(props);

		this.state = defaultState;

		this.pivotTableRef = null;
	}

	pivotTableRef: PivotReportTable | null;

	setIsOptionsCollapsed = (value: boolean) => {
		this.setState((state) => ({
			...state,
			isOptionsCollapsed: value
		}))
	}

	export = () => {
		const {pivotTableRef} = this;
		pivotTableRef?.export();
	}

	renderMainTable = (zoom: number) => {
		const {
			nodeId, token,
			report, isReportLoading,
			updateReportField, treeId, balances
		} = this.props;

		if(!report || isReportLoading){
			return null;
		}

		return (
			<div
				className = {`${containerClassName}__table`}
			>
				<PivotReportTable
					ref = {(ref) => this.pivotTableRef = ref}
					zoom = {zoom}
					nodeId = {nodeId}
					report = {report}
					token = {token}
					treeId={treeId}
					balances={balances}
					updateReportField = {updateReportField}
				/>
			</div>
		);
	}

	render(){
		const {
			className,
			nodeId, nodeName,
			report, isReportLoading,
			currentUser, securityProvider,
			reportTemplates,
			token,
			applyTemplate, saveTemplate, deleteTemplate,
			updateReport, updateReportField
		} = this.props;

		const {isOptionsCollapsed} = this.state

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		if(!report || isReportLoading){
			return (
				<Skeleton
					className = {effectiveClassName}
				/>
			)
		}

		return (
			<div
				className = {effectiveClassName}
			>
				<Workspace
					title = {nodeName ? localization.reports_page_name_template.replace("{}", nodeName) : ''}
					currentUser = {currentUser}
					securityProvider = {securityProvider}
					reportNodeId = {nodeId}
					leftPanel = {(
						<PivotReportOptions
							className = {`${containerClassName}__properties`}
							nodeId = {nodeId}
							report = {report}
							token = {token}
							updateReport = {updateReport}
							templates = {reportTemplates}
							applyTemplate = {applyTemplate}
							saveTemplate = {saveTemplate}
							deleteTemplate = {deleteTemplate}
							isCollapsed = {isOptionsCollapsed}
							setIsCollapsed = {this.setIsOptionsCollapsed}
							updateReportField = {updateReportField}
							doExport = {this.export}
						/>
					)}
					customRender = {this.renderMainTable}
				/>
			</div>
		)
	}
}

export default PivotReport
