import React, { memo } from 'react';
import { Typography, Collapse } from 'antd';
import { AlertTriangle, RefreshCcw } from 'react-feather';

import { Button } from '../../components';
import { localization } from '../../settings';
const { Paragraph } = Typography;

interface ErrorProps{
	message: string,
	reset: () => void
}

const Error = (props: ErrorProps) => {
	return (
		<div className="bra-error">
			<AlertTriangle className="bra-error-sign" />
			<Paragraph>
				<h1>{localization.error_title}</h1>
				<Button
					className="bra-error-button"
					type="primary"
					icon={<RefreshCcw />}
					onClick={props.reset}
				>
					{localization.error_button}
				</Button>
				<Collapse>
					<Collapse.Panel header={localization.error_info} key="-1">
						{props.message}
					</Collapse.Panel>
				</Collapse>
			</Paragraph>
		</div>
	)
}

export default memo(Error);