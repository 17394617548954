import React, { memo } from 'react';
import { localization } from '../../settings';

interface EmptyProps{
    className?: string,
}

const Empty = (props: EmptyProps) => {
    const {className} = props;
    const containerClassName = 'empty-container';
    const effectiveContainerClassName = className ? `${containerClassName} ${className}`: containerClassName;
    return (
        <div className = {effectiveContainerClassName}>
            <span>{localization.noData}</span>
        </div>
    )
}

export default memo(Empty);