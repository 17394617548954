import { connect } from 'react-redux';

import { CommonPageProps } from '../../models';
import { State } from '../../reducers';
import actions from '../../actions';

import FormulaEditor from './FormulaEditor';

import './FormulaEditor.scss';
import './FormulaLegend.scss';
import './FormulaPanel.scss';
import './FormulaEntry.scss';

const mapStateToProps = (state: State, _: CommonPageProps) => {
	
	return {
		balancesPerTree: state.balances,
		balanceAttributes: state.balanceAttributes,
		nodeAttributesPerTree: state.nodeAttributesForTree,
		formulaPerBalance: state.balanceFormula,
		balanceTypes: state.balanceTypes,
		token: state.auth.token,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	unloadTree: (treeId: number) =>
		dispatch(actions.tree.unload(`${treeId}`)),
	loadBalances: (treeId: number, token: string) =>
		dispatch(actions.balances.load(treeId, token)),
	loadBalanceFormula: (treeId: number, balanceId: number, token: string) =>
		dispatch(actions.balanceFormula.load(treeId, balanceId, token)),
	loadBalanceAttributes: (treeId: number, balanceId: number, token: string) =>
		dispatch(actions.balanceAttributes.load(balanceId, treeId, token)),
	loadNodeAttributes: (treeId: number, token: string) => 
		dispatch(actions.nodeAttributesForTree.load(treeId, token)),
	loadBalanceTypes: (token: string) => 
		dispatch(actions.balanceTypes.load(token)),

	setBalanceFormula: (treeId: number, balanceId: number, formula: string, token: string) =>
		dispatch(actions.balanceFormula.set(treeId, balanceId, formula, token)),
	resetTree: (treeId: number) =>
		dispatch(actions.tree.reset(treeId))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormulaEditor)