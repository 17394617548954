import React from 'react';
import { Lock, MinusSquare, PlusSquare } from 'react-feather';


interface TableHeaderProps{
	title: string,
	isFixed?: boolean,

	onExpand?: () => void,
	onCollapse?: () => void,
}

const TableHeader = (props: TableHeaderProps) => {
	const {
		title, isFixed,
		onExpand, onCollapse
	} = props;

	const containerClassName = 'header-cell';

	const expanderElement = onExpand ? (
		<div
			className = {`${containerClassName}__expander`}
		>
			<PlusSquare
				className = {`feather-ico`}
				onClick = {onExpand}
			/>
		</div>
	) : null;

	const collapserElement = onCollapse ? (
		<div
			className = {`${containerClassName}__collapser`}
		>
			<MinusSquare
				className = {`feather-ico`}
				onClick = {onCollapse}
			/>
		</div>
	) : null;

	return (
		<div className = {containerClassName}>
			{expanderElement}
			{collapserElement}
			<span
				className = {`${containerClassName}__text`}
				title = {title}
			>
				{title}
			</span>
			{isFixed && <Lock className = {`${containerClassName}__lock`}/>}
		</div>
	);
}

export default TableHeader;