import React from 'react';
import {RefreshCw} from 'react-feather';

import { AttributeLocator, TransactionAttributes, TransactionFilterValueItem, TransactionsTableState, TransactionsFilters, TransactionsList, TransactionType, UpdateAttributeLocatorType, UserAccount } from '../../models';
import { TransactionsTable } from '../../components';
import { getSecurityProvider, localization, TreeSecurityProvider } from '../../settings';

import Workspace from '../Workspace';

interface TransactionsContainerProps{
	className?: string,

	treeId: number,
	treeName: string | null,
	authorIdFilterValues: TransactionFilterValueItem[] | null,
	nodeIdFilterValues: TransactionFilterValueItem[] | null,
	correspondentIdFilterValues: TransactionFilterValueItem[] | null,
	containerState: TransactionsTableState | null,

	currentUser: UserAccount,
	treeSecurityProvider: TreeSecurityProvider,
	token: string,

	transactionTypes: TransactionType[] | null,
	transactions: TransactionsList | null,
	transactionAttributes: TransactionAttributes,
	loadTransactions: (treeId: number, page: number, size: number, filters: TransactionsFilters, token: string) => void,
	loadTransactionAttributes: (attributes: AttributeLocator[], token: string) => void,
	loadTransactionFilterValues: (treeId: number, fieldName: string, token: string) => void,
	updateTransaction: (transactionId: number, changes: {comment?: string | null, customDate?: Date}, token: string) => void,
	updateTransactionAttributes: (attribute: UpdateAttributeLocatorType, token: string) => void,
	cancelTransaction: (treeId: number, transactionId: number, token: string) => void,
	saveContainerState: (treeId: number, state: TransactionsTableState) => void,
}

const containerClassName = 'transactions-container';

class TransactionsContainer extends React.Component<TransactionsContainerProps>{
	onRefresh = () => {
		const {
			treeId, transactions,
			token,
			loadTransactions,
			loadTransactionFilterValues
		} = this.props;
		transactions && loadTransactions(treeId, transactions.currentPage, transactions.pageSize, transactions.filters, token);
		loadTransactionFilterValues(treeId, "authorId", token);
		loadTransactionFilterValues(treeId, "nodeId", token);
		loadTransactionFilterValues(treeId, "correspondentId", token);
	}

	renderPageTitle = (title: string) => {
		return (
			<div className = {`${containerClassName}__header`}>
				<span
					className = {`${containerClassName}__header__text`}
				>
					{title}
				</span>
				<span
					className = {`${containerClassName}__header__icon`}
				>
					<RefreshCw
						className = {`feather-ico`}
						onClick = {() => this.onRefresh()}
					/>
				</span>
			</div>
		);
	}

	render(){
		const {
			className,
			treeId, treeName,
			currentUser, token, treeSecurityProvider,
			authorIdFilterValues, nodeIdFilterValues, correspondentIdFilterValues,
			transactions, transactionTypes, transactionAttributes,
			containerState,
			loadTransactions, loadTransactionAttributes,
			updateTransaction, updateTransactionAttributes,
			saveContainerState, cancelTransaction
		} = this.props;

		const title = localization.operations_page_name_template.replace('{}', `${treeName}`);

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		return (
			<div className = {effectiveClassName}>
				<Workspace
					title = {title}
					pageTitle = {this.renderPageTitle(title)}
					transactionsListId = {treeId}
					currentUser = {currentUser}
					securityProvider = {getSecurityProvider(currentUser.permissions)}
				>
					<TransactionsTable
						treeId = {treeId}

						authorIdFilterValues = {authorIdFilterValues}
						nodeIdFilterValues = {nodeIdFilterValues}
						correspondentIdFilterValues = {correspondentIdFilterValues}

						transactions = {transactions}
						transactionTypes = {transactionTypes}
						transactionAttributes = {transactionAttributes}

						containerState = {containerState}

						treeSecurityProvider = {treeSecurityProvider}
						token = {token}

						loadTransactions = {loadTransactions}
						loadTransactionAttributes = {loadTransactionAttributes}

						updateTransaction = {updateTransaction}
						updateTransactionAttributes = {updateTransactionAttributes}

						cancelTransaction = {cancelTransaction}

						saveContainerState = {saveContainerState}
					/>
				</Workspace>
			</div>
		)
	}
}

export default TransactionsContainer;