const site = {
	"home": {
		"name": "Панель управления",
		"url": "/"
	},
	"users": {
		"name": "Пользователи",
		"url": "/users"
	},
	"newUser": {
		"name": "Новый пользователь",
		"url": "/users/new"
	},
	"roles": {
		"name": "Системные роли",
		"url": "/roles"
	},
	"newRole": {
		"name": "Новая роль",
		"url": "/roles/new"
	},
	"actionGroups": {
		"name": "Группы действий",
		"url": "/actionGroups",
	},
	"newActionGroup": {
		"name": "Новая группа действий",
		"url": "/actionGroups/new",
	},
	"attributeGroups": {
		"name": "Группы атрибутов",
		"url": "/attributeGroups"
	},
	"balances": {
		"name": "Балансы",
		"url": "/balances"
	},
	"trees": {
		"name": "Деревья и узлы",
		"url": "/trees"
	},
	"transactions": {
		"name": "Транзакции",
		"url": "/trees/:treeId/transactions"
	},
	"transactionsElastic": {
		"name": "Линейные таблицы",
		"url": "/trees/:treeId/line"
	},
	"reports": {
		"name": 'Отчет по {}',
		"url": "/trees/:treeId/report"
	},
	"transactionChangesLogs": {
		"name": "Логи изменения транзакций",
		"url": "/trees/:treeId/transactionChanges"
	}
}

export default site;