import React, {Dispatch, FC, SetStateAction} from 'react'
import {Modal} from "antd";
import {localization} from "../../settings";
import {version} from '../../../package.json';
import {VersionInfo} from "../../models";
import {Button} from "../index";


interface Props {
    isModalVisible: boolean;
    setIsModalVisible: Dispatch<SetStateAction<boolean>>;
    version: VersionInfo;
}

export const TopMenuDialog: FC<Props> = ({isModalVisible, setIsModalVisible, version: backendVersion}) => {

    return (
        <>
            <Modal
                title={'version info'}
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button type="primary" onClick={() => setIsModalVisible(false)}>{localization.ok}</Button>
                ]}
            >
                <span>Front: </span>
                <p>version: {version}</p>

                <span>Back</span>
                {backendVersion?.version && <p>version: {backendVersion.version}</p>}
                {backendVersion?.gitBranch && <p>branch: {backendVersion?.gitBranch}</p>}
                {backendVersion?.gitHash && <p>commit: {backendVersion?.gitHash}</p>}
                {backendVersion?.env && <p>environment: {backendVersion?.env}</p>}

            </Modal>
        </>
    );
}