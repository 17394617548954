//Please refactor all of this
import React, { useEffect, useRef, useState } from 'react';
import {Form, Input, Modal} from 'antd';
import { FormInstance } from 'antd/lib/form';

import { errors, localization } from '../../settings';

interface SetNameDialogProps extends React.HTMLProps<HTMLElement>{
	title?: string,
	initialValue?: string,
	inputPlaceholder?: string,

	onCreate?: (name: string) => void
}

interface SetNameDialogFnProps{
	title?: string,
	initialValue?: string,
	inputPlaceholder?: string,
	style?: React.CSSProperties,
	maskStyle?: React.CSSProperties,

	onOk?: (name: string) => void,
	onClose?: () => void
}

const handleOk = (
	form: FormInstance<any>,
	isMountedContainer: {current: boolean},
	setIsVisible: (value: boolean) => void,
	onCreate?: (name: string) => void
) => {
	form.validateFields()
		.then(values => {
			const {name} = values;
			isMountedContainer.current && onCreate && onCreate(name);
			isMountedContainer.current && setIsVisible(false);
		}, reason => {
			isMountedContainer.current && console.log('Validation failed\n%o', reason);
		})
}

const handleCancel = (form: FormInstance<any>, setIsVisible: (value:boolean) => void) => {
	form.resetFields();
	setIsVisible(false);
}

const SetNameDialog = (props: SetNameDialogProps) => {
	const {onCreate, title, initialValue, inputPlaceholder} = props;
	const [isVisible, setIsVisible] = useState(false);
	const [form] = Form.useForm();
	const initialValues = {"name": initialValue || ''};

	const _isMounted = useRef(true);
	useEffect(() => {
		if(isVisible){
			form.resetFields();
		}
		_isMounted.current = true;
		return () => { // ComponentWillUnmount in Class Component
			_isMounted.current = false;
		}
	}, [initialValue, form, isVisible]);

	const clonedChildren = React.Children.map(props.children, (child: any) => {
		return React.cloneElement(child, {onClick: () => setIsVisible(true)})
	})

	return (
		<>
			{clonedChildren}
			<Modal
				title={title}
				visible={isVisible}
				onOk={() => handleOk(form, _isMounted, setIsVisible, onCreate)}
				onCancel={() => handleCancel(form, setIsVisible)}
				okText={localization.save}
				cancelText={localization.cancel}
			>
				<Form
					initialValues = {initialValues}
					form = {form}
				>
					<Form.Item
						name = "name"
						rules = {[{required: true, message: errors.fields_is_required}]}
					>
						<Input
							placeholder = {inputPlaceholder}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}


const showSetNameDialog = (props: SetNameDialogFnProps) => {
	const {onOk, onClose, title, initialValue, inputPlaceholder, style, maskStyle} = props;
	const initialValues = {"name": initialValue || ''};

	const formHolder: React.RefObject<FormInstance<any>> = {current: null};
	const nameInputHolder: React.RefObject<Input> = {current: null};

	const handleFnOk = (
		close?: () => void,
		onCreate?: (name: string) => void
	) => {
		const result = formHolder.current?.validateFields()
			.then(values => {
				const {name} = values;
				onCreate && onCreate(name);
				close && close();
			});
		return result;
	}
	
	const handleFnCancel = (close?: () => void) => {
		formHolder.current?.resetFields();
		close && close();
	}	

	Modal.confirm({
		title: title,
		visible: true,
		style: style,
		icon: null,
		okText: localization.save,
		cancelText: localization.cancel,
		maskClosable: true,
		maskStyle: maskStyle,
		onOk: () => handleFnOk(onClose, onOk),
		onCancel: () => handleFnCancel(onClose),
		content: (
			<Form
				initialValues = {initialValues}
				ref = {formHolder}
			>
				<Form.Item
					name = "name"
					rules = {[{required: true, message: errors.fields_is_required}]}
				>
					<Input
						ref = {nameInputHolder}
						placeholder = {inputPlaceholder}
					/>
				</Form.Item>
			</Form>
		)
	})
}

SetNameDialog.show = showSetNameDialog;

export default SetNameDialog;