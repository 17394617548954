import React from 'react';

import { AttributeValue, AttributeVisibility } from '../../models';

import { CustomPopup } from '..';
import { Edit2, PlusCircle } from 'react-feather';
import { localization } from '../../settings';
import EditAttributeValuePanel from './EditAttributeValuePanel';


interface AttributeValuesProps{
	className?: string | null,
	values?: AttributeValue[] | null,
	visibilities: AttributeVisibility[] | null,

	addValue: (value: {name: string, visibilityId: number}) => void,
	editValue: (id: number, value: {name: string | null, visibilityId: number | null}) => void,
	deleteValue: (id: number) => void,
	onSelectValue: (attributeValueId: number) => void
}

interface AttributeValuesState{
	isPopupShown: boolean,
	editModeValue?: AttributeValue | null
}

const defaultState: AttributeValuesState = {
	isPopupShown: false
}

const containerClassName = 'attribute-values-list';

class AttributeValues extends React.Component<AttributeValuesProps, AttributeValuesState> {
	constructor(props: AttributeValuesProps){
		super(props);
		this.state = defaultState;
	}

	showPopup = () => {
		this.setState((state) => ({...state, isPopupShown: true}));
	}

	hidePopup = () => {
		this.setState((state) => ({...state, isPopupShown: false, editModeValue: undefined}));
	}

	switchToEditMode = (value: AttributeValue | null, event: React.MouseEvent) => {
		event.stopPropagation();
		this.setState((state) => ({...state, editModeValue: value}))
	}

	switchToListMode = () => {
		this.setState((state) => ({...state, editModeValue: undefined}))
	}

	renderListModeContent = () => {
		const {values, onSelectValue} = this.props;
		const vals = [...values];
		let a = vals?.splice(vals?.findIndex(el => el.name === 'Не используется'), 1);
		let b = vals?.splice(vals?.findIndex(el => el.name === 'Отсутствует'), 1);

		const selectedValue = vals?.find(val => val.isSelected);
		const availableUnselectedValues = (vals || [])
			.filter(v => !v.isSelected)
			.sort((a, b) => a.id - b.id)

		const isReadOnly = selectedValue?.isBlocked;
		const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
		const concatedArr = [a[0]?.isSelected ? a[0] : b[0]?.isSelected ? b[0] : selectedValue, ...availableUnselectedValues.sort((a, b) => collator.compare(a?.name, b?.name)), !(a?.[0]?.isSelected) ? a[0] : null, !(b?.[0]?.isSelected) ? b[0] : null]
		const availableValueElements = concatedArr
			.filter((v): v is AttributeValue => v != null)
			.map(v => {
				const className = `${containerClassName}__values-list__entry${v.isSelected ? ' selected': ''}`;
				return (
					<div
						key = {v.id}
						className = {className}
						onClick = {() => !isReadOnly && onSelectValue(v.id)}
					>
						<div className = {`${containerClassName}__values-list__entry__name`}>
							{v.name}
						</div>
						<div className = {`${containerClassName}__values-list__entry__visibility`}>
							{v.visibility.name}
						</div>
						<div
							className = {`${containerClassName}__values-list__entry__actions`}
							onClick = {(event) => this.switchToEditMode(v, event)}
						>
							<Edit2
								className = 'feather-ico'
							/>
						</div>
					</div>
				)
			})
		return (
			<>
				{availableValueElements}
				<div
					className = {`${containerClassName}__values-list__add-new`}
					onClick = {(event) => this.switchToEditMode(null, event)}
				>
					<PlusCircle className = {`${containerClassName}__values-list__add-new__ico`} />
					<span>{localization.newAttributeValueButton}</span>
				</div>
			</>
		)
	}

	renderEditModeContent = () => {
		const {visibilities, addValue, editValue, deleteValue} = this.props;
		const {editModeValue} = this.state;
		return (
			<EditAttributeValuePanel
				value = {editModeValue || null}
				visibilities = {visibilities}
				readOnly ={!!editModeValue}
				close = {this.switchToListMode}

				addValue = {addValue}
				editValue = {editValue}
				deleteValue = {deleteValue}
			/>
		)
	}

	render(){
		const {className, values} = this.props;
		const {isPopupShown, editModeValue} = this.state
		const selectedValue = values?.find(val => val.isSelected);

		const content = editModeValue !== undefined ? (
			this.renderEditModeContent()
		): (
			this.renderListModeContent()
		)
		let effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		return (
			<div className = {effectiveClassName}>
				<div
					className = {`${containerClassName}__selected-value`}
					onClick = {this.showPopup}
				>
					{selectedValue?.name}
				</div>
				<CustomPopup
					className = {`${containerClassName}__values-list`}
					visible = {isPopupShown}
					onClose = {this.hidePopup}
				>
					{content}
				</CustomPopup>
			</div>
		);	
	}
}

export default AttributeValues;