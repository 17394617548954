import React, { useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';

import { Role, Permission, CommonPageProps } from '../../models';

import NotFound from '../NotFound';
import EditRolePermissions from './EditRolePermissions';
import {SubmitRoleType} from './EditRolePermissions';
import { site } from '../../settings';
import { PageTitle } from '../../components';

interface EditRoleProps extends CommonPageProps{
	isRolesLoading: boolean,
	isRoleUpdating: boolean,
	isPermissionsLoading: boolean,
	roles: Role[] | null,
	permissions: Permission[] | null,
	isNew?: boolean,
	token: string,

	loadRoles: (token: string) => void,
	loadPermissions: (token: string) => void
	updateRole: SubmitRoleType,
	createRole: SubmitRoleType,
	rolesReset: () => void
}

const containerClassName = 'edit-role'

const EditRole = (props: EditRoleProps) => {
	const {id} = useParams<{id: string}>();

	const {
		roles, isRolesLoading, isRoleUpdating, isNew,
		permissions, isPermissionsLoading,
		token, securityProvider,
		loadRoles, updateRole, createRole,
		loadPermissions
	} = props;

	useEffect(() => {
		if(!isNew && roles === null && !isRolesLoading){
			loadRoles(token);
		}
		if(permissions === null && securityProvider.permissions.canRead && !isPermissionsLoading){
			loadPermissions(token);
		}
	});

	let submitRole: SubmitRoleType;
	let role: Role | undefined;

	if(!isNew){
		if(!isRolesLoading){
			role = roles?.find(r => r.id?.toString() === id);

			if(!role){
				return (<NotFound/>);
			}
		}
		submitRole = updateRole;
	} else {
		submitRole = createRole;
	}

	let content: JSX.Element;
	if((role || isNew) && !isRolesLoading && !isRoleUpdating && !isPermissionsLoading){
		content = (
			<EditRolePermissions
				role={role}
				isNew={isNew}
				permissions={permissions}
				token={token}
				submitRole={submitRole}
				securityProvider = {securityProvider}
				className = {`${containerClassName}__content`}
			/>
		)
	} else {
		content = <Skeleton active/>
	}

	return (
		<Row className = {`${containerClassName} page-content`}>
			<Col md={{span: 12, offset: 2}}>
				<PageTitle
					title = {isNew ? site.newRole.name : role?.name || ''}
					backUrl = {site.roles.url}
				/>
				{content}
			</Col>
		</Row>
	);
}

export default EditRole;