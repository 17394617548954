import actions from '../actions';
import { SingleEntityPerTree, TransactionsTableState } from '../models';

export interface TransactionsElasticContainerStates extends SingleEntityPerTree<TransactionsTableState>{
}

const defaultState: TransactionsElasticContainerStates = {
}

const transactionsContainerStates = (state: TransactionsElasticContainerStates = defaultState, action: any): TransactionsElasticContainerStates => {
	switch(action.type){
		case actions.transactionsElasticContainerState.types.TRANSACTIONS_ELASTIC_CONTAINER_STATE_SAVE: {
			const {treeId, transactionsState} = action.payload;
			return {
				...state,
				[treeId]: {
					entity: transactionsState,
					error: null,
					isLoading: false
				}
			}
		}

		case actions.transactionsElastic.types.TRANSACTIONS_ELASTIC_UNLOAD: {
			const {id} = action.payload;
			const treeId = parseInt(id);
			const updatedState = {
				...state
			};
			delete updatedState[treeId];
			return updatedState;
		}
	}
	return state;
}

export default transactionsContainerStates;