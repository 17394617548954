import React from 'react';
import { Modal, Skeleton } from 'antd';

import { Button, SetNameDialog, Switcher } from '../../components';
import { EntityPerTree, AttributeVisibility, Tree, AttributeGroup, NodesState } from '../../models';
import { localization } from '../../settings';
import { findNode } from '../../tools';

import AttributesTable from './AttributesTable';
import { PlusCircle } from 'react-feather';

export interface AttributesDialogProps{
	className?: string | null,
	visible?: boolean | null,

	tree: Tree | null,
	node: Tree | null,
	nodesInfo: NodesState,
	isAttributeVisibilitiesLoading: boolean,
	attributeVisibilities: AttributeVisibility[] | null,
	attributeGroups: EntityPerTree<AttributeGroup>,
	token: string,

	onClose: () => void,

	loadAttributeVisibilities: (token: string) => void,
	loadAttributeGroups: (treeId: number, token: string) => void,
	loadNodeAttributes: (nodeId: number, token: string) => void
	createAttribute: (name: string, nodeId: number, treeId: number, token: string) => void,
	updateAttribute: (attribute: {id: number, name?: string, visibilityId?: number, nodeId: number}, treeId: number, token: string) => void,
	deleteAttribute: (id: number, nodeId: number, treeId: number, token: string) => void,

	createAttributeValue: (value: {name: string, visibilityId: number}, nodeId: number, attributeId: number, token: string) => void,
	editAttributeValue: (id: number, value: {name: string | null, visibilityId: number | null}, nodeId: number, attributeId: number, token: string) => void,
	deleteAttributeValue: (id: number, nodeId: number, attributeId: number, token: string) => void,
	setAttributeValue: (nodeId: number, attributeId: number, value: {id: number, propagate?: boolean}, token: string) => void,

	setAttributeGroups: (attributeId: number, nodeId: number, treeId: number, newGroupIds: number[], currentGroupIds: number[], token: string) => void
}

interface AttributesDialogState{
	selectedPageId: 'own' | 'inherited'
}

const defaultState: AttributesDialogState = {
	selectedPageId: 'inherited'
}

const containerClassName = 'attributes-dialog';

class AttributesDialog extends React.Component<AttributesDialogProps, AttributesDialogState>{
	constructor(props: AttributesDialogProps){
		super(props);
		this.state = defaultState;
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			node, nodesInfo,
			token,
			attributeVisibilities, isAttributeVisibilitiesLoading, loadAttributeVisibilities,
			attributeGroups, loadAttributeGroups,
			loadNodeAttributes
		} = this.props;

		if(!attributeVisibilities && !isAttributeVisibilitiesLoading){
			loadAttributeVisibilities(token);
		}

		if(node?.id && !attributeGroups[node.id]){
			loadAttributeGroups(node.id, token);
		}

		if(node && nodesInfo[node.id] && !nodesInfo[node.id]?.attributes && !nodesInfo[node.id]?.isAttributesLoading){
			loadNodeAttributes(node.id, token);
		}
	}

	createAttributeValue = (value: {name: string, visibilityId: number}, attributeId: number) => {
		const {node, token, createAttributeValue} = this.props;
		node?.id && createAttributeValue(value, node.id, attributeId, token);
	}

	editAttributeValue = (id: number, value: {name: string | null, visibilityId: number | null}, attributeId: number) => {
		const {node, token, editAttributeValue} = this.props;
		node?.id && editAttributeValue(id, value, node.id, attributeId, token);
	}

	deleteAttributeValue = (id: number, attributeId: number) => {
		const {node, token, deleteAttributeValue} = this.props;
		node?.id && deleteAttributeValue(id, node.id, attributeId, token);
	}

	getColumns = () => {
		const result = [{
			title: localization.nodeAttributes_table_attribute,
			dataIndex: 'name'
		},{
			title: localization.nodeAttributes_table_value,
			dataIndex: 'value'
		},{
			title: localization.nodeAttributes_table_propagate,
			dataIndex: 'propagate'
		}];
		if(this.state.selectedPageId === 'inherited'){
			result.push({
				title: localization.nodeAttributes_table_inherited,
				dataIndex: 'inherited'
			});
		}
		result.push(...[
			{
				title: localization.nodeAttributes_table_groups,
				dataIndex: 'groups'
			},{
				title: localization.nodeAttributes_table_visibility,
				dataIndex: 'visibility'
			}
		])
		return result;
	}

	getDataSource = () => {
		const {tree, node, nodesInfo} = this.props;
		const {selectedPageId} = this.state;
		const attributes = node?.id && nodesInfo[node.id]?.attributes;
		const safeAttributes = attributes || [];
		return safeAttributes
			.filter(attr => selectedPageId === 'own' ? attr.nodeId === node?.id : attr.nodeId !== node?.id)
			.map(attr => {
				const value = attr.values.find(attr => attr.isSelected);
				return {
					key: attr.id,
					name: attr.name,
					value: value?.name,
					propagate: value?.propagate ? 'yes': 'no',
					inherited: findNode(attr.nodeId, tree)?.name,
					groups: [],
					visibility: attr.visibility.name
				}
			});
	}

	changePage = (id: React.ReactText) => {
		if(id === 'own'){
			this.setState((state) => ({
				...state,
				selectedPageId: 'own'
			}));
		} else {
			this.setState((state) => ({
				...state,
				selectedPageId: 'inherited'
			}));
		}
	}

	renderContent = () => {
		const {
			node, nodesInfo, attributeVisibilities, attributeGroups, tree,
			token,
			createAttribute, updateAttribute, deleteAttribute,
			setAttributeValue, setAttributeGroups
		} = this.props;
		const {selectedPageId} = this.state;

		const nodeInfo = node?.id && nodesInfo[node.id];
		if(!nodeInfo || nodeInfo.isAttributesLoading){
			return <Skeleton/>
		}
		const attributes = (nodeInfo.attributes || [])
			.filter((a) => selectedPageId === 'own' ? a.nodeId === node?.id : a.nodeId !== node?.id);
		const currentAttributeGroups = tree?.id ? attributeGroups[tree?.id]?.list : [];
		const currentAttributeGroupsIsLoading = tree?.id ? attributeGroups[tree?.id]?.isLoading : false;
		return (
			<>
				<div className = {`${containerClassName}__switcher`}>
					<Switcher
						className = {`${containerClassName}__switcher__component`}
						options = {[{
							label: localization.nodeAttributes_inherited_attributes,
							value: 'inherited'
						},{
							label: localization.nodeAttributes_own_attributes,
							value: 'own'
						}]}
						selectedId = {selectedPageId}
						onChange = {this.changePage}
					/>
					<Button
						className = {`${containerClassName}__switcher__new-attr`}
						icon = {<PlusCircle/>}
						onClick = {() => {
							node?.id && tree?.id && SetNameDialog.show({
								title: localization.newAttributeTitle,
								inputPlaceholder: localization.newAttributeNamePlaceholder,
								onOk: (name) => createAttribute(name, node.id, tree.id, token)
							})
						}}
					>
						{localization.newAttributeButton}
					</Button>
				</div>
				<div className ={`${containerClassName}__content`}>
					<AttributesTable
						className = {`${containerClassName}__content__table`}
						mode = {selectedPageId}
						attributes = {attributes || []}
						attributeVisibilities = {attributeVisibilities || []}
						attributeGroups = {currentAttributeGroups || null}
						attributeGroupsIsLoading = {!!currentAttributeGroupsIsLoading}
						tree = {tree}
						updateAttribute = {(id, name, visibilityId) => node?.id && tree?.id && updateAttribute({id, name, visibilityId, nodeId: node.id}, tree.id, token)}
						deleteAttribute = {(id) => node?.id && tree?.id && deleteAttribute(id, node.id, tree.id, token)}
						createAttributeValue = {this.createAttributeValue}
						editAttributeValue = {this.editAttributeValue}
						deleteAttributeValue = {this.deleteAttributeValue}
						onSetAttributeValue = {(attributeId, attributeValueId, propagateValue) =>
							node?.id && setAttributeValue(node.id, attributeId, {id: attributeValueId, propagate: propagateValue}, token)}
						onChangeGroups = {(attributeId, newGroupIds, currentGroupIds) => {
							return node?.id && tree?.id && setAttributeGroups(attributeId, node.id, tree.id, newGroupIds, currentGroupIds, token)
						}}
					/>
				</div>
			</>
		)
	}

	render(){
		const {
			className, visible,
			node,
			onClose
		} = this.props;

		const effectiveContainerClassName = className ? `${containerClassName} ${className}` : containerClassName;

		return (
			<Modal
				className = {effectiveContainerClassName}
				visible = {visible || false}
				onCancel = {onClose}
				footer = {null}
				centered
				width="auto"
			>
				<div className = {`${containerClassName}__header`}>
					<span className ={`${containerClassName}__header__prefix`}>{localization.nodeAttributes_title_prefix}&nbsp;</span>
					<span className ={`${containerClassName}__header__name`}>{node?.name}</span>
				</div>
				{this.renderContent()}
			</Modal>
		)
	}
}

export default AttributesDialog;