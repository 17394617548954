import actions from "../actions";

import { EntityPerTree, TransactionsElasticAttribute } from "../models";

export interface TransactionsElasticAttributesState extends EntityPerTree<TransactionsElasticAttribute>{
}

const defaultState: TransactionsElasticAttributesState = {
}

const state = (state: TransactionsElasticAttributesState = defaultState, action: any): TransactionsElasticAttributesState => {
	switch(action.type){
		case actions.transactionsElasticAttributes.types.TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD: {
			const {treeId} = action.payload;
			return {
				...state,
				[treeId]: {
					isLoading: true,
					list: null,
					error: null
				}
			}
		}
		case actions.transactionsElasticAttributes.types.TRANSACTIONS_ELASTIC_ATTRIBUTES_LOAD_SUCCESS: {
			const {treeId, attributes}: {treeId: number, attributes: TransactionsElasticAttribute[]}
				= action.payload;
			return {
				...state,
				[treeId]: {
					isLoading: false,
					list: attributes,
					error: null
				}
			};
		}
	}
	return state;
}

export default state;