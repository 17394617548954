import { dxElement } from 'devextreme/core/element';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import dxPivotGrid from 'devextreme/ui/pivot_grid';
import { Workbook } from 'exceljs';
import saver from 'file-saver';

export interface ExportOptions{
	component?: dxPivotGrid,
	element?: dxElement,
	model?: any,
	fileName?: string,
	cancel?: boolean,
}

const onExport = (e: ExportOptions) => {
	const workbook = new Workbook();
	const worksheet = workbook.addWorksheet('Main');
	exportPivotGrid({
		component: e.component,
		worksheet: worksheet,
		customizeCell: () => {

		}
	}).then(() => {
		workbook.xlsx.writeBuffer()
			.then(buffer => {
				saver.saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PivotGrid.xlsx');
			})
	})
	e.cancel = true;
}

export default onExport;