import React, { memo } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

interface PaginationProps{
	className?: string,
	pageNumber: number,
	onChange?: (page: number) => void,
}

const containerClassName = 'bra-pagination';

const Pagination = (props: PaginationProps) => {
	const {
		className,
		pageNumber,
		onChange
	} = props;

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const number = Number(e.target.value);
		if(isNaN(number)){
			return;
		}
		onChange && onChange(number);
	}

	const handleButtonClick = (number: number) => {
		(number > 0) && onChange && onChange(number);
	}

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__content`}>
				<div
					className = {`${containerClassName}__content__button`}
				>
					<ChevronLeft
						className={'feather-ico'}
						onClick = {() => handleButtonClick(pageNumber - 1)}
					/>
				</div>
				<input
					className = {`${containerClassName}__content__current-page`}
					type = 'number'
					value = {pageNumber}
					onChange = {handleInputChange}
				/>
				<div
					className = {`${containerClassName}__content__button`}
				>
					<ChevronRight
						className={'feather-ico'}
						onClick = {() => handleButtonClick(pageNumber + 1)}
					/>
				</div>
			</div>
		</div>
	)
}

export default memo(Pagination);