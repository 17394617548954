import { Skeleton } from 'antd';
import React from 'react';

import { Balance, EntityPerTree, TransactionType, Tree, TreesHash, UserAccount } from '../../models';
import { localization, SecurityProvider, site, slugs, TreeSecurityProvider } from '../../settings';
import { findNode, selectNode } from '../../tools';
import TransactionWizard, { SourceEndpoint, TargetEndpoint } from '../TransactionWizard';
import TreeProperties from '../TreeProperties';
import TreeView from '../TreeView';
import Workspace from '../Workspace';

enum TransactionMode{
	SingleNode,
	TwoNodes
}

interface TransactionWizardWrapperProps{
	className?: string,

	trees: TreesHash,
	balances: EntityPerTree<Balance>,

	currentTree: Tree | null,
	treeId: number,
	selectedNode: Tree | null,
	selectedBalanceId: number | null,
	selectedRowId: string | number | null,
	fixedRowIds: React.ReactText[],
	expandedRows: React.ReactText[],

	currentUser: UserAccount,
	token: string,

	securityProvider: SecurityProvider,
	treeSecurityProvider: TreeSecurityProvider,

	addNode: (treeId: number, parentId: number, name: string, token: string) => void,
	updateNodeName: (treeId: number, nodeId: number, name: string, token: string) => void,

	clearSelection: () => void,
	toggleFixRows: () => void,
	setExpandedRows: (keys: React.ReactText[]) => void,
	confirmDeleteNode: (treeId: number, nodeId: number) => void,
	showUserTreePermissionsDialog: (nodeId: React.ReactText) => void,
	showNodeAttributesDialog: (nodeId: React.ReactText) => void,
	onEntryClick: (nodeId: React.ReactText, balanceId?: string | number | undefined) => void,

	loadTree: (id: string, token: string) => void,
	loadBalances: (treeId: number, token: string) => void,
}

interface TransactionWizardWrapperState{
	transactionInProgress: {
		mode: TransactionMode,
		source: SourceEndpoint,
		target?: TargetEndpoint,
		supportedBalanceTypeId?: number,
		type: TransactionType,
		expandedRows: React.ReactText[]
	} | null
}

const defaultState: TransactionWizardWrapperState = {
	transactionInProgress: null
}

const getIsParent = (childNode: Tree) => (node: Tree | null): boolean => {
	return !!node?.children?.find(n => n.id === childNode.id)
}

class TransactionWizardWrapper extends React.Component<TransactionWizardWrapperProps, TransactionWizardWrapperState>{
	constructor(props: TransactionWizardWrapperProps){
		super(props)
		this.state = defaultState;
	}

	componentDidMount(){
		this.loadData();
	}

	componentDidUpdate(){
		this.loadData();
	}

	loadData = () => {
		const {
			trees, loadTree,
			balances, loadBalances,
			token
		} = this.props;
		const {transactionInProgress} = this.state;

		const targetTreeId = transactionInProgress?.target?.treeId;
		if(targetTreeId){
			const targetTree = trees[targetTreeId];
			if(!targetTree || (!targetTree.tree && !targetTree.isLoading)){
				loadTree(`${targetTreeId}`, token)
			}
			const targetBalances = balances[targetTreeId];
			if(!targetBalances || (!targetBalances.list && !targetBalances.isLoading)){
				loadBalances(targetTreeId, token);
			}
		}
	}

	showTransactionWizard = (type: TransactionType) => {
		const {
			treeId, currentTree, expandedRows,
			selectedNode, balances, selectedBalanceId
		} = this.props;
		if(!selectedNode || !selectedBalanceId){
			return;
		}

		let supportedBalanceTypeId: number | undefined;
		let target: TargetEndpoint | undefined;
		let mode: TransactionMode;
		switch(type.slug){
			case slugs.transaction_types.replenishment:
			case slugs.transaction_types['write-off']:{
				const parent = selectNode(getIsParent(selectedNode), currentTree);
				if(parent){
					target = {
						treeId: treeId,
						node: parent,
						balanceId: selectedBalanceId
					}
				}
				mode = TransactionMode.SingleNode;
				break;
			}
			case slugs.transaction_types.income:
			case slugs.transaction_types.consumption:
			case slugs.transaction_types.transfer:{
				const balance = balances[treeId]?.list?.find(b => b.id === selectedBalanceId);
				if(balance){
					supportedBalanceTypeId = balance.typeId;
				}
				target = {
					treeId: treeId,
					node: null,
					balanceId: null
				}
				mode = TransactionMode.TwoNodes;
			}
		}

		this.setState((state) => ({
			...state,
			transactionInProgress: {
				mode: mode,
				source: {
					treeId: treeId,
					node: selectedNode,
					balanceId: selectedBalanceId,
				},
				target: target,
				type: type,
				supportedBalanceTypeId: supportedBalanceTypeId,
				expandedRows: expandedRows
			}
		}))
	}

	setTransactionExpandedRows = (keys: React.ReactText[]) => {
		this.setState((state) => {
			if(!state.transactionInProgress){
				return state;
			}
			return {
				...state,
				transactionInProgress: {
					...state.transactionInProgress,
					expandedRows: keys
				}
			}
		})
	}

	cancelTransactionWizard = () => {
		this.setState((state) => ({
			...state,
			transactionInProgress: null
		}))
	}

	setCorrespondentTree = (treeId: number) => {
		this.setState((state) => {
			if(state.transactionInProgress){
				return {
					...state,
					transactionInProgress: {
						...state.transactionInProgress,
						target: {
							treeId: treeId,
							node: null,
							balanceId: null,
						}
					}
				}
			}
			return state;
		})
	}

	onTargetNodeClick = (nodeId: React.ReactText, balanceId?: React.ReactText) => {
		const {trees} = this.props;

		this.setState((state) => {
			let targetNodeId: React.ReactText | null = null;

			const transaction = state.transactionInProgress;
			const target = transaction?.target;
			if(transaction && transaction.mode === TransactionMode.TwoNodes && target && target.treeId && balanceId){
				if(target.node?.id !== nodeId || target.balanceId !== balanceId){
					targetNodeId = nodeId;
				}
				const targetTree = trees[target.treeId]?.tree || null;
				const targetNode = findNode(targetNodeId, targetTree);
				return {
					...state,
					transactionInProgress: {
						...transaction,
						target: {
							treeId: target.treeId,
							node: targetNode,
							balanceId: Number(balanceId)
						}
					}
				}
			}
			return state;
		})
	}

	isSeveralModesSupport = () => {
		const {transactionInProgress} = this.state;

		return [
			slugs.transaction_types.income,
			slugs.transaction_types.consumption
		].indexOf(transactionInProgress?.type.slug || '') > -1;
	}

	trySwitchMode = () => {
		const {transactionInProgress} = this.state;
		if(!transactionInProgress){
			return;
		}
		if([
			slugs.transaction_types.income,
			slugs.transaction_types.consumption
		].indexOf(transactionInProgress.type.slug || '') < 0){
			return;
		}

		if(transactionInProgress.mode === TransactionMode.SingleNode){
			this.setState((state) => ({
				...state,
				transactionInProgress: {
					...transactionInProgress,
					mode: TransactionMode.TwoNodes,
					target: {
						treeId: transactionInProgress.source.treeId,
						node: null,
						balanceId: null
					}
				}
			}))
		} else {
			this.setState((state) => ({
				...state,
				transactionInProgress: {
					...transactionInProgress,
					mode: TransactionMode.SingleNode,
					target: undefined
				}
			}))
			}
	}

	renderInProgress = () => {
		const {
			className,
			trees, currentTree, treeId,
			balances,
			currentUser,
			securityProvider, treeSecurityProvider,
			toggleFixRows,
			clearSelection,
			selectedNode
		} = this.props;

		const {
			transactionInProgress
		} = this.state;

		if(!transactionInProgress){
			return (
				<Skeleton/>
			)
		}

		let shownTreeId = treeId;
		let shownTree: Tree | null | undefined = currentTree;
		if(transactionInProgress.target?.treeId){
			shownTreeId = transactionInProgress.target.treeId;
			shownTree = trees[shownTreeId]?.tree;
		}
		const targetNodeId = transactionInProgress.target?.node?.id || null;
		const targetBalanceId = transactionInProgress.target?.balanceId || null;
		const highlightedCells = transactionInProgress.source.treeId === shownTreeId ? [{
			nodeId: transactionInProgress.source.node.id,
			balanceId: transactionInProgress.source.balanceId
		}] : null;

		const currentBalances = balances[shownTreeId]?.list || [];

		return (
			<Workspace
				className = {className}
				title = {localization.newTransaction_transfer_pageTitle_template.replace("{}", shownTree?.name || '')}
				backUrl = {site.trees.url}
				treeId = {shownTreeId}
				currentUser = {currentUser}
				securityProvider = {securityProvider}
				leftPanel = {(
					<TransactionWizard
						className = {`${className}__tree-properties`}
						source = {transactionInProgress.source}
						target = {transactionInProgress.target}
						isExpanderShown = {this.isSeveralModesSupport()}
						isExpanded = {transactionInProgress.mode !== TransactionMode.SingleNode}
						triggerExpanded = {this.trySwitchMode}
						transactionType = {transactionInProgress.type}
						currentUser = {currentUser}
						securityProvider = {securityProvider}
						node={selectedNode}

						onCancel = {this.cancelTransactionWizard}
						onClose = {clearSelection}
						setCorrespondentTree = {(treeId) => this.setCorrespondentTree(treeId)}
					/>
				)}
			>
				<TreeView
					key = {`tree-view__${currentTree?.id}`}
					className = {`${className}__table`}
					currentUser = {currentUser}
					tree = {shownTree || null}
					balances = {currentBalances}
					selectedBalanceId = {targetBalanceId}
					selectedRowId = {targetNodeId}
					highlightedCells = {highlightedCells}
					expandedRows = {transactionInProgress.expandedRows}

					securityProvider = {securityProvider}
					treeSecurityProvider = {treeSecurityProvider}
					availableBalanceTypeIds = {transactionInProgress.supportedBalanceTypeId ? [transactionInProgress.supportedBalanceTypeId] : null}

					onEntryClick = {this.onTargetNodeClick}
					toggleFixRows = {toggleFixRows}
					setExpandedRows = {this.setTransactionExpandedRows}
				/>
			</Workspace>
		)
	}

	render(){
		const {
			className,
			currentTree, treeId, selectedBalanceId, selectedNode,
			balances, selectedRowId,
			currentUser,
			securityProvider, treeSecurityProvider,
			expandedRows, setExpandedRows,
			fixedRowIds, toggleFixRows,
			confirmDeleteNode, showUserTreePermissionsDialog, showNodeAttributesDialog,
			onEntryClick, clearSelection,
		} = this.props;

		const {
			transactionInProgress
		} = this.state

		if(transactionInProgress){
			return this.renderInProgress()
		}

		const currentBalances = balances[treeId]?.list || [];

		return (
			<Workspace
				className = {className}
				title = {currentTree?.name}
				backUrl = {site.trees.url}
				treeId = {treeId}
				currentUser = {currentUser}
				securityProvider = {securityProvider}
				leftPanel = {(
					<TreeProperties
						key = {`tree-properties__${currentTree?.id}`}
						currentUser = {currentUser}
						tree = {currentTree}
						node = {selectedNode}
						selectedBalanceId = {selectedBalanceId}
						className = {`${className}__tree-properties`}

						securityProvider = {securityProvider}
						treeSecurityProvider = {treeSecurityProvider}

						onClose = {clearSelection}
						fixRows = {toggleFixRows}
						confirmDeleteNode = {confirmDeleteNode}
						showPermissionsDialogFor = {showUserTreePermissionsDialog}
						showAttributesDialogFor = {showNodeAttributesDialog}
						createTransaction = {this.showTransactionWizard}
					/>
				)}
			>
				<TreeView
					key = {`tree-view__${currentTree?.id}`}
					className = {`${className}__table`}
					currentUser = {currentUser}
					tree = {currentTree}
					balances = {currentBalances}
					selectedBalanceId = {selectedBalanceId}
					selectedRowId = {selectedRowId}
					fixedRowIds = {fixedRowIds}
					expandedRows = {expandedRows}


					securityProvider = {securityProvider}
					treeSecurityProvider = {treeSecurityProvider}

					onEntryClick = {onEntryClick}
					toggleFixRows = {toggleFixRows}
					setExpandedRows = {setExpandedRows}
				/>
			</Workspace>
		)
	}
}

export default TransactionWizardWrapper;