import { memo } from 'react';
import { connect } from 'react-redux'

import actions from '../../actions'
import { CommonPageProps } from '../../models';
import { State } from '../../reducers';

import Users from './Users'

const mapStateToProps = (state: State, _: CommonPageProps) => {
	return {
		isLoading: state.users.isLoading,
		users: state.users.list,
		isRolesLoading: state.roles.isLoading,
		roles: state.roles.list,
		token: state.auth.token,
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	loadUsers: (token: string) => dispatch(actions.users.load(token)),
	deleteUser: (id: number, token: string) => dispatch(actions.user.delete(id, token)),
	loadRoles: (token: string) => dispatch(actions.roles.load(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(Users))