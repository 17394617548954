import actions from '../actions';
import { AssignedUser, Attribute, NodeInfo, NodesState, UserInfo } from '../models';

const defaultState: NodesState = {}

const getDefaultNodeInfo = (nodeId: number): NodeInfo => ({
	id: nodeId,
	name: null,
	isNameLoading: false,
	nameError: null,
	assignedUsers: null,
	isAssignedUsersLoading: false,
	attributes: null,
	isAttributesLoading: false,
	users: {}
})

const updateNodeName = (state: NodesState, nodeId: number, name: string | null, isLoading: boolean, error: string | null): NodesState => {
	return {
		...state,
		[nodeId]: {
			...getDefaultNodeInfo(nodeId),
			...state[nodeId],
			name: name,
			isNameLoading: isLoading,
			nameError: error
		}
	}
}

const updateUserForNode = (state: NodesState, nodeId: number, user: UserInfo): NodesState => {
	return {
		...state,
		[nodeId]: {
			...getDefaultNodeInfo(nodeId),
			...state[nodeId],
			users: {
				...state[nodeId]?.users,
				[user.id]: {
					...user
				}
			}
		}
	}
}

const updateAssignedUsersForNode = (state: NodesState, nodeId: number, users: AssignedUser[] | null, isLoading: boolean): NodesState => {
	return {
		...state,
		[nodeId]: {
			...getDefaultNodeInfo(nodeId),
			...state[nodeId],
			assignedUsers: users,
			isAssignedUsersLoading: isLoading,
		}
	}
}

const updateAttributesForNode = (state: NodesState, nodeId: number, attributes: Attribute[] | null, isLoading: boolean): NodesState => {
	return {
		...state,
		[nodeId]: {
			...getDefaultNodeInfo(nodeId),
			...state[nodeId],
			attributes: attributes,
			isAttributesLoading: isLoading
		}
	}
}

const resetAllAttributes = (state: NodesState): NodesState => {
	const updatedState: NodesState = {...state};

	Object.keys(state).forEach(key => {
		try{
			const index = parseInt(key);
			const nodeInfo = state[index];
			if(nodeInfo){
				updatedState[index] = {
					...nodeInfo,
					attributes: null
				}
			}
		}catch(ex){
			console.warn(ex);
		}
	});
	return updatedState;
}

const nodes = (state: NodesState = defaultState, action: any): NodesState => {
	switch(action.type){
		case actions.node.types.NODE_LOAD_NAME:{
			const {nodeId} = action.payload;
			return updateNodeName(state, nodeId, null, true, null);
		}
		case actions.node.types.NODE_LOAD_NAME_SUCCESS:{
			const {nodeId, name} = action.payload;
			return updateNodeName(state, nodeId, name, false, null);
		}
		case actions.node.types.NODE_LOAD_NAME_FAILED:{
			const {nodeId, message} = action.payload;
			return updateNodeName(state, nodeId, '', false, message);
		}

		case actions.node.types.NODE_LOAD_ACTION_GROUPS:{
			const {userId, nodeId} = action.payload;
			return updateUserForNode(state, nodeId, {
				...(state[nodeId]?.users || {})[userId],
				id: userId,
				actionGroupIds: null,
				treePermissions: null,
				isActionGroupsLoading: true
			});
		}
		case actions.node.types.NODE_LOAD_ACTION_GROUPS_SUCCESS:{
			const {userId, nodeId, actionGroupIds, treePermissions} = action.payload;
			return updateUserForNode(state, nodeId, {
				...state[nodeId]?.users[userId],
				id: userId,
				actionGroupIds: actionGroupIds,
				treePermissions: treePermissions,
				isActionGroupsLoading: false
			})
		}
		case actions.node.types.NODE_LOAD_ACTION_GROUPS_FAILED:{
			const {userId, nodeId} = action.payload;
			
			console.error('NODE_LOAD_ACTION_GROUPS_FAILED\n%o', action.payload);

			return updateUserForNode(state, nodeId, {
				...state[nodeId]?.users[userId],
				id: userId,
				actionGroupIds: [],
				treePermissions: [],
				isActionGroupsLoading: false
			})
		}

		case actions.node.types.NODE_ACTION_GROUP_ASSIGN:{
			const {userId, nodeId} = action.payload;

			return updateUserForNode(state, nodeId, {
				id: userId,
				actionGroupIds: [],
				treePermissions: [],
				...state[nodeId]?.users[userId],
				isActionGroupsLoading: true
			});
		}
		case actions.node.types.NODE_ACTION_GROUP_ASSIGN_SUCCESS:{
			const {add, userId, nodeId, actionGroupId} = action.payload;
			const currentActionGroups = state[nodeId]?.users[userId]?.actionGroupIds || [];
			const updatedActionGroupIds = add ?
				[...currentActionGroups, actionGroupId] :
				currentActionGroups.filter(agId => agId !== actionGroupId);

			return updateUserForNode(state, nodeId, {
				id: userId,
				treePermissions: [],
				...state[nodeId]?.users[userId],
				isActionGroupsLoading: false,
				actionGroupIds: updatedActionGroupIds
			});
		}
		case actions.node.types.NODE_ACTION_GROUP_ASSIGN_FAILED:{
			const {userId, nodeId} = action.payload;
			console.error('NODE_ACTION_GROUP_ASSIGN_FAILED:\n%o', action.payload);
			return updateUserForNode(state, nodeId, {
				id: userId,
				treePermissions: [],
				...state[nodeId]?.users[userId],
				isActionGroupsLoading: false,
				actionGroupIds: null
			});
		}
		//assigned users
		case actions.node.types.NODE_LOAD_USERS:{
			const {nodeId} = action.payload;
			return updateAssignedUsersForNode(state, nodeId, null, true);
		}
		case actions.node.types.NODE_LOAD_USERS_SUCCESS:{
			const {nodeId, users} = action.payload;
			return updateAssignedUsersForNode(state, nodeId, users, false);
		}
		case actions.node.types.NODE_LOAD_USERS_FAILED:{
			const {nodeId} = action.payload;
			return updateAssignedUsersForNode(state, nodeId, [], false);
		}
		//attributes
		case actions.node.types.NODE_LOAD_ATTRIBUTES:{
			const {nodeId} = action.payload;
			return updateAttributesForNode(state, nodeId, null, true);
		}
		case actions.node.types.NODE_LOAD_ATTRIBUTES_SUCCESS:{
			const {nodeId, attributes} = action.payload;
			return updateAttributesForNode(state, nodeId, attributes, false);
		}
		case actions.node.types.NODE_LOAD_ATTRIBUTES_FAILED:{
			const {nodeId} = action.payload;
			return updateAttributesForNode(state, nodeId, [], false);
		}
		
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_DELETE_SUCCESS:
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_UPDATE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_CREATE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_UPDATE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_DELETE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_SET_VALUE_SUCCESS:
		case actions.attribute.types.ATTRIBUTE_SET_GROUPS_SUCCESS:
		case actions.attributeValue.types.ATTRIBUTE_VALUE_CREATE_SUCCESS:
		case actions.attributeValue.types.ATTRIBUTE_VALUE_UPDATE_SUCCESS:
		case actions.attributeValue.types.ATTRIBUTE_VALUE_DELETE_SUCCESS:
		{
			return resetAllAttributes(state);
		}
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_DELETE_FAILED:
		case actions.attributeGroup.types.ATTRIBUTE_GROUP_UPDATE_FAILED:
		case actions.attribute.types.ATTRIBUTE_CREATE_FAILED:
		case actions.attribute.types.ATTRIBUTE_UPDATE_FAILED:
		case actions.attribute.types.ATTRIBUTE_DELETE_FAILED:
		case actions.attribute.types.ATTRIBUTE_SET_VALUE_FAILED:
		case actions.attribute.types.ATTRIBUTE_SET_GROUPS_FAILED:
		case actions.attributeValue.types.ATTRIBUTE_VALUE_CREATE_FAILED:
		case actions.attributeValue.types.ATTRIBUTE_VALUE_UPDATE_FAILED:
		case actions.attributeValue.types.ATTRIBUTE_VALUE_DELETE_FAILED:{
			console.error(`${action.type}\n%o`, action.payload);
			return resetAllAttributes(state);
		}

	}
	return state;
}

export default nodes;