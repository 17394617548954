const actionTypes = {
	ATTRIBUTE_CREATE: 'ATTRIBUTE\\CREATE',
	ATTRIBUTE_CREATE_SUCCESS: 'ATTRIBUTE\\CREATE_SUCCESS',
	ATTRIBUTE_CREATE_FAILED: 'ATTRIBUTE\\CREATE_FAILED',

	ATTRIBUTE_UPDATE: 'ATTRIBUTE\\UPDATE',
	ATTRIBUTE_UPDATE_SUCCESS: 'ATTRIBUTE\\UPDATE_SUCCESS',
	ATTRIBUTE_UPDATE_FAILED: 'ATTRIBUTE\\UPDATE_FAILED',

	ATTRIBUTE_DELETE: 'ATTRIBUTE\\DELETE',
	ATTRIBUTE_DELETE_SUCCESS: 'ATTRIBUTE\\DELETE_SUCCESS',
	ATTRIBUTE_DELETE_FAILED: 'ATTRIBUTE\\DELETE_FAILED',

	ATTRIBUTE_SET_VALUE: 'ATTRIBUTE\\SET_VALUE',
	ATTRIBUTE_SET_VALUE_SUCCESS: 'ATTRIBUTE\\SET_VALUE_SUCCESS',
	ATTRIBUTE_SET_VALUE_FAILED: 'ATTRIBUTE\\SET_VALUE_FAILED',

	ATTRIBUTE_SET_GROUPS: 'ATTRIBUTE\\SET_GROUPS',
	ATTRIBUTE_SET_GROUPS_SUCCESS: 'ATTRIBUTE\\SET_GROUPS_SUCCESS',
	ATTRIBUTE_SET_GROUPS_FAILED: 'ATTRIBUTE\\SET_GROUPS_FAILED',
}


const create = (attribute: {name: string, nodeId: number, visibilityId: number, treeId: number}, token: string) => ({
	type: actionTypes.ATTRIBUTE_CREATE,
	payload: {
		token,
		...attribute
	}
})

const createSuccess = (attributeId: number, nodeId: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_CREATE_SUCCESS,
	payload: {
		attributeId,
		treeId,
		nodeId
	}
})

const createFailed = (message: string, nodeId: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_CREATE_FAILED,
	payload: {
		message,
		treeId,
		nodeId
	}
})


const update = (attribute: {id: number, name?: string, visibilityId?: number, nodeId: number}, treeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_UPDATE,
	payload: {
		token,
		treeId,
		...attribute
	}
})

const updateSuccess = (nodeId: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_UPDATE_SUCCESS,
	payload: {
		nodeId,
		treeId,
	}
})

const updateFailed = (message: string, nodeId: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_UPDATE_FAILED,
	payload: {
		message,
		treeId,
		nodeId
	}
})

const deleteAttr = (id: number, nodeId: number, treeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_DELETE,
	payload: {
		token,
		id,
		treeId,
		nodeId
	}
})

const deleteSuccess = (nodeId: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_DELETE_SUCCESS,
	payload: {
		treeId,
		nodeId
	}
})

const deleteFailed = (message: string, nodeId: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_DELETE_FAILED,
	payload: {
		message,
		treeId,
		nodeId
	}
})

const setValue = (nodeId: number, attributeId: number, value: {id: number, propagate?: boolean}, token: string) => ({
	type: actionTypes.ATTRIBUTE_SET_VALUE,
	payload: {
		nodeId,
		attributeId,
		attributeValueId: value.id,
		propagateValue: value.propagate,
		token
	}
})

const setValueSuccess = (nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_SET_VALUE_SUCCESS,
	payload: {
		nodeId
	}
})

const setValueFailed = (message: string, nodeId: number) => ({
	type: actionTypes.ATTRIBUTE_SET_VALUE_FAILED,
	payload: {
		message,
		nodeId
	}
})

const setGroups = (nodeId: number, attributeId: number, newGroupIds: number[], currentGroupIds: number[], treeId: number, token: string) => ({
	type: actionTypes.ATTRIBUTE_SET_GROUPS,
	payload: {
		nodeId,
		treeId,
		attributeId,
		newGroupIds,
		currentGroupIds,
		token
	}
})

const setGroupsSuccess = (nodeId: number, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_SET_GROUPS_SUCCESS,
	payload: {
		treeId,
		nodeId
	}
})

const setGroupsFailed = (nodeId: number, message: string, treeId: number) => ({
	type: actionTypes.ATTRIBUTE_SET_GROUPS_FAILED,
	payload: {
		treeId,
		nodeId,
		message
	}
})

export default {
	types: actionTypes,
	create,
	createSuccess,
	createFailed,
	update,
	updateSuccess,
	updateFailed,
	delete: deleteAttr,
	deleteSuccess,
	deleteFailed,
	setValue,
	setValueSuccess,
	setValueFailed,
	setGroups,
	setGroupsSuccess,
	setGroupsFailed
};