import actions from '../actions';

export interface TreePermissionsState{
	list: any[] | null,
	isLoading: boolean
}

const defaultState: TreePermissionsState = {
	list: null,
	isLoading: false
}

const treePermissions = (state: TreePermissionsState = defaultState, action: any): TreePermissionsState => {
	switch(action.type){
		case actions.treePermissions.types.TREE_PERMISSIONS_LOAD:
			return {...state, isLoading: true};
		case actions.treePermissions.types.TREE_PERMISSIONS_LOAD_SUCCESS:
			const { treePermissions } = action.payload;
			return {...state, list: treePermissions, isLoading: false}
		case actions.treePermissions.types.TREE_PERMISSIONS_LOAD_FAILED:
			console.error('treePermissions failed\n%o', action.payload);
			return {...state, list: [], isLoading: false}
	}
	return state;
}

export default treePermissions;