import React from 'react';

interface Option{
    text?: string,
    value?: number
}

interface SelectProps{
    className?: string | null,
    options?: Option[],
    value?: number,

    onChange?: (value: number) => void
}

const containerClassName = 'bra-select';

const getOnChangeEventHandler = (onChange?: (value: number) => void) =>
{
    if(onChange){
        return (event: React.ChangeEvent<HTMLSelectElement>) => 
        {
            onChange(parseFloat(event.target.value));
        }
    }
    return undefined;
}

const Select = (props: SelectProps) => {
    const {className, options, value, onChange} = props;
    const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
    const optionElements = (options || []).map(opt => {
        return (
            <option key = {opt.value} value = {opt.value}>
                {opt.text}
            </option>
        )
    })
    return (
        <select className = {effectiveClassName} value = {value} onChange={getOnChangeEventHandler(onChange)}>
            {optionElements}
        </select>
    );
}

export default Select;