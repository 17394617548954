import { Attribute, User } from "../models"

const actionTypes = {
	NODE_LOAD_NAME: 'NODE\\LOAD_NAME',
	NODE_LOAD_NAME_SUCCESS: 'NODE\\LOAD_NAME_SUCCESS',
	NODE_LOAD_NAME_FAILED: 'NODE\\LOAD_NAME_FAILED',

	NODE_LOAD_USERS: 'NODE\\LOAD_USERS',
	NODE_LOAD_USERS_SUCCESS: 'NODE\\LOAD_USERS_SUCCESS',
	NODE_LOAD_USERS_FAILED: 'NODE\\LOAD_USERS_FAILED',

	NODE_LOAD_ACTION_GROUPS: 'NODE\\LOAD_ACTION_GROUP',
	NODE_UPDATE_ACTION_GROUPS: 'NODE\\UPDATE_ACTION_GROUPS',
	NODE_LOAD_ACTION_GROUPS_SUCCESS: 'NODE\\LOAD_ACTION_GROUP_SUCCESS',
	NODE_LOAD_ACTION_GROUPS_FAILED: 'NODE\\LOAD_ACTION_GROUP_FAILED',

	NODE_ACTION_GROUP_ASSIGN: 'NODE\\ASSIGN_ACTION_GROUP',
	NODE_ACTION_GROUP_ASSIGN_SUCCESS: 'NODE\\ASSIGN_ACTION_GROUP_SUCCESS',
	NODE_ACTION_GROUP_ASSIGN_FAILED: 'NODE\\ASSIGN_ACTION_GROUP_FAILED',

	NODE_LOAD_ATTRIBUTES: 'NODE\\LOAD_ATTRIBUTES',
	NODE_LOAD_ATTRIBUTES_SUCCESS: 'NODE\\LOAD_ATTRIBUTES_SUCCESS',
	NODE_LOAD_ATTRIBUTES_FAILED: 'NODE\\LOAD_ATTRIBUTES_FAILED',
}

const loadName = (nodeId: number, token: string) => ({
	type: actionTypes.NODE_LOAD_NAME,
	payload: {
		nodeId,
		token
	}
})

const loadNameSuccess = (name: string, nodeId: number) => ({
	type: actionTypes.NODE_LOAD_NAME_SUCCESS,
	payload: {
		nodeId,
		name
	}
})

const loadNameFailed = (message: string, nodeId: number) => ({
	type: actionTypes.NODE_LOAD_NAME_FAILED,
	payload: {
		nodeId,
		message
	}
})

const loadActionGroups = (userId: number, nodeId: number, token: string) => ({
	type: actionTypes.NODE_LOAD_ACTION_GROUPS,
	payload: {
		userId,
		nodeId,
		token
	}
})

const updateActionGroups = (userId: number, nodeId: number, groups: any) => ({
	type: actionTypes.NODE_UPDATE_ACTION_GROUPS,
	payload: {
		userId,
		nodeId,
		groups
	}
})

const loadActionGroupsSuccess = (userId: number, nodeId: number, actionGroupIds: number[], treePermissions: string[]) => ({
	type: actionTypes.NODE_LOAD_ACTION_GROUPS_SUCCESS,
	payload: {
		userId,
		nodeId,
		actionGroupIds,
		treePermissions
	}
})

const loadActionGroupsFailed = (userId: number, nodeId: number, message: string) => ({
	type: actionTypes.NODE_LOAD_ACTION_GROUPS_FAILED,
	payload: {
		userId,
		nodeId,
		message
	}
})

const assignActionGroup = (add: boolean, userId: number, actionGroupId: number, nodeId: number, token: string) => ({
	type: actionTypes.NODE_ACTION_GROUP_ASSIGN,
	payload: {
		add,
		userId,
		actionGroupId,
		nodeId,
		token
	}
})

const assignActionGroupSuccess = (add: boolean, userId: number, actionGroupId: number, nodeId: number) => ({
	type: actionTypes.NODE_ACTION_GROUP_ASSIGN_SUCCESS,
	payload: {
		add,
		userId,
		actionGroupId,
		nodeId
	}
})

const assignActionGroupFailed = (add: boolean, userId: number, actionGroupId: number, nodeId: number, message: string) => ({
	type: actionTypes.NODE_ACTION_GROUP_ASSIGN_FAILED,
	payload: {
		add,
		userId,
		actionGroupId,
		nodeId,
		message
	}
})

const loadNodeUsers = (nodeId: number, token: string) => ({
	type: actionTypes.NODE_LOAD_USERS,
	payload: {
		nodeId,
		token
	}
});

const loadNodeUsersSuccess = (nodeId: number, users: User[]) => ({
	type: actionTypes.NODE_LOAD_USERS_SUCCESS,
	payload: {
		nodeId,
		users
	}
})

const loadNodeUsersFailed = (nodeId: number, message: string) => ({
	type: actionTypes.NODE_LOAD_USERS_FAILED,
	payload: {
		nodeId,
		message
	}
})

const loadNodeAttributes = (nodeId: number, token: string) => ({
	type: actionTypes.NODE_LOAD_ATTRIBUTES,
	payload: {
		nodeId,
		token
	}
})

const loadNodeAttributesSuccess = (nodeId: number, attributes: Attribute[]) => ({
	type: actionTypes.NODE_LOAD_ATTRIBUTES_SUCCESS,
	payload: {
		nodeId,
		attributes
	}
})

const loadNodeAttributesFailed = (nodeId: number, message: string) => ({
	type: actionTypes.NODE_LOAD_ATTRIBUTES_FAILED,
	payload: {
		nodeId,
		message
	}
})

export default {
	types: actionTypes,

	loadName,
	loadNameSuccess,
	loadNameFailed,

	loadNodeUsers,
	loadNodeUsersSuccess,
	loadNodeUsersFailed,

	loadActionGroups,
	updateActionGroups,
	loadActionGroupsSuccess,
	loadActionGroupsFailed,
	
	assignActionGroup,
	assignActionGroupSuccess,
	assignActionGroupFailed,
	
	loadNodeAttributes,
	loadNodeAttributesSuccess,
	loadNodeAttributesFailed
}