import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { Plus } from 'react-feather';

import { CommonPageProps, TreeListEntry, NodesState } from '../../models';
import { getTreeSecurityProvider, localization } from '../../settings';
import { EmptyPage, PageTitle, SetNameDialog, Button } from '../../components';

import TreePreview from './TreePreview';

interface TreesProps extends CommonPageProps{
	trees?: TreeListEntry[] | null,
	isLoading: boolean,
	token: string,
	nodesInfo: NodesState,

	loadTrees: (token: string) => void,
	createTree: (name: string, token: string) => void,
	renameTree: (id: number, name: string, token: string) => void,
	deleteTree: (id: number, token: string) => void
}

const treesClassName = "trees-container";

const Trees = (props: TreesProps) => {
	const {
		trees, isLoading, token,
		nodesInfo, securityProvider, currentUser,
		createTree, renameTree, deleteTree,
		loadTrees
	} = props;

	useEffect(() => {
		if(trees === null && !isLoading){
			loadTrees(token)
		}
	});

	const treesArray = trees ? Object.values(trees) : null;

	const addTreeButtonElement = (
		<SetNameDialog
			title = {localization.newTreeTitle}
			inputPlaceholder = {localization.tree_name}
			onCreate = {(name: string) => createTree(name, token)}
		>
			<Button
				type = "primary"
				icon = {<Plus/>}
			>
				{localization.newTreeButton}
			</Button>
		</SetNameDialog>
	);
	
	let treeElements: JSX.Element | JSX.Element[] | null;
	if(!isLoading){
		if(!treesArray || treesArray.length < 1){
			if(securityProvider.node.canCreate){
				treeElements = (
					<EmptyPage
						caption = {(
							<div className="empty-page-caption">
								<h1>{localization.empty_trees_title}</h1>
								<p>{localization.empty_trees_text}</p>
								{addTreeButtonElement}
							</div>
						)}
					/>
				)
			}else{
				treeElements = null
			}
		} else {
			treeElements = treesArray.map(tree => {
				const treeSecurityProvider = getTreeSecurityProvider(currentUser, tree.id || null, nodesInfo);
				return (
					<TreePreview
						id = {tree.id}
						key = {tree.id}
						title = {tree.name}
						token = {token}
						rename = {treeSecurityProvider.node.canEdit ? renameTree : null}
						deleteTree = {treeSecurityProvider.node.canDelete ? deleteTree: null}
					/>
				)
			});
		}
	} else {
		treeElements = (<Skeleton/>);
	}

	return (
		<div className={`page-content ${treesClassName}`}>
			<PageTitle
				title = {localization.trees_title}
				buttonTitle = {localization.newTreeButton}
				button = {addTreeButtonElement}
				showButton = {treesArray && treesArray.length > 0 && securityProvider.node.canCreate}
			/>
			<div className={`${treesClassName}__body`}>
				{treeElements}
			</div>
		</div>
	)
}

export default Trees;