import { Select } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { X } from 'react-feather';

interface SelectFilterProps{
	className?: string;
	valueId?: number | null;
	availableValues: {id: number, text: string}[] | null,

	onChange?: (value: number) => void,
	onReset?: () => void,
	canLoadValues?: () => boolean | null;
	loadValues?: () => void | null,
}

const containerClassName = 'bra-select-filter';

const SelectFilter = (props: SelectFilterProps) => {
	const {
		className,
		valueId,
		availableValues, canLoadValues, loadValues,
		onChange, onReset
	} = props;

	useEffect(() => {
		if(!availableValues && canLoadValues && loadValues){
			loadValues();
		}
	}, [availableValues, canLoadValues, loadValues])

	const [shownValue, setShownValue] = useState(valueId);

	const handleOnChange = (value: number) => {
		setShownValue(value);
		onChange && onChange(value);
	}

	const handleOnReset = () => {
		setShownValue(null);
		onReset && onReset();
	}

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__content`}>
				<Select<number>
					className = {`${containerClassName}__content__select`}
					value = {shownValue || undefined}
					onChange = {handleOnChange}
				>
					{availableValues?.map(v => (
						<Select.Option
							key = {v.id}
							value = {v.id}
						>
							{v.text}
						</Select.Option>
					))}
				</Select>
				<X
					className = {`${containerClassName}__content__x`}
					onClick = {handleOnReset}
				/>
			</div>
		</div>
	)
}

export default memo(SelectFilter);