import React, {memo, useEffect} from 'react';
import { Menu } from 'antd';
import { Box, ChevronDown, File, Menu as MenuIcon, Settings, Users } from 'react-feather';
import { Moment } from 'moment';

import { Button, PropertiesSection, UserList } from '../../../components';
import { Attribute, Balance, BalanceType, TransactionType, Tree, User } from '../../../models';
import { localization, SecurityProvider, site, TreeSecurityProvider, pivotReport, slugs } from '../../../settings';

import RecordsList from './RecordsList';
import { useDispatch } from 'react-redux';
import actions from '../../../actions';

interface NodeInfoProps{
	className?: string,

	selectedBalanceId?: React.ReactText | null,
	node: Tree,
	attributes?: Attribute[] | null,
	balances?: Balance[] | null,
	balanceTypes?: BalanceType[] | null,
	users?: User[] | null,
	transactionTypes: TransactionType[] | null,

	securityProvider: SecurityProvider,
	treeSecurityProvider: TreeSecurityProvider,
	token: string,

	onCreateTransaction: (transactionType: TransactionType) => void,
	getNodeAttributesHandler: (nodeId: number) => () => void,
	getNodePermissionsHandler: (nodeId: number) => () => void,
	loadReport: (nodeId: number, date: [Moment, Moment], token: string) => void,
	loadTransactions: (treeId: number, token: string) => void,
	loadTransactionsElastic: (treeId: number, token: string) => void,
	go: (url: string) => void
}

const containerClassName = 'node-info'

const NodeInfo = (props: NodeInfoProps) => {
	const {
		className,
		selectedBalanceId, node,
		treeSecurityProvider,
		securityProvider,
		attributes, balances, balanceTypes, users,
		transactionTypes,
		onCreateTransaction, getNodeAttributesHandler, getNodePermissionsHandler,
		loadReport, loadTransactions, loadTransactionsElastic,
		go,
		token
	} = props;

	const dispatch = useDispatch();

	const getOnCreateTransactionHandler = (type: TransactionType) => () => {
		return onCreateTransaction(type);
	}

	const onPivotReportClick = () => {
		loadReport(node.id, pivotReport.getDefaultDates(), token);
		go(site.reports.url.replace(':treeId', `${node.id}`));
	}

	const onTransactionsClick = () => {
		loadTransactions(node.id, token);
		go(site.transactions.url.replace(':treeId', `${node.id}`));
	}

	const onTransactionsElasticClick = () => {
		loadTransactionsElastic(node.id, token);
		go(site.transactionsElastic.url.replace(':treeId', `${node.id}`));
	}

	const onTransactionChangesClick = () => {
		go(site.transactionChangesLogs.url.replace(':treeId', `${node.id}`));
	}

	const filteredTransactionTypes = transactionTypes?.filter(
	    (t) =>
	      	treeSecurityProvider?.allowedTransactions[
	      	  	t.slug as keyof typeof treeSecurityProvider.allowedTransactions
	      	],
	);

	const operationsMenu = (
		<Menu>
			{filteredTransactionTypes?.map(tt => (
				<Menu.Item
					key = {tt.id}
					onClick = {getOnCreateTransactionHandler(tt)}
				>
					{tt.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const editAttributesButtonElement = treeSecurityProvider.node.attributes.canAssign ? (
		<Button
			icon = {<Settings />}
			block
			onClick = {getNodeAttributesHandler(node.id)}
		>
			{localization.editAttributes}
		</Button>
	): null;

	const assignUsersButtonElement = treeSecurityProvider.node.users.canAssign && securityProvider.actionGroups.canRead && securityProvider.users.canRead ? (
		<Button
			icon={<Users />}
			block
			onClick = {getNodePermissionsHandler(node.id)}
		>
			{localization.editPermissions}
		</Button>
	): null;

	const selectedBalanceTypeId = balances?.find(b => b.id === selectedBalanceId)?.typeId;
	const balanceTypeSlug = balanceTypes?.find(bt => bt.id === selectedBalanceTypeId)?.slug;
	const isTransactionSupported =
    	selectedBalanceId &&
    	selectedBalanceTypeId &&
    	balanceTypeSlug !== slugs.balance_types.calculate &&
    	filteredTransactionTypes?.length;

	const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

	useEffect(() => {
    treeSecurityProvider.node.users.canView &&
      dispatch(actions.node.loadNodeUsers(node.id, token));
  	}, [dispatch, node.id, token, treeSecurityProvider.node.users.canView]);

	return (
		<div className = {effectiveClassName}>
			<div className = {`${containerClassName}__top-buttons`}>
				{treeSecurityProvider.node.report.canView && (
					<Button
						className = {`${containerClassName}__top-buttons__button`}
						block
						type = 'primary'
						icon = {<File />}
						onClick = {onPivotReportClick}
					>
						{localization.treeProperties_report}
					</Button>)
				}
				{!!isTransactionSupported && (
					<Button
						className = {`${containerClassName}__top-buttons__button`}
						block
						overlay = {operationsMenu}
					>
						<div className = {`${containerClassName}__top-buttons__button__content`}>
							<div className = {`${containerClassName}__top-buttons__button__content__main`}>
								<span>{localization.treeProperties_newTransaction}</span>
							</div>
							<div className = {`${containerClassName}__top-buttons__button__content__addition`}>
								<ChevronDown className = 'feather-ico'/>
							</div>
						</div>
					</Button>
				)}
				{treeSecurityProvider.transactions.canEditOperations && (
					<Button
						className = {`${containerClassName}__top-buttons__button ${containerClassName}__top-buttons__button__transactions`}
						block
						borderless
						icon = {<MenuIcon />}
						onClick = {onTransactionsClick}
					>
						{localization.treeProperties_operationsTitle}
					</Button>
				)}
				{treeSecurityProvider.transactions.canReadFullTransactions && (
				<Button
					className = {`${containerClassName}__top-buttons__button ${containerClassName}__top-buttons__button__transactions-elastic`}
					block
					borderless
					icon = {<Box />}
					onClick = {onTransactionsElasticClick}
				>
					{localization.treeProperties_transactionsElasticTitle}
				</Button>
				)}
				{securityProvider.isAdmin && (
					<Button
						className = {`${containerClassName}__top-buttons__button ${containerClassName}__top-buttons__button__transaction-changes`}
						block
						borderless
						onClick = {onTransactionChangesClick}
					>
						{localization.treeProperties_transactionChangeLogs}
					</Button>
				)}
			</div>
			<div className = {`${containerClassName}__scroll-holder`}>
				{treeSecurityProvider.node.attributes.canView && (
					<PropertiesSection
						title = {localization.attributes}
						className = {`${containerClassName}__section`}
						footer = {editAttributesButtonElement}
					>
						<RecordsList
							records = {attributes || []}
							isLoading = {!attributes}
							getKey = {(attr) => attr.id}
							renderTitle = {(attr) => attr.name}
							renderValue = {(attr) => attr.values.find(v => v.isSelected)?.name}
						/>
					</PropertiesSection>
				)}
				<PropertiesSection
					title = {localization.balances}
					className = {`${containerClassName}__section`}
				>
					<RecordsList
						className = {`${containerClassName}__balances`}
						records = {balances || []}
						isLoading = {!balances}
						getKey = {(b) => b.id}
						renderTitle = {(b) => balanceTypes?.find(t => t.id === b.typeId)?.name || ''}
						renderValue = {(b) => b.name}
					/>
				</PropertiesSection>
				{treeSecurityProvider.node.users.canView && (
					<PropertiesSection
						title = {localization.users}
						className = {`${containerClassName}__section`}
						footer = {assignUsersButtonElement}
					>
						<UserList
							users = {users}
							isLoading = {!users}
						/>
					</PropertiesSection>
				)}
			</div>
		</div>
	)
}

export default memo(NodeInfo)