import { Tree } from "../models";

const actionTypes = {
	TREE_LOAD: 'TREE\\LOAD',
	TREE_LOAD_SUCCESS: 'TREE\\LOAD_SUCCESS',
	TREE_LOAD_FAILED: 'TREE\\LOAD_FAILED',

	// just delete nodes and balances data
	TREE_RESET: 'TREE\\RESET',
	// completely wipes data
	TREE_UNLOAD: 'TREE\\UNLOAD',

	TREE_CREATE: 'TREE\\CREATE',
	TREE_CREATE_SUCCESS: 'TREE\\CREATE_SUCCESS',
	TREE_CREATE_FAILED: 'TREE\\CREATE_FAILED',

	TREE_UPDATE: 'TREE\\UPDATE',
	TREE_UPDATE_SUCCESS: 'TREE\\UPDATE_SUCCESS',
	TREE_UPDATE_FAILED: 'TREE\\UPDATE_FAILED',

	TREE_DELETE: 'TREE\\DELETE',
	TREE_DELETE_SUCCESS: 'TREE\\DELETE_SUCCESS',
	TREE_DELETE_FAILED: 'TREE\\DELETE_FAILED',

	TREE_NODE_ADD: 'TREE\\NODE_ADD',
	TREE_NODE_ADD_SUCCESS: 'TREE\\NODE_ADD_SUCCESS',
	TREE_NODE_ADD_FAILED: 'TREE\\NODE_ADD_FAILED',

	TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD: 'TREE_ASSIGNED_ATTRIBUTE_GROUPS\\LOAD',
	TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD_SUCCESS: 'TREE_ASSIGNED_ATTRIBUTE_GROUPS\\LOAD_SUCCESS',
	TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD_FAILED: 'TREE_ASSIGNED_ATTRIBUTE_GROUPS\\LOAD_FAILED',

	TREE_ASSIGN_ATTRIBUTE_GROUP: 'TREE_ATTRIBUTE_GROUP\\ASSIGN',
	TREE_ASSIGN_ATTRIBUTE_GROUP_SUCCESS: 'TREE_ATTRIBUTE_GROUP\\ASSIGN_SUCCESS',
	TREE_ASSIGN_ATTRIBUTE_GROUP_FAILED: 'TREE_ATTRIBUTE_GROUP\\ASSIGN_FAILED',

	TREE_ASSIGNED_BALANCES_LOAD: 'TREE_ASSIGNED_BALANCES\\LOAD',
	TREE_ASSIGNED_BALANCES_LOAD_SUCCESS: 'TREE_ASSIGNED_BALANCES\\LOAD_SUCCESS',
	TREE_ASSIGNED_BALANCES_LOAD_FAILED: 'TREE_ASSIGNED_BALANCES\\LOAD_FAILED',

	TREE_ASSIGN_BALANCE: 'TREE_BALANCE\\ASSIGN',
	TREE_ASSIGN_BALANCE_SUCCESS: 'TREE_BALANCE\\ASSIGN_SUCCESS',
	TREE_ASSIGN_BALANCE_FAILED: 'TREE_BALANCE\\ASSIGN_FAILED'
}

const load = (id: string, token: string) => ({
	type: actionTypes.TREE_LOAD,
	payload: {
		id,
		token
	}
})

const unload = (id: string) => ({
	type: actionTypes.TREE_UNLOAD,
	payload: {
		id
	}
})

const reset = (id: number) => ({
	type: actionTypes.TREE_RESET,
	payload: {
		id
	}
})

const create = (name: string, token: string) => ({
	type: actionTypes.TREE_CREATE,
	payload: {
		name,
		token
	}
})

const createSuccess = ()=> ({
	type: actionTypes.TREE_CREATE_SUCCESS
})

const createFailed = (message: string)=> ({
	type: actionTypes.TREE_CREATE_SUCCESS,
	payload: {
		message
	}
})

const loadSuccess = (id: number, tree: Tree) => ({
	type: actionTypes.TREE_LOAD_SUCCESS,
	payload: {
		id,
		tree
	}
})

const loadFailed = (id: number, message: string) => ({
	type: actionTypes.TREE_LOAD_FAILED,
	payload: {
		id,
		message
	}
})

const update = (treeId: number, nodeId: number, name:string, token: string) => ({
	type: actionTypes.TREE_UPDATE,
	payload: {
		treeId,
		nodeId,
		name,
		token
	}
})

const updateSuccess = (treeId: number, nodeId: number, name: string) => ({
	type: actionTypes.TREE_UPDATE_SUCCESS,
	payload: {
		treeId,
		nodeId,
		name
	}
})

const updateFailed = (treeId: number, message: string) => ({
	type: actionTypes.TREE_UPDATE_FAILED,
	payload: {
		treeId,
		message
	}
})

const deleteTree = (treeId: number, nodeId: number, token: string) => ({
	type: actionTypes.TREE_DELETE,
	payload: {
		treeId,
		nodeId,
		token
	}
})

const deleteSuccess = (treeId: number, nodeId: number) => ({
	type: actionTypes.TREE_DELETE_SUCCESS,
	payload: {
		treeId,
		nodeId
	}
})

const deleteFailed = (treeId: number, message: string) => ({
	type: actionTypes.TREE_DELETE_FAILED,
	payload: {
		treeId,
		message
	}
})

const addNode = (treeId: number, parentId: number, name: string, token: string) => ({
	type: actionTypes.TREE_NODE_ADD,
	payload: {
		treeId,
		parentId,
		name,
		token
	}
})

const addNodeSuccess = (treeId: number, parentId: number, nodeId: number, name: string) => ({
	type: actionTypes.TREE_NODE_ADD_SUCCESS,
	payload: {
		treeId,
		parentId,
		nodeId,
		name
	}
})

const addNodeFailed = (treeId: number, message: string) => ({
	type: actionTypes.TREE_NODE_ADD_FAILED,
	payload: {
		treeId,
		message
	}
})

const loadAssignedAttributeGroups = (treeId: number, userId: number, token: string) => ({
	type: actionTypes.TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD,
	payload: {
		treeId,
		userId,
		token
	}
})

const loadAssignedAttributeGroupsSuccess = (treeId: number, userId: number, assignedIds: number[]) => ({
	type: actionTypes.TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD_SUCCESS,
	payload: {
		treeId,
		userId,
		assignedIds
	}
})

const loadAssignedAttributeGroupsFailed = (treeId: number, userId: number, message: string) => ({
	type: actionTypes.TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD_FAILED,
	payload: {
		treeId,
		userId,
		message
	}
})

const assignAttributeGroup = (treeId: number, userId: number, attributeGroupId: number, isAdd: boolean, token: string) => ({
	type: actionTypes.TREE_ASSIGN_ATTRIBUTE_GROUP,
	payload: {
		treeId,
		userId,
		attributeGroupId,
		isAdd,
		token
	}
})

const assignAttributeGroupSuccess = (treeId: number, userId: number) => ({
	type: actionTypes.TREE_ASSIGN_ATTRIBUTE_GROUP_SUCCESS,
	payload: {
		treeId,
		userId
	}
})

const assignAttributeGroupFailed = (treeId: number, userId: number, message: string) => ({
	type: actionTypes.TREE_ASSIGN_ATTRIBUTE_GROUP_FAILED,
	payload: {
		treeId,
		userId,
		message
	}
})

const loadAssignedBalances = (treeId: number, userId: number, token: string) => ({
	type: actionTypes.TREE_ASSIGNED_BALANCES_LOAD,
	payload: {
		treeId,
		userId,
		token
	}
})

const loadAssignedBalancesSuccess = (treeId: number, userId: number, assignedIds: number[]) => ({
	type: actionTypes.TREE_ASSIGNED_BALANCES_LOAD_SUCCESS,
	payload: {
		treeId,
		userId,
		assignedIds
	}
})

const loadAssignedBalancesFailed = (treeId: number, userId: number, message: string) => ({
	type: actionTypes.TREE_ASSIGNED_BALANCES_LOAD_FAILED,
	payload: {
		treeId,
		userId,
		message
	}
})

const assignBalance = (treeId: number, userId: number, balanceId: number, isAdd: boolean, token: string) => ({
	type: actionTypes.TREE_ASSIGN_BALANCE,
	payload: {
		treeId,
		userId,
		balanceId,
		isAdd,
		token
	}
})

const assignBalanceSuccess = (treeId: number, userId: number) => ({
	type: actionTypes.TREE_ASSIGN_BALANCE_SUCCESS,
	payload: {
		treeId,
		userId
	}
})

const assignBalanceFailed = (treeId: number, userId: number, message: string) => ({
	type: actionTypes.TREE_ASSIGN_BALANCE_FAILED,
	payload: {
		treeId,
		userId,
		message
	}
})


export default {
	types: actionTypes,
	create,
	createSuccess,
	createFailed,
	load,
	loadSuccess,
	loadFailed,
	unload,
	reset,
	update,
	updateSuccess,
	updateFailed,
	delete: deleteTree,
	deleteSuccess,
	deleteFailed,
	addNode,
	addNodeFailed,
	addNodeSuccess,
	loadAssignedAttributeGroups,
	loadAssignedAttributeGroupsSuccess,
	loadAssignedAttributeGroupsFailed,
	assignAttributeGroup,
	assignAttributeGroupSuccess,
	assignAttributeGroupFailed,
	loadAssignedBalances,
	loadAssignedBalancesSuccess,
	loadAssignedBalancesFailed,
	assignBalance,
	assignBalanceSuccess,
	assignBalanceFailed
};