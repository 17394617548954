import actions from '../actions';
import { Role } from '../models';

export interface RolesState{
	list: Role[] | null,
	isLoading: boolean,
	isRoleUpdating: boolean
}

const defaultState: RolesState = {
	list: null,
	isLoading: false,

	isRoleUpdating: false
}

const roles = (state: RolesState = defaultState, action: any): RolesState => {
	switch(action.type){
		case actions.roles.types.ROLES_LOAD:
			return {...state, isLoading: true};
		case actions.roles.types.ROLES_LOAD_SUCCESS:
			const { roles } = action.payload;
			return {...state, list: roles, isLoading: false}
		case actions.roles.types.ROLES_LOAD_FAILED:
			console.error('roles failed\n%o', action.payload);
			return {...state, list: [], isLoading: false};

		case actions.roles.types.ROLES_RESET:
			return {...defaultState};

		case actions.role.types.ROLE_CREATE:
			return {...state, isRoleUpdating: true};
		case actions.role.types.ROLE_UPDATE:
			return {...state, isRoleUpdating: true};
		case actions.role.types.ROLE_DELETE:
			return {...state, isRoleUpdating: true};
		case actions.role.types.ROLE_REQUEST_SUCCESS:
			return {...state, isRoleUpdating: false, list: null};
		case actions.role.types.ROLE_REQUEST_FAILED:
			console.error('Role errro!\n%o', action.payload);
			return {...state, isRoleUpdating: false, list: null};
		}
	return state;
}

export default roles