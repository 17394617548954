import { AttributeVisibility } from "../models";

const actionTypes = {
	ATTRIBUTE_VISIBILITIES_LOAD: 'ATTRIBUTE_VISIBILITIES\\LOAD',
	ATTRIBUTE_VISIBILITIES_LOAD_SUCCESS: 'ATTRIBUTE_VISIBILITIES\\LOAD_SUCCESS',
	ATTRIBUTE_VISIBILITIES_LOAD_FAILED: 'ATTRIBUTE_VISIBILITIES\\LOAD_FAILED',
}

const load = (token: string) => ({
	type: actionTypes.ATTRIBUTE_VISIBILITIES_LOAD,
	payload: {
		token
	}
})

const loadSuccess = (visibilities: AttributeVisibility[]) => ({
	type: actionTypes.ATTRIBUTE_VISIBILITIES_LOAD_SUCCESS,
	payload: {
		visibilities
	}
})

const loadFailed = (message: string) => ({
	type: actionTypes.ATTRIBUTE_VISIBILITIES_LOAD_FAILED,
	payload: {
		message: message
	}
})

export default {
	types: actionTypes,
	load,
	loadSuccess,
	loadFailed,
};