import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { CommonPageProps, NodesState, TransactionChangeLogsFilters, TransactionChangeLogsPerTree } from '../../models';
import { UsersState } from '../../reducers/users';
import { getTreeSecurityProvider, transactionChanges as transactionChangesSettings } from '../../settings';

import TransactionChangeLogsContainer from './transactionChangeLogsContainer';

interface TransactionChangeLogsProps extends CommonPageProps{
	transactionChangeLogs: TransactionChangeLogsPerTree,
	nodes: NodesState,
	users: UsersState,
	token: string,

	loadNodeName: (nodeId: number, token: string) => void,
	loadUsers: (token: string) => void,

	loadTransactionChangeLogs: (nodeId: number, currentPage: number, pageSize: number, filters: TransactionChangeLogsFilters, token: string) => void,
	unloadTransactionChangeLogs: (nodeId: number) => void,
}

const TransactionChangeLogs = (props: TransactionChangeLogsProps) => {
		const {treeId} = useParams<{treeId: string}>();
		const {
			nodes, users,
			transactionChangeLogs,
			currentUser, token,
			loadTransactionChangeLogs, loadNodeName, loadUsers
		} = props;

		const safeId = parseInt(treeId);
		const node = nodes && nodes[safeId];
		const currentTransactionChangeLogs = transactionChangeLogs[safeId];
		const usersList = users.list;

		useEffect(() => {
			if(!currentTransactionChangeLogs || (!currentTransactionChangeLogs.list && !currentTransactionChangeLogs.isLoading && !currentTransactionChangeLogs.error)){
				const currentPage = currentTransactionChangeLogs?.currentPage || transactionChangesSettings.defaultFirstPage;
				const pageSize = currentTransactionChangeLogs?.pageSize || transactionChangesSettings.defaultPageSize;
				const filters = currentTransactionChangeLogs?.filters || {};
				loadTransactionChangeLogs(safeId, currentPage, pageSize, filters, token);
			}
			if(!node || (!node.name && !node.isNameLoading && !node.nameError)){
				loadNodeName(safeId, token);
			}
			if(!users || (!users.list && !users.isLoading)){
				loadUsers(token);
			}
		});

		const treeSecurityProvider = getTreeSecurityProvider(currentUser, safeId, nodes);

		return (
			<TransactionChangeLogsContainer
				treeId = {safeId}
				treeName = {node?.name || null}
				usersList = {usersList}
				transactionChangeLogs = {currentTransactionChangeLogs}
				currentUser = {currentUser}
				token = {token}
				treeSecurityProvider = {treeSecurityProvider}
				loadTransactionChangeLogs = {loadTransactionChangeLogs}
			/>
		)
}

export default TransactionChangeLogs;