import { all, call, put, takeEvery, takeLeading} from 'redux-saga/effects'

import {config} from '../settings'

import actions from '../actions';
import { payloads, Transaction, TransactionAuthor, TransactionNode, TransactionOperation, TransactionOperationAttribute, TransactionsFilters, TransactionType } from '../models';
import handleResponse from './handleResponse';
import moment from 'moment';

const {
	loadSuccess, loadFailed,
	createSuccess, createFailed,
	updateSuccess, updateFailed,
	cancelSuccess, cancelFailed
} = actions.transactions;
const {raise} = actions.error;

type ResponseType = {status: number, body: any} | {error: any};
type ResponsePromiseType = Promise<ResponseType>;

const extractFiltersString = (filters: TransactionsFilters): string => {
	const result = Object.entries(filters)
		.reduce((acc, cur) => {
			const newPart = cur[1] ? `&filter[${cur[0]}]=${encodeURIComponent(cur[1] || '')}` : null;
			return newPart ? acc + newPart : acc;
		}, '');
	return result;
}

const tryLoad = (payload: {treeId: number, currentPage: number, pageSize: number, filters: TransactionsFilters, token: string}): ResponsePromiseType => {
	try{
		const {treeId, currentPage, pageSize, filters, token} = payload;
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const pagination = `page=${currentPage}&size=${pageSize}`;
		const queryFilter = extractFiltersString(filters);

		const params = queryFilter.length > 0 ? `${pagination}${queryFilter}` : pagination;
		const base_url = `${config.api_url}/tree/${treeId}/transactions`;

		return fetch(`${base_url}?${params}`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractAttribute = (entry: any): TransactionOperationAttribute => {
	return {
		attributeId: entry.attributeId,
		attributeName: entry.attributeName,
		value: entry.value,
		valueId: entry.valueId || null,
		type: entry.type
	}
}

const extractNode = (entry: any): TransactionNode | null => {
	if(!entry){
		return null
	}
	return {
		id: entry.id,
		name: entry.name
	}
}

const extractOperation = (entry: any): TransactionOperation => {
	return {
		id: entry.id,
		node: extractNode(entry.node),
		correspondent: extractNode(entry.correspondent),
		value: entry.value,
		attributes: (entry.attributes || []).map(extractAttribute)
	}
}

const extractTransactionType = (entry: any): TransactionType => {
	return {
		id: entry.id,
		name: entry.name,
		slug: entry.slug
	}
}

const extractDate = (entry: any): Date => {
	const dateStr = entry.date == null ? entry : entry.date.replace(' ', 'T');
	const result = new Date(dateStr);
	return result;
}

const extractAuthor = (entry: any): TransactionAuthor => {
	return {
		id: entry.id,
		name: entry.name
	}
}

const extractStatus = (entry: any): string | undefined => {
	return entry?.slug;
}

const extractTransaction = (entry: any): Transaction => {
	return {
		id: entry.id,
		author: extractAuthor(entry.author),
		node: extractNode(entry.node),
		correspondent: extractNode(entry.correspondent),
		comment: entry.comment,
		createdAt: extractDate(entry.createdAt),
		customDate: extractDate(entry.customDate),
		type: extractTransactionType(entry.type),
		operations: (entry.operations || []).map(extractOperation),
		status: extractStatus(entry?.status)
	}
}

const addAttributesFrom = (formData: FormData, entry: payloads.CreateTransactionEntryType, offset?: number) => {
	const attributeValues = Object.entries(entry.attributeValues || {});
	attributeValues
		.forEach(([attrId, value], index) => {
			const attributeIndex = index + (offset || 0);
			formData.append(`attributes[${attributeIndex}][attributeId]`, attrId);
			formData.append(`attributes[${attributeIndex}][nodeId]`, `${entry.nodeId}`);
			formData.append(`attributes[${attributeIndex}][balanceId]`, `${entry.balanceId}`);
			if(value){
				if('id' in value){
					formData.append(`attributes[${attributeIndex}][valueId]`, `${value.id}`);
				}else{
					formData.append(`attributes[${attributeIndex}][value]`, value.value);
				}
			}
		});
	return attributeValues.length;
}

const tryCreate = (payload: payloads.CreateTransactionPayloadType & {token: string}): ResponsePromiseType => {
	try{
		const {value, externalValue, customDate, transactionTypeId, source, target, comment, token} = payload;
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);

		const formData = new FormData();
		const customDateString = customDate
			.format("DD.MM.yyyy");
		formData.append("value", `${value}`);
		formData.append("customDate", customDateString);
		formData.append("transactionTypeId", `${transactionTypeId}`);
		if(comment){
			formData.append("comment", comment);
		}
		if(externalValue){
			formData.append("externalValue", `${externalValue}`);
		}
		const offset = addAttributesFrom(formData, source);
		if(target){
			formData.append('externalNodeId', `${target.nodeId}`);
			formData.append('externalBalanceId', `${target.balanceId}`);
			addAttributesFrom(formData, target, offset);
		}
		return fetch(`${config.api_url}/tree/${source.nodeId}/balances/${source.balanceId}/transaction/`, {
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryUpdate = (payload: payloads.UpdateTransactionPayloadType & {token: string}): ResponsePromiseType => {
	try{
		const {
			transactionId,
			changes,
			token
		} = payload;
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);

		const formData = new FormData();
		if(changes.comment !== undefined){
			formData.append('comment', changes.comment || '');
		}
		if(changes.customDate !== undefined){
			const customDate = moment(changes.customDate)
				.format("DD.MM.yyyy");
			formData.append('customDate', customDate);
		}

		return fetch(`${config.api_url}/transactions/${transactionId}`,{
			method: 'POST',
			headers: headers,
			body: formData
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}));
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryCancel = (payload: {treeId: number, transactionId: number, token: string}): ResponsePromiseType => {
	try{
		const {
			treeId,
			transactionId,
			token
		} = payload;
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);

		return fetch(`${config.api_url}/tree/${treeId}/transactions/${transactionId}`,{
			method: 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}));
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const loadSaga = function*(action: any){
	const {treeId, currentPage, pageSize, filters} = action.payload;
	try{
		const response: ResponseType = yield call(tryLoad, action.payload);
		yield* handleResponse(
			response,
			(body: any) => loadSuccess(treeId, currentPage, pageSize, filters, body.data.map(extractTransaction)),
			(message: any) => loadFailed(treeId, currentPage, pageSize, filters, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(treeId, currentPage, pageSize, filters, 'An error occurred'));
		return;
	}
}

const createSaga = function*(action: any){
	const {payload} = action;
	console.log(payload)
	try{
		const response: ResponseType = yield call(tryCreate, payload);
		yield* handleResponse(
			response,
			() => {
				const treeIdsToRefresh = payload.target
					? [payload.source.treeId, payload.target.treeId]
					: [payload.source.treeId]

				return createSuccess(treeIdsToRefresh)
			},
			(message: any) => createFailed([payload.source.treeId], message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed([payload?.source?.treeId], 'An error occurred'));
		return;
	}
}

const updateSaga = function*(action: any){
	const {payload} = action;
	const {treeId, transactionId} = payload;
	try{
		const response: ResponseType = yield call(tryUpdate, payload);
		yield* handleResponse(
			response,
			() => updateSuccess(treeId, transactionId),
			(message: any) => updateFailed(treeId, transactionId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed(treeId, transactionId, 'An error occurred'));
		return;
	}
}

const cancelSaga = function*(action: any){
	const {payload} = action;
	const {treeId, transactionId} = action?.payload as {treeId: number, transactionId: number};
	try{
		const response: ResponseType = yield call(tryCancel, payload)
		yield* handleResponse(
			response,
			() => cancelSuccess(treeId, transactionId),
			(message: any) => cancelFailed(treeId, transactionId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(cancelFailed(treeId, transactionId, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield all([
		takeLeading(actions.transactions.types.TRANSACTIONS_LOAD, loadSaga),
		takeEvery(actions.transactions.types.TRANSACTION_CREATE, createSaga),
		takeEvery(actions.transactions.types.TRANSACTION_UPDATE, updateSaga),
		takeEvery(actions.transactions.types.TRANSACTION_CANCEL, cancelSaga)
	]);
}

export default watch;
