import { TransactionsTableState } from "../models";

const actionTypes = {
	TRANSACTIONS_CONTAINER_STATE_SAVE: 'TRANSACTIONS_CONTAINER_STATE\\SAVE',
}

const save = (treeId: number, transactionsState: TransactionsTableState) => ({
	type: actionTypes.TRANSACTIONS_CONTAINER_STATE_SAVE,
	payload: {
		treeId,
		transactionsState
	}
})

export default {
	types: actionTypes,
	save
};