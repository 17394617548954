import { all, call, put, takeEvery, takeLeading} from 'redux-saga/effects';

import {config, site} from '../settings';
import actions from '../actions';
import history from '../history';

import { Tree } from '../models';
import handleResponse from './handleResponse';

interface TreeInResponse {
	node: {
		id: number,
		name?: string
	},
	unifying?: boolean,
	children: TreeInResponse[]
}
type ResponseType = {status: number, body: {data?: TreeInResponse, error?: string}} | {error: any}
type ResponsePromise = Promise<ResponseType>

const {
	loadSuccess, loadFailed,
	createSuccess, createFailed,
	updateSuccess, updateFailed,
	deleteSuccess, deleteFailed,
	addNodeSuccess, addNodeFailed,
	loadAssignedAttributeGroupsSuccess, loadAssignedAttributeGroupsFailed,
	assignAttributeGroupSuccess, assignAttributeGroupFailed,
	loadAssignedBalancesSuccess, loadAssignedBalancesFailed,
	assignBalanceSuccess, assignBalanceFailed
} = actions.tree;
const {raise} = actions.error;

const { load: loadTrees } = actions.trees;

const tryDeleteNode = (id: number, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);

		return fetch(`${config.api_url}/nodes/${id}`, {
			method: 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}, reason => {
			return {
				error: reason
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryCreateOrUpdateNode = (parentId: number | null, id: number | null, name: string, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		const body = new FormData();
		body.append('name', name);
		if(parentId != null){
			body.append('parent_id', `${parentId}`);
		}
		const url = id == null ?
			`${config.api_url}/nodes/`:
			`${config.api_url}/nodes/${id}`;

		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: body
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}, reason => {
			return {
				error: reason
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryLoadTree = (id: number, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		return fetch(`${config.api_url}/tree/${id}`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}, reason => {
			return {
				error: reason
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryLoadAssignedAttributeGroups = (treeId: number, userId: number, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		return fetch(`${config.api_url}/users/${userId}/tree/${treeId}/attribute-groups`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}, reason => {
			return {
				error: reason
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryAssignAttributeGroup = (treeId: number, userId: number, attributeGroupId: number, isAdd: boolean, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		return fetch(`${config.api_url}/nodes/${treeId}/attributes/groups/${attributeGroupId}/users/${userId}`, {
			method: isAdd ? 'POST' : 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}, reason => {
			return {
				error: reason
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryLoadAssignedBalances = (treeId: number, userId: number, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		return fetch(`${config.api_url}/users/${userId}/tree/${treeId}/balances`, {
			method: 'GET',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}, reason => {
			return {
				error: reason
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const tryAssignBalance = (treeId: number, userId: number, balanceId: number, isAdd: boolean, token: string): ResponsePromise => {
	try{
		const headers = new Headers([["Authorization", `Bearer ${token}`]]);
		return fetch(`${config.api_url}/tree/${treeId}/balances/${balanceId}/users/${userId}`, {
			method: isAdd ? 'POST' : 'DELETE',
			headers: headers
		}).then(response => {
			return response.json().then(json => ({
				status: response.status,
				body: json
			}))
		}, reason => {
			return {
				error: reason
			}
		});
	}catch(ex){
		return new Promise((resolve) => resolve({error: ex}));
	}
}

const extractTree = (entry: TreeInResponse): Tree => {
	const result: Tree = {
		...entry.node,
		unifying: entry.unifying,
		key: entry.node.id
	}
	if(entry.children && entry.children.length > 0){
		result.children = entry.children.map(c => extractTree(c));
	}
	return result;
}

const loadTreeSaga = function*(action: any){
	const {id, token} = action.payload;
	try{
		const response = yield call(tryLoadTree, id, token);
		if(response.error){
			yield put(loadFailed(id, response.error.message));
			return;
		}
		if(response.body.error){
			yield put(loadFailed(id, response.body.error))
			return;
		}
		const tree = extractTree(response.body.data);
		yield put(loadSuccess(id, tree));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadFailed(id, 'An error occurred'));
		return;
	}
}

const createTreeSaga = function*(action: any){
	const {name, token} = action.payload;
	try{
		const response = yield call(tryCreateOrUpdateNode, null, null, name, token);
		if(response.error){
			yield put(createFailed(response.error.message));
			return;
		}
		if(response.body?.error){
			yield put(createFailed(response.body.error));
			return;
		}
		yield put(loadTrees(token));
		const {id} = response.body;
		yield call(history.push, `${site.trees.url}/${id}`);
		yield put(createSuccess());
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(createFailed('An error occurred'));
		return;
	}
}

const updateTreeSaga = function*(action: any){
	const {treeId, nodeId, name, token} = action.payload;
	try{
		const response = yield call(tryCreateOrUpdateNode, null, nodeId, name, token);
		if(response.error){
			yield put(updateFailed(treeId, response.error.message));
			return;
		}
		if(response.body?.error){
			yield put(updateFailed(treeId, response.body.error));
			return;
		}
		if(treeId === nodeId){
			yield put(loadTrees(token));
		}
		yield put(updateSuccess(treeId, nodeId, name));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(updateFailed(treeId, 'An error occurred'));
		return;
	}
}

const addNodeSaga = function*(action: any){
	const {treeId, parentId, name, token} = action.payload;
	try{
		const response = yield call(tryCreateOrUpdateNode, parentId, null, name, token);
		if(response.error){
			yield put(addNodeFailed(treeId, response.error.message));
			return;
		}
		if(response.body?.error){
			yield put(addNodeFailed(treeId, response.body?.error));
			return;
		}
		yield put(addNodeSuccess(treeId, parentId, response.body.id, name));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(addNodeFailed(treeId, 'An error occurred'));
		return;
	}
}

const deleteTreeSaga = function*(action: any){
	const {treeId, nodeId, token} = action.payload;
	try{
		const response = yield call(tryDeleteNode, nodeId, token);
		if(response.error){
			yield put(deleteFailed(treeId, response.error.message));
			return;
		}
		if(response.body?.error){
			yield put(deleteFailed(treeId, response.body.error));
			return;
		}
		if(treeId === nodeId){
			yield put(loadTrees(token));
			yield call(history.push, site.trees.url);
		}
		yield put(deleteSuccess(treeId, nodeId));
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(deleteFailed(treeId, 'An error occurred'));
		return;
	}
}

const loadAssignedAttributeGroupsSaga = function*(action: any){
	const {treeId, userId, token} = action.payload;
	try{
		const response = yield call(tryLoadAssignedAttributeGroups, treeId, userId, token);
		yield* handleResponse(
			response,
			(body: any) => loadAssignedAttributeGroupsSuccess(treeId, userId, body.data?.map((e: any) => e.id)),
			(message: string) => loadAssignedAttributeGroupsFailed(treeId, userId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadAssignedAttributeGroupsFailed(treeId, userId, 'An error occurred'));
		return;
	}
}

const assignAttributeGroupSaga = function*(action: any){
	const {treeId, userId, attributeGroupId, isAdd, token} = action.payload;
	try{
		const response = yield call(tryAssignAttributeGroup, treeId, userId, attributeGroupId, isAdd, token);
		yield* handleResponse(
			response,
			() => assignAttributeGroupSuccess(treeId, userId),
			(message: string) => assignAttributeGroupFailed(treeId, userId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(assignAttributeGroupFailed(treeId, userId, 'An error occurred'));
		return;
	}
}

const extractIds = (entry: any) => entry.id;

const loadAssignedBalancesSaga = function*(action: any){
	const {treeId, userId, token} = action.payload;
	try{
		const response = yield call(tryLoadAssignedBalances, treeId, userId, token);
		yield* handleResponse(
			response,
			(body: {data: any[]}) => loadAssignedBalancesSuccess(treeId, userId, body.data.map(entry => extractIds(entry))),
			(message: string) => loadAssignedBalancesFailed(treeId, userId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(loadAssignedBalancesFailed(treeId, userId, 'An error occurred'));
		return;
	}
}

const assignBalanceSaga = function*(action: any){
	const { treeId, userId, balanceId, isAdd, token} = action.payload;
	try{
		const response = yield call(tryAssignBalance, treeId, userId, balanceId, isAdd, token);
		yield* handleResponse(
			response,
			() => assignBalanceSuccess(treeId, userId),
			(message: string) => assignBalanceFailed(treeId, userId, message)
		)
	}catch(ex){
		yield put(raise(`${ex}`));
		console.error((ex as any)?.stack);
		yield put(assignBalanceFailed(treeId, userId, 'An error occurred'));
		return;
	}
}

const watch = function*() {
	yield all([
		takeEvery(actions.tree.types.TREE_CREATE, createTreeSaga),
		takeEvery(actions.tree.types.TREE_UPDATE, updateTreeSaga),
		takeEvery(actions.tree.types.TREE_DELETE, deleteTreeSaga),

		takeEvery(actions.tree.types.TREE_NODE_ADD, addNodeSaga),

		takeLeading(actions.tree.types.TREE_LOAD, loadTreeSaga),
		takeLeading(actions.tree.types.TREE_ASSIGNED_ATTRIBUTE_GROUPS_LOAD, loadAssignedAttributeGroupsSaga),
		takeEvery(actions.tree.types.TREE_ASSIGN_ATTRIBUTE_GROUP, assignAttributeGroupSaga),

		takeLeading(actions.tree.types.TREE_ASSIGNED_BALANCES_LOAD, loadAssignedBalancesSaga),
		takeEvery(actions.tree.types.TREE_ASSIGN_BALANCE, assignBalanceSaga)
	]);
}

export default watch;